<template lang="pug">
  .cost-centers-filter
    span.filter-title {{ $t('common.order_by_category') }}
    el-select(
      v-model='selectedOrderByCategory'
      filterable
      :placeholder="$t('common.select_order_category')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='category in categories'
        :key='category.id'
        :label='category.name'
        :value="category.value !== '' ? category.id : category.value"
      )
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "OrderByDropdown",

  props: {
    value: {
      type: String,
      default: "",
    },

    view: {
      type: String,
      required: false,
    },
  },

  computed: {
    selectedOrderByCategory: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

  },

  data() {
    return {
      categories: [],
    };
  },

  async mounted() {
    await this.handleGetCostCenters()
  },

  methods: {
    async handleGetCostCenters() {
      this.categories.push({
          id: "0",
          name: "All",
          value: "",
      });
      if (this.view === 'bikes') {
        this.categories.push({
          id: "1",
          name: "Ultima mentenanță",
          value: "1",
        });
        this.categories.push({
          id: "2",
          name: "Următoarea mentenanță (crescător)",
          value: "2",
        });
        this.categories.push({
          id: "3",
          name: "Următoarea mentenanță (descrescător)",
          value: "3",
        });
        this.categories.push({
          id: "4",
          name: "Ultima cursă",
          value: "4",
        });
      }
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
