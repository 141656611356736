<template lang="pug">
  .forgot-password-view
    .form
      .background-wrapper
        img.header-img(src="@assets/auth/header.svg")
        el-row.d-flex.justify-content-center
          el-col(:span="16")
            el-form.login-form(
              :model="form"
              ref="ForgotPasswordForm"
              :rules="forgotPasswordViewValidations"
              @submit.native.prevent
              @keyup.enter.native="handleForgotPassword('ForgotPasswordForm')"
            )
              h2 {{ $t('auth.forgot_password') }}
              h4.mt-4 {{ $t('auth.auth_forgot_message') }}
              el-form-item.mt-4(
                prop="email"
                :label="$t('common.email_address')"
              )
                el-input(
                  v-model="form.email"
                  name="email"
                )
            el-row.text-center
              el-button.dark-blue-btn(
                @click="handleForgotPassword('ForgotPasswordForm')"
                :disabled="disabledButton"
                :loading="loading"
              ) {{ $t('auth.send') }}
    .background
</template>

<script>
import { mapActions } from "vuex";
import { customValidations } from "@utils/customValidators";
import { authenticationViewValidations } from "@utils/formValidations/authenticationViewValidations";

export default {
  name: "ForgotPasswordView",
  mixins: [authenticationViewValidations, customValidations],

  data() {
    return {
      form: {
        email: "",
      },
      loading: false,
    };
  },

  computed: {
    disabledButton() {
      return this.form.email === "";
    },
  },

  methods: {
    ...mapActions("user", ["forgotPasswordRequest"]),

    handleForgotPassword(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const payload = this.form;
          this.forgotPasswordRequest(payload.email)
            .then(() => {
              this.$router.push("/login");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.forgot-password-view{
  display: flex;

  h4 {
    color: rgba(6, 16, 88, 0.6) !important;
    font-family: Avenir, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 100px;
  }
}
</style>
