<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='station_logs'
    @selection-change="$emit('selectionChange', $event)"
  )
    //- @TODO: uncomment if we need this in the future
    //- el-table-column(
    //-   type="selection"
    //-   width="55"
    //- )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='docking_station_name'
      :label="$t('label.docking_name')"
      width="250"
    )
      template(slot-scope='scope')
        span {{ scope.row.docking_station_name }}    
    el-table-column(
      prop='description'
      :label="$t('label.description')"
    )
      template(slot-scope='scope')
        span {{ scope.row.description }}    
    el-table-column(
      prop='created_at'
      :label="$t('label.date')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='created_at'
      :label="$t('label.date_created_at')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.updated_at) }}    
    //- @TODO: uncomment if we need these in the future
    //- el-table-column(
    //-   prop='actions'
    //-   :label="$t('label.actions')"
    //- )
    //-   template(slot-scope='scope')
    //-     .d-flex
    //-       img.cursor-pointer.mr-2(
    //-         v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['show'])"
    //-         src="@assets/preview.svg"
    //-         @click="handleViewDockingStationsLog(scope.row)"
    //-       )
    //-       img.cursor-pointer.ml-2(
    //-         v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['destroy'])"
    //-         src="@assets/actions/delete.svg"
    //-         @click="handleDeleteDockingStationsLogDialog(scope.row)"
    //-       )
</template>

<script>
import { dynamicViewsConstants } from "@src/constants";
import {
  filterUtils,
  MAX_FIELD_LENGTH,
  trimTableField,
} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "StationLogsViewTable",

  mixins: [general, filterUtils],

  props: {
    station_logs: {
      type: Array,
    },
  },

  data() {
    return {
      dynamicViewsConstants,
      MAX_FIELD_LENGTH,
      trimTableField,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteDockingStationsLog();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "collectionDataToDelete",
      "dialogData",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewDockingStationsLogDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setAllowDeletion",
      "setDataToDelete",
      "setLoading",
    ]),

    ...mapMutations("DockingStationsLog", ["setDockingStationsLog"]),

    ...mapActions("DockingStationsLog", ["deleteDockingStationsLog"]),

    handleDeleteDockingStationsLog() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };
        this.deleteDockingStationsLog(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteDockingStationsLogDialog(tariff) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: this.$t("station_logs.validations.delete_title"),
        dialogMessage: this.$t("station_logs.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(tariff);
    },

    handleViewDockingStationsLog(tariff) {
      this.setDockingStationsLog(tariff);
      this.showViewDockingStationsLogDialog();
    },
  },
};
</script>
