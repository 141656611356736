import axios from "axios";
import messageUtils from "@utils/messageUtils";

import Jsona from "jsona";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  shouldFetchStatistics: false,
  shouldFetchTokens: false,
  technicalSettings: {}
};

export const getters = {
  formattedTechnicalSettings(state) {
    return {
      ram_usage_percentage: state.technicalSettings.ram_usage_percentage,
      cpu_usage_percentage: state.technicalSettings.cpu_usage_percentage,
      disk_usage_percentage: state.technicalSettings.disk_usage_percentage,
      processor_name: state.technicalSettings.processor_name,
      server_uptime_unit: state.technicalSettings.server_uptime.split(" ")[1],
      server_uptime:  Number(state.technicalSettings.server_uptime.split(" ")[0]),
      no_of_weboscket_connections: state.technicalSettings.no_of_weboscket_connections,
    }
  }
}

export const actions = {
  async getTokens({commit}, payload) {
    try {
      const response = await axios.get(`/v1/user_access_tokens${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTechnicalStats({commit}, payload) {
    try {
      const response = await axios.get(`/v1/technical_stats`);
      return commit('setTechnicalSettings', dataFormatter.deserialize(response.data))
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async revokeToken({commit}, id) {
    try {
      await axios.post(`/v1/user_access_tokens/${id}/revoke`);
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};


export const mutations = {
  setShouldFetchStatistics(state, value) {
    state.shouldFetchStatistics = value
  },

  setShouldFetchTokens(state, value) {
    state.shouldFetchTokens = value
  },

  setTechnicalSettings(state, payload) {
    state.technicalSettings = payload
  }
};
