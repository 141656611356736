<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='firmwares'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='signature'
      :label="$t('firmwares.signature')"
    )
      template(v-slot='scope')
        //- dblclick
        span.text-underline.cursor-pointer(@click="copyToClipBoard(scope.row.file_url)") {{ scope.row.signature }}
        br
        span.text-underline.cursor-pointer(@click="copyToClipBoard(scope.row.short_file_url)") {{ scope.row.signature + "(short link)" }}
    el-table-column(
      prop='mcu_type'
      :label="$t('firmwares.mcu_type')"
      width="130"
    )
      template(v-slot='scope')
        span {{ scope.row.mcu_type }}
    el-table-column(
      prop='compiled_datetime'
      :label="$t('firmwares.compiled_datetime')"
    )
      template(v-slot='scope')
        span {{ scope.row.compiled_date + " " + scope.row.compiled_time }}
    el-table-column(
      prop='description'
      :label="$t('firmwares.description')"
    )
      template(v-slot='scope')
        span {{ scope.row.description }}
    el-table-column(
      prop='download'
      :label="$t('firmwares.download')"
      width="125"
    )
      template(v-slot='scope')
        button.begin-maintenance-btn.cursor-pointer(
          @click="handleDownload(scope.row.file_url)"
        ) {{ $t('firmwares.download') }}
    //- el-table-column(
    //-   prop='upload'
    //-   :label="$t('firmwares.upload')"
    //-   width="100"
    //- )
    //-   template(v-slot='scope')
    //-     button.begin-maintenance-btn.cursor-pointer(
    //-       @click="handleUpload(scope.row)"
    //-     ) {{ $t('firmwares.upload') }}
    el-table-column(
      prop='updated'
      :label="'Actualizat'"
    )
      template(v-slot='scope')
        span {{ computeDateTimeFormat(scope.row.updated_at) }}
    el-table-column(
      prop='comment'
      :label="'Comentariu'"
    )
      template(v-slot='scope')
        span {{ scope.row.comment }}
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "FirmwaresViewTable",
  mixins: [filterUtils, general],

  props: {
    firmwares: {
      type: Array,
    },
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
      ...mapMutations("dialog", [
      "showUploadFirmwareDialog"
    ]),

    async handleUpload() {
      this.showUploadFirmwareDialog();
    },

    async handleDownload(firmwareLink){
      const link = document.createElement('a');
      link.href = firmwareLink;
      const filename = firmwareLink.split('/').pop();
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    copyToClipBoard(textToCopy) {
      const tmpTextField = document.createElement("textarea")
      tmpTextField.textContent = textToCopy
      tmpTextField.setAttribute("style","position:absolute; right:200%;")
      document.body.appendChild(tmpTextField)
      tmpTextField.select()
      tmpTextField.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand("copy")
      tmpTextField.remove();

      this.$message({
        type: "success",
        message: "Link copiat",
      });
    },

  },
};
</script>

