<template lang="pug">
  .rent-tariffs-view
    h3 {{ $t('rent_tariffs.rent_tariffs') }}
      .rent-tariff-top-page-wrapper(:style="{display: showHowToRead ? 'block' : 'flex'}").justify-space-between
        .how-to-read-pricing(:style="{display: showHowToRead ? 'block' : 'flex'}")
          span(@click="showHowToRead = !showHowToRead") {{ $t('rent_tariffs.how_to_read_pricing_tariff.how_to_read_pricing_tariff') }}
            i(:style="{transform: showHowToRead ? 'rotate(180deg)' : 'rotate(0deg)'}").el-icon-caret-bottom
          .content(v-if="showHowToRead")
            p ⏳ <strong>{{ $t('rent_tariffs.initialFee.initialFee') }}</strong> - {{ $t('rent_tariffs.initialFee.info') }}
            p ⌛️ <strong>{{ $t('rent_tariffs.secondaryFee.secondaryFee') }}</strong> - {{ $t('rent_tariffs.secondaryFee.info') }}
            p {{ $t('rent_tariffs.how_to_read_pricing_tariff.the_initial_fee') }}
            p ❗️ {{ $t('rent_tariffs.how_to_read_pricing_tariff.once_a_time_block') }}
    .filters
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .rent-tariffs-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['by_keyword'])"
        )
          el-button.dark-blue-btn(
            @click="showAddRentTariffDialog"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['create'])"
          )
            h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          RentTariffsViewTable(
            v-loading="loading"
            :rent_tariffs="filteredRentTarrifs"
          )
    Pagination(
      :view="'tariffs'"
      :search="search"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetTariffs"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { roles } from "@utils/roles";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import Pagination from "@components/_shared/Pagination";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import RentTariffsViewTable from "@components/rent_tariffs/RentTariffsViewTable";

export default {
  mixins: [general],
  components: { SearchWithButtons, Pagination, FilterCostCenterDropdown, RentTariffsViewTable },

  data() {
    return {
      rent_tariffs: [],
      search: "",
      selectedCostCenterId: "",
      selectedCostCenter: null,
      loading: false,
      showHowToRead: false,
      roles,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    filteredRentTarrifs(){
      if(!this.selectedCostCenterId){
        return this.rent_tariffs;
      }

      return this.rent_tariffs.filter(rentTarrif =>
          rentTarrif.cost_center.id === this.selectedCostCenterId
      )}
  },

  methods: {
    ...mapMutations("dialog", [
      "showAddRentTariffDialog",
    ]),

    handleGetTariffs(data) {
      this.rent_tariffs = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.rent-tariffs-view {
  .how-to-read-pricing {
    padding: 16px;
    background: #fff;
    border-radius: 14px;
    align-items: center;

    span {
      display: block;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;
      color: #1c2567;

      i {
        margin-left: 16px;
      }
    }

    .content {
      margin-top: 16px;

      p {
        margin: 0;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        color: #000;
        font-weight: 300;
        margin-bottom: 8px;
        font-family: $avenir-next-regular;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
