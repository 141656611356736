<template lang="pug">
  .chart
    apexchart(
      type="bar"
      :height="280"
      :weight="230"
      :options="chartPowerOptions"
      :series="chartTransactions"
      :key="chartComponentKey"
    )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import { chartPowerOptions } from "@utils/generalUtils";
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import Statistic from "@components/_shared/Statistic";

export default {
  name: "ChartPower",
  mixins: [filterUtils],
  props: {
    chartData: {
      type: Array,
      default: [],
    },

    chartType: {
      type: String,
    },

    statisticTitle: {
      type: String,
    }
  },

  components: {
    apexchart: VueApexCharts,
    Statistic,
  },

  watch: {
    chartData() {
      this.mapTransactionsForChart();
    },
  },

  data() {
    return {
      chartTransactions: [
        {
          name: this.$t("trackers.power"),
          data: [],
        },
      ],

      chartComponentKey: 0,
      chartPowerOptions: chartPowerOptions,
    };
  },

 created() {
    this.mapTransactionsForChart();
  },

  methods: {
    mapTransactionsForChart() {
      if (this.chartType === "powerVbat") {
        this.computepowerVbatInfos('Baterie');
      } else if (this.chartType === "powerVin") {
        this.computepowerVbatInfos('Intrare');
      }
    },

    computepowerVbatInfos(chartTitle) {
      this.chartComponentKey += 1;

      this.chartPowerOptions.xaxis.categories = [];
      this.chartTransactions[0].data.splice(0);
    
      const mappedDays = [];

      if (this.chartData.length > 0) {
        this.chartData.forEach((power) => {
          const powerDate = new Date(power.date);
          let timeFormatter = new Intl.DateTimeFormat('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          });

          let dateFormatter = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
          });

          let timePart = timeFormatter.format(powerDate);
          let datePart = dateFormatter.format(powerDate);
          mappedDays.push(`${timePart} ${datePart}`);
          this.chartTransactions[0].data.push(power.value);

          this.chartPowerOptions = {
            ...this.chartPowerOptions,
            ...{
              xaxis: {
                categories: mappedDays, 
              },

              yaxis: {
                title: {
                  text: chartTitle, 
                },
              },
            },
          };
        })
      }     
    },
  },

};
</script>
