<template lang="pug">
  .login-view
    .form
      .background-wrapper
        img.header-img(src="@assets/auth/header.svg")
        el-row.d-flex.justify-content-center
          el-col(:span="16")
            el-form.login-form(
              :model="form"
              ref="LoginForm"
              :rules="loginViewValidations"
              @submit.native.prevent
              @keyup.enter.native="handleLogin('LoginForm')"
            )
              h2 {{ $t('auth.login') }}
              el-form-item.mt-4(
                prop="email"
                :label="$t('common.email_address')"
              )
                el-input(
                  v-model="form.email"
                  name="email"
                )
              el-form-item(
                prop="password"
                :label="$t('label.password')"
              )
                el-input(
                  v-model="form.password"
                  :show-password="true"
                  name="password"
                  type="password"
                )
              el-row.text-right
                a(@click="goToForgotPassword") {{ $t('auth.forgot_password') }}

            el-row.text-center
              el-button.dark-blue-btn(
                @click="handleLogin('LoginForm')"
                :disabled="disabledButton"
                :loading="loading"
              ) {{ $t('auth.login') }}
            div.text-center.mt-5 {{ 'Version: ' + versionText }}
    .background
</template>

<script>
import { mapActions } from "vuex";
import { authenticationViewValidations } from "@utils/formValidations/authenticationViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "LoginView",
  mixins: [authenticationViewValidations, customValidations],

  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },

  computed: {
    disabledButton() {
      return this.form.email === "" || this.form.password === "";
    },

    versionText() {
      return process.env.VUE_APP_VERSION;
    }
  },

  methods: {
    ...mapActions({
      login: "auth/login",
    }),

    handleLogin(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          const payload = this.form;
          this.login(payload)
              .then(() => {
                this.$router.push('/settings');
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    goToForgotPassword() {
      this.$router.push({ name: "forgot_password" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.login-view {
  display: flex;

  a {
    color: $dark-blue;
    font-family: "Avenir Next", sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
      flex-direction: column;
      margin-top: 100px;
  }
}
</style>
