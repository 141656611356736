<template lang="pug">
  el-menu-item(
    index='/cost_centers'
    v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.COST_CENTER, ['index'])"
  )
    .img-wrapper
      img(src="@src/assets/sidebar/cost_centers.svg")
    router-link.sub-menu-link(to="/cost_centers")
      span  {{ $t('sidebar.cost_centers') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "CostCentersItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
