<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='users'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='first_name'
      :label="$t('label.first_name')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.first_name && scope.row.first_name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.first_name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.first_name) }}
        span(v-if="scope.row.first_name && scope.row.first_name.length <= MAX_FIELD_LENGTH") {{ scope.row.first_name }}
    el-table-column(
      prop='last_name'
      :label="$t('label.last_name')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.last_name && scope.row.last_name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.last_name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.last_name) }}
        span(v-if="scope.row.last_name && scope.row.last_name.length <= MAX_FIELD_LENGTH") {{ scope.row.last_name }}
    //- width="350px"
    el-table-column(
      prop='email'
      :label="$t('common.email_address')"

    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.email.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.email"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.email) }}
        span(v-if="scope.row.email.length <= MAX_FIELD_LENGTH") {{ scope.row.email }}
    el-table-column(
      prop='phone_number'
      :label="$t('common.phone_number')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.phone_number && scope.row.phone_number.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.phone_number"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.phone_number) }}
        span(v-if="scope.row.phone_number && scope.row.phone_number.length <= MAX_FIELD_LENGTH") {{ scope.row.phone_number }}
    el-table-column(
      v-if="hideIcon()"
      prop='cost_center_id'
      :label="$t('common.cost_center_name')"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer(@click="goToWIthId('cost_centers', '')") {{ scope.row.cost_center_names.toString() }}
    el-table-column(
      prop='cost_center_id'
      :label="'Rol: Centru de cost'"
    )
      template(slot-scope='scope')
        span {{ handleUserRolesAndCostCenters(scope.row.user_roles_and_cost_centers.data)}}
    //- v-if="hideIcon()"
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          //- img.cursor-pointer.mr-2(
          //-   v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['show'])"
          //-   src="@assets/preview.svg"
          //-   @click="goToWIthId('users', scope.row.id)"
          //- )
          //-  && hideIcon()
          img.cursor-pointer.mr-2(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['update', 'show'])"
            src="@assets/actions/edit.svg"
            @click="goToWIthId('users/edit_user', scope.row.id)"
          )
          //- i.el-icon-refresh-left.cursor-pointer.mr-2(
          //-   v-loading="loadingResetUserPassword"
          //-   @click="handleResetUserPassword(scope.row.id)"
          //-   style="font-size: 35px; margin-right: 0"
          //- )
          img.cursor-pointer.mr-2(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['destroy'])"
            src="@assets/actions/delete.svg"
            @click="handleDeleteUserDialog(scope.row)"
          )
</template>

<script>
import { dynamicViewsConstants } from "@src/constants";
import { MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { roles } from "@utils/roles";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";

export default {
  name: "UsersViewTable",
  mixins: [routerUtils, general],

  props: {
    users: {
      type: Array,
    },
  },

  data() {
    return {
      loadingResetUserPassword: false,

      dynamicViewsConstants,
      MAX_FIELD_LENGTH,
      roles,
      trimTableField,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteUser();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "allowDeletion",
      "dataToDelete",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapActions("user", ["deleteUser", "getUsers", "resetUserPassword"]),

    hideIcon(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    async handleDeleteUser() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteUser(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteUserDialog(user) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("users.validations.delete_title")} ${
          user.first_name
        } ${user.last_name}`,
        dialogMessage: this.$t("users.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(user);
    },

    async handleResetUserPassword(id) {
      this.loadingResetUserPassword = true;
      await this.resetUserPassword(id);
      this.loadingResetUserPassword = false;
    },

    handleUserRolesAndCostCenters(roleAndCostCenter){
      var ddd = '';
      roleAndCostCenter.forEach ((element) => {
        ddd+=" ";
      if (element.attributes.role_info){
        ddd += element.attributes.role_info.name;
      }
      if (element.attributes.role_info && element.attributes.cost_center_info != null){
        ddd += ': ' + element.attributes.cost_center_info.name;
      }
      });
      return ddd;
    }
  },
};
</script>

<style lang="scss" scoped>
.grid-buttons {
  display: inline-grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }

  i.el-icon-refresh-left {
    margin: auto 0;
  }
}
</style>
