<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='bikes'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}

    el-table-column(
      prop='status'
      :label="$t('common.status')"
    )
      template(v-slot='scope')
        .status-wrapper.d-flex.align-items-center
          .switch(@click="handleSwitchBikeStatus(scope.row)")
            el-switch(
              v-if="!isCurrentUserAdmin()"
              :key="switchKey"
              :value="scope.row.status === 'available_in_station'"
              :active-color="'#13ce66'"
              :inactive-color="scope.row.status === 'under_maintenance' ? '#ffd666' : (scope.row.status === 'to_be_activated' ? '#d3d3d3' : '#ff4949')"
              :disabled="scope.row.status === 'to_be_activated' || !showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status'])"
            )
            el-switch(
              v-else
              :key="switchKey"
              :value="scope.row.status === 'available_in_station'"
              :active-color="'#13ce66'"
              :inactive-color="scope.row.status === 'under_maintenance' ? '#ffd666' : (scope.row.status === 'to_be_activated' ? '#d3d3d3' : '#ff4949')"
              :disabled="scope.row.status === 'to_be_activated'"
            )
          span.status {{ bikesStatuses[scope.row.status].message }}
    el-table-column(
      prop='serial_number'
      :label="$t('common.serial_number')"
    )
    el-table-column(
      prop='docking_station_id'
      :label="$t('common.docking_station')"
    )
      template(v-slot='scope')
        span(@click="goToWIthId('docking_stations_list', scope.row.docking_station.id)")
        span.text-underline.cursor-pointer(v-if="scope.row.docking_station"
          @click="handleQrCodeClick(scope.row.docking_station)"
        ) {{ scope.row.docking_station.serial_number + '-' + (scope.row.bay && scope.row.bay.number || '0') }}
        span(v-else) -
    el-table-column(
      prop='scope.row.franchise && scope.row.franchise.name'
      :label="$t('common.cost_center_name')"
    )
      template(v-slot='scope')
        span.text-underline.cursor-pointer(@click="goToWIthId('cost_centers', '')") {{ scope.row.cost_center && scope.row.cost_center.name }}
    el-table-column(
      prop='bike_type'
      :label="$t('common.tracker')"
    )
      template(v-slot='scope')
        span(v-if="scope.row.tracker")
          span.text-underline.cursor-pointer(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRACKER, ['show'])"
            @click="handleOpenViewTrackerModal( scope.row.tracker)"
          ) {{ scope.row.tracker.name }}
          span(
            v-else
            :class="{ 'disabled': true }"
          ) {{ scope.row.tracker.name }}
        span(v-else) -

    el-table-column(
      prop='last_customer'
      :label="$t('common.last_customer')"
    )
      template(v-slot='scope')
        span.text-underline.cursor-pointer(
          v-if="scope.row.last_customer"
          @click="openInNewTab(scope.row.last_customer.id)"
        ) {{ scope.row.last_customer.first_name + ' ' + scope.row.last_customer.last_name }}
        span(v-else) -
    el-table-column(
      prop='next_maintenance_at'
      :label="$t('label.next_maintenance')"
    )
      template(v-slot='scope')
        span(v-if="scope.row.status == 'to_be_activated'")
          .status-wrapper.d-flex.align-items-center
            span.oval-with-tooltip.grey.tooltip-container
              span.tooltiptext.break-word(v-html="$t('bikes.table.grey_dot')")
              span.tooltip-trigger
                Tooltip(:tooltipText="$t('bikes.table.grey_dot')"
                :showTooltipSymbol="false")
            span {{ computeDateFormat(scope.row.next_maintenance_at) }}
        span(v-else)
          .status-wrapper.d-flex.align-items-center(v-if="isMaintenanceInMoreThan30Days(scope.row.next_maintenance_at) && scope.row.status !== 'under_maintenance'")
            span.oval-with-tooltip.completed.tooltip-container
              span.tooltiptext.break-word(v-html="$t('bikes.table.completed_dot')")
              span.tooltip-trigger
                Tooltip(:tooltipText="$t('bikes.table.completed_dot')"
                :showTooltipSymbol="false")
            span {{ computeDateFormat(scope.row.next_maintenance_at) }}
          .status-wrapper.d-flex.align-items-center(v-if="!wasMaintenanceNotDoneOnTime(scope.row.next_maintenance_at) && !isMaintenanceInMoreThan30Days(scope.row.next_maintenance_at) || isMaintenanceToday(scope.row.next_maintenance_at) || scope.row.status == 'under_maintenance'")
            span.oval-with-tooltip.pending.tooltip-container
              span.tooltiptext.break-word(v-html="$t('bikes.table.pending_dot')")
              span.tooltip-trigger
                Tooltip(:tooltipText="$t('bikes.table.pending_dot')"
                :showTooltipSymbol="false")
            span {{ computeDateFormat(scope.row.next_maintenance_at) }}
          .status-wrapper.d-flex.align-items-center(v-if="wasMaintenanceNotDoneOnTime(scope.row.next_maintenance_at) && !isMaintenanceToday(scope.row.next_maintenance_at) && scope.row.status !== 'under_maintenance'")
            span.oval-with-tooltip.unavailable.tooltip-container
              span.tooltiptext.break-word(v-html="$t('bikes.table.unavailable_dot')")
              span.tooltip-trigger
                Tooltip(:tooltipText="$t('bikes.table.unavailable_dot')"
                :showTooltipSymbol="false")
            span {{ computeDateFormat(scope.row.next_maintenance_at) }}

    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(v-slot='scope')
        .grid-buttons
          img.cursor-pointer.ml-1(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show'])"
            src="@assets/preview.svg"
            @click="handleViewBikeDetails(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show']) && isCurrentUserAdmin()"
            src="@assets/actions/edit.svg"
            @click="handleOpenEditBikeDetails(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="scope.row.can_be_deleted && isCurrentUserAdmin()"
            src="@assets/actions/delete.svg"
            @click="handleDeleteBikeDialog(scope.row)"
          )
          img.cursor-pointer.ml-1(
            src="@assets/actions/fix.svg"
            v-if="scope.row.status !== 'in_ride' && isCurrentUserAdmin()"
            @click="handleFixBike(scope.row)"
          )
          //- (showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance']) && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status']))
          img.cursor-pointer.ml-1(
            src="@assets/actions/fix.svg"
            v-if="scope.row.status !== 'in_ride' && !isCurrentUserAdmin() && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance'])"
            @click="handleStartBikeMaintenance(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="scope.row.dockingStationID && handleComputeUnlock(scope.row) && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status'])"
            src="@assets/actions/unlock.svg"
            @click="handleUnlockBike(scope.row)"
          )
          img.cursor-pointer.ml-1(
            v-if="['previously_stolen_but_now_returned'].includes(scope.row.status)"
            src="@assets/actions/found.svg"
            @click="handleFoundBike(scope.row)"
          )
          // TODO: dont forget about this
          //span.unlock-warning.cursor-pointer(
          //  v-if="showUnlockWarning.find(el => el[0] === scope.row.id)"
          //  @click="openUnlockWarning(showUnlockWarning.find(el => el[0] === scope.row.id))"
          //)
          //  i.el-icon-warning
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  filterUtils,
  inBetween,
  MAX_FIELD_LENGTH,
  trimTableField,
} from "@utils/filterUtils";
import { bikesStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import Tooltip from '@components/_shared/Tooltip';

export default {
  name: "BikesViewTable",
  mixins: [filterUtils, routerUtils, general],

  components: { 
    Tooltip,
  },

  props: {
    bikes: {
      type: Array,
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  data() {
    return {
      switchKey: 0,
      MAX_FIELD_LENGTH,
      bikesStatuses,
      inBetween,
      trimTableField,
      dynamicViewsConstants,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteBike();
    },

    bikes() {
      this.switchKey += 1;
    }
  },

  methods: {
    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),
    ...mapMutations("dockingStation", ["setQrCode"]),
    ...mapMutations("tracker", ["setTracker"]),
    ...mapMutations("dialog", [
      "showViewBikeDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "showStartBikeMaintenanceDialog",
      "showEditBikeDetailsDialog",
      "showQrCodeDialog",
      "showViewTrackerDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog"
    ]),
    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapActions("bike", [
      "getBike",
      "deleteBike",
      "switchBikeStatus",
      "startMaintenance",
      "finishMaintenance",
    ]),
    ...mapActions("dockingStation", ["getDock"]),
    ...mapActions("tracker", ["getTracker"]),

    isMaintenanceToday(date) {
      if(date) {
        const maintenanceDate = new Date(date).getTime();
        return this.$moment(maintenanceDate).isSame(new Date(), "day");
      }
      return null;
    },

    isMaintenanceInMoreThan30Days(date) {
      if(date) {
        const maintenanceDate = new Date(date).getTime();
        const currentDate = new Date().getTime();
        const sevenDaysInMs = 29 * 24 * 60 * 60 * 1000;
        return  maintenanceDate - currentDate  >= sevenDaysInMs;
      }
      return null;
    },

    wasMaintenanceNotDoneOnTime(date) {
      if(date) {
        const maintenanceDate = new Date(date).getTime();
        const currentDate = new Date().getTime();     
        return  maintenanceDate < currentDate;
      }
      return null;
    },

    getCurrentDateAndTime() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let hours = today.getHours();
      let seconds = today.getSeconds();
      let yyyy = today.getFullYear() + " " + hours + ":" + seconds;

      return (today = mm + "/" + dd + "/" + yyyy);
    },

    async handleSwitchBikeStatus(bike) {
      if (bike.status === "available_in_station") {
        let event_name =
          bike.status === "available_in_station"
            ? "make_unavailable"
            : "make_available";

        let params = {
          id: bike.id,
          event_name,
        };
        await this.switchBikeStatus(params);
        this.setShouldFetchBikes(true);
      } else if (bike.status === "under_maintenance") {
        let params = {
          id: bike.id,
          maintenance_status: "completed",
          next_maintenance_at: this.computeDateFormat(
            this.getCurrentDateAndTime()
          ),
          maintenance_description: "Completed",
        };

        await this.finishMaintenance(params).then(() => {
          let switchParams = {
            id: bike.id,
            event_name: "make_available",
          };
          this.switchBikeStatus(switchParams);
          this.setShouldFetchBikes(true);
        });
      } else if (bike.status === "unavailable") {
        if(bike.docking_station) {
          let dockStationObject = await this.getDock(bike.docking_station.id);

          if (dockStationObject.status !== "under_maintenance") {
            //make sure that the dock station is available first
            let event_name = "make_available";

            let params = {
              id: bike.id,
              event_name,
            };
            await this.switchBikeStatus(params);
            this.setShouldFetchBikes(true);
          } else {
            return this.$message({
              type: "error",
              message: this.$t("dialogs.bikes.dock_station_not_available"),
            });
          }
        } else {
          return this.$message({
          type: "error",
          message: this.$t("dialogs.bikes.bike_not_in_docking_station"),
          });
        }
      }
    },

    async handleFixBike(bike) {
    // TODO clarify which stasuses are valid for maintenance
      if(bike.status === "to_be_activated") {
        return this.$message({
          type: "error",
          message: this.$t("bikes.table.actions.cannot_start_maintenance_because_of_its_status"),
        });
      } else if(bike.status === "available_in_station") {
        this.setShouldFetchBikes(true);
        this.showStartBikeMaintenanceDialog();
        this.setBike(bike);
        return;
      } else if(bike.status === "under_maintenance") {
        // if someone is already working on the bike
        this.setShouldFetchBikes(true);
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
      } else {
        // set the bike on Ongoing maintenance
        await this.startMaintenance(bike.id)
        .then(() => {
          this.setShouldFetchBikes(true);
          this.showViewBikeMaintenanceDialog();
          this.setBike(bike);
        });
      }
    },

    async handleStartBikeMaintenance(bike) {
      // TODO clarify which stasuses are valid for maintenance
      if (bike.status === "available_in_station" || bike.status === "to_be_activated" || bike.status === "missing_as_result_of_admin_closing_a_ride" || bike.status === 'reported_missing') {
        return this.$message({
          type: "error",
          message: this.$t("bikes.table.actions.cannot_start_maintenance_because_of_its_status"),
        });
      } else if (bike.status === "under_maintenance") {
        // if someone is already working on the bike
        this.setShouldFetchBikes(true);
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
      } else {
        // set the bike on Ongoing maintenance
        await this.startMaintenance(bike.id)
        .then(() => {
          this.setShouldFetchBikes(true);
          this.showViewBikeMaintenanceDialog();
          this.setBike(bike);
        });
      }
    },

    handleUnlockBike() {
      //TODO: to be handled when server is ready
    },

    handleFoundBike() {
      //TODO: to be handled when server is ready
    },

    async handleDeleteBike() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

      await this.deleteBike(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteBikeDialog(bike) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("bikes.validations.delete_title")} ${
          bike.serial_number
        }`,
        dialogMessage: this.$t("bikes.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(bike);
    },

    async handleViewBikeDetails(bike) {
      const response = await this.getBike(bike.id);
      this.showViewBikeDetailsDialog();
      this.setBike(response);
    },

    handleOpenEditBikeDetails(bike) {
      this.setBike(bike);
      this.showEditBikeDetailsDialog();
    },

    async handleQrCodeClick(dockStation) {
      let dockStationObject = await this.getDock(dockStation.id);

      if (dockStationObject) {
        this.setQrCode(dockStationObject.bays[0].qr_code);
        this.showQrCodeDialog();
      }
    },

    async handleOpenViewTrackerModal(tracker) {
      console.log(tracker)
      const response = await this.getTracker(tracker.id)
      this.setTracker(response.data);
      this.showViewTrackerDialog();
    },
    
    openInNewTab(id) {
      let route = this.$router.resolve({path: `customers/${id}`});
      window.open(route.href, '_blank');
    },

    isCurrentUserAdmin(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    // ifMaintenancesToday(date) {
    //   if (date) {
    //     const maintenanceDate = new Date(date).getTime();
    //     return !!(
    //       this.$moment(maintenanceDate).isSame(new Date(), "day") ||
    //       maintenanceDate < new Date().getTime()
    //     );
    //   } else {
    //     return null;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

// .el-popper.is-customized {
//   /* Set padding to ensure the height is 32px */
//   padding: 6px 12px;
//   background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
// }

// .el-popper.is-customized .el-popper__arrow::before {
//   background: linear-gradient(45deg, #b2e68d, #bce689);
//   right: 0;
// }

/* Global styles for customized tooltip */
.customized-tooltip >>> .el-tooltip__popper {
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.customized-tooltip >>> .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 3px rgb(238, 238, 238);
  // border-style: solid;
  // border: 1px solid grey;
  padding: 10px;
  padding-left: 15px;
  position: absolute;
  z-index: 100;
  top: -200%;
  right: 160%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  font-family: "AvenirNextW10Medium", sans-serif;
  // font-family: Montserat, sans-serif;
  // font-family: "AvenirNextW10Medium", sans-serif;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -9px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent transparent rgb(255, 255, 255);
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  cursor: pointer;
}

.unlock-warning {
  display: block;

  i {
    font-size: 35px;
    color: $warning;
  }
}

.grid-buttons {
  display: inline-grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }
}
</style>
