<template lang="pug">
  .edit-user-view
    h3 {{ $t('customer.edit_customer') }}
    el-breadcrumb.last-breadcrumb-bold.mt-3(separator="/")
      el-breadcrumb-item(:to="{ path: '/customers' }") {{ $t('customers.customers') }}
      el-breadcrumb-item {{ $t('customer.edit_customer') }}
    .edit-user-wrapper.pt-4
      el-row.pl-4.pr-4.position-relative
        el-col(:span="24")
          UserDetailsForm(
            ref="customerDetails"
            :user="customerDetails"
          )
        el-button.dark-blue-btn.pop-up-btn.add-user-btn.position-absolute(
          @click="handleEditCustomer('AddUserForm')"
          v-loading="loading"
        ) {{ $t('common.save_changes') }}
      .hr-custom
      .custom-accordion
        el-row.pl-4.pr-4
          el-col(:span="24")
            el-collapse(
              v-model="activeNames"
              accordion
            )
              Collapse(
                v-for="(collapse, index) in collapses"
                :key="collapse"
                ref="collapseDetails"
                :isEdit='true'
                :collapse="collapse"
                :collapses="collapses"
                :index="index"
                @removeCollapse="handleRemoveCollapse"
              )
      el-button.ml-4.mt-4.dark-blue-btn.pop-up-btn(
        @click="handleAddCollapse"
      ) {{ $t('common.add_more') }}
</template>

<script>
import _ from "lodash";
import UserDetailsForm from "@components/users/UserDetailsForm";
import Collapse from "@components/users/Collapse";
import RolePermissionsList from "@components/_shared/RolePermissionsList";
import { mapActions, mapMutations, mapState } from "vuex";
import { roles } from "@utils/roles";

export default {
  components: { UserDetailsForm, Collapse, RolePermissionsList },

  computed: {
    ...mapState("customer", ["customer", "collapsesData", "newAddCustomerData"]),
  },

  data() {
    return {
      customerDetails: null,
      activeNames: [0],
      collapses: [],
      loading: false,
      loadEditCollapses: false
    };
  },

  async created() {
    await this.handleGetCustomerDetails()
    await this.handleGetCostCenters();
    await this.handlePrefillCollapsesData()
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("customer", ["updateCustomer", "getCustomer"]),
    ...mapMutations("customer", [
      "setCustomer",
      "setShouldFetchCustomers",
      "setShouldFetchCustomer",
      "setShouldFetchCustomerTickets",
      "setDeviceInfo",
      "setLoadedCostCenters",
      "setNewAddCustomerCollapseData",
      "setCollapsesDataCustomer",
      "setDisabledCostCenter"
    ]),

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("?page_size=1000");

      cost_centers.data.forEach((costCenter) => {
        costCenter.disabled = false;
      });

      this.setLoadedCostCenters(cost_centers.data);
    },

    async handleEditCustomer() {
      let isCustomerDetailsValid = false;
      let isRolePermissionValid = false;

      this.$refs.customerDetails.$refs['AddUserForm'].validate((valid) => {
        isCustomerDetailsValid = !!valid;
      });

      if (this.collapses.length > 1) {
        this.$refs.collapseDetails.forEach((collapse) => {
        collapse.$refs["RolesAndPermissionsForm"].validate((valid) => {
          isRolePermissionValid = !!valid;
        });
      })
      } else {
        isRolePermissionValid = true
      }

      if (isCustomerDetailsValid && isRolePermissionValid) {
        this.handleMapCollapseData();

        let payload = { ...this.newAddCustomerData, id: this.$route.params.id };

        await this.updateCustomer(payload).then(() => {
          this.$router.push('/customers')
        }).finally(() => {
          this.loading = false;
        })
      }
    },

    handleMapCollapseData() {
      let mappedCustomerRoleCostCenters = [];
      let mappedCustomerCostCenterPermissions = [];

      const schemaCustomerRoleCostCenter = (role_id, cost_center_id) => {
        return {
          role_id: Number(role_id),
          cost_center_id: cost_center_id ? Number(cost_center_id) : null,
        };
      };

      const schemaCustomerRoleCostCenterPermissions = (cost_center_id, permission_id, grant_type) => {
        return {
          cost_center_id: cost_center_id ? Number(cost_center_id) : null,
          permission_id: Number(permission_id),
          grant_type,
        };
      };

      // map user_role_cost_centers
      this.collapsesData.forEach((collapseData) => {
        collapseData.role_ids.forEach((role_id) => {
          mappedCustomerRoleCostCenters.push(
            schemaCustomerRoleCostCenter(role_id, collapseData.cost_center_id)
          );
        });

        // map user_cost_center_permissions
        collapseData.permissions.forEach((perm) => {
          mappedCustomerCostCenterPermissions.push(
            schemaCustomerRoleCostCenterPermissions(
                collapseData.cost_center_id,
                perm.permission_id,
                perm.grant_type
            )
          );
        });
      });

      //its empty??
      this.setNewAddCustomerCollapseData({
        mappedCustomerRoleCostCenters,
        mappedCustomerCostCenterPermissions,
      });
    },


    handleAddCollapse() {
      if (this.collapses.length >= 1) {
        let lastItem = _.clone(this.collapses[this.collapses.length - 1]);
        this.collapses.push((lastItem += 1));
      } else {
        this.collapses.push(0)
      }

      this.activeNames = this.collapses.length - 1 // toggling collapse only the latest added
    },

    handleRemoveCollapse(index) {
      if (this.collapses.length > 1) {
        this.collapses.splice(index, 1);
      }
    },

    async handleGetCustomerDetails() {
      let id = this.$route.params.id;
      this.customerDetails = await this.getCustomer(id);
    },

    async handlePrefillCollapsesData() {
      this.collapses = []
      let collapsesData = []

      // prefill vuex data
      // create collapses with cost_center_id and role_ids
      this.customerDetails.user_role_cost_centers.forEach((userRoleCostCenter, index) => {
        collapsesData.push({
          cost_center_id: null,
          role_ids: [userRoleCostCenter.role.id],
          loadedChecklists: [...userRoleCostCenter.permissions],
          alreadyChecked: [...userRoleCostCenter.permissions.map(perm => perm.id)],
          permissions: [],
          collapse: index
        })

        // IF THE CUSTOMER DOESN'T HAVE A COST CENTER ASSIGN, EXIT
        if (!userRoleCostCenter.cost_center) {
          return;
        }

        // IF THE CUSTOMER HAS A COST CENTER ASSIGNED
        if (!collapsesData.some(el => el.cost_center_id === userRoleCostCenter.cost_center.id)) {
          collapsesData.push({
            cost_center_id: userRoleCostCenter.cost_center.id,
            role_ids: [userRoleCostCenter.role.id],
            loadedChecklists: [...userRoleCostCenter.permissions],
            alreadyChecked: [...userRoleCostCenter.permissions.map(perm => perm.id)],
            permissions: [],
            collapse: index
          })
        } else {
          let collapsesDataIndex = collapsesData.findIndex(el => el.cost_center_id === userRoleCostCenter.cost_center.id)
          collapsesData[collapsesDataIndex].role_ids.push(userRoleCostCenter.role.id)
          collapsesData[collapsesDataIndex].loadedChecklists.push(...userRoleCostCenter.permissions)
          collapsesData[collapsesDataIndex].alreadyChecked.push(...userRoleCostCenter.permissions.map(perm => perm.id))
        }
      })

      // update authorized
      this.customerDetails.authorize_user_cost_center_permissions.forEach((authorized => {
        let foundCollapseDataIndex = collapsesData.findIndex(el => el.cost_center_id === authorized.cost_center.id)
        collapsesData[foundCollapseDataIndex].alreadyChecked.push(authorized.permission.id)
        collapsesData[foundCollapseDataIndex].loadedChecklists.push(authorized.permission.id)
      }))

      // update restricted
      collapsesData.forEach(data => {
        this.customerDetails.restrict_user_cost_center_permissions.forEach((restricted) => {
          if (restricted.cost_center.id === data.cost_center_id) {
            _.remove(data.alreadyChecked, id => id === restricted.permission.id);
          }
        })
      })

      collapsesData.forEach(data => {
        this.collapses.push(data.collapse);

        this.setCollapsesDataCustomer({
          collapse: data.collapse,
          cost_center_id: data.cost_center_id,
          role_ids: data.role_ids,
          alreadyChecked: data.alreadyChecked,
          loadedChecklists: data.loadedChecklists,
          permissions: [],
        });

        this.setDisabledCostCenter({
          selectedCostCenterId: data.cost_center_id,
          disabled: true,
        });
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-user-btn {
  right: 24px;
}
</style>
