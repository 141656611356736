<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='trackers'
    @selection-change="$emit('selectionChange', $event)"
    @row-click="handleGoWithId"
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='created_at'
      :label="$t('trackers.last_report')"
      width="130px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.last_report") <strong>{{scope.row.tracker_latest_info.last_report.category}}</strong> {{ computeDateFormat(scope.row.tracker_latest_info.last_report.added_at) }} 
        span(v-else) -  
    el-table-column(
      prop='imei'
      :label="$t('trackers.fw_version')"
      width="140px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.about") {{ computeFWVerionDateFormat(scope.row.tracker_latest_info.about.fw_ver) }}
        span(v-else) -
    el-table-column(
      prop='tracker_last_location.data'
      :label="$t('trackers.last_location')"
      width="210px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.location")
          <strong> 
          span {{'Locație: '}} 
          </strong>
          span.break-word(:title="$t('trackers.latitude')") {{scope.row.tracker_latest_info.location.latitude }}, 
          span.break-word(:title="$t('trackers.longitude')") {{scope.row.tracker_latest_info.location.longitude }}
          <br>
          <br>
          <strong> 
          span.break-word {{$t('trackers.time_of_create')}}: 
          </strong>
          span.break-word {{computeTrackerDateFormat(scope.row.tracker_latest_info.location.added_at) }} <br>
          <strong>
          span.break-word {{$t('trackers.time_of_report')}}: 
          </strong>
          span.break-word {{computeTrackerDateFormat(scope.row.tracker_latest_info.location.reported_at) }} 
        span(v-else) - 
    el-table-column(
      prop='category'
      :label="$t('trackers.last_power')"
      width="210px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.power")
          <strong>
          span.break-word {{$t('trackers.v_in')}}: 
          </strong>
          span.break-word {{ scope.row.tracker_latest_info.power.v_in /1000.0 }}V <br>
          <strong>
            span {{$t('trackers.v_bat')}}: 
          </strong>
            span.break-word {{ scope.row.tracker_latest_info.power.v_bat /1000.0 }}V <br>
          <br>
          <strong> 
          span.break-word {{$t('trackers.time_of_create')}}: 
          </strong>
          span.break-word {{computeTrackerDateFormat(scope.row.tracker_latest_info.power.added_at) }} <br>
          <strong>
          span.break-word {{$t('trackers.time_of_report')}}: 
          </strong>
          span.break-word {{computeTrackerDateFormat(scope.row.tracker_latest_info.power.reported_at) }} 
        span(v-else) -
    el-table-column(
      prop='category'
      :label="$t('trackers.bike')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.bike")
          <strong>
            span.break-word {{$t('trackers.hw_version')}}: 
          </strong>
            span.break-word {{ scope.row.bike.bike_type  }} <br>
          <strong>
            span.break-word {{$t('bikes-map.bike-serial-number')}}: 
          </strong>
            span.break-word {{ scope.row.bike.serial_number }} <br>
          <strong>
            span.break-word {{$t('bikes-map.bike-rfid-code')}}: 
          </strong>
            span {{ scope.row.bike.rfid }} <br>
          <strong>
            span.break-word {{$t('bikes-map.bike-state-of-charge')}}: 
          </strong>
            span.break-word {{ $t(`${handleBikeStateOfCharge(scope.row.bike.state_of_charge)}`) }}
        span(v-else) -  
</template>

<script>
import {
  filterUtils,
  MAX_FIELD_LENGTH,
  trimTableField
} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { joinValuesWithComma } from "@utils/generalUtils";
import { mapMutations } from "vuex";
import { routerUtils } from "@utils/router";

export default {
  name: "TrackersViewTable",
  mixins: [routerUtils, filterUtils, general],

  props: {
    trackers: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
    };
  },

  methods: {
    trimTableField,
    joinValuesWithComma,
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    handleGoWithId(row) {
      this.goToWIthId('trackers', row.id)
    },

    handleHWType(data){
      if (data == 20) {return "mechanical"}
      else if (data == 21) {return "electric"}
      else {return "unknown"}
    },

    handleBikeStateOfCharge(state_of_charge){
      if (state_of_charge == 1) return 'bikes.state_of_charge.fully_charged'
      if (state_of_charge == 2) return 'bikes.state_of_charge.medium_charged'
      if (state_of_charge == 3) return 'bikes.state_of_charge.low_battery'
      return 'bikes.state_of_charge.undefined'
    },

    handleGetTrackerData() {

    },
  }
};
</script>

<style lang="scss" scoped>
.gdpr-message {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper {
  height: 293px;
  margin: 23px auto 40px;
  width: 100%;
}

.view-ride-details {
  padding-bottom: 24px;

  .price-plans {
    span {
      display: block;
      overflow-wrap: break-word;
    }
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
</style>
