<template lang="pug">
  .tickets-view(v-if="hideIcon()")
    h3 {{ $t('tickets.tickets') }}
    .filters
      // filter by date range
      FilterDatePicker(
        v-model="dateRange"
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['by_date_range'])"
      )

      // filter by status
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ticketsStatuses"
        v-model="selectedStatus"
      )

      // filter by category
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ticketsCategory"
        v-model="selectedCategory"
      )

      //filter by type
      FilterType(
        :name="$t('common.type_filter')"
        v-model="selectedType"
      )
      FilterCostCenterDropdown(v-model="selectedCostCenterId")

    .tickets-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.TICKET, ['by_keyword'])"
        )
      TicketsViewTable(
        v-loading="loading"
        :tickets="tickets"
        :selectedStatus="selectedStatus"
      )
    // TODO: to update prop name activName to selectedStatus after all tabs will be deleted
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'tickets'"
      :dateRange="dateRange"
      :activeName="selectedStatus"
      :search="search"
      :costCenterId="selectedCostCenterId"
      :statusType="selectedType"
      :ticketTypeId="selectedType"
      :categoryType="selectedCategory"
      @viewData="handleGetTickets"
      @handleTableLoading="handleLoading"
    )
  .tickets-view(v-else)
    h3 {{ $t('tickets.tickets') }}
    .tab-view
      FilterTabs(
        v-model="activeName"
        :view="'tickets'"
        :tabs="tabs"
      )
    .filters
      // filter by date range
      FilterDatePicker(
        v-model="dateRange"
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['by_date_range'])"
      )

      // filter by status
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ticketsStatuses"
        v-model="selectedStatus"
      )

      //filter by type
      FilterType(
        :name="$t('common.type_filter')"
        v-model="selectedType"
      )
      FilterCostCenterDropdown(v-model="selectedCostCenterId")

    .tickets-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.TICKET, ['by_keyword'])"
        )
      TicketsViewTable(
        v-if=`activeName == 'client'`
        v-loading="loading"
        :tickets="tickets"
        :selectedStatus="selectedStatus"
      )
      TicketsStationViewTable(
        v-if=`activeName == 'tablet'`
        v-loading="loading"
        :tickets="tickets"
        :selectedStatus="selectedStatus"
      )
    // TODO: to update prop name activName to selectedStatus after all tabs will be deleted
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'tickets'"
      :dateRange="dateRange"
      :activeName="selectedStatus"
      :search="search"
      :costCenterId="selectedCostCenterId"
      :statusType="selectedType"
      :ticketTypeId="selectedType"
      :categoryType="selectedCategory"
      @viewData="handleGetTickets"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterTabs from "@components/_shared/FilterTabs";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import FilterStatus from "@components/_shared/FilterStatus";
import FilterType from "@components/_shared/FilterType";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import TicketsViewTable from "@components/tickets/TicketsViewTable";
import TicketsStationViewTable from "@components/tickets/TicketsStationViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import { ticketsReports } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";

export default {
  mixins: [general, routerUtils],
  components: {
    Pagination,
    FilterTabs,
    FilterCostCenterDropdown,
    SearchWithButtons,
    TicketsViewTable,
    TicketsStationViewTable,
    FilterStatus,
    FilterDatePicker,
    FilterType
  },

  data() {
    return {
      ticketsStatuses: [
        { name: "All", value: 'all' },
        { name: "Unresolved", value: 'unresolved' },
        { name: "In Progress", value: 'in_progress' },
        { name: "Resolved", value: 'resolved' },
      ],
      ticketsCategory: [
        { name: "All", value: 'all' },
        { name: "Client", value: 'client' },
        { name: "Application", value: 'application' },
        { name: "Tablet", value: 'tablet' },
        { name: "Rfid_v2", value: 'rfid_v2' },
        { name: "Backend", value: 'backend' },
        { name: "General", value: 'general' },
      ],
      search: "",
      tabs: [
        { name: "client", tickets: [] },
        { name: "tablet", tickets: [] }
      ],
      activeName: 'client',
      viewName: 'client',

      // filters
      dateRange: [],
      selectedCostCenterId: "",
      selectedStatus: "all",
      selectedType: "all",
      selectedCategory:"client",

      tickets: [],
      loading: false,
      dynamicViewsConstants
    };
  },
    watch: {
    activeName(){
      this.viewName = this.activeName
      this.selectedCategory = this.activeName
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    handleGetTickets(data) {
      this.tickets = data
    },

    handleLoading(loading) {
      this.loading = loading
    },

    handleGetArrayFromTypeStatuses() {
      let arrayOfTypeStatuses = Object.keys(ticketsReports).map(key => {
        return {
          name: key,
          value: key
        }
      })

      arrayOfTypeStatuses.unshift({ name: 'All', value: 'all' })
      return arrayOfTypeStatuses
    },

    hideIcon(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },
  },
};
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
