<template lang="pug">
  .bikes-view
    h3 {{ $t('firmwares.firmwares') }}
    .bikes-wrapper
      .actions(style="margin-right: 0;")
        el-button.dark-blue-btn.cursor-pointer(
          @click="handleUpload()"
        ) {{ $t('firmwares.upload') }}
      el-row
        el-col(:span='24')
          FirmwaresViewTable(
            v-loading="loading"
            :firmwares="firmwares"
          )
    Pagination(
      v-if="activeName && activeName !== ''"
      :view="'firmwares'"
      :activeName="activeName"
      :search="search"
      :reload="reload"
      :dateRange="dateRange"
      :orderByCategory="selectedOrderByCategory"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetSoftwares"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import OrderByDropdown from "@components/_shared/OrderByDropdown";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapMutations, mapState } from "vuex";
import FirmwaresViewTable from "@components/firmwares/FirmwaresViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [general],

  components: {
    Pagination,
    FilterDatePicker,
    OrderByDropdown,
    FilterCostCenterDropdown,
    SearchWithButtons,
    FirmwaresViewTable,
  },

  data() {
    return {
      activeName: "all",
      firmwares: [],
      search: "",
      reload: false,
      generatePausedForSeconds: false,
      dateRange: [],
      selectedOrderByCategory: "",
      selectedCostCenterId: "",
      loading: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showUploadFirmwareDialog"
    ]),

    async handleUpload() {
      this.showUploadFirmwareDialog();
    },

    handleGetSoftwares(data) {
      this.firmwares = data;
    },

    handleLoading(loading) {
      this.loading = loading;
      if(!loading) {
        setTimeout(() => this.generatePausedForSeconds = loading, 5000);
      }else {      
        this.generatePausedForSeconds = loading
      }
      this.reload = false;
    },

    reloadData(){
      this.reload = true;
    },

  },
};
</script>

<style lang="scss" scoped>
.bikes-view {
  .status-wrapper {
    .status {
      margin-left: 5px;
    }
  }
}
</style>
