<template lang="pug">
  .edit-cost-center
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'EditCostCenter'"
    )
    el-form(
      :model="form"
      v-loading="loadingForm"
      ref="EditCostCenterForm"
      :rules="addCostCenterRules"
      @submit.native.prevent
    )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="name"
            :label="$t('common.name')"
          )
            el-input(
              v-model.number="form.name"
              name="name"
            )
        el-col(:span="8")
          el-form-item(
            prop="company"
            :label="$t('common.company')"
          )
            el-input(
              v-model.number="form.company"
              name="company"
            )
        el-col.address(:span="8")
          el-form-item(
            prop="address"
            :label="$t('label.address')"
          )
            el-autocomplete(
              ref="autocomplete"
              v-model="form.address"
              name="address"
              :trigger-on-focus="false"
              @select="handleSelectAddress"
              :fetch-suggestions="addressSearchResultsDebounce"
            )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="email"
            :label="$t('common.email_address')"
          )
            el-input(
              v-model="form.email"
              name="email"
            )
        el-col(:span="8")
          el-form-item(
            prop="phone"
            :label="$t('common.phone_number')"
          )
            el-input(
              v-model="form.phone"
              name="phone"
            )
        el-col(:span="8")
          el-form-item(
            prop="comission"
            :label="$t('common.commission')"
          )
            el-input(
              v-model.number="form.comission"
              name="comission"
            )
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="currency"
            :label="$t('label.currency')"
          )
            el-select(
              v-model="form.currency"
            )
              el-option(
                v-for="currency in currencyOptions"
                :key="currency.value"
                :label="currency.label"
                :value="currency.value"
              )
        el-col(:span="8")
          el-form-item(
            prop="payment_processor"
            :label="$t('label.payment')"
          )
            el-select(
              v-model="form.payment_processor"
            )
              el-option(
                v-for="payment in paymentOptions"
                :key="payment.value"
                :label="payment.label"
                :value="payment.value"
              )
        el-col(:span="8")
          el-form-item(
            prop="stripe_account_id"
            :label="$t('label.stripe_account_id')"
          )
            el-input(
              v-model="form.stripe_account_id"
              name="stripe_account_id"
              type="input"
            )
      hr.hr-custom
      el-row(:gutter="24")
        el-col(:span="8")
          el-form-item(
            prop="unique_registration_code"
            :label="$t('common.cui')"
          )
            el-input(
              v-model="form.unique_registration_code"
              name="unique_registration_code"
            )
        el-col(:span="8")
          el-form-item(
            prop="trade_register_number"
            :label="$t('common.commercial_register')"
          )
            el-input(
              v-model="form.trade_register_number"
              name="trade_register_number"
            )
        el-col(:span="8")
          el-form-item(
            prop="bank_name"
            :label="$t('common.bank')"
          )
            el-select(
              v-model="form.bank_name"
              name="bank_name"
            )
              el-option(
                v-for="bank in sortedBanks"
                :key="bank"
                :label="bank"
                :value="bank"
              )
    el-row.text-center
      el-button.dark-blue-btn.mt-5.pop-up-btn(
        @click="handleEditCostCenter('EditCostCenterForm')"
        :loading="loadingSubmitBtn"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMaps from "@components/_shared/GoogleMaps";
import { customValidations } from "@utils/customValidators.js";
import { CostCentersViewValidations } from "@utils/formValidations/costCentersViewValidations";
import _ from "lodash";
import { currencyOptions } from "@utils/generalUtils";
import Vue from "vue";

export default {
  name: "EditCostCenter",
  mixins: [CostCentersViewValidations, customValidations, currencyOptions],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      form: {
        name: null,
        company: null,
        email: null,
        phone: null,
        comission: null,
        currency: null,
        address: null,
        address_lat: null,
        address_long: null,
        payment_processor: null,
        unique_registration_code: null,
        trade_register_number: null,
        bank_name: null,
        stripe_account_id: null
      },
      loadingForm: false,
      loadingSubmitBtn: false,

      banks: [
        'Alpha Bank',
        'BRCI',
        'Banca FEROVIARA',
        'Intesa Sanpaolo',
        'BCR',
        'BCR Banca pentru Locuinţe',
        'Eximbank',
        'Banca Românească',
        'Banca Transilvania',
        'Leumi',
        'BRD',
        'CEC Bank',
        'Crédit Agricole',
        'Credit Europe',
        'Garanti Bank',
        'Idea Bank',
        'Libra Bank',
        'Vista Bank',
        'OTP Bank',
        'Patria Bank',
        'First Bank',
        'Porsche Bank',
        'ProCredit Bank',
        'Raiffeisen',
        'Aedificium Banca pentru Locuinte',
        'UniCredit',
        'Alior Bank',
        'BLOM Bank France',
        'BNP Paribas',
        'Citibank',
        'ING',
        'TBI Bank'
      ]
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("map", ["coordinates"]),

    ...mapState("costCenter", ["costCenter"]),

    sortedBanks() {
      return this.banks.sort((a, b) => a.localeCompare(b));
    }
  },

  watch: {
    costCenter() {
      this.handleGetFormData()
      this.updateMapFromAddress();
    },
  },

  async created() {
    this.loadingForm = true
    this.handleGetFormData()
    this.updateMapFromAddress();
    if (!(this.form.address_long && this.form.address_lat)) {
      this.form.address_lat = this.coordinates.lat;
      this.form.address_long = this.coordinates.lng;
    }
    this.loadingForm = false
  },

  methods: {
    ...mapActions("map", ["getPlaceByKeyword", "getPlaceByID"]),

    ...mapActions("costCenter", ["updateCostCenter"]),

    ...mapMutations("dialog", ["hideEditCostCenterDialog"]),

    ...mapMutations("costCenter", ["setShouldFetchCostCenters"]),

    ...mapMutations("map", ["setMapCoordinates"]),

    handleGetFormData() {
      this.form = { ...this.costCenter };
    },

    async handleSelectAddress(item) {
      this.selectedPlaceID = item.place_id;
      const placeDetails = await this.getPlaceByID(item.place_id);

      let coordinates = {
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      };

      await this.setMapCoordinates(coordinates);
    },

    async handleGetPlaceData(address, autocompleteCallback) {
      let placesDescription = [];

      // on typing in the field
      if (address !== "") {
        let query = `?keyword=${address}`;
        const places = await this.getPlaceByKeyword(query);

        placesDescription = places.map((place) => {
          return { value: place.description, place_id: place.place_id };
        });

        autocompleteCallback(placesDescription);
      }
    },

    addressSearchResultsDebounce: _.debounce(function (
            address,
            autocompleteCallback
        ) {
          this.handleGetPlaceData(address, autocompleteCallback);
        },
        300),

    async handleMarkerDragEnd(address, markerPosition) {
      await this.setMapCoordinates(markerPosition);
      this.form.address = address;
      this.form.address_lat = markerPosition.lat;
      this.form.address_long = markerPosition.lng;

      this.$refs.autocomplete.focus();
    },

    updateMapFromAddress() {
      this.setMapCoordinates({
        lat: Number(this.form.address_lat),
        lng: Number(this.form.address_long),
      });
    },

    handleEditCostCenter(formName) {
      this.loadingSubmitBtn = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };

          this.updateCostCenter(params)
              .then(() => {
                this.hideEditCostCenterDialog();
                this.setShouldFetchCostCenters(true);
              })
              .finally(() => {
                this.loadingSubmitBtn = false;
              });
        } else {
          this.loadingSubmitBtn = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.address {
  .el-autocomplete {
    width: 100%;
  }
}

.el-select {
  width: 100%;
}
</style>