export const state = {
  dataToUpdate: null,
  passwordConfirmation: null,

  dialogData: {
    dialogTitle: "",
    dialogMessage: "",
  },

  allowUpdate: false,
  loading: false,
};

export const mutations = {
  setDialogData(state, { dialogTitle, dialogMessage }) {
    state.dialogData.dialogTitle = dialogTitle;
    state.dialogData.dialogMessage = dialogMessage;
  },

  setDataToUpdate(state, dataToUpdate) {
    state.dataToUpdate = dataToUpdate;
  },

  setAllowUpdate(state, value) {
    state.allowUpdate = value;
  },

  setPasswordConfirmation(state, password) {
    state.passwordConfirmation = password;
  },

  setLoading(state, value) {
    state.loading = value;
  },
};
