<template lang="pug">
  .view-contact-details
    el-row.center.details-bold.mt-4(:gutter="50" type="flex" justify="center")
      el-col(:span="14")
        span {{ $t('common.email_address') }}
      el-col(:span="7")
        span {{ $t('common.phone_number') }}

    el-row.center.text-normal(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
      el-col(:span="14")
        span {{ customer.email }}
      el-col(:span="7")
        span {{ customer.phone_number }}
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ViewContactDetails",

  computed: {
    ...mapState("customer", ["customer"]),
  },
};
</script>
