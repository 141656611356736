<template lang="pug">
  .transactions-view
    h3 {{ $t('transactions.transactions') }}
    .filters
      FilterDatePicker(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['by_date_range'])"
        v-model="dateRange"
        @onChange="handleGetTransactionsStatistics()"
      )
      FilterCostCenterDropdown(
        v-model="selectedCostCenterId"
        @onChange="handleGetTransactionsStatistics()"
      )
    .charts-wrapper.mb-4(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['show_statistics'])"
      v-loading="loadingStatistics"
    )
      Chart(
        :statisticTitle="$t('transactions.average_earnings')"
        :chartData="filteredTransactions"
        :chartType="'filteredTransactions'"
      )
      hr.hr-custom
      Chart.mt-4(
        :statisticTitle="$t('transactions.average_last_day_earnings')"
        :chartData="lastDayTransactions"
        :chartType="'lastDayTransactions'"
      )
    .export-wrapper.d-flex
      el-button.dark-blue-btn.export(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TRANSACTION, ['export'])"
        @click="handleExportToExcel"
      )
        h4 {{ $t('transactions.export') }}
    h3 {{ $t('transactions.transactions') }}
    .rides-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['by_keyword'])"
        )
      el-row
        TransactionsViewTable(
          v-loading="loading"
          :transactions="transactions"
          :group_ride_transactions="group_ride_transactions"
        )
        Pagination(
          view="transactions"
          :search="search"
          :dateRange="dateRange"
          :costCenterId="selectedCostCenterId"
          @viewData="handleGetTransactions"
          @handleTableLoading="handleLoading"
        )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";
import Statistic from "@components/_shared/Statistic";
import { routerUtils, } from "@utils/router";
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import Chart from "@components/transactions/Chart";
import { computeDateRangeWithSelectedCostCenterId } from "@utils/generalUtils";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import TransactionsViewTable from "@components/transactions/TransactionsViewTable";
import GroupRidesTransactionsViewTable from "@components/transactions/GroupRidesTransactionsViewTable";

export default {
  mixins: [filterUtils, routerUtils, general],

  components: {
    Statistic,
    Pagination,
    FilterDatePicker,
    FilterCostCenterDropdown,
    SearchWithButtons,
    Chart,
    TransactionsViewTable,
    GroupRidesTransactionsViewTable
  },

  data() {
    return {
      search: "",
      dateRange: [],
      selectedCostCenterId: "",
      transactions: [],
      group_rides: [],
      group_ride_transactions: [],
      filteredTransactions: {},
      lastDayTransactions: {},
      loadingStatistics: false,
      loading: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetTransactionsStatistics();
  },

  methods: {
    ...mapActions("transaction", [
      "getTransactions",
      "getTransactionsStatistics",
      "exportTransactions",
      "getGroupRideInfo",
      "getGroupRides"
    ]),

    handleGetTransactions(data) {
      this.transactions = data;
    },

    handleGetGroupRidesTransactions(data) {
      this.group_ride_transactions = data;
    },

    handleGetGroupRides(data) {
      this.group_rides = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    async handleGetTransactionsStatistics() {
      if (this.showItemDynamically(this.user, dynamicViewsConstants.resource_names.TRANSACTION, ['show_statistics'])) {
        this.loadingStatistics = true;
        let computedUrl = computeDateRangeWithSelectedCostCenterId(
            this.selectedCostCenterId,
            this.dateRange
        );

        if(computedUrl!="?")
          computedUrl += "&";

        computedUrl += "structure=v2";
        
        const statistics = await this.getTransactionsStatistics(computedUrl);
        this.filteredTransactions = statistics.filtered_transactions;
        this.lastDayTransactions = statistics.last_day_transactions;
        this.loadingStatistics = false;
      }
    },

    async handleExportToExcel() {
      const computedUrl = computeDateRangeWithSelectedCostCenterId(
          this.selectedCostCenterId,
          this.dateRange
      );
      const response = await this.exportTransactions(computedUrl);
      const blob = new Blob([response.data], { type: response.headers['content-type'] })

      let a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '');
      a.download = decodeURIComponent(fileName);
      a.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.export-wrapper {
  .export {
    height: 40px;
    margin: 20px 0 !important;
    width: 124px;
    border-radius: 20px;
    background: $gradient-start !important;
    box-shadow: none !important;

    h4 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 13px;
    }
  }
}
</style>
