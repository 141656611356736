<template lang="pug">
  .info-button-container
    button.info-button(v-if="showTooltipSymbol") ℹ️
    if tooltipVisible
      .tooltip(v-html="tooltipText")
</template>

<script>
export default {
  props: {
    tooltipText: {
      type: String,
      required: true
    },
    showTooltipSymbol: {
      type: Boolean,
      default: true
    },
  }
};
</script>

<style>
.info-button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }

  .info-button-container {
  position: relative;
  display: inline-block;
  z-index: 99;
}

.tooltip {
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: rgb(133, 51, 51);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  white-space: normal;
  z-index: 99;
  width: 250px;
  text-align: left;
}

</style>
