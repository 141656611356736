<template lang="pug">
  .view-ticket-details
    .carousel
      .carousel-not-available.d-flex.justify-content-center(v-if="carouselItems.length === 0") {{ $t('tickets.dialog.images_not_provided') }}
      el-carousel(
        v-else
        :autoplay="false"
        :interval="5000"
        indicator-position="none"
        arrow="never"
      )
        el-carousel-item(
          :key="carouselItems[currentItem]"
          :name="carouselItems[currentItem]"
          v-for="image in ticket.images"
        )
          img(:src="ticket.images[currentImageIndex]" @click="openImageInNewTab(ticket.images[currentImageIndex])").carousel-img
        i.el-icon-caret-right.arrows.arrow-right(@click="handleImageNavigation('next', ticket.images)")
        i.el-icon-caret-left.arrows.arrow-left(@click="handleImageNavigation('previous', ticket.images)")
    .col
      el-row.center.details-bold(:gutter="50" type="flex" justify="center")
        el-col(:span="3")
          span(v-if="carouselItems.length === 0") 0 din 0
          span(v-else) {{ (currentImageIndex + 1) + " din " + ticket.images.length }}
    .col
      el-row.center.details-bold(:gutter="50" type="flex" justify="center")
        el-col(:span="7")
          span {{  $t('tickets.dialog.time_of_report') }}

        el-col(:span="7")
          span {{ $t('tickets.dialog.location') }}

        el-col(:span="7")
          span {{  $t('tickets.dialog.status') }}

      el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
        el-col(:span="7")
          span {{ computeDateFormat(ticket.created_at) }}

        el-col(:span="7" style="text-align:justify;")
          span.text-underline.cursor-pointer(v-if="ticket.latitude" style="text-align: center;" @click="openNewPage(ticket.latitude, ticket.longitude)") {{ "Mergi la locație " }}
          span.text-underline.cursor-pointer.newWindow6(v-if="ticket.latitude" style="text-align: center;" @click="openNewPage(ticket.latitude, ticket.longitude)")
          span(v-else) -

        el-col(:span="7")
          .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.status, 41, 60)")
            span.oval.cancelled
            span {{ ticketsStatuses[ticket.status].message }}
          .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.status, 1, 20)")
            span.oval.pending
            span {{ ticketsStatuses[ticket.status].message }}
          .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.status, 21, 40)")
            span.oval.completed
            span {{ ticketsStatuses[ticket.status].message }}
    .col
      el-row.center.details-bold(:gutter="50" type="flex" justify="center")
        el-col(:span="7")
          span {{ $t('tickets.dialog.bike') }}

        el-col(:span="7")
          span {{ $t('tickets.dialog.docking_station') }}

        el-col(:span="7")
          span.break-word(v-if="ticket.user && ticket.user.first_name") {{ $t('tickets.dialog.customer_name') }}
          span.break-word(v-else style="text-align:center;") 

      el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
        el-col(:span="7" style="text-align:justify;")
          span.break-word(v-if="ticket.bike" style="text-align:center;") {{ ticket.bike.serial_number }}
          span.break-word(v-else style="text-align:center;") -

        el-col(:span="7" style="text-align:justify;")
          span.break-word(v-if="ticket.docking_station" style="text-align:center;") {{ ticket.docking_station.serial_number }} 
          span.break-word(v-else-if="ticket.bay && !ticket.docking_station" style="text-align:center;") {{ ticket.docking_station_serial_number + "-" + ticket.bay.number }}
          span.break-word(v-else style="text-align:center;") -

        el-col(:span="7")
          span(v-if="ticket.user && ticket.user.first_name")
            span.text-underline.cursor-pointer(
              v-if="isCustomerTicket(ticket)"
            @click="openInNewTab(ticket.user.id)"
            ) {{ ticket.user.first_name + " " + ticket.user.last_name }}
            span( 
              v-else-if="!isCustomerTicket(ticket)"
            ) {{ ticket.user.first_name + " " + ticket.user.last_name }}
            span(v-else) -
          span(v-else) 
    .col
      el-row.center.details-bold(:gutter="50" type="flex" justify="center")
        el-col(:span="21")
          span {{ $t('tickets.dialog.description') }}

      el-row.center(:gutter="50" type="flex" justify="center" style="margin-top: 10px")
        el-col(:span="21" style="text-align:justify;")
          span.break-word(style="text-align:center;") {{ ticket.description }}

</template>

<script>
import { mapMutations, mapState } from "vuex";
import { ticketsReports, ticketsStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";

export default {
  name: "ViewTicketDetails",
  mixins: [filterUtils, routerUtils],

  data() {
    return {
      currentItem: null,
      carouselItems: [],
      currentImageIndex: 0,
      customerTicketsTypes: ["stolen_bike", "broken_bike", "lock_problem", "unlock_problem", "broken_qr", "ride_problem", "problem_with_someone_elses_bike", "payment_problem", "suggestions", "unknown_client_issue"],
      ticketsStatuses,
      ticketsReports,
      inBetween,
    };
  },

  computed: {
    ...mapState("ticket", ["ticket"]),
  },

  created() {
    this.handleInitCarouselItems()
  },

  watch: {
    ticket(){
      this.handleInitCarouselItems();
      this.currentImageIndex = 0;
    }
  },

  methods: {
    ...mapMutations("dialog", ["hideViewTicketDetailsDialog"]),

    openImageInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },

    openNewPage(lat, long) {
      const searchUrl = `https://www.google.com/search?q=${lat},${long}`;
      window.open(searchUrl, '_blank');
    },

    handleCustomerClick(ticket){
      this.goToWIthId('customers', ticket.user.id);
      this.hideViewTicketDetailsDialog();
    },

    handleInitCarouselItems() {
      this.carouselItems = this.ticket.images
    },

    handleImageNavigation(direction, images) {
      if (direction === 'next') {
        const itemsCount = images.length - 1;
        if (this.currentImageIndex < itemsCount) {
          this.currentImageIndex += 1;
        } else {
          this.currentImageIndex = 0;
        }
      } else {
        const itemsCount = images.length - 1;
        if (this.currentImageIndex <= 0) {
          this.currentImageIndex = itemsCount;
        } else {
          this.currentImageIndex -= 1;
        }
      }
    },

    openInNewTab(id) {
      let route = this.$router.resolve({path: `customers/${id}`});
      window.open(route.href, '_blank');
    },

    isCustomerTicket(ticket) {
      if(ticket.ticket_type_object)
        return this.customerTicketsTypes.includes(ticket.ticket_type_object.status);
      return false;
    },

  },
};
</script>

<style lang="scss" scoped>
.view-ticket-details {
  padding-bottom: 24px;

  .carousel-not-available {
    height: 200px;
    align-items: center;
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .carousel {
    position: relative;
    margin-top: 16px;
    margin-bottom: 20px;

    .arrows {
      position: absolute;
      font-size: 28px;
      cursor: pointer;
    }

    .arrow-left,
    .arrow-right {
      top: 50%;
      transform: translateY(-50%);
    }

    .arrow-right {
      right: 0;
    }

    .arrow-left {
      left: 0;
    }

    .carousel-img {
      height: 200px;
      width: 270px;
      object-fit: cover;
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
  }

  .el-carousel__item {
    height: 200px;
    width: 270px;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}



.newWindow6 {
     position: relative;
     display: inline-block;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow6:after {
     position: absolute;
     top: -8px;
     right: -3px;
     content: "\1f855";
     /*      content:"\2197"; */
     /*       content:"\2b67";*/
     font-size: 0.8em;
}
.newWindow6:before {
     position: absolute;
     top: -3px;
     right: -3px;
     content: " ";
     border: 4px solid white;
}

.newWindow {
     background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==)
          no-repeat center;
     width: 1em;
     height: 1em;
}

a[target="_blank"] {
     position: relative;
}

a[target="_blank"]:after {
     position: absolute;
     top: 3px;
     right: -15px;
     content: "\1f855";
     font-size: 13px;
     color: black;
     line-height: 3px;
     height: 5px;
     width: 5px;
     border-right: 2px solid white;
     border-top: 2px solid white;
}
a[target="_blank"]:before {
     position: absolute;
     top: 4px;
     right: -15px;
     content: " ";
     border: 1px solid black;
     width: 8px;
     height: 8px;
}


.newWindow3 {
     position: relative;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow3:after {
     position: absolute;
     top: -10px;
     right: -4px;
     /*      content:"\2197"; */
     content: "\1f855";
     /*       content:"\2b67";
           font-size:1.1em */
}
.newWindow3:before {
     position: absolute;
     top: -3px;
     right: -3px;
     content: " ";
     border: 4px solid white;
}

.newWindow4 {
     position: relative;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow4:after {
     position: absolute;
     top: -7px;
     right: -2px;
     /*      content:"\2197"; */
     content: "\1f855";
     /*       content:"\2b67";*/
     font-size: 0.8em;
}
.newWindow4:before {
     position: absolute;
     top: -3px;
     right: -3px;
     content: " ";
     border: 4px solid white;
}

.newWindow5 {
     position: relative;
     border: 1px solid;
     width: 8px;
     height: 8px;
}

.newWindow5:after {
     position: absolute;
     top: -9px;
     right: -2px;
     /*      content:"\2197"; */
     content: "\2197";
     /*       content:"\2b67";*/
     font-size: 0.9em;
}
.newWindow5:before {
     position: absolute;
     top: -4px;
     right: -4px;
     content: " ";
     border: 4px solid white;
}
</style>
