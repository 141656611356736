<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='cost_centers'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='costCenterName'
      :label="$t('common.name')"
      width="160px"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.name && scope.row.name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.name) }}
        span(v-if="scope.row.name && scope.row.name.length <= MAX_FIELD_LENGTH") {{ scope.row.name }}
    el-table-column(
      prop='companyName'
      :label="$t('common.company')"
      width="160px"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.company && scope.row.company.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.company"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.company) }}
        span(v-if="scope.row.company && scope.row.company.length <= MAX_FIELD_LENGTH") {{ scope.row.company }}
    el-table-column(
      prop='address'
      :label="$t('label.address')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.address && scope.row.address.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.address"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.address) }}
        span(v-if="scope.row.address && scope.row.address.length <= MAX_FIELD_LENGTH") {{ scope.row.address }}
    el-table-column(
      prop='email'
      :label="$t('common.email_address')"
      width="170px"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.email && scope.row.email.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.email"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.email) }}
        span(v-if="scope.row.email && scope.row.email.length <= MAX_FIELD_LENGTH") {{ scope.row.email }}
    el-table-column(
      prop='phoneNumber'
      :label="$t('common.phone_number')"
      width="150px"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.phone && scope.row.phone.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.phone"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.phone) }}
        span(v-if="scope.row.phone && scope.row.phone.length <= MAX_FIELD_LENGTH") {{ scope.row.phone }}
    el-table-column(
      v-if="hideSubmenu()"
      prop='commissionFee'
      :label="$t('common.commission')"
      width="100px"
    )
      template(slot-scope='scope') {{ scope.row.comission }}%
    el-table-column(
      v-if="hideSubmenu()"
      prop='currency'
      :label="$t('label.currency')"
      width="100px"
    )
      template(slot-scope='scope') {{ computeCurrency(scope.row.currency) }}
    el-table-column(
      v-if="hideSubmenu()"
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          img.cursor-pointer.mb-1.mr-2(
            src="@assets/actions/edit.svg"
            @click="handleOpenEditCostCenterDialog(scope.row)"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.COST_CENTER, ['update'])"
          )
          img.cursor-pointer.mb-1.mr-2(
            v-if="hideSubmenu()"
            src="@assets/actions/fix.svg"
            @click="handleCostCenterSettings(scope.row)"
          )
          img.cursor-pointer.mb-1(
            src="@assets/actions/delete.svg"
            @click="handleDeleteCostCenterDialog(scope.row)"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.COST_CENTER, ['destroy'])"
          )
</template>

<script>
import { roles } from "@utils/roles";
import { MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { currencyOptions } from "@utils/generalUtils";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { routerUtils } from "@utils/router";

export default {
  name: "CostCentersViewTable",
  mixins: [general, currencyOptions, routerUtils],

  props: {
    cost_centers: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
      trimTableField,
      dynamicViewsConstants,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteCostCenter();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "dialogData",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapActions("costCenter", ["deleteCostCenter"]),

    ...mapMutations("dialog", [
      "showAddCostCenterDialog",
      "showEditCostCenterDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("costCenter", ["setCostCenter"]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setPasswordConfirmation",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    hideSubmenu() {
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    handleOpenEditCostCenterDialog(cost_center) {
      this.setCostCenter(cost_center);
      this.showEditCostCenterDialog();
    },

    handleCostCenterSettings(costCenter) {
      this.setCostCenter(costCenter);
      this.goToWIthId("cost_center_configurable_settings", costCenter.id);
    },

    handleDeleteCostCenter() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteCostCenter(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteCostCenterDialog(cost_center) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("cost_centers.validations.delete_title")} ${
          cost_center.name
        }`,
        dialogMessage: this.$t("cost_centers.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(cost_center);
    },

    computeCurrency(currency) {
      let computedCurrency = "";
      this.currencyOptions.forEach((option) => {
        if (option.value === currency) {
          computedCurrency = option.label;
        }
      });
      return computedCurrency;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-buttons {
  display: flex;
  flex-wrap: wrap;
}
</style>
