<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='transactions'
  )
    el-table-column(
      prop='created_at'
      :label="$t('common.invoice_date')"
    )
      template(slot-scope='scope')
        span.date-field {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='customer_full_name'
      :label="$t('common.customer_name')"
    )
      template(v-slot='scope')
        span.text-underline.cursor-pointer(
          @click="goToWIthId('customers', scope.row.customer.id)"
        ) {{ scope.row.customer.first_name + " " + scope.row.customer.last_name }}
    el-table-column(
      prop='amount'
      :label="$t('common.amount_to_pay')"
    )
      template(v-slot='scope')
        span {{ scope.row.amount + " " + $t("label.ron.label") }}
    el-table-column(
      prop='refunded_amount'
      :label="$t('common.amount_refunded')"
    )
      template(v-slot='scope')
        span {{ scope.row.refunded_amount + " " + $t("label.ron.label") }}
    el-table-column(
      prop='status'
      :label="$t('common.ride_status')"
    )
      template(slot-scope='scope')
        .status-wrapper(v-if="scope.row.status_object.color=='green'")
          span {{ scope.row.status_object.label }}
          span.oval.completed
        .status-wrapper(v-if="scope.row.status_object.color=='red'")
          span {{ scope.row.status_object.label }}
          span.oval.cancelled
        .status-wrapper(v-if="scope.row.status_object.color=='yellow'")
          span {{ scope.row.status_object.label }}
          span.oval.pending
    el-table-column(
      prop='group_ride.id'
      :label="$t('common.group_ride')"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer(
          @click="handleViewTransactionDetails(scope.row.group_ride)"
        ) {{ scope.row.group_ride.id }}
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { transactionStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: 'TransactionsViewTable',
  mixins: [filterUtils, routerUtils, general],

  props: {
    transactions: {
      type: Array
    }
  },

  data() {
    return {
      transactionStatuses,
      inBetween,
      dynamicViewsConstants,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState('transaction', ['group_ride']),
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewTransactionDetailsDialog",
    ]),
    
    ...mapMutations("transaction", ["setGroupRide"]),

    async handleViewTransactionDetails(group_ride) {
      this.showViewTransactionDetailsDialog();
      this.setGroupRide(group_ride);
    },
  }
}
</script>
