<template lang="pug">
  .export-maintenanaces
    FilterCostCenterDropdown.filter-cost-center(v-model="selectedCostCenterId"
    :view="'exportMaintenances'")
    .cost-centers-filter
      span.filter-title(:style="'margin-left: 10px;'") {{ $t('bikes.validations.next_maintenance') }}
      el-date-picker.date-picker(
        :style="'width: 90%; margin-left: 10px;'"
        v-model="nextMaintenanceDate"
        type="date"
        :placeholder="$t('placeholder.pick_date')"
        :pickerOptions="pickerOptions"
        format="dd/MM/yyyy"
        :default-value="new Date()"
      )
    .export-btn
      el-button.dark-blue-btn(
        v-loading="loading"
        @click="handleUpdateBikesMaintenancesDialog()"
      )
        h4 {{ $t('common.continue') }}

</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { disabledPastDates } from "@utils/dateRange";
import { customValidations } from "@utils/customValidators";
import { filterUtils } from "@utils/filterUtils";
import { setObjectValues } from "@utils/generalUtils";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";

export default {
  name: "UpdateBikesMaintenances",
  mixins: [customValidations, filterUtils],

    components: {
    FilterDatePicker,
    FilterCostCenterDropdown
  },

  data() {
    return {
      selectedCostCenterId: "",
      cost_centers: [],
      nextMaintenanceDate: null,

      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return disabledPastDates(time);
        },
      },
      setObjectValues
    }
  },

  watch: {
    allowUpdate() {
      this.handleUpdateBikesMaintenances();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationUpdate", [
      "dataToUpdate",
      "allowUpdate",
      "passwordConfirmation",
    ]),
  },

  created() {
    this.handleGetCostCenters();
  },

  methods: {

    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapMutations("dialog", [
      "hideUpdateBikesMaintenancesDialog",
      "showViewPasswordValidationUpdateDialog",
      "hideViewPasswordValidationUpdateDialog"
    ]),
    ...mapMutations("passwordValidationUpdate", [
      "setDialogData",
      "setLoading",
      "setDataToUpdate",
      "setAllowUpdate",
    ]),
    ...mapActions("bike", ["updateBikesMaintenances"]),

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("?page_size=1000");
      this.cost_centers = cost_centers.data;
    },

    clearForm() {
      this.selectedCostCenterId = "";
      this.nextMaintenanceDate = null;
      this.loading = false;
    },

    async handleUpdateBikesMaintenances() {
      if (this.allowUpdate) {
        let payload = {
          cost_center_id: this.dataToUpdate.costCenterId,
          next_maintenance_at: this.dataToUpdate.nextMaintenanceDate,
          password_confirmation: this.passwordConfirmation,
        };

        await this.updateBikesMaintenances(payload)
            .then(() => {
              this.hideViewPasswordValidationUpdateDialog();
              this.clearForm();
              this.hideUpdateBikesMaintenancesDialog();
            })
            .catch((error) => {
              this.setAllowUpdate(false);
            })
            .finally(() => {
              this.setLoading(false);
            });
      }
    },

    handleUpdateBikesMaintenancesDialog() {
      this.loading = true;

      if(this.selectedCostCenterId == "" || this.nextMaintenanceDate == null) {
        this.$message({
          type: "error",
          message: this.$t("dialogs.export"),
        });
        this.loading = false;
        return;
      }

      const cost_center = this.cost_centers.find(item => item.id === this.selectedCostCenterId);

      this.showViewPasswordValidationUpdateDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("bikes.validations.update_bike_maintenance_title")} ${
          cost_center.name
        }`,
        dialogMessage: this.$t("bikes.validations.update_bike_maintenance_warning"),
      });

      let payloadDataToUpdate = {
        costCenterId: this.selectedCostCenterId,
        nextMaintenanceDate: this.computeDateFormat(this.nextMaintenanceDate),
        password_confirmation: this.passwordConfirmation,
      };

      this.setAllowUpdate(false);
      this.setDataToUpdate(payloadDataToUpdate);

      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.export-maintenanaces {
  padding: 24px 0;
  // margin-top: 40px;
  min-width: 99%;

  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  justify-content: center !important;
  align-items: center !important;

  background-color: #eff2fa;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}

.filter-date-picker {
  margin: auto;
  
}
.filter-cost-center {
  width: 85%;
  margin: auto;
}

.export-btn {
  margin: auto;
  margin-top: 15px;
}
</style>
