<template lang="pug">
  .details
    el-form(
      :model="form"
      ref="EditBikeForm"
      :rules="editBikeRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="rfid"
            :label="$t('label.rfid')"
          )
            el-input(
              v-model="form.rfid"
              name="rfid"
            )
      el-row.d-flex.justify-content-center(:gutter="30")
         el-col(:span="9")
           el-form-item(
             prop="cost_center_id"
             :label="$t('common.tracker')"
           )
             el-select(
               v-model='form.tracker.name'
               filterable
               :placeholder="$t('placeholder.tracker')"
             )
               el-option(
                 v-for='value in trackers'
                 :key='value.id'
                 :label='value.name'
                 :value='value.id'
               )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="9")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.bike_type')"
          )
            el-select(
              v-model='form.type'
              filterable

            )
              el-option(
                v-for='type in types'
                :key='type'
                :label='$t("placeholder." + type)'
                :value='type'
              )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn.mt-4(
        @click="handleEditBike('EditBikeForm')"
        :loading="loading"
      ) {{ $t('common.edit') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "ViewBikeEditDetails",
  mixins: [bikesViewValidations, customValidations],

  data() {
    return {
      loading: false,
      types: [
        'mechanical',
        'electrical',
      ],
      form: {
        rfid: "",
        type: null,
        trackerId: null,
        trackerName: '',
        tracker: null
      },
      editForm:{
      },
      trackers: [],
      trackerID: null,
      bikeID: null,
      trackerForm: {}

    }
  },

  computed: {
    ...mapState("bike", ["bike"]),
  },

  created() {
    this.handleGetFormData()
    this.handleTrackers()
  },

  methods: {
    ...mapActions('bike', ['updateBike', 'assignTracker', 'unassignTracker']),
    ...mapActions('tracker', ['getTrackersUnassignedToBikes']),
    ...mapMutations('bike', ['setShouldFetchBikes']),
    ...mapMutations('dialog', ['hideEditBikeDetailsDialog']),

    async handleTrackers(){
      this.trackers.push({
          id: -1,
          name: 'Nedefinit'
        })
      const response = await this.getTrackersUnassignedToBikes('page_size=1000')

      for (let i = 0; i < response.data.length; i++) {
        this.trackers.push({
          id:response.data[i].id,
          name: response.data[i].name
        })
      }
    },

    handleGetFormData() {
      this.form.rfid = this.bike.rfid;
      this.form.type = this.bike.bike_type;
      if(this.bike.tracker){
        this.form.tracker = {id: this.bike.tracker.id, name: this.bike.tracker.name};
        this.trackerName = this.bike.tracker.name;
      }else {
        this.form.tracker = {id:  -1, name: 'Nedefinit'};
        this.trackerName = 'Nedefinit';
      }
    },

    handleEditFormData(){
      if(this.bike.rfid != this.form.rfid) { this.editForm.rfid = this.form.rfid }
      if(this.bike.bike_type != this.form.type) { this.editForm.bike_type = this.form.type }

      if(this.bike.tracker){
        if(this.bike.tracker.name != this.form.tracker.name)
        {
          this.trackerForm.trackerId = this.form.tracker.name
          this.trackerForm.bikeId = this.bike.id
          this.trackerID = this.form.tracker.name
        }
      }else {
        if(this.form.tracker.name != this.trackerName){
            this.trackerForm.trackerId = this.form.tracker.name
            this.trackerForm.bikeId = this.bike.id
            this.trackerID = this.form.tracker.name
        }
      }
    },

    handleTrackerAssignment(){
      if(this.bike.tracker) {
        if(this.trackerID && this.trackerID != this.bike.tracker.id){
          if(this.trackerID == -1){
            let params = { id: this.trackerForm.bikeId}
            this.unassignTracker(params);
          }
          else {
            let params = {
            id: this.trackerForm.bikeId,
            tracker_id: this.trackerForm.trackerId,
            }
            this.assignTracker(params);
          }
        }
      }else {
        if(this.trackerID && this.trackerID != -1){
            let params = {
              id: this.trackerForm.bikeId,
              tracker_id: this.trackerForm.trackerId,
            }
            this.assignTracker(params);
        }
      }


    },

    handleEditBike(formName) {
      this.handleEditFormData();
      this.handleTrackerAssignment();
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let payload = { ...this.editForm, id: this.bike.id };
          this.updateBike(payload)
              .then(() => {
                this.hideEditBikeDetailsDialog();
                this.clearForm();
                this.setShouldFetchBikes(true);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    clearForm() {
      setObjectValues(this.form, "");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select{
  width: 100%;
}
</style>
