<template lang="pug">
  el-menu.el-menu-wrapper.scroll-el-menu(
    :class="{'closed-menu': !isMenuOpen}"
    :router="false"
    :default-active="activeLink"
    :collapse-transition="false"
    mode="vertical"
    v-if="computeSideBar"
    ref="sidebar"
  )
    NavProfileComponent(
      :isMenuOpen="isMenuOpen"
      @toggleMenuOpen="handleToggleMenuOpen"
    )
    div.menu-list
      CostCentersItem
      DockingStationsMapItem
      DockingStationsListItem
      RentTariffsItem
      StationLogsItem
      UsersItem
      TicketsItem
      SmartCardsItem
      TransactionsItem
      RidesItem
      BikesItem
      CustomersItem
      SettingsItem
      AdminSettingsItem
</template>

<script>
import { mapState } from "vuex";
import NavProfileComponent from "../_shared/NavProfileComponent.vue";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import { sideMenuMixins } from "@src/mixins/sideMenu";
import CostCentersItem from "@components/sidebar/CostCentersItem";
import DockingStationsMapItem from "@components/sidebar/DockingStationsMapItem";
import DockingStationsListItem from "@components/sidebar/DockingStationsListItem";
import RentTariffsItem from "@components/sidebar/RentTariffsItem";
import StationLogsItem from "@components/sidebar/StationLogsItem";
import UsersItem from "@components/sidebar/UsersItem";
import TicketsItem from "@components/sidebar/TicketsItem";
import SmartCardsItem from "@components/sidebar/SmartCardsItem";
import TransactionsItem from "@components/sidebar/TransactionsItem";
import RidesItem from "@components/sidebar/RidesItem";
import BikesItem from "@components/sidebar/BikesItem";
import CustomersItem from "@components/sidebar/CustomersItem";
import SettingsItem from "@components/sidebar/SettingsItem";
import AdminSettingsItem from "@components/sidebar/AdminSettingsItem";

export default {
  mixins: [general, sideMenuMixins],

  components: {
    AdminSettingsItem,
    SettingsItem,
    CustomersItem,
    BikesItem,
    RidesItem,
    TransactionsItem,
    TicketsItem,
    SmartCardsItem,
    RentTariffsItem,
    StationLogsItem,
    DockingStationsListItem,
    DockingStationsMapItem,
    CostCentersItem,
    NavProfileComponent,
    UsersItem,
  },

  data() {
    return {
      isMenuOpen: true,
      activeLink: this.$route.path,
      dynamicViewsConstants,
    };
  },

  methods: {
    handleToggleMenuOpen(isMenuOpen) {
      this.isMenuOpen = isMenuOpen;
      this.toggleSideMenu(isMenuOpen);

      localStorage.setItem("isMenuOpen", this.isMenuOpen);
    },
  },

  created() {
    localStorage.setItem("isMenuOpen", this.isMenuOpen);
  },

  computed: {
    ...mapState("auth", ["user"]),

    computeSideBar() {
      return (
        this.user &&
        this.activeLink !== "/set-password" &&
        this.activeLink !== "/change_email"
      );
    },
  },

  watch: {
    $route(newVal) {
      this.activeLink = newVal.path;
    },
  },
};
</script>

<style lang="scss">
/* sub menu with link */
.sub-menu-link {
  width: 100%;
  text-decoration: none;
  display: inline-block;
}
</style>
