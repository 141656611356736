export const settingsViewValidations = {
  data() {
    return {
      profileDataFormRules: {
        first_name: [
          {
            required: true,
            message: this.$t("common.validations.enter_first_name"),
            trigger: "blur",
          },
        ],
        last_name: [
          {
            required: true,
            message: this.$t("common.validations.enter_last_name"),
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            validator: this.phoneNumberValidator,
            trigger: "blur",
          },
        ],
        profile_image: [
          {
            required: true,
            message: this.$t("settings.validations.profile_picture"),
            trigger: "blur",
          },
        ],
      },

      passwordFormRules: {
        password: [
          {
            required: true,
            validator: this.validateConfirmationPassword,
            trigger: "blur",
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: this.validateConfirmationPassword,
            trigger: "blur",
          },
        ],
      },

      switchCostCenterRules: {
        cost_center_id: [
          {
            required: true,
            message: this.$t("common.validations.cost_center"),
            trigger: "blur",
          },
        ],
      },
    };
  },
};
