export const stationCommandsViewValidations = {
  data() {
    return {
      sendCommandFormRules: {
        cmd: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        docking_station_id: [
          {
            required: false,
            trigger: "blur",
          },
        ],
        bay_number: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        cost_center_id: [
          {
            required: false,
            trigger: "blur",
          },
        ],
        cmd_command: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        cmd_params: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        ride_id: [
          {
            required: false,
            trigger: "blur",
          },
        ]
        // endRideTime: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //   },
        // ],
        // statusRide: [
        //   {
        //     required: true,
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
};
