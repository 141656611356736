<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='tokens'
  )
    el-table-column(
      prop='device_name'
      :label="$t('label.expired')"
    )
      template(slot-scope='scope')
        span {{ scope.row.expired }}
    el-table-column(
      prop='last_name'
      :label="$t('label.last_name')"
    )
      template(slot-scope='scope')
        span(
          v-if='scope.row.user_last_name'
        ) {{ scope.row.user_last_name }}
        span(v-else) -
    el-table-column(
      prop='first_name'
      :label="$t('label.first_name')"
    )
      template(slot-scope='scope')
        span(
          v-if='scope.row.user_first_name'
        ) {{ scope.row.user_first_name }}
        span(v-else) -
    el-table-column(
      prop='ip'
      :label="$t('label.user_id')"
    )
      template(slot-scope='scope')
        span {{ scope.row.user_id }}
    el-table-column(
      prop='created_at'
      :label="$t('label.date')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-1(
          src="@assets/actions/delete.svg"
          @click="handleDeleteTokenDialog(scope.row)"
        )
</template>

<script>
import { filterUtils, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { joinValuesWithComma } from "@utils/generalUtils";
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: 'TechnicalDashboardViewTable',
  mixins: [filterUtils],

  props: {
    tokens: {
      type: Array
    }
  },

  data() {
    return {
      MAX_FIELD_LENGTH
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  watch: {
    allowDeletion() {
      this.handleDeleteToken();
    },
  },

  methods: {
    trimTableField,
    joinValuesWithComma,
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapActions("technicalDashboard", ["revokeToken"]),

    handleDeleteToken() {
      if (this.allowDeletion) {
        let computedQueriesUrl = `${this.dataToDelete.id}`;

        this.revokeToken(computedQueriesUrl)
            .then(() => {
              this.hideViewPasswordValidationDeleteDialog();
            })
            .catch((error) => {
              this.setAllowDeletion(false);
            })
            .finally(() => {
              this.setLoading(false);
            });
      }
    },

    handleDeleteTokenDialog(token) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("technical_dashboard.validations.delete_title")} #${
            token.id
        }`,
        dialogMessage: this.$t("technical_dashboard.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(token);
    },
  }
}
</script>
