<template lang="pug">
  .switch-cost-center
    el-form(
      :model="form"
      ref="SwitchCostCenterForm"
      :rules="switchCostCenterRules"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="16")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.cost_center_name')"
          )
            el-select(
              v-model='form.cost_center_id'
              filterable
              :placeholder="$t('placeholder.select_cost_center')"
            )
              el-option(
                v-for='cost_center in cost_centers'
                :key='cost_center.id'
                :label='cost_center.name'
                :value='cost_center.id'
              )
    el-row.text-center
      el-button.dark-blue-btn.pop-up-btn(
        @click="handleSwitchCostCenter('SwitchCostCenterForm')"
        :loading="loading"
      ) {{ $t('common.switch') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { customValidations } from "@utils/customValidators";
import { setObjectValues } from "@utils/generalUtils";
import { settingsViewValidations } from "@utils/formValidations/settingsViewValidations";

export default {
  name: "SwitchCostCenter",
  mixins: [customValidations, settingsViewValidations],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      cost_centers: [],
      form: {
        cost_center_id: "",
      },
      loading: false,
      setObjectValues
    }
  },

  async created() {
    await this.handleGetCostCenters();
    this.handleFillSelectedCostCenter()
  },

  methods: {
    ...mapMutations("dialog", [
      "hideSwitchCostCenterDialog"
    ]),

    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("user", ["updateProfile"]),

    clearForm() {
      this.setObjectValues(this.form, "");
    },

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("?page_size=1000");
      this.cost_centers = cost_centers.data;
    },

    handleSwitchCostCenter(formName) {
      this.loading = true;

      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = {
            selected_cost_center_id: this.form.cost_center_id
          };

          this.updateProfile(params)
              .then(() => {
                this.clearForm();
                this.hideSwitchCostCenterDialog();

                // refresh page after switching cost center
                location.reload()
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    handleFillSelectedCostCenter() {
      if (this.user.selected_cost_center) {
        this.form.cost_center_id = this.user.selected_cost_center.id
      }
    }
  },
};
</script>
