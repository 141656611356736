export const usersViewValidations = {
  data() {
    return {
      addUserFormRules: {
        name: [
          {
            required: true,
            message: this.$t("common.validations.enter_last_name"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            validator: this.emailValidator,
            trigger: "blur",
          },
        ],
        phone_number: [
          {
            required: true,
            validator: this.phoneNumberValidator,
            trigger: "blur",
          },
        ],
      },

      rolesAndPermissionsRules: {
        cost_center_id: [
          {
            required: false,
            message: this.$t("placeholder.enter_cost_center_id"),
            trigger: "blur",
          },
        ],
        role_id: [
          {
            required: true,
            message: this.$t("common.select_role"),
            trigger: "blur",
          },
        ],
      }
    };
  },
};
