<template lang="pug">
  .start-bike-maintenance.d-flex
    el-row.center(:gutter="50" type="flex" justify="center")
      el-col(:span="18")
        span.title.text-center {{ $t('dialogs.bikes.start_maintenance_title') }}
    el-row.center(:gutter="50" type="flex" justify="center")
      el-col
        .status-wrapper.d-flex
          span.status-title {{ $t('dialogs.bikes.start_maintenance_status') }}
          .status-switch.d-flex(
            @click="handleSwitchBikeStatus"
          )
            el-switch(
              :value="bike.status === 'available_in_station'"
              active-color="#13ce66"
              inactive-color="#ff4949"
            )
            span.status {{ bikesStatuses[bike.status].message }}
    el-row.text-center
      el-button(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance'])"
        :class="bike.status === 'available_in_station' ? 'gray-btn' : 'dark-blue-btn'"
        @click="handleStartMaintenance"
        :loading="loading"
      ) {{ $t('dialogs.bikes.start_maintenance') }}
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { bikesStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants} from "@src/constants";

export default {
  name: "StartBikeMaintenance",
  mixins: [general],

  data() {
    return {
      loading: false,
      bikesStatuses,
      inBetween,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("bike", ["bike"]),
  },

  methods: {
    ...mapActions("bike", ["getBike", "switchBikeStatus", "startMaintenance"]),

    ...mapMutations("dialog", [
      "showViewBikeMaintenanceDialog",
      "hideStartBikeMaintenanceDialog",
    ]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    handleStartMaintenance() {
      if (this.bike.status === "unavailable") {
        this.startMaintenance(this.bike.id).then(() => {
          this.setBike(this.bike);
          this.showViewBikeMaintenanceDialog();
          this.hideStartBikeMaintenanceDialog();
          this.setShouldFetchBikes(true);
        });
      }
    },

    async handleSwitchBikeStatus() {
      this.loading = true;
      let event_name =
        this.bike.status === "available_in_station"
          ? "make_unavailable"
          : "make_available";

      let params = {
        id: this.bike.id,
        event_name,
      };
      await this.switchBikeStatus(params).then(() => {
        this.getBike(this.bike.id);
        this.loading = false;
      });
      this.setShouldFetchBikes(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.start-bike-maintenance {
  flex-direction: column;
  align-items: center;

  .el-button {
    line-height: 22px;
    height: 40px;
    width: fit-content;
    padding: 9px 27px !important;
  }

  .title {
    display: block;
    word-break: break-word;
  }

  .status-wrapper {
    flex-direction: column;
    margin: 24px 0;

    .status-title {
      margin-bottom: 14px;
    }

    .status-switch {
      align-items: center;
      width: 120px;

      span {
        margin-left: 4px;
      }
    }
  }
}
</style>
