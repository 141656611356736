<template lang="pug">
  .change-email-view(v-loading="pageLoading")
    .d-flex
      .form
        .background-wrapper
          img.header-img(src="@assets/auth/header.svg")
          el-row.d-flex.justify-content-center
            el-col(:span="16")
              el-form(
                :model="form"
                ref="setEmailForm"
                :rules="changeEmailViewValidations"
                @submit.native.prevent
                @keyup.enter.native="handleForgotPassword('setEmailForm')"
              )
                h2 {{ $t('auth.change_your_email') }}
                el-form-item.mt-4(
                  prop="email"
                  :label="$t('auth.new_email')"
                )
                  el-input(
                    v-model="form.email"
                    name="email"
                    type="email"
                  )
                el-form-item.mt-4(
                  prop="email_confirmation"
                  :label="$t('auth.confirm_new_email')"
                )
                  el-input(
                    v-model="form.email_confirmation"
                    name="email_confirmation"
                    type="email"
                  )
              el-row.text-center
                el-button.dark-blue-btn(
                  @click="setNewMail('setEmailForm')"
                  :disabled="disabledButton"
                  :loading="loading"
                ) {{ $t('auth.send') }}
      .background
</template>

<script>
import { mapActions } from "vuex";
import { authenticationViewValidations } from "@/src/utils/formValidations/authenticationViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "ChangeEmail",
  mixins: [authenticationViewValidations, customValidations],

  data() {
    return {
      access_token: null,
      form: {
        email: null,
        email_confirmation: null,
      },
      pageLoading: false,
      loading: false,
    };
  },

  computed: {
    disabledButton() {
      return this.form.email === "" || this.form.email_confirmation === "";
    },
  },

  beforeRouteEnter(to, from, next) {
    let params = new URL(document.location).href;
    let token = params.split('=')[1];

    if (token) {
      next();
    } else {
      next("/login");
    }
  },

  mounted() {
    this.access_token = this.$route.query.token;
  },

  methods: {
    ...mapActions("user", ["changeUserEmail"]),

    setNewMail(formName) {
      this.loading = true;
      const form = this.$refs[formName];
      form.validate(async (valid) => {
        if (valid) {
          const payload = { ...this.form };
          payload.access_token = this.access_token;

          this.changeUserEmail(payload)
            .then(() => {
              this.$router.push("/login");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
