import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  tracker: null,
  shouldFetchTrackers: false,
};
export const actions = {
  async getTrackers({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/trackers${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTracker({ commit }, id) {
    try {
      const response = await axios.get(`/v1/trackers/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  
  async getTrackerDetailsForCategory({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/trackers/${payload.id}/details?category=${payload.params}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTrackersLocations({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/trackers/locations${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTrackerTrail({ commit }, payload) {
    try {
      let response;
      if (payload.start_date) {
        let computedParams = `?by_date_range[start_date]=${payload.start_date}&by_date_range[end_date]=${payload.end_date}`;
        response = await axios.get(`/v1/trackers/${payload.id}/tracker_trail${computedParams}`);
      }else{
        response = await axios.get(`/v1/trackers/${payload.id}/tracker_trail`);
      }
      commit("setTracker", dataFormatter.deserialize(response.data));

      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTrackersUnassignedToBikes({ commit }, payload) {
    try {
      const response = await axios.get(
        `/v1/trackers?${payload}&unasigned_to_bikes=true`
      );
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

};

export const mutations = {
  setTracker(state, tracker) {
    state.tracker = tracker;
  },

  setShouldFetchTrackers(state, value) {
    state.shouldFetchTrackers = value;
  },
};
