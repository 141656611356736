import Vue from "vue";

const helpers = {
  showErrors(error) {
    let errors = [];

    if(error.response.data) {
      if (error.response.data.errors) {
        errors = error.response.data.errors;
      } else if (!error.response.data.error) {
        let arrayBufferError = String.fromCharCode.apply(
          null,
          new Uint8Array(error.response.data)
        );

        errors = JSON.parse(arrayBufferError).errors;
      }

      let mappedErrors = errors.map(
        (error) => error.source + " -> " + error.details
      );

      Vue.prototype.$notify({
        title: "Error",
        dangerouslyUseHTMLString: true,
        message: this.decodeUTF8(mappedErrors.join("<br>")) || this.decodeUTF8(error.response.data.error_description),
        type: "error",
        position: "top-left",
        customClass: "custom-notify",
      });

      return errors;
    } 

    const parsedData = JSON.parse(error.response.config.data);

    if(error.response.status == 403 && 'password_confirmation' in parsedData){
      Vue.prototype.$notify({
        title: "Error",
        dangerouslyUseHTMLString: true,
        message: "Parolă incorectă",
        type: "error",
        position: "top-left",
        customClass: "custom-notify",
      });

      return error;
    }else if(error.response.status == 403){
      Vue.prototype.$notify({
        title: "Error",
        dangerouslyUseHTMLString: true,
        message: "Acces interzis la această resursă",
        type: "error",
        position: "top-left",
        customClass: "custom-notify",
      });

      return error;
    } 



  },

  decodeUTF8(string) {
    try {
      return decodeURIComponent(escape(string));
    } catch (e) {
      console.error("Error decoding UTF-8 string:", e);
      return string;
    }
  },

  showSuccess(data) {
    Vue.prototype.$notify({
      title: "Success",
      message: data,
      type: "success",
      position: "top-left",
      customClass: "custom-notify",
    });
  },

  showWarning(data) {
    Vue.prototype.$notify({
      title: "Warning",
      message: data,
      type: "warning",
      position: "top-left",
      customClass: "custom-notify",
    });
  },
};

export default helpers;
