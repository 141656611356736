<template lang="pug">
  .view-docking-station-statistics
    .docking-station-details-modal        
      el-table(
        v-loading="loading"
        :data="statusHistory"
        center
        header-cell-class-name='table-header'
        cell-class-name='pl-4'
        style='width: 100%;overflow-y: scroll;'
      )
        el-table-column(
          width="55"
        )
          template(slot-scope='scope')
            span.pre-line {{ scope.$index + 1}}

        el-table-column(
          prop="created_at"
          :label="$t('docking_stations.created_at')"
        )        
          template(slot-scope='scope')
            span.bay_number {{ computeDateTimeFormat(scope.row.created_at) }}

        el-table-column(
          prop="status"
          :label="$t('docking_stations.status')"
        )
          template(slot-scope='scope')
            .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 21, 40)")
              span.oval.completed
              span {{ dockingStationsStatuses[scope.row.status].message }}

            .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 41, 60)")
              span.oval.cancelled
              span {{ dockingStationsStatuses[scope.row.status].message }}
            .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 1, 20)")
              span.oval.pending
              span {{ dockingStationsStatuses[scope.row.status].message }}
            .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 60, 100)")
              span.oval.cancelled
              span {{ scope.row.status }}

        el-table-column(
          prop="user_email"
          :label="$t('docking_stations.user_email')"
        )
          template(slot-scope='scope')
            span(v-if="scope.row.user_email") {{ scope.row.user_email }}
            span(v-else) -
            
      Pagination(
        :view="'dockingStationStatusHistory'"
        @viewData="handleGetDockingStationStatusHistory"
        @handleTableLoading="handleLoading"
      )
</template>

<script>
import { mapState } from "vuex";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { dockingStationsStatuses } from "@utils/statuses";
import { general } from "@src/services/dynamicViews/general";
import Pagination from "@components/_shared/Pagination";

export default {
  name: "DockingStationStatusHistory",
  mixins: [filterUtils, general],

  components: {
    Pagination,
  },

  data() {
    return {
      statusHistory: [],
      statistics: {
        no_of_total_rides: 0,
        no_of_completed_rides: 0,
        no_of_rides_with_other_errors: 0,
        no_of_started_rides: 0,
        no_of_only_ended_rides: 0,
        no_of_only_started_rides: 0,
        no_of_rides_canceled_by_admin: 0,
        no_of_rides_with_hardware_errors: 0
      },

      loading: false,

      inBetween,
      dockingStationsStatuses,
    };
  },

  computed: {
    ...mapState("dockingStation", ["dockingStation"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    handleGetDockingStationStatusHistory(data) {
      this.statusHistory = data;
    },

    handleLoading(loading) {
      this.loading = loading
    },
  },
};
</script>
