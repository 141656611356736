<template lang="pug">
  .upload-firmwares
    el-form(
      :model="form"
      ref="FirmwareForm"
      @submit.native.prevent
      class="form-container"
    )
      el-row(:gutter="24")
        el-form-item(
          prop="comment"
          :label="$t('firmwares.comment')"
          class="form-item"
        )
          el-input(
            v-model="form.comment"
            name="comment"
          )
      el-row.d-flex.mt-3(:gutter="24")
        .upload-wrapper.d-flex
          label(for="file-upload" class="file-upload-label")
            //- span.file-name {{ uploadFileName }}
            input.pl-5(
              @change="selectFile"
              ref="fileupload"
              id="file-upload" 
              type="file"
              slot="trigger"
            )
      el-button(
        type="primary"
        @click="submitForm('FirmwareForm')"
        :disabled="form.file && form.comment ? false : true"
        :class="form.file && form.comment ? 'dark-blue-btn' : 'gray-btn'"
        class="submit-button"
      ) {{ $t('firmwares.confirm') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState, mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "UploadFirmware",
  mixins: [general],

  data() {
    return {
      form: {
        file: null,
        comment: null
      },
      uploadFileName: this.$t("dialogs.inventory.upload_file"),

      dynamicViewsConstants,
      setObjectValues
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["hideUploadFirmwareDialog"]),
    ...mapMutations("firmware", ["setShouldFetchFirmwares"]),

    ...mapActions("firmware", ["firmwareUpload"]),

    clearForm() {
      this.setObjectValues(this.form, "");
      this.$refs.fileupload.value = null;
    },

    selectFile(e) {
      if (e.target.files[0] === undefined) {
        this.form.file = undefined;
        this.uploadFileName = this.$t("dialogs.inventory.upload_file");
        return;
      }

      this.form.file = e.target.files[0];
      const file = document.querySelector("#file-upload");
      this.uploadFileName = file.value
        .split("\\")
        [file.value.split("\\").length - 1].toString();
    },

    isFileTypeValid() {
      let allowedTypes = /(\.bin)$/i;
      return allowedTypes.exec(this.form.file.name);
    },

    submitForm(formName ) {
      this.loading = true;
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          if (this.form.file !== undefined) {
            if (this.isFileTypeValid()) {
              const formData = new FormData();
              formData.append("file", this.form.file, this.form.file.name);
              formData.append("comment", this.form.comment);

              this.firmwareUpload(formData)
                  .then(() => {
                    this.clearForm();
                    this.hideUploadFirmwareDialog();
                    this.setShouldFetchFirmwares(true);
                  })
                  .finally(() => {
                    this.loading = false;
                  });
            } else {
              this.$message({
                type: "warning",
                message: this.$t("dialogs.inventory.invalid_filename"),
              });
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@variables";

.upload-wrapper {
  align-items: center;
  margin-bottom: 20px;
}

.file-name {
  margin-left: 10px;
  color: #606266;
}

.submit-button {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
}

</style>
