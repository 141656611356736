<template lang="pug">
  .view-transaction-details
    .transactions-history-table
      el-table#expandTable(
        :data="transactions"
        v-loading="loadingTable"
        :gutter="0"
        center
        height="250"
        style="width: 100%;"
        ref="expandTable"
        @expand-change="handleViewGroupRideRelationships"
      )
        el-table-column(
          type='expand'
        )
          template(slot-scope="props")
            el-row
              el-col(
                :span="11"
                :offset="1"
              )
                ul
                  li(v-if="transaction.ride.id") {{ $t('common.ride') }} : {{ transaction.ride.id }}
                  li(v-else) {{ $t('common.group_ride') }} : {{ transaction.group_ride.id }}
                  li(v-if="transaction && transaction.refunded_amount") {{ $t('common.amount_refunded') }} : {{ transaction.refunded_amount }} {{ $t("label.ron.label")}}
                  li(v-else) {{ $t('common.amount_refunded') }} : 0 {{ $t("label.ron.label")}}
                  li(v-if="transaction && transaction.ride.price") {{ $t('common.ride_price_paid') }} : {{ $n(transaction.ride.price, 'currency') + "/ \n" + $n(transaction.ride.already_paid_ammount,'currency') }}
              el-col(
                :span="12"
              )
                ul
                  li.text-underline.cursor-pointer(
                    v-if="transaction && transaction.cost_center.name"
                    @click="goToWIthId('cost_centers', '');hideViewTransactionDetailsDialog();"
                  ) {{ $t('common.cost_center') }} : {{ transaction.cost_center.name }} 
                  li.text-underline.cursor-pointer(
                    v-if="transaction && transaction.customer.first_name && transaction.customer.last_name"
                    @click="goToWIthId('customers', transaction.customer.id);hideViewTransactionDetailsDialog();"
                    ) {{ $t('common.customer_name') }} : {{ transaction.customer.first_name + " " + transaction.customer.last_name }}
                  li(v-if="transaction && transaction.ride.duration_seconds") {{ $t('common.ride_duration') }} : {{ computeRideDuration(transaction.ride.duration_seconds) }}
        el-table-column(
          prop="id"
          :label="$t('label.id')"
        )
          template(v-slot='scope')
            span {{ scope.row.id }}
        el-table-column(
          prop="group_ride.id"
          :label="$t('common.group_ride')"
        )
          template(v-slot='scope')
            span {{ scope.row.group_ride.id }}    
        el-table-column(
          prop="dateAndTime"
          :label="$t('transactions.date_and_time')"
        )
          template(v-slot='scope')
            span {{ computeDateFormat(scope.row.created_at) }}
        el-table-column(
          prop='amount'
          :label="$t('common.amount_to_pay')"
        )
          template(v-slot='scope')
            span {{ $n(scope.row.amount, 'currency') }}
        el-table-column(
          prop='status'
          :label="$t('common.status')"
        )
          template(slot-scope='scope')
            .status-wrapper(v-if="scope.row.status_object.color=='green'")
              span {{ scope.row.status_object.label }}
              span.oval.completed
            .status-wrapper(v-if="scope.row.status_object.color=='red'")
              span {{ scope.row.status_object.label }}
              span.oval.cancelled
            .status-wrapper(v-if="scope.row.status_object.color=='yellow'")
              span {{ scope.row.status_object.label }}
              span.oval.pending
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { transactionHistoryStatuses, transactionStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "ViewTransactionDetails",
  mixins: [filterUtils, routerUtils, general],

  data() {
    return {
      group_rides_relationships: [],
      transactions: [],
      transaction: null,
      transaction_data: null,
      transactionHistory: [],
      transactionStatuses,
      transactionHistoryStatuses,
      inBetween,
      loadingTable: false,
      dynamicViewsConstants,
      customerObject: []
    };
  },

  watch: {
    async isViewTransactionDetailsDialogVisible() {
      await this.handleGetTransactionHistory()
    },
  },

  computed: {
    ...mapState("dialog", ["isViewTransactionDetailsDialogVisible"]),

    ...mapState("auth", ["user"]),

    ...mapState('transaction', ['group_ride']),
  },

  async mounted() {
    await this.handleGetTransactionHistory()
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewTransactionDetailsDialog",
      "hideViewTransactionDetailsDialog",
      "showViewPaymentActionDialog",
    ]),

    ...mapActions("transaction", ["getGroupRideInfo", "getTransactionInfo"]),

    async handleGetTransactionHistory() {
      this.loadingTable = true;
      this.group_rides_relationships  = await this.getGroupRideInfo(this.group_ride.id);
      this.transactions = this.group_rides_relationships.transactions
      this.loadingTable = false;
    },

    async fetchTransactionInfo(id) {
      this.transaction_data = await this.getTransactionInfo(id);
      this.transaction = this.transaction_data.data
    },

    timedOutHandleViewGroupRideRelationships(row){
      setTimeout(this.handleViewGroupRideRelationships(row), 3000);
    },

    async handleViewGroupRideRelationships(row) {
      var element = document.getElementById("expandTable");
      if(this.last_expanded_row==null){
        await this.fetchTransactionInfo(row.id);
        this.last_expanded_row=row;}
      else
      {
        element.classList.add("noClick");
        await this.$refs.expandTable.toggleRowExpansion(this.last_expanded_row, false);
        await this.fetchTransactionInfo(row.id);
        this.last_expanded_row=row;
        setTimeout(element.classList.remove("noClick"), 5000);
      }
    },

    indexMethod(index) {
      return index + 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;

  span {
    font-size: 16px;
    word-break: break-word;
  }

  .status {
    margin-left: 5px;
  }
}
</style>
