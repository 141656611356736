<template lang="pug">
  div
    el-submenu(
      route index='/docking_stations_map'
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['show_statistics', 'index'])"
    )
      template(slot='title')
        router-link.sub-menu-link(to="/docking_stations_map")
          .img-wrapper
            img(src="@src/assets/sidebar/stations.svg")
          span  {{ $t('sidebar.docking_stations_map') }}
      el-menu-item(
        index='/docking_stations_list'
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['index'])"
      )
        router-link.sub-menu-link(to="/docking_stations_list")
          span.link-custom  {{ $t('sidebar.docking_stations_list') }}
      el-menu-item(
        index='/station_logs'
        v-if="hideLogs()"
      )
        router-link.sub-menu-link(
          to="/station_logs"
        )
          span  {{ $t('sidebar.station_logs') }}
      el-menu-item(
        index='/rent_tariffs'
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['index'])"
      )
        router-link.sub-menu-link(
          to="/rent_tariffs"
        )
          span  {{ $t('sidebar.rent_tariffs') }}
    el-menu-item(
      index='/docking_stations_map'
      v-if=`
            showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['show_statistics']) &&
            !showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['index'])
        `
    )
      template(slot='title')
        router-link.sub-menu-link(to="/docking_stations_map")
          .img-wrapper
            img(src="@src/assets/sidebar/stations.svg")
          span  {{ $t('sidebar.docking_stations_map') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { roles } from "@utils/roles";

export default {
  name: "DockingStationsMapItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  },
  methods: {
    hideLogs() {
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    }
  }
}
</script>
