<template lang="pug">
  .chart
    apexchart(
      type="line"
      :height="350"
      :weight="230"
      :options="chartRotationOptions"
      :series="chartTransactions"
      :key="chartComponentKey"
    )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import { chartRotationOptions } from "@utils/generalUtils";
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import Statistic from "@components/_shared/Statistic";

export default {
  name: "ChartRotation",
  mixins: [filterUtils],
  props: {
    chartData: {
      type: Array,
      default: [],
    },
  },

  components: {
    apexchart: VueApexCharts,
    Statistic,
  },

  watch: {
    chartData() {
      this.mapTransactionsForChart();
    },
  },

  data() {
    return {
      chartTransactions: [
        {
          name: this.$t("trackers.roll"),
          data: [],
        },
        {
          name: this.$t("trackers.pitch"),
          data: [],
        }
      ],

      chartComponentKey: 0,
      chartRotationOptions: chartRotationOptions,
    };
  },

  created() {
    this.mapTransactionsForChart();
  },

  methods: {
    mapTransactionsForChart() {
      this.computerotationInfos();
    },

    computerotationInfos() {
      this.chartComponentKey += 1;

      this.chartRotationOptions.xaxis.categories = [];

      this.chartTransactions[0].data.splice(0);
      this.chartTransactions[1].data.splice(0);

      const mappedDays = [];

      // map the transactions to match apex data
      if (this.chartData.length > 0) {
        this.chartData.forEach((rotation) => {
          const rotationDate = new Date(rotation.date);
          let timeFormatter = new Intl.DateTimeFormat('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
          });

          let dateFormatter = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: '2-digit'
          });

          let timePart = timeFormatter.format(rotationDate);
          let datePart = dateFormatter.format(rotationDate);
          mappedDays.push(`${timePart} ${datePart}`);
          this.chartTransactions[0].data.push(rotation.roll);
          this.chartTransactions[1].data.push(rotation.pitch);

          this.chartRotationOptions = {
            ...this.chartRotationOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

              yaxis: {
                title: {
                  text: "",
                },
              },
            },
          };
        });
      }
    },
  },

};
</script>
