<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='docks'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='name'
      :label="$t('common.dock_name')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.name) }}
        span(v-if="scope.row.name.length <= MAX_FIELD_LENGTH") {{ scope.row.name }}
    el-table-column(
      prop='address'
      :label="$t('label.address')"
      width="200"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.address.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.address"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.address) }}
        span(v-if="scope.row.address.length <= MAX_FIELD_LENGTH") {{ scope.row.address }}
    el-table-column(
      prop='cost_center_name'
      :label="$t('common.cost_center_name')"

    )
      template(slot-scope='scope')
        span {{ scope.row.cost_center_name }}
    el-table-column(
      prop='bays_count'
      :label="$t('docking_stations_list.bike_available_capacity')"
    )
      template(slot-scope='scope')
        span {{ `${scope.row.no_of_available_bikes}/${scope.row.bays_count}` }}
    el-table-column(
      prop='status'
      :label="$t('common.status')"
    )
      template(slot-scope='scope')
        .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 21, 40)")
          span.oval.completed
          span {{ dockingStationsStatuses[scope.row.status].message }}

        .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 41, 60)")
          span.oval.cancelled
          span {{ dockingStationsStatuses[scope.row.status].message }}
        .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 1, 20)")
          span.oval.pending
          span {{ dockingStationsStatuses[scope.row.status].message }}
        .status-wrapper(v-if="inBetween('docksStatuses', scope.row.status, 60, 100)")
          span.oval.cancelled
          span {{ scope.row.status }}
    el-table-column(
      prop='online'
      :label="$t('label.online_offline')"
    )
      template(slot-scope='scope')
        .status-wrapper(v-if="scope.row.online")
          span.oval.completed
          span {{ $t('common.online') }}
        .status-wrapper(v-else)
          span.oval.cancelled
          span {{ $t('common.offline') }}
        span(v-if="hideSubmenu()") {{ scope.row.last_pong }}
    el-table-column(
      v-if="hideSubmenu()"
      prop='device_uuid'
      :label="$t('label.paired_unpaired')"
      width="110"
    )
      template(slot-scope='scope')
        button.begin-maintenance-btn.cursor-pointer(
          v-if="showPairUnpairButton(scope.row)"
          @click="handleUnpairDock(scope.row)"
        ) {{ $t('docking_stations_list.unpair') }}
        span(v-else) -
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        .grid-buttons
          img.cursor-pointer.mr-2(
            src="@assets/preview.svg"
            @click.prevent="!btnIsDisabled && handleViewDockDetails(scope.row)"
            :class="{ 'disabled-img': btnIsDisabled }"
          )
          img.cursor-pointer.mr-2(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['update'])"
            src="@assets/actions/edit.svg"
            @click.prevent="!btnIsDisabled && handleDockManipulation(scope.row, 'editDock')"
            :class="{ 'disabled-img': btnIsDisabled }"
          )
          img.cursor-pointer.mr-2(
            v-if="hideSubmenu()"
            src="@assets/actions/fix.svg"
            @click.prevent="!btnIsDisabled && handleDockSettings(scope.row)"
            :class="{ 'disabled-img': btnIsDisabled }"
          )
          img.cursor-pointer(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['destroy'])"
            src="@assets/actions/delete.svg"
            @click.prevent="!btnIsDisabled && handleDockManipulation(scope.row, 'deleteDock')"
            :class="{ 'disabled-img': btnIsDisabled }"
          )
</template>

<script>
import { roles } from "@utils/roles";
import { inBetween, MAX_FIELD_LENGTH } from "@utils/filterUtils";
import { dockingStationsStatuses } from "@utils/statuses";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";

export default {
  name: "DockingStationListViewTable",
  mixins: [routerUtils, general],

  props: {
    docks: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
      inBetween,
      dockingStationsStatuses,
      dynamicViewsConstants,
      btnIsDisabled: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "collectionDataToDelete",
      "dialogData",
      "allowDeletion",
      "dataToDelete",
      "passwordConfirmation",
    ]),
  },

  watch: {
    allowDeletion() {
      this.handleDeleteDock();
    },
  },

  methods: {
    ...mapActions("dockingStation", ["getDock", "deleteDock", "updateDock"]),

    ...mapMutations("dialog", [
      "showAddDockingStationDialog",
      "showEditDockingStationDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
      "showViewDockDetailsDialog",
    ]),

    ...mapMutations("dockingStation", [
      "setDockingStation",
      "setShouldFetchDocks",
    ]),
    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setAllowDeletion",
      "setDataToDelete",
      "setLoading",
    ]),

    hideSubmenu() {
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    handleDockManipulation(dockingStation, type) {
      if (type === "deleteDock") {
        this.handleOpenDeleteDockDialog(dockingStation);
      } else if (type === "editDock") {
        this.setDockingStation(dockingStation);
        this.showEditDockingStationDialog();
      }
    },

    handleDockSettings(dockingStation) {
      this.setDockingStation(dockingStation);
      this.goToWIthId(
        "docking_station_configurable_settings",
        dockingStation.id
      );
    },

    handleDeleteDock() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };
        this.deleteDock(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    async handleViewDockDetails(dock) {
      this.btnIsDisabled = true;
      const response = await this.getDock(dock.id);
      this.showViewDockDetailsDialog();
      this.setDockingStation(response);
      this.btnIsDisabled = false;
    },

    handleOpenDeleteDockDialog(dock) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: this.$t("docking_stations_list.validations.delete_title"),
        dialogMessage: this.$t(
          "docking_stations_list.validations.delete_warning"
        ),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(dock);
    },

    handleUnpairDock(dock) {
      this.$confirm(
        this.$t("docking_stations_list.confirm_unpair_message"),
        this.$t("docking_stations_list.confirm_unpair_title"),
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          const payload = {
            id: dock.id,
            device_uuid: "",
          };
          await this.updateDock(payload);
          this.setShouldFetchDocks(true);
        })
        .catch(() => {});
    },

    showPairUnpairButton(dock) {
      return dock.device_uuid && dock.device_uuid !== "";
    },
  },
};
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;
  word-break: keep-all !important;
}

.grid-buttons {
  display: inline-grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: 40px 40px;
  grid-gap: 3px;

  img {
    margin: auto 0;
  }
}

.disabled-img {
  opacity: 0.5;
  cursor: not-allowed;
}

</style>
