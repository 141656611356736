<template lang="pug">
  .docking-stations-map
    h3 {{ $t('docking_stations_map.docking_stations_map') }}
    .statistics.d-flex
      .statistic.docking-stations-chart.d-flex
        #chart.d-flex.align-items-center
          apexchart(
            v-if="loadedChartInfo"
            type="pie"
            width="140"
            :options="chartOptions"
            :series="series"
          )
          .custom-legend.d-flex
            span.d-flex.align-items-center
              img.mr-2(src="@assets/dockingStationsMap/available-location.svg")
              span.font-weight-bold {{ series[0] }} {{ $t('docking_stations_map.functional_stations') }}
            span.d-flex.align-items-center.mt-2
              img.mr-2(src="@assets/dockingStationsMap/unavailable-location.svg")
              span.font-weight-bold {{ series[1] }} {{ $t('docking_stations_map.unavailable_stations') }}
      Statistic.statistic.d-flex(
        :data="ridesInProgress"
        :text="$t('docking_stations_map.rides_in_progress')"
        :icon_name="'clock'"
      )
      Statistic.statistic.d-flex(
        :data="availableBikes"
        :text="$t('docking_stations_map.available_bikes')"
        :icon_name="'bike'"
      )
      Statistic.statistic.d-flex(
        :data="nrOfClients"
        :text="$t('docking_stations_map.nr_customers')"
        :icon_name="'customer'"
      )
    DockingStationsMap(
      v-if="loadedChartInfo"
      :docks="[...workingDocks, ...underMaintenanceDocks]"
    )
</template>

<script>
import DockingStationsMap from "@components/docking_stations/DockingStationsMap.vue";
import { mapActions, mapState } from "vuex";
import Statistic from "@components/docking_stations/Statistic.vue";
import VueApexCharts from 'vue-apexcharts'
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  mixins: [general],

  components: {
    DockingStationsMap,
    Statistic,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      ridesInProgress: 0,
      availableBikes: 0,
      nrOfClients: 0,
      docks: [],
      workingDocks: [],
      underMaintenanceDocks: [],

      // apex settings
      loadedChartInfo: false,
      series: [0, 0],
      chartOptions: {
        chart: {
          width: 280,
          type: 'pie',
          selection: {
            show: false
          }
        },
        tooltip: {
          enabled: false
        },
        stroke: {
          show: false,
        },
        fill: {
          colors: ['#266fca', '#e41e26']
        },
        legend: {
          show: false
        },
        labels: ['working','unavailable'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'center'
            }
          }
        }]
      },

      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetMapStatistics()
    await this.handleComputeChartInfo()
  },

  methods: {
    ...mapActions('map', ['getMapStatistics']),

    async handleGetMapStatistics() {
      const response = await this.getMapStatistics()
      this.workingDocks = response.docking_stations.data
      // if (response.docking_stations.working) {
      //   this.workingDocks = response.docking_stations.working
      // }

      // if (response.docking_stations.under_maintenance) {
      //   this.underMaintenanceDocks = response.docking_stations.under_maintenance
      // }

      this.ridesInProgress = response.no_of_rides_in_progress
      this.availableBikes = response.no_of_bikes_available
      this.nrOfClients = response.no_of_clients
    },

    handleComputeChartInfo() {
      if (this.workingDocks && this.workingDocks.length > 0) {
        this.workingDocks.forEach(workingDock => {
          if(workingDock.attributes.status == "working")
            this.series[0] += 1
          else
            this.series[1] += 1
        })
      }

      // if (this.underMaintenanceDocks && this.underMaintenanceDocks.length > 0) {
      //   this.underMaintenanceDocks.forEach(underMaintenanceDock => {
      //     this.series[1] += 1
      //   })
      // }

      this.loadedChartInfo = true
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.docking-stations-chart {
  align-items: center;
}

.docking-stations-map {
  .custom-legend {
    flex-direction: column;
    min-width: 130px;

    img {
      height: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .statistics {
    margin: 24px 0;

    .statistic {
      align-items: center;
      width: 244px;
      padding: 18px 25px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-right: 24px;
    }
  }
}
</style>
