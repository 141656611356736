<template lang="pug">
  .export-maintenanaces
    FilterDatePicker.filter-date-picker(
      v-model="dateRange"
    )
    FilterCostCenterDropdown.filter-cost-center(v-model="selectedCostCenterId"
    :view="'exportMaintenances'")
    .export-btn
      el-button.dark-blue-btn(
        v-loading="loading"
        @click="handleExportBikesMaintenances()"
      )
        h4 {{ $t('common.export') }}
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { disabledPastDates } from "@utils/dateRange";
import { customValidations } from "@utils/customValidators";
import { filterUtils } from "@utils/filterUtils";
import { setObjectValues } from "@utils/generalUtils";

import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";

export default {
  name: "ExportBikesMaintenances",
  mixins: [customValidations, filterUtils],

    components: {
    FilterDatePicker,
    FilterCostCenterDropdown
  },

  data() {
    return {
      selectedCostCenterId: "",
      dateRange: [new Date(new Date().setHours(0,0,0,0)), new Date()],
      cost_centers: [],

      loading: false,
      setObjectValues
    }
  },

  created() {
    this.handleGetCostCenters();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapMutations("dialog", ["hideExportBikesMaintenancesDialog"]),
    ...mapActions("bike", ["getBikesMaintenances"]),

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("?page_size=1000");
      this.cost_centers = cost_centers.data;
    },

    clearForm() {
      this.selectedCostCenterId = "";
      this.dateRange = [new Date(new Date().setHours(0,0,0,0)), new Date()];
      this.loading = false;
    },
    
    async handleExportBikesMaintenances() {
      this.loading = true;

      if(this.selectedCostCenterId == "" || this.dateRange == null) {
        this.$message({
          type: "error",
          message: this.$t("dialogs.export"),
        });
        this.loading = false;
        return;
      }

      let params = `?cost_center_id=${this.selectedCostCenterId}&date_range[start_date]=${this.dateRange[0].toISOString()}&date_range[end_date]=${this.dateRange[1].toISOString()}`;

      try {
        const response = await this.getBikesMaintenances(params)

        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '');
        a.download = decodeURIComponent(fileName);
        a.click();

        this.clearForm();
        this.hideExportBikesMaintenancesDialog();
      } catch (error) {
        this.loading = false;
        console.error('Download failed:', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.export-maintenanaces {
  padding: 24px 0;
  // margin-top: 40px;
  min-width: 99%;

  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  justify-content: center !important;
  align-items: center !important;

  background-color: #eff2fa;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}

.filter-date-picker {
  margin: auto;
  
}
.filter-cost-center {
  width: 85%;
  margin: auto;
}

.export-btn {
  margin: auto;
  margin-top: 15px;
}
</style>
