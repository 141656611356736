<template lang="pug">
  .configurable-settings-view
    h3 {{ $t('configurable_settings.configurable_settings') }}
    .tab-view
      FilterTabs(
        v-model="activeName"
        :view="'admin_settings'"
        :tabs="tabs"
      )
    .configurable-settings-wrapper.my-4
      ConfigurableSettingsTable(
        v-loading="loading"
        :configSettings="configSettings"
      )
    Pagination(
      v-if="activeName && activeName !== ''"
      :view="'admin_settings'"
      :activeName="activeName"
      @viewData="handleGetData"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import FilterTabs from "@components/_shared/FilterTabs";
import ConfigurableSettingsTable from "@components/settings/ConfigurableSettingsTable"
import { mapState } from "vuex";
import Pagination from "@components/_shared/Pagination";

export default {
  name: "ConfigurableSettingsView",
  components: { ConfigurableSettingsTable, Pagination, FilterTabs },

  data() {
    return {
      configSettings: [],
      loading: false,
      activeName: "all",
      tabs: [
        { name: "all", roles: [] },
        { name: "cms", roles: [] },
        { name: "mobile", roles: [] },
        { name: "station", roles: [] },
        { name: "rfid", roles: [] },
        { name: "tracker", roles: [] },
        { name: "watchdog", roles: [] },
      ],
    }
  },

  computed: {
    ...mapState('configSettings', ['shouldFetchConfSettings']),
  },

  methods: {
    handleLoading(loading) {
      this.loading = loading;
    },

    handleGetData(data){
      this.loading = true;
      this.configSettings = data;
      this.loading = false;
    }
  }
};
</script>
