<template lang="pug">
  .inventory-view
    h3 {{ $t('inventory.inventory') }}
    .tab-view
      FilterTabs(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_status'])"
        v-model="activeName"
        :view="'bikes'"
        :tabs="tabs"
      )
    .filters
      FilterDatePicker(
        v-model="dateRange"
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_date_range'])"
      )
    .inventory-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_keyword'])"
        )
          el-button.darker-blue-btn(
            v-if="activeName == 'inventory' && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['assign_cost_center'])"
            @click="handleOpenAssignCostCenterDialog"
          )
            h4 {{ $t('inventory.assign_cost_center') }}
          el-button.dark-blue-btn(
            v-if="activeName == 'inventory' && showItemDynamically(user, dynamicViewsConstants.resource_names.INVENTORY_BIKE, ['create'])"
            @click="showAddBikeInventoryDialog"
          )
            h4 {{ $t('common.add') }}
          el-button.green-btn(
            v-if="activeName == 'inventory' && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['bulk_insert'])"
            @click="showAddBulkDialog"
          )
            h4 {{ $t('inventory.add_bulk') }}
      el-row
        el-col(:span='24')
          InventoryViewTable(
            v-if=`activeName == 'inventory'`
            v-loading="loading"
            :bikes="bikes"
            @selectionChange="handleSelectionChange"
          )
    Pagination(
      :view="`${viewName}`"
      :search="search"
      :dateRange="dateRange"
      @viewData="handleDataForPagination"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterTabs from "@components/_shared/FilterTabs";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import InventoryViewTable from "@components/bikes_inv/InventoryViewTable";
import TrackerViewTable from "@components/bikes_inv/TrackerViewTable";

export default {
  name: "Inventory",
  mixins: [filterUtils, general],
  components: {
    Pagination, 
    FilterTabs,
    FilterDatePicker,
    SearchWithButtons,
    InventoryViewTable,
    TrackerViewTable
  },

  data() {
    return {
      search: "",
      tabs: [
        { name: "inventory", bikes: [] },
      ],
      activeName: 'inventory',
      viewName: 'inventory',
      dateRange: [],
      bikes: [],
      multipleSelection: [],
      loading: false,
      dynamicViewsConstants
    };
  },
  watch: {
    activeName(){
       this.viewName = this.activeName
      // if(this.activeName == 'all'){
      //   handleGetInventory()
      // this.handleDataForPagination()
      // }
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("bike", ["deleteBike"]),

    ...mapMutations("dialog", [
      "showAddBikeInventoryDialog",
      "showViewAssignCostCenterDialog",
      "showAddBulkDialog",
    ]),

    ...mapMutations("bike", ["setSelectedBikes"]),

    handleDataForPagination(data){
      if(this.activeName == 'inventory'){
        this.bikes = data;
      }
    },

    handleGetInventory(data) {
      this.bikes = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    handleSelectionChange(value) {
      this.multipleSelection = value;
      this.setSelectedBikes(this.multipleSelection);
    },

    handleOpenAssignCostCenterDialog() {
      if (this.multipleSelection.length > 0) {
        this.showViewAssignCostCenterDialog();
      } else {
        this.$message({
          type: "warning",
          message: this.$t("inventory.validations.select_bikes"),
        });
      }
    },
  },
};
</script>
