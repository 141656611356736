<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='logs'
    @selection-change="$emit('selectionChange', $event)"
  )
    el-table-column(
      type="selection"
      width="55"
    )
    el-table-column(
      prop='device_name'
      :label="$t('label.device_name')"
      width="300px"
    )
      template(slot-scope='scope')
        span {{ joinValuesWithComma([scope.row.device_name, scope.row.platform_name, scope.row.browser_name, scope.row.user_agent])}}
    el-table-column(
      prop='email'
      :label="$t('label.email')"
      width="350px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.email") {{ scope.row.email }}
        span(v-else) -
    el-table-column(
      prop='first_name && last_name'
      :label="$t('label.first_name')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.first_name || scope.row.last_name") {{ joinValuesWithComma([scope.row.first_name, scope.row.last_name]) }}
        span(v-else) -
    el-table-column(
      prop='ip'
      :label="$t('label.ip_address')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.ip") {{ scope.row.ip }}
        span(v-else) -
    el-table-column(
      prop='event && item_type'
      :label="$t('label.event')"
    )
      template(slot-scope='scope')
        span {{ joinValuesWithComma([scope.row.event, scope.row.item_type]) }}
    el-table-column(
      prop='created_at'
      :label="$t('label.date')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-1(
          src="@assets/actions/delete.svg"
          @click="handleDeleteLogDialog(scope.row)"
        )
</template>

<script>
import { filterUtils, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { joinValuesWithComma } from "@utils/generalUtils";
import { mapMutations } from "vuex";

export default {
  name: 'LogsViewTable',
  mixins: [filterUtils],

  props: {
    logs: {
      type: Array
    }
  },

  data() {
    return {
      MAX_FIELD_LENGTH
    }
  },

  methods: {
    trimTableField,
    joinValuesWithComma,
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    handleDeleteLog() {
      if (this.allowDeletion) {
        let computedQueriesUrl = `${this.dataToDelete.id}?password_confirmation=${this.passwordConfirmation}`;

        // TODO: to be handled when endpoint is ready
        // this.deleteBike(computedQueriesUrl)
        //     .then(() => {
        //       this.hideViewPasswordValidationDeleteDialog();
        //     })
        //     .catch((error) => {
        //       this.setAllowDeletion(false);
        //     })
        //     .finally(() => {
        //       this.setLoading(false);
        //     });
      }
    },

    handleDeleteLogDialog(log) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("logs.validations.delete_title")} #${
            log.id
        }`,
        dialogMessage: this.$t("logs.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(log);
    },
  }
}
</script>
