import SettingsView from "@views/SettingsView";
import ForgotPasswordView from "@views/auth/ForgotPasswordView";
import LoginView from "@views/auth/LoginView";
import CustomerRideHistoryView from "@views/CustomerRideHistoryView";
import CustomersView from "@views/CustomersView";
import RentTariffsView from "@views/RentTariffsView";
import StationLogsView from "@views/StationLogsView";
import InventoryView from "@views/InventoryView";
import BikesView from "@views/BikesView";
import TrackersView from "@views/TrackersView";
import BikesMapView from "@views/BikesMapView";
import RidesView from "@views/RidesView";
import TransactionsView from "@views/TransactionsView";
import TicketsView from "@views/TicketsView";
import TrackerDetailsView from "@views/TrackerDetailsView";
import UsersView from "@views/users/UsersView";
import RolesView from "@views/roles/RolesView";
import RoleView from "@views/roles/RoleView";
import EditRoleView from "@views/roles/EditRoleView";
import AddUserView from "@views/users/AddUserView";
import DockingStationsListView from "@views/DockingStationsListView";
import DockingStationsMapView from "@views/DockingStationsMapView";
import CostCentersView from "@views/CostCentersView";
import ChangePasswordView from "@views/auth/ChangePasswordView";
import ChangeEmailView from "@views/auth/ChangeEmailView";
import EditUserView from "@views/users/EditUserView";
import UserView from "@views/users/UserView";
import LogsView from "@views/LogsView";
import TechnicalDashboardView from "@views/TechnicalDashboardView";
import Firmwares from "@views/Firmwares"
import StationCommands from "@views/StationCommands";
import { dynamicViewsConstants } from "@src/constants";
import ConfigurableSettings from "@views/ConfigurableSettingsView";
import AddRoleView from "@views/roles/AddRoleView";
import TicketsSettingsView from "@views/TicketsSettingsView";
import DockingStationSettingsView from "@components/docking_stations/DockingStationSettingsView";
import CostCenterSettingsView from "@components/cost_centers/CostCenterSettingsView";
import EditCustomerView from "@views/customers/EditCustomerView";
import TicketView from "@components/tickets/TicketView";
import SmartCardsView from "@views/SmartCardsView";
import AdminCommands from "@views/AdminCommands.vue";

export default [
  {
    path: "/",
    redirect: { name: "settings" },
    requiresAuth: false,
  },
  {
    path: "/cost_centers",
    name: "cost_centers",
    component: CostCentersView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.COST_CENTER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/cost_center_configurable_settings/:id",
    name: "cost_center_configurable_settings",
    component: CostCenterSettingsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.COST_CENTER,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/docking_stations_map",
    name: "docking_stations_map",
    component: DockingStationsMapView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.DOCKING_STATION,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/docking_stations_list",
    name: "docking_stations_list",
    component: DockingStationsListView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.DOCKING_STATION,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/docking_station_configurable_settings/:id",
    name: "docking_station_configurable_settings",
    component: DockingStationSettingsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.DOCKING_STATION,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: UsersView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: RolesView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.ROLE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/roles/add_role",
    name: "add_role",
    component: AddRoleView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.ROLE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/roles/:id",
    name: "role",
    component: RoleView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.ROLE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/roles/edit/:id",
    name: "role_edit",
    component: EditRoleView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.ROLE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/users/add_user",
    name: "add_user",
    component: AddUserView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/users/edit_user/:id",
    name: "user_edit",
    component: EditUserView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/users/:id",
    name: "user_view",
    component: UserView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.USER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/tickets",
    name: "tickets",
    component: TicketsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TICKET,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/tickets/details/:id",
    name: "ticket_view",
    component: TicketView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TICKET,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/smart_cards",
    name: "smart_cards",
    component: SmartCardsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.SMART_CARD,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/transactions",
    name: "transactions",
    component: TransactionsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TRANSACTION,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/rides",
    name: "rides",
    component: RidesView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.RIDE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/bikes_map",
    name: "bikes_map",
    component: BikesMapView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.BIKE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/bikes",
    name: "bikes",
    component: BikesView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.BIKE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/trackers",
    name: "trackers",
    component: TrackersView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TRACKER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/trackers/:id",
    name: "tracker",
    component: TrackerDetailsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.TRACKER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/inventory",
    name: "inventory",
    component: InventoryView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.INVENTORY_BIKE,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/rent_tariffs",
    name: "rent_tariffs",
    component: RentTariffsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.RENT_TARIFF,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/station_logs",
    name: "station_logs",
    component: StationLogsView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.DOCKING_STATION,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/customers",
    name: "customers",
    component: CustomersView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.CUSTOMER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/customers/:id",
    name: "customer",
    component: CustomerRideHistoryView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.CUSTOMER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/customers/edit_customer/:id",
    name: "customer_edit",
    component: EditCustomerView,
    meta: {
      requiresAuth: true,
      resource_name: dynamicViewsConstants.resource_names.CUSTOMER,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/forgot_password",
    name: "forgot_password",
    component: ForgotPasswordView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/logs",
    name: "logs",
    component: LogsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/technical_dashboard",
    name: "technical_dashboard",
    component: TechnicalDashboardView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/softwares",
    name: "softwares",
    component: Firmwares,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/station_commands",
    name: "station_commands",
    component: StationCommands,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/configurable_settings",
    name: "configurable_settings",
    component: ConfigurableSettings,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/tickets_settings",
    name: "tickets_settings",
    component: TicketsSettingsView,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
  {
    path: "/change_password",
    name: "change_password",
    component: ChangePasswordView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/change_email",
    name: "change_email",
    component: ChangeEmailView,
    meta: {
      requiresAuth: false,
      requiresSuperAdmin: false,
    },
  },
  {
    path: "/admin_commands",
    name: "admin_commands",
    component: AdminCommands,
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
    },
  },
];
