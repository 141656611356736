<template lang="pug">
  .edit-role-view.pb-2(v-loading="pageLoading")
    h3 {{ $t('roles.edit_role') }}
    el-breadcrumb.last-breadcrumb-bold.mt-3(separator="/")
      el-breadcrumb-item(:to="{ path: '/roles' }") {{ $t('roles.roles') }}
      el-breadcrumb-item {{ $t('roles.edit_role') }}
    .edit-role-wrapper.pt-4(v-if="role")
      .role-details
        el-row.pl-4.pr-4.pb-1
          el-col(:span="16")
            h3.mb-2 {{ upperCaseFirstLetters(`${$t('roles.permissions').toLowerCase()} ${role.name}`) }}
        RolePermissionsList(
          v-model="checkList"
          :role="role"
        )
      .hr-custom.mt-4.mb-4
      el-button.dark-blue-btn.pop-up-btn.ml-4(
        @click="handleSubmit"
        :loading="submitLoading"
      ) {{ $t('common.save_changes') }}
</template>

<script>
import RolePermissionsList from "@components/_shared/RolePermissionsList"
import { upperCaseFirstLetters } from "@utils/generalUtils";
import { mapActions } from "vuex";

export default {
  components: { RolePermissionsList },

  data() {
    return {
      role: null,
      checkList: [],
      pageLoading: false,
      submitLoading: false,
      upperCaseFirstLetters,
    };
  },

  async mounted() {
    await this.handleGetRole();
  },

  methods: {
    ...mapActions("role", [
      "getRole",
      "assignPermissions",
    ]),

    async handleGetRole() {
      this.pageLoading = true;
      this.role = await this.getRole(this.$route.params.id);
      this.pageLoading = false;
    },

    async handleSubmit() {
      this.submitLoading = true;
      let params = {
        role_id: this.role.id,
        permission_ids: this.checkList,
      };
      await this.assignPermissions(params);
      this.submitLoading = false;
    },
  },
};
</script>

