<template lang="pug">
  .modal-bike-details
    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
    )
      el-col(:span="7")
        span {{ $t('common.serial_number') }}

      el-col(:span="7")
        span {{  $t('label.rfid') }}

      el-col(:span="7")
        span {{  $t('common.state_of_charge') }}

      el-col(:span="7")
        span {{ $t('common.tracker') }}

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="7")
        span.break-word {{ bike.serial_number}}

      el-col(:span="7")
        span.break-word {{ bike.rfid}}

      el-col(:span="7")
        span.break-word {{ $t(`${handleBikeStateOfCharge(bike.state_of_charge)}`) }}

      el-col(:span="7")
        span.break-word {{ (bike.tracker && bike.tracker.name) || '-'}}

    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 20px"
    )
      el-col(:span="7")
        span {{ $t('common.cost_center_name') }}

      el-col(:span="7")
        span {{ $t('common.docking_station') }}-{{ $t('common.bay') }}

      el-col(:span="7")
        span {{  $t('common.bike_type') }}

      el-col(:span="7")
        span {{ $t('trackers.serial_number') }}

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="7")
        span.break-word {{ (bike.cost_center && bike.cost_center.name) || '-' }}

      el-col(:span="7")
        span.break-word {{ (bike.docking_station && bike.docking_station.name) || '-' }}-{{ (bike.bay && bike.bay.number) || '-'}}

      el-col(:span="7")
        span.break-word {{ bike.bike_type || '-' }}

      el-col(:span="7")
        span(v-if="bike.tracker")
          span(v-if="bike.tracker.mac" :title="$t('common.mac')") {{ bike.tracker.mac }}
          br
          span(v-if="bike.tracker.imei" :title="$t('common.imei')") {{ bike.tracker.imei }}
          span(v-if="bike.tracker.dev_eui" :title="$t('common.dev_eui')") {{ bike.tracker.dev_eui }}
        span(v-else) -
    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 20px"
    )

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )

    el-row.center.details-bold(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span {{ $t('trackers.latitude') }} {{ $t('trackers.longitude') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_report') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_create') }}

    el-row.center(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span(v-if='bike.tracker.tracker_latest_info.location').break-word {{ bike.tracker.tracker_latest_info.location.latitude  || '-' }}, {{ bike.tracker.tracker_latest_info.location.longitude  || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.location') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.location.reported_at) || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.location') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.location.added_at) || '-' }}
        span(v-else) -
    el-row.center.details-bold(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 20px"
    )
      el-col(:span="14") 
        span {{ $t('trackers.v_in') }}/ {{ $t('trackers.v_bat') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_report') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_create') }}

    el-row.center(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span(v-if='bike.tracker.tracker_latest_info.power') {{ bike.tracker.tracker_latest_info.power.v_in /1000.0 + 'V' || '-' }}/ {{ bike.tracker.tracker_latest_info.power.v_bat /1000.0 + 'V' || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.power') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.power.reported_at) || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.power') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.power.added_at) || '-' }}
        span(v-else) -
    el-row.center.details-bold(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span {{ $t('trackers.fw_version') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_report') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_create') }}

    el-row.center(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span(v-if='bike.tracker.tracker_latest_info.about') {{ computeFWVerionDateFormat(bike.tracker.tracker_latest_info.about.fw_ver)  || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.about') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.about.reported_at) || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.about') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.about.added_at) || '-' }}
        span(v-else) -

    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 15px; margin-left: -25px;"
    )
      el-col
        span {{  $t('common.status') }}
    el-row(
      :gutter="50"
      style="margin-top: 10px; margin-bottom: 16px;"
    )
      .status-wrapper
        el-col(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['update_status'])"
          prop='status'
          :span="7"
        )
          .status-wrapper.d-flex.align-items-center
            .switch(@click="handleSwitchBikeStatus(bike)")
              el-switch(
                :value="bike.status === 'available_in_station'"
                active-color="#13ce66"
                inactive-color="#ff4949"
              )
            span.status {{ bikesStatuses[bike.status].message }}
        el-col(style="margin-left: 215px;")
          span(v-if="bike.status === 'in_ride' || bike.status === 'available_in_station'")
            el-tooltip.item(
              v-if="bike.status === 'in_ride' || bike.status === 'available_in_station'"
              effect="light"
              :content="'Mentenanța nu poate începe dacă bicicleta este disponibilă sau în cursă'"
              placement="top-end"
            )
              button.begin-maintenance-btn.cursor-pointer(
                v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance']) && (bike.status === 'in_ride' || bike.status === 'available_in_station')"
                :class="(bike.status === 'in_ride' || bike.status === 'available_in_station') ? 'gray-btn' : 'dark-blue-btn'"
                :disabled="bike.status === 'in_ride' || bike.status === 'available_in_station'"
                @click="handleStartMaintenance(bike)"
              )
                div(v-if="bike.status !== 'under_maintenance'") {{ $t('dialogs.bikes.begin_maintenance') }}
                div(v-if="bike.status === 'under_maintenance'") {{ $t('dialogs.bikes.continue_maintenance') }}
          span(v-else)
            button.begin-maintenance-btn.cursor-pointer(
              v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance']) && (bike.status != 'in_ride' || bike.status != 'available_in_station')"
              :class="(bike.status === 'in_ride' || bike.status === 'available_in_station') ? 'gray-btn' : 'dark-blue-btn'"
              :disabled="bike.status === 'in_ride' || bike.status === 'available_in_station'"
              @click="handleStartMaintenance(bike)"
            )
              div(v-if="bike.status !== 'under_maintenance'") {{ $t('dialogs.bikes.begin_maintenance') }}
              div(v-if="bike.status === 'under_maintenance'") {{ $t('dialogs.bikes.continue_maintenance') }}
    BikeMap()
</template>

<script>
import { bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import BikeMap from "@components/bikes/viewBike/BikeMap"

export default {
  name: "Details",
  mixins: [filterUtils, general],

  components: { BikeMap },

  data() {
    return {
      bikesStatuses,
      inBetween,
      dynamicViewsConstants,
      trackerData: {
        latitude: null,
        longitude: null,
        reported_at: null,
        created_at: null
      },

      tooltipVisible: false,

    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewRideDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "hideViewBikeDetailsDialog",
      "showViewBikeDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "showStartBikeMaintenanceDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
      "showEditBikeDetailsDialog",
      "showQrCodeDialog",
    ]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    ...mapActions("bike", [
      "getBike",
      "switchBikeStatus",
      "startMaintenance",
      "finishMaintenance",
    ]),

    ...mapActions("dockingStation", ["getDock"]),

    handleBikeStateOfCharge(state_of_charge){
      if (state_of_charge == 1) return 'bikes.state_of_charge.fully_charged'
      if (state_of_charge == 2) return 'bikes.state_of_charge.medium_charged'
      if (state_of_charge == 3) return 'bikes.state_of_charge.low_battery'
      return 'bikes.state_of_charge.undefined'
    },

    handleStartMaintenance(bike) {
      if (bike.status === "under_maintenance") {
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
        // this.hideViewBikeDetailsDialog();
      } else {
        this.startMaintenance(bike.id).then(() => {
          this.setBike(bike);
          this.showViewBikeMaintenanceDialog();
          // this.hideViewBikeDetailsDialog();
          this.setShouldFetchBikes(true);
        });
      }
    },

    async handleSwitchBikeStatus(bike) {
      console.log(bike.id)
      if (bike.status === "available_in_station") {
        let event_name =
          bike.status === "available_in_station"
            ? "make_unavailable"
            : "make_available";

        let params = {
          id: bike.id,
          event_name,
        };
        await this.switchBikeStatus(params);

        this.setShouldFetchBikes(true);
        // this.hideViewBikeDetailsDialog();
        const response = await this.getBike(bike.id);
        this.setBike(response);
        this.showViewBikeDetailsDialog();
      }
        else if (bike.status === "under_maintenance") {
        let params = {
          id: bike.id,
          maintenance_status: "completed",
          next_maintenance_at: this.computeDateFormat(
            this.getCurrentDateAndTime()
          ),
          maintenance_description: "Completed",
        };

        await this.finishMaintenance(params).then(() => {
          let switchParams = {
            id: bike.id,
            event_name: "make_available",
          };
          this.switchBikeStatus(switchParams);
          this.setShouldFetchBikes(true);
          const response = this.getBike(bike.id);
          this.setBike(response);
          this.showViewBikeDetailsDialog();
        });
      } else if (bike.status === "unavailable") {
        if (bike.docking_station) {
          let dockStationObject = await this.getDock(bike.docking_station.id);

          if (dockStationObject.status !== "under_maintenance") {
            //make sure that the dock station is available first
            let event_name = "make_available";

            let params = {
              id: bike.id,
              event_name,
            };
            await this.switchBikeStatus(params);
            this.setShouldFetchBikes(true);
            // this.hideViewBikeDetailsDialog();
            const response = await this.getBike(bike.id);
            this.setBike(response);
            this.showViewBikeDetailsDialog();
          } else {
            return this.$message({
              type: "error",
              message: this.$t("dialogs.bikes.dock_station_not_available"),
            });
          }
        } else {
          return this.$message({
          type: "error",
          message: this.$t("dialogs.bikes.bike_not_in_docking_station"),
          });
        }
      }
    },
    
    getCurrentDateAndTime() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let hours = today.getHours();
      let seconds = today.getSeconds();
      let yyyy = today.getFullYear() + " " + hours + ":" + seconds;

      return (today = mm + "/" + dd + "/" + yyyy);
    },

      showTooltip(bike) {
        if (bike.status === 'in_ride' || bike.status === 'available_in_station') {
          this.tooltipVisible = true;
        }
      },
      hideTooltip(bike) {
        this.tooltipVisible = false;
      }
  },
};
</script>

<style>
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  /* background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
   */
   background: blue;
}

.el-popper.is-customized .el-popper__arrow::before {
  background: red;
  /* background: linear-gradient(45deg, #b2e68d, #bce689); */
  right: 0;
}
</style>
