<template lang="pug">
  .configurable-settings-view
    el-breadcrumb.mt-3.middle-breadcrumb-bold(separator="/")
      el-breadcrumb-item(:to="{ path: '/docking_stations_list' }") {{ $t('configurable_settings.configurable_settings_docking_station') }} 
      el-breadcrumb-item {{ dockingStation ? dockingStation.name : "-" }}   
      el-breadcrumb-item {{ $t('configurable_settings.configurable_settings') }} 
    .tab-view
      FilterTabs(
        v-model="activeName"
        :view="'admin_settings'"
        :tabs="tabs"
      )
    .configurable-settings-wrapper.my-4
      ConfigurableSettingsTable(
        v-loading="loading"
        :configSettings="dockSettings"
      )
    Pagination(
      v-if="newDockingStation && activeName && activeName !== ''"
      :activeName="activeName"
      :view="'dock_station_settings'"
      @viewData="handleGetData"
      @handleTableLoading="handleLoading"
    )
</template>
<script>
import FilterTabs from "@components/_shared/FilterTabs";
import { mapActions, mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";
import ConfigurableSettingsTable from "@components/settings/ConfigurableSettingsTable";
import Pagination from "@components/_shared/Pagination";

export default {
  name: "DockingStationSettingsView",
  components: { ConfigurableSettingsTable, Pagination,  FilterTabs},
  mixins: [routerUtils],

  data(){
    return {
      dockingStation: null,
      loading: false,
      dockSettings: null,
      activeName: "all",
      tabs: [
        { name: "all", roles: [] },
        { name: "cms", roles: [] },
        { name: "mobile", roles: [] },
        { name: "station", roles: [] },
        { name: "rfid", roles: [] },
        { name: "tracker", roles: [] },
      ],
      newDockingStation: false,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetDockingStation();
  },

  async mounted() {
    await this.handleGetDockingStation();
  },

  methods: {
    ...mapActions("dockingStation", ["getDock"]),
    ...mapMutations("dockingStation", ["setDockingStation"]),

    handleLoading(loading) {
      this.loading = loading;
    },

    handleGetData(data){
      this.loading = true;
      this.dockSettings = data;
      this.loading = false;
    },

    async handleGetDockingStation(){
      const response = await this.getDock(this.$route.params.id);
      this.dockingStation = response;
      this.setDockingStation(this.dockingStation);
      this.newDockingStation = true;
    },

    handleGoTo(){
      this.goTo('docking_stations_list')
    },
  }
}
</script>
