<template lang="pug">
  .tabs
    el-tabs.mt-4(
      v-model='activeName'
      @tab-click="handleTabClick"
    )
      el-tab-pane(
        v-for="tab in tabs"
        :label="$t(`${view}.tab.${tab.name}`)"
        :name="tab.name"
        :key="tab.name"
      )
</template>

<script>
import { roles } from "@utils/roles";

export default {
  name: "FilterTabs",

  props: {
    view: {
      type: String,
      required: true,
    },

    tabs: {
      type: Array,
      required: true,
    },

    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      roles,
    };
  },

  computed: {
    activeName: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    handleTabClick({ name }) {
      this.activeName = name;
    },
  },
};
</script>
