<template lang="pug">
  .customer-tickets-view
    el-form(
      :model="form"
      v-loading="loadingForm"
      ref="AddNfcForm"
      @submit.native.prevent
    )
      el-row.d-flex.justify-content-center(:gutter="30")
        el-col(:span="12")
          el-form-item(
            prop="nfc"
            :label="'Codul NFC'"
          )
            el-input(
              v-model="form.nfc"
              name="nfc"
              @keydown.native.space.prevent
            )
        el-col(:span="10" style="margin-top: 15px")
          el-button.dark-blue-btn.pop-up-btn.mt-4(
            @click="handleAddNfc('AddNfcForm')"
            :loading="loading"
          ) {{ 'Adaugă' }}
    .customer-tickets-wrapper
      el-row
        el-col(:span='24')
          el-table(
            style='width: 100%;'
            header-cell-class-name='table-header'
            cell-class-name='pl-4'
            :data='nfcs'
          )
            el-table-column(
              prop='status_type'
              :label="'NFC'"
              min-width="360px"
            )
              template(slot-scope='scope')
                span {{ scope.row.nfc }}
            el-table-column(
              prop='actions'
              :label="$t('label.actions')"
              width="120px"
            )
              template(slot-scope='scope')
                .d-flex
                  img.cursor-pointer.mr-2(
                    src="@assets/actions/delete.svg"
                    @click="handleDeleteNfc(scope.row)"
                  )
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { setObjectValues } from "@utils/generalUtils";
import _ from "lodash";

export default {
  name: "ViewUpdateCustomer",

  data() {
    return {
      nfcs: [],

      form: {
        nfc: "",
      },

      loading: false,
      loadingForm: false,
      setObjectValues,
      // customer: null
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("customer", ["customer"]),
  },

  watch: {
    customer() {
      this.clearForm();
      this.handleGetFormData();
    },
  },

  created() {
    this.loadingForm = true;
    this.clearForm();
    this.handleGetFormData();
    this.loadingForm = false;
  },

  methods: {
    ...mapActions("customer", ["assignNfcToCustomer", "unassignNfcToCustomer"]),

    ...mapMutations("dialog", ["hideUpdateCustomerDialog"]),

    ...mapMutations("customer", ["setShouldFetchCustomer"]),

    clearForm() {
      setObjectValues(this.form, "");
    },

    handleAddNfc(formName){
      this.loading = true;

      const form = this.$refs[formName];

      let params = {
        id: this.customer.id,
        nfc: this.form.nfc,
      };

      this.assignNfcToCustomer(params)
          .then(() => {
            this.hideUpdateCustomerDialog();
            // this.setShouldFetchCustomer(true);
            location.reload();
          })
          .finally(() => {
            this.loading = false;
            this.clearForm();
          });
    },

    handleDeleteNfc(nfc){
      let params = {
        id: this.customer.id,
        nfc: nfc.nfc,
      };

      this.unassignNfcToCustomer(params)
          .then(() => {
            this.hideUpdateCustomerDialog();
            // this.setShouldFetchCustomer(true);
            location.reload()
          })
          .finally(() => {
            this.loading = false;
            this.clearForm();
          });
    },

    handleGetFormData(){
      this.nfcs = [];
      this.customer.rfids.forEach ((element) => this.nfcs.push({ nfc: element }))
    },
  },

};
</script>
