<template lang="pug">
  .customer-view(v-loading="loading")
    .page-load(v-if="!loading")
      h3 {{ $t('customer.customer') }}
      .user-info-filter.d-flex.justify-space-between
        .user-info-wrapper.d-flex
          img(src="@assets/customers/portrait-placeholder.png").profile-pic
          .user-info
            h4 {{ customer.first_name + " " + customer.last_name }}
            span(v-if="inBetween('customerStatuses', customer.status, 21, 40)").d-flex.align-items-center
              span.oval.completed
              span {{ usersAndCustomersStatuses[customer.status].message }}
            span(v-if="inBetween('customerStatuses', customer.status, 41, 60)").d-flex.align-items-center
              span.oval.cancelled
              span {{ usersAndCustomersStatuses[customer.status].message }}
            span(v-if="inBetween('customerStatuses', customer.status, 1, 20)").d-flex.align-items-center
              span.oval.pending
              span {{ usersAndCustomersStatuses[customer.status].message }}
            span(v-if="inBetween('customerStatuses', customer.status, 61, 80)").d-flex.align-items-center
              span.oval.grey
              span {{ usersAndCustomersStatuses[customer.status].message }}
            span(v-if="inBetween('customerStatuses', customer.status, 81, 100)").d-flex.align-items-center
              span.oval.oval-available
              span {{ usersAndCustomersStatuses[customer.status].message }}
        .filters
          FilterDatePicker(
            v-model="dateRange"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['by_date_range'])"
          )
      //- .justify-space-evenly
      .prices-wrapper.justify-space-evenly(
        v-loading="loadingStatistics"
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['show_customer_transactions_statistics'])"
      )
        Statistic.pt-3.pb-3.pl-3.pr-3(
          :name="'Curse cu succes / Curse anulate'"
          :value="earnings.rides"
          :icon_name="'ride-stats'"
          :isMoneyStatistic="false"
          :fullWidth="true"
        )
        Statistic.pt-3.pb-3.pl-3.pr-3(
          :name="$t('customer.total')"
          :value="earnings.total_earnings"
          :icon_name="'total'"
          :isMoneyStatistic="true"
          :fullWidth="true"
        )
        Statistic.pt-3.pb-3.pl-3.pr-3(
          :name="$t('customer.per_ride')"
          :value="earnings.avg_earnings_per_ride"
          :icon_name="'per-ride'"
          :isMoneyStatistic="true"
          :fullWidth="true"
          :showTooltip="true"
        )
        Statistic.pt-3.pb-3.pl-3.pr-3(
          :name="$t('customer.refunded')"
          :value="earnings.total_refunds"
          :icon_name="'per-minute'"
          :isMoneyStatistic="true"
          :fullWidth="true"
        )
      .show-tickets-wrapper
        el-button.dark-blue-btn.green-btn(
          @click="handleEditCustomer(customer)"
        )
          h4 {{ 'Editează client' }}
        el-button.dark-blue-btn.view-tickets-btn(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['show_tickets'])"
          @click="handleViewCustomerTickets(customer.tickets)"
        )
          h4 {{ $t('customer.show_tickets') }}
        el-button.dark-blue-btn.see-contact-details-btn(
          @click="handleViewContactDetails(customer)"
        )
          h4 {{ $t('customer.see_contact_details') }}

        el-button.dark-blue-btn.restrict-btn(
          v-if='customer.status === "active"'
          @click="restrictCustomer(customer)"
        )
          h4 {{ $t('customer.restrict_customer') }}

        el-button.green-btn.unrestrict-btn(
          v-if='customer.status === "inactive"'
          @click="unrestrictCustomer(customer)"
        )
          h4 {{ $t('customer.unrestrict_customer') }}

        el-button.dark-blue-btn.device-info-btn(
          @click="handleViewDeviceInfo(deviceInfo)"
        )
          h4 {{ $t('customer.device_info_customer') }}

      .rides-wrapper(v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['show_rides'])")
        .actions
          SearchWithButtons(v-model="search")
        CustomerRideHistoryViewTable(
          :rides="rides"
          v-loading="loadingTable"
        )
      Pagination(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['show_rides'])"
        :view="'customerRideHistory'"
        :search="search"
        :dateRange="dateRange"
        :costCenterId="selectedCostCenterId"
        @viewData="handleGetCustomersRideHistory"
        @handleTableLoading="handleLoading"
      )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { usersAndCustomersStatuses } from "@utils/statuses";
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import Statistic from "@components/_shared/Statistic";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import CustomerRideHistoryViewTable from "@components/customers/CustomerRideHistoryViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [filterUtils, general],

  components: {
    Pagination,
    SearchWithButtons,
    Statistic,
    FilterDatePicker,
    CustomerRideHistoryViewTable,
  },

  data() {
    return {
      search: "",
      dateRange: [],
      customer: {},
      rides: [],
      deviceInfo: {},
      selectedCostCenterId: "",

      loading: false,
      loadingStatistics: false,
      loadingTable: false,
      earnings: {
        rides: "0 / 0",
        total_earnings: 0,
        avg_earnings_per_ride: 0,
        total_refunds: 0,
      },
      pickerOptions: {
        disabledDate(time) {
          return disabledFutureDates(time);
        },
        shortcuts: dateRangeShortcuts,
      },
      usersAndCustomersStatuses,
      inBetween,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  watch: {
    async dateRange() {
      await this.handleGetCustomersStatistics();
    },
  },

  async created() {
    this.setCostCenter();
    await this.handleGetCustomer();
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapActions("customer", [
      "getCustomer",
      "getCustomerTransactionsStatistics",
      "updateCustomerRestricted",
      "getDeviceInfo",
    ]),

    ...mapMutations("dialog", [
      "showViewCustomerTickets",
      "showCustomerDetailsDialog",
      "showCustomerDeviceInfoDialog",
      "showUpdateCustomerDialog",
    ]),

    ...mapMutations("customer", ["setCustomer", "setDeviceInfo"]),

    setCostCenter(){
      if(this.user.selected_cost_center){
         this.selectedCostCenterId = this.user.selected_cost_center.id
      }
    },

    handleEditCustomer(customer){
      this.setCustomer(customer);
      this.showUpdateCustomerDialog();

      // this.$router.push(`/customers/edit_customer/${customer.id}`)
    },

    handleGetCustomersRideHistory(data) {
      // TODO: when server ready, handle pagination py page & date_range
      this.rides = data;
    },

    handleLoading(loading) {
      this.loadingTable = loading;
    },

    async handleGetCustomersStatistics() {
      if (
          !this.showItemDynamically(
              this.user,
              dynamicViewsConstants.resource_names.CUSTOMER,
              ["show_customer_transactions_statistics"]
          )
      ) {
        return;
      }

      this.loadingStatistics = true;
      let params = {
        id: this.customer.id,
        // params:
        //     this.dateRange && this.dateRange.length > 0
        //         ? `?by_date_range[start_date]=${this.dateRange[0]}&by_date_range[end_date]=${this.dateRange[1]}`
        //         : "",
      };
      const statistics = await this.getCustomerTransactionsStatistics(params);
      this.earnings.rides = `${statistics.valid_rides} / ${statistics.rides_with_error}`;
      this.earnings.total_earnings = statistics.total_earnings;
      this.earnings.avg_earnings_per_ride = statistics.avg_earnings_per_ride;
      this.earnings.total_refunds = statistics.total_refunds;
      this.loadingStatistics = false;
    },

    async handleGetCustomer() {
      this.loading = true;
      this.customer = await this.getCustomer(this.$route.params.id);
      this.deviceInfo = await this.getDeviceInfo(this.$route.params.id);
      this.loading = false;
    },

    async restrictCustomer(customer) {
      let params = {
        id: customer.id,
        status: "inactive",
      };

      this.updateCustomerRestricted(params).then(async () => {
        await this.handleGetCustomer();
      });
    },

    async unrestrictCustomer(customer) {
      let params = {
        id: customer.id,
        status: "active",
      };

      this.updateCustomerRestricted(params).then(() => {
        this.handleGetCustomer();
      });
    },

    handleViewCustomerTickets(tickets) {
      this.showViewCustomerTickets();
    },

    handleViewContactDetails(customer) {
      this.setCustomer(customer);
      this.showCustomerDetailsDialog();
    },

    handleViewDeviceInfo(deviceInfo) {
      this.showCustomerDeviceInfoDialog();
      this.setDeviceInfo(deviceInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.rides-wrapper {
  margin-top: 0 !important;
}

.extra-charge-btn{
  border-radius: 50px;
}

.show-tickets-wrapper {
  margin: 32px 0;

  .view-tickets-btn,
  .see-contact-details-btn,
  .extra-charge-btn,
  .restrict-btn,
  .unrestrict-btn,
  .green-btn,
  .device-info-btn {
    height: 36px;

    h4 {
      font-size: 14px !important;
      font-weight: 500 !important;
      letter-spacing: 0.1px;
      line-height: 13px;
    }
  }
}

.customer-view {
  .user-info-filter {
    margin-top: 36px;
    align-items: center;

    .user-info-wrapper {
      align-items: center;

      .profile-pic {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 26px;
      }

      .user-info {
        h4 {
          color: $secondary;
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
