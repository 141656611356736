<template lang="pug">
  .view-bike-details
    el-form(
      :model="form"
      ref="ViewBikeMaintenanceForm"
      :rules="viewBikeMaintenanceRules"
      @submit.native.prevent
    )
      el-row.center.details-bold(:gutter="20" type="flex" justify="center")
        el-col(:span="8")
          el-form-item(
            prop="maintenanceDescription"
            :label="$t('label.maintenance_description')"
          )
            el-input(
              v-model="form.maintenanceDescription"
              name="maintenanceDescription"
              type="textarea"
              :rows="5"
              resize="none"
              style="max-height: 120px;"
            )
        el-col(:span="7")
          el-form-item(
            :label="$t('label.maintenance_date')"
          )
            el-date-picker.date-picker(
              v-model="bike.next_maintenance_at"
              :readonly="true"
              type="date"
              :placeholder="$t('placeholder.pick_date')"
              :pickerOptions="pickerOptions"
              format="dd/MM/yyyy"
              :default-value="new Date()"
            )
            el-form-item(
              prop="maintenanceStatus"
              :label="$t('label.maintenance_status')"
            )
              el-select.w-100(
                v-model='form.maintenanceStatus'
                filterable
                :placeholder="$t('placeholder.select_status')"
              )
                el-option(
                  v-for='status in maintenanceStatuses'
                  :key='status.value'
                  :label='status.text'
                  :value='status.value'
                )
        el-col(:span="8")
          el-form-item(
            prop="technician"
            :label="$t('label.technician')"
          )
          el-row(type="flex" justify="space-between" v-loading="loadingAssignedTechnician")
            el-col
              span(v-if="assignedTechnician") {{ assignedTechnician.first_name + " " + assignedTechnician.last_name}}
              el-select.w-100(
                v-if="!loadingAssignedTechnician && !assignedTechnician"
                v-model='form.technicianId'
                filterable
                :placeholder="$t('placeholder.select_technician')"
              )
                el-option(
                  v-for='technician in techniciansAndOperators'
                  :key='technician.id'
                  :label='technician.first_name + " " + technician.last_name  + ` (${technician.cost_center_names})`'
                  :value='technician.id'
                )
          el-row
            button.begin-maintenance-btn.cursor-pointer.align-self-end(
              v-if="!loadingAssignedTechnician && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['reassign_technician'])"
              @click="form.technicianId ? handleReassignTechnician() : assignedTechnician = null"
            ) {{ assignedTechnician ? $t('common.unassign'): $t('common.assign') }}
          el-form-item(
            v-if="form.maintenanceStatus === 'partial_rideable'"
            prop="maintenanceStatusDetails"
            :label="$t('label.maintenance_status_details')"
          )
            el-select.w-100(
              v-if="form.maintenanceStatus === 'partial_rideable'"
              v-model='form.maintenanceStatusDetails'
              filterable
              :placeholder="$t('common.status')"
            )
              el-option(
                v-for='status in maintenanceStatusesDetails'
                :key='status.value'
                :label='status.text'
                :value='status.value'
              )
          el-form-item(
            v-if="form.maintenanceStatus === 'completed'"
            prop="nextMaintenanceDate"
            :label="$t('label.next_maintenance_date')"
          )
            el-date-picker.date-picker(
              v-if="form.maintenanceStatus === 'completed'"
              v-model="form.nextMaintenanceDate"
              type="date"
              :placeholder="$t('placeholder.pick_date')"
              :pickerOptions="pickerOptions"
              format="dd/MM/yyyy"
              :default-value="new Date()"
            )
      el-row.center.details-bold(
        :gutter="20"
        type="flex"
        justify="center"
        style="margin-top: 32px; margin-bottom: 16px; text-align:center;"
      )
        el-col(:span="15")
          el-input(
            v-model="form.maintenanceDescriptionExample"
            :readonly="true"
            name="address"
            type="textarea"
            resize="none"
            :rows="5"
          )
        el-col(:span="8")
          el-form-item(
            v-if="form.maintenanceStatus === 'partial_rideable'"
            prop="nextMaintenanceDate"
            :label="$t('label.next_maintenance_date')"
          )
            el-date-picker.date-picker(
              v-model="form.nextMaintenanceDate"
              name="nextMaintenanceDate"
              type="date"
              :placeholder="$t('placeholder.pick_date')"
              :pickerOptions="pickerOptions"
              format="dd/MM/yyyy"
              :default-value="new Date()"
            )
      el-row(:gutter="20" center style="margin-top: 32px; margin-bottom: 16px; text-align:center;")
        el-col
          el-button.dark-blue-btn.finish-btn(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['finish_maintenance'])"
            @click="handleFinishMaintenance('ViewBikeMaintenanceForm')"
          ) {{ $t('bikes.finish') }}
        //- el-col
      //- el-row(:gutter="50" center style="margin: 16px 0; text-align:center;")
      //-   el-col
      //-     span.font-weight-bolder {{ $t('common.maintenance_history') }}
      //- .bike-modal-table
      //-   BikeMaintenancesHistory(:activeName="activeName" v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_maintenances'])")
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { bikesMaintenanceStatuses } from "@utils/statuses";
import { setObjectValues } from "@utils/generalUtils";
import { disabledPastDates } from "@utils/dateRange";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { bikesViewValidations } from "@utils/formValidations/bikesViewValidations";
import BikeMaintenancesHistory from "@components/bikes/viewBike/BikeMaintenancesHistory";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "ViewBikeMaintenance",
  mixins: [bikesViewValidations, filterUtils, general],
  components: { BikeMaintenancesHistory },

  data() {
    return {
      activeName: "maintenance_history",
      maintenance_history: [],
      technicians: [],
      administrators: [],
      assignedTechnician: null,
      form: {
        maintenanceDescription: null,
        maintenanceDescriptionExample: this.$t(
          "dialogs.bikes.maintenance_description_example"
        ),
        maintenanceStatus: null,
        maintenanceStatusDetails: null,
        nextMaintenanceDate: null,
        technicianId: null
      },
      loadingAssignedTechnician: false,
      maintenanceStatuses: [
        {
          text: this.$t("dialogs.bikes.maintenance_statuses_completed"),
          value: "completed",
        },
        {
          text: this.$t("dialogs.bikes.maintenance_statuses_partial"),
          value: "partial_rideable",
        },
      ],
      maintenanceStatusesDetails: [
        {
          text: this.$t("dialogs.bikes.maintenance_statuses_details_rideable"),
          value: "completed",
        },
        {
          text: this.$t(
            "dialogs.bikes.maintenance_statuses_details_non_rideable"
          ),
          value: "partial_non_rideable",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return disabledPastDates(time);
        },
      },
      bikesMaintenanceStatuses,
      inBetween,
      setObjectValues,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("bike", ["bike"]),

    ...mapState("dialog", [
      "isViewBikeDetailsDialogVisible"]
    ),

    techniciansAndOperators(){
      return this.technicians.concat(this.administrators);
    }
  },

  async created() {
    await this.handleGetAssignedTechnician() // TODO: FIX - show the new updated technician on maintenances dialog load
    await this.handleGetTechnicians()
  },

  methods: {
    ...mapActions("bike", ["finishMaintenance", "getUsersByRole", "reassignTechnician", "getBike"]),

    ...mapActions("user", ["getUser"]),

    ...mapMutations("dialog", ["hideViewBikeMaintenanceDialog", "showViewBikeDetailsDialog"]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    clearForm() {
      setObjectValues(this.form, "");
    },

    async handleGetTechnicians() {
      this.technicians = await this.getUsersByRole("technician");
      this.administrators =  await this.getUsersByRole("administrator_operator");
    },

    async handleGetAssignedTechnician() {
      this.loadingAssignedTechnician = true
            this.setShouldFetchBikes(true)
      await this.getBike(this.bike.id)
      this.assignedTechnician = await this.getUser(this.bike.technician.id)
      this.loadingAssignedTechnician = false
    },

    async handleReassignTechnician() {
      this.loadingAssignedTechnician = true
      let params = {
        id: this.bike.id,
        technician_id: this.form.technicianId
      }
      await this.reassignTechnician(params)
      this.setShouldFetchBikes(true)
      await this.getBike(this.bike.id)
      this.form.technicianId = null
      await this.handleGetAssignedTechnician()
      this.loadingAssignedTechnician = false
    },

    computeBikeMaintenanceStatus() {
      if (this.form.maintenanceStatus === "completed") {
        return "completed";
      }
      if (
        this.form.maintenanceStatus === "partial_rideable" &&
        this.form.maintenanceStatusDetails === "completed"
      ) {
        return "partial_rideable";
      }
      // non rideable
      return "partial_non_rideable";
    },

    handleFinishMaintenance(formName) {
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          let params = {
            id: this.bike.id,
            maintenance_status: this.computeBikeMaintenanceStatus(),
            next_maintenance_at: this.computeDateFormat(
              this.form.nextMaintenanceDate
            ),
            maintenance_description: this.form.maintenanceDescription,
          };
          this.finishMaintenance(params).then(() => {
            this.setShouldFetchBikes(true);
            this.hideViewBikeMaintenanceDialog();
            if(this.isViewBikeDetailsDialogVisible == true) {
              const response = this.getBike(this.bike.id);
              this.setBike(response);
              this.showViewBikeDetailsDialog();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.view-bike-details {
  .finish-btn {
    height: 40px;
    width: 160px;
    box-shadow: 0 10px 20px 0 $perano-transparent;
  }

  textarea {
    max-height: 120px;
  }

  .details-bold .el-col span {
    line-height: 41px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 100%;
  }
}
</style>
