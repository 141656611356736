<template lang="pug">
  .configurable-settings-view
    el-breadcrumb.mt-3.middle-breadcrumb-bold(separator="/")
      el-breadcrumb-item(:to="{ path: '/cost_centers' }") {{ $t('configurable_settings.configurable_settings_cost_center') }} 
      el-breadcrumb-item {{ costCenter ? costCenter.name : "-"}}   
      el-breadcrumb-item {{ $t('configurable_settings.configurable_settings') }}
    .tab-view
      FilterTabs(
        v-model="activeName"
        :view="'admin_settings'"
        :tabs="tabs"
      )
    .configurable-settings-wrapper.my-4
      ConfigurableSettingsTable(
        v-loading="loading"
        :configSettings="costCenterSettings"
      )
    Pagination(
      v-if="newCostCenter && activeName && activeName !== ''"
      :activeName="activeName"
      :view="'cost_center_settings'"
      @viewData="handleGetData"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import FilterTabs from "@components/_shared/FilterTabs";
import { mapActions, mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";
import ConfigurableSettingsTable from "@components/settings/ConfigurableSettingsTable";
import Pagination from "@components/_shared/Pagination";

export default {
  name: "CostCenterSettingsView",
  components: { ConfigurableSettingsTable, Pagination, FilterTabs},
  mixins: [routerUtils],

  data(){
    return {
      costCenter: null,
      loading: false,
      costCenterSettings: null,
      activeName: "all",
      tabs: [
        { name: "all", roles: [] },
        { name: "cms", roles: [] },
        { name: "mobile", roles: [] },
        { name: "station", roles: [] },
        { name: "rfid", roles: [] },
        { name: "tracker", roles: [] },
      ],
      newCostCenter: false,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetCostCenter();
  },

  async mounted() {
    await this.handleGetCostCenter();
  },


  methods: {
    ...mapActions("costCenter", ["getCostCenter"]),
    ...mapMutations("costCenter", ["setCostCenter"]),

    handleLoading(loading) {
      this.loading = loading;
    },

    handleGetData(data){
      this.loading = true;
      this.costCenterSettings = data;
      this.loading = false;
    },

    async handleGetCostCenter(){
      const response = await this.getCostCenter(this.$route.params.id);
      this.costCenter = response.data;
      this.setCostCenter(this.costCenter);
      this.newCostCenter = true;
    },

    handleGoTo(){
      this.goTo('cost_centers')
    },
  }
}
</script>
