<template lang="pug">
  .modal-bike-map
    GoogleMaps(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="'ViewBike'"
      :lastRideCoordinates="this.lastRideCoordinates"
      :trackerCoordinates="this.trackerCoordinates"
      :bikeCoordinates="bike"
    )
</template>

<script>
import { bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import GoogleMaps from "@components/_shared/GoogleMaps";

export default {
  name: "BikeMap",
  mixins: [filterUtils, general],

  components: {
    GoogleMaps,
  },

  data() {
    return {
      bikesStatuses,
      inBetween,
      dynamicViewsConstants,
      lastRideCoordinates: [],
      trackerCoordinates: [],
      trackerData: {
        latitude: null,
        longitude: null,
        reported_at: null,
        created_at: null
      }

    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
  },

    async created() {
    this.isLoading = true;
    },

  async created() {
    this.handleCoordinatesForMap();
  },

  watch: {
    bike(){
      this.handleCoordinatesForMap();
    }
  },

  methods: {
    ...mapMutations("map", ["setMapCoordinates"]),

    async handleMarkerDragEnd(markerPosition) {
      await this.setMapCoordinates(markerPosition);
    },

    handleCoordinatesForMap(){
      this.lastRideCoordinates = [];
      this.trackerCoordinates = [];
      if(this.bike.last_known_position) {
        this.lastRideCoordinates = [this.bike.last_known_position.latitude, this.bike.last_known_position.longitude];
      } else{ 
        this.lastRideCoordinates = [];
      }

      if(this.bike.tracker && this.bike.tracker.tracker_latest_info && this.bike.tracker.tracker_latest_info.location != null){
        this.trackerCoordinates = [this.bike.tracker.tracker_latest_info.location.latitude, this.bike.tracker.tracker_latest_info.location.longitude];
      } else{ 
        this.trackerCoordinates = [];
      }

    },
  },
};
</script>
