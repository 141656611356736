import {
  bikesMaintenanceStatuses,
  bikesStatuses,
  dockingStationsStatuses,
  ridesStatuses,
  ticketsReports,
  ticketsStatuses,
  transactionHistoryStatuses,
  transactionStatuses,
  usersAndCustomersStatuses,
} from "@utils/statuses";

export const MAX_FIELD_LENGTH = 60;

export function trimTableField(field) {
  let trimmedField;
  if (field !== undefined || field !== "") {
    trimmedField = (trimmedField = field
      .split("")
      .slice(0, MAX_FIELD_LENGTH));
    return `${trimmedField.join("")}...`;
  }
}

export const inBetween = (filterByStatuses, value, min, max) => {
  if (filterByStatuses !== null) {
    if (filterByStatuses === "bikesStatuses") {
      return bikesStatuses[value].id >= min && bikesStatuses[value].id <= max;
    } else if (filterByStatuses === "bikesMaintenanceStatuses") {
      return (
        bikesMaintenanceStatuses[value].id >= min &&
        bikesMaintenanceStatuses[value].id <= max
      );
    } else if (filterByStatuses === "docksStatuses") {
      return (
        dockingStationsStatuses[value].id >= min &&
        dockingStationsStatuses[value].id <= max
      );
    } else if (filterByStatuses === "ridesStatuses") {
      return ridesStatuses[value].id >= min && ridesStatuses[value].id <= max;
    } else if (filterByStatuses === "customerStatuses") {
      return (
        usersAndCustomersStatuses[value].id >= min &&
        usersAndCustomersStatuses[value].id <= max
      );
    } else if (filterByStatuses === "ticketsStatuses") {
      return (
        ticketsStatuses[value].id >= min && ticketsStatuses[value].id <= max
      );
    } else if (filterByStatuses === "ticketsReports") {
      return ticketsReports[value].id >= min && ticketsReports[value].id <= max;
    } else if (filterByStatuses === "transactionsStatuses") {
      return (
        transactionStatuses[value].id >= min &&
        transactionStatuses[value].id <= max
      );
    } else if (filterByStatuses === "transactionHistoryStatuses") {
      return (
        transactionHistoryStatuses[value].id >= min &&
        transactionHistoryStatuses[value].id <= max
      );
    }
  } else {
    // normal filter, between 2 values
    return value >= min && value <= max;
  }
};

export const filterUtils = {
  methods: {
    computeDateFormat(date) {
      return date ? this.$moment(date).format("D/MM/YYYY HH:mm") : "-";
    },

    computeDateTimeFormat(date) {
      return date ? this.$moment(date).format("DD/MM/YYYY HH:mm:ss") : "-";
    },

    computeTrackerDateFormat(date) {
      return date ? this.$moment(date).format("DD/MM/YY HH:mm:ss") : "-";
    },

    computeFWVerionDateFormat(date) {
      return date ? this.$moment(date).format("MMM DD YYYY HH:mm:ss") : "-";
    },

    computePaidStatus(price, alreadyPayed) {
      return price === alreadyPayed
        ? this.$t("rides.paid")
        : this.$t("rides.not_paid");
    },

    computeRideDuration(sec) {
      const hours = Math.floor(sec / 3600);
      const minutes = Math.floor((sec - hours * 3600) / 60);
      const seconds = sec - hours * 3600 - minutes * 60;

      if (hours < 1) {
        return `${minutes}m ${seconds}s`;
      }
      return `${hours}h ${minutes}m ${seconds}s`;
    },
  },
};
