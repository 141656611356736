<template lang="pug">
  .role-view
    h3 {{ $t('roles.view_role') }}
    el-breadcrumb.mt-3.last-breadcrumb-bold(separator="/")
      el-breadcrumb-item(:to="{ path: '/roles' }") {{ $t('roles.roles') }}
      el-breadcrumb-item {{ $t('roles.view_role') }}
    .view-role-wrapper.pt-4(v-loading="loading")
      .role-details
        el-row.pl-4.pr-4.pb-4
          el-col(:span="16")
            h3.mb-2 {{ upperCaseFirstLetters(role.name) }}
          el-col.d-flex.justify-content-flex-end(:span="8")
            el-button.dark-blue-btn.pop-up-btn(
              @click="handleEditRole"
            ) {{ $t('common.edit') }}
      .hr-custom
      .permissions.pt-4.pl-4.pr-4
        h3 {{ $t('roles.permissions') }}
        .permissions-list.pt-2
          p.mb-1(v-for="permission in role.permissions")
            span.check-icon.mr-2 &#10004;
            span {{ permission.name }}
</template>

<script>
import { mapActions } from "vuex";
import { upperCaseFirstLetters } from "@utils/generalUtils";

export default {
  data() {
    return {
      role: {},
      loading: false,
      upperCaseFirstLetters,
    };
  },

  async mounted() {
    await this.handleGetRole();
  },

  methods: {
    ...mapActions("role", ["getRole"]),

    async handleGetRole() {
      this.loading = true;
      this.role = await this.getRole(this.$route.params.id);
      this.loading = false;
    },

    handleEditRole() {
      this.$router.push(`/roles/edit/${this.role.id}`);
    },
  },
};
</script>

