<template lang="pug">
  .technical-dashboard-view
    h3 {{ $t('technical_dashboard.technical_dashboard') }}
    .statistics.d-flex(v-loading="loadingStatistics")
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('technical_dashboard.ram_usage_percentage')"
        :value="technicalSettings.ram_usage_percentage + ' %'"
        :icon_name="'expanding-blue'"
        :fullWidth="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('technical_dashboard.cpu_usage_percentage')"
        :value="technicalSettings.cpu_usage_percentage + ' %'"
        :icon_name="'expanding-orange'"
        :fullWidth="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('technical_dashboard.disk_usage_percentage')"
        :value="technicalSettings.disk_usage_percentage + ' %'"
        :icon_name="'expanding-green'"
        :fullWidth="false"
        :justifyContent="'left'"
      )
    .statistics.d-flex(v-loading="loadingStatistics")
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('technical_dashboard.server_uptime')"
        :value="technicalSettings.server_uptime"
        :fullWidth="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('technical_dashboard.no_of_weboscket_connections')"
        :value="technicalSettings.no_of_weboscket_connections"
        :icon_name="'expanding-orange'"
        :fullWidth="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('technical_dashboard.processor_name')"
        :value="technicalSettings.processor_name"
        :fullWidth="false"
        :justifyContent="'left'"
      )
    .technical_dashboard-wrapper
      el-row
        el-col(:span='24')
          TechnicalDashboardViewTable(
            v-loading="loadingTable"
            :tokens="tokens"
          )
    Pagination(
      :view="'tokens'"
      :search="search"
      :dateRange="dateRange"
      @viewData="handleGetTokens"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { filterUtils, } from "@utils/filterUtils";
import Statistic from "@components/_shared/Statistic";
import TechnicalDashboardViewTable from "@components/technical_dashboard/TechnicalDashboardViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [filterUtils, general],

  components: {
    Pagination,
    FilterDatePicker,
    SearchWithButtons,
    Statistic,
    TechnicalDashboardViewTable
  },

  data() {
    return {
      dateRange: [],
      tokens: [],
      search: "",
      loadingTable: false,
      loadingStatistics: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState('technicalDashboard', ['shouldFetchStatistics', 'technicalSettings']),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "dialogData",
      "allowDeletion",
      "passwordConfirmation",
    ]),

    ...mapGetters('technicalDashboard', ['formattedTechnicalSettings']),
  },

  watch: {
    allowDeletion() {
      this.handleRevokeToken();
    },

    async shouldFetchStatistics() {
      if (this.shouldFetchStatistics) {
        await this.getTechnicalStats()
        this.setShouldFetchStatistics(false)
      }
    }
  },

  async created() {
    this.handleGetStatistics();
  },

  methods: {

    ...mapActions('technicalDashboard', ['getTokens' ,'getTechnicalStats', 'revokeToken']),

    ...mapMutations("revokeToken", ["setShouldFetchStatistics", "setShouldFetchTokens"]),

    handleGetTokens(data) {
      this.tokens = data;
    },

    handleLoading(loading) {
      this.loadingTable = loading;
    },

    async handleGetStatistics() {
      this.loadingStatistics = true
      await this.getTechnicalStats()
      this.loadingStatistics = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";
</style>
