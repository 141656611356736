<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='configSettings'
  )
    el-table-column(
      prop='id'
      :label="$t('label.id')"
    )
    el-table-column(
      prop='key'
      :label="$t('label.key')"
      width="540px"
    )
    el-table-column(
      prop='category'
      :label="$t('label.category')"
    )
    el-table-column(
      prop='type'
      :label="$t('label.type')"
    )
    el-table-column(
      prop='value'
      :label="$t('label.value')"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.row.value}}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-1(
          src="@assets/actions/edit.svg"
          @click="handleOpenEditSettings(scope.row)"
        )
</template>

<script>

import { general } from "@src/services/dynamicViews/general";
import { mapMutations } from "vuex";

export default {
  name: "ConfigurableSettingsTable",
  mixins: [general],

  props: {
    configSettings: {
      type: Array,
      required: true,
      default: []
    }
  },

  methods: {
    ...mapMutations('configSettings', ['setConfigSetting']),
    ...mapMutations('dialog', ['showEditConfSettingsDialog']),

    handleOpenEditSettings(value) {
      this.setConfigSetting(value)
      this.showEditConfSettingsDialog()
    }
  },
}
</script>
