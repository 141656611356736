<template lang="pug">
  .bikeing-station-map-wrapper
    GmapMap.custom-map(
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="width: 400px; height: 300px"
    )

</template>

<script>
import customMarkerAvailable from "@assets/bikesMap/available-bike.svg";
import customMarkerInRide from "@assets/bikesMap/in-ride-bike.svg";
import customMarkerInMaintenance from "@assets/bikesMap/in-maintenance-bike.svg";
import customMarkerUnavailable from "@assets/bikesMap/unavailable-bike.svg";
import customCluster from "@assets/bikesMap/cluster-bikes.svg";
import customTrailButton from "@assets/bikesMap/trail-button.svg"
import messageUtils from "@utils/messageUtils";
import { gmapApi } from "vue2-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { bikesMaintenanceStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";
import { mapActions, mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";
import i18n from "@i18n";

export default {
  props: {
    bikes: {
      type: Array,
      default: () => [],
    },
    locationType: {
      type: String,
      default: () => "",
    },
    dateRange: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      markers: [],
      bikesInfo: [],
      polyline: null,
      infowindow: [],
      markerCluster: null,
      getPolylineForBike: 0,
      zoom: 10,
      center: {
        lat: 0,
        lng: 0,
      },

      bikesMaintenanceStatuses,
      inBetween,
    };
  },

  watch: {
    // google() {
    //   this.drawMarkers();
    // },
    bikes() {
      this.drawMarkers();
    },

  },

  computed: {
    google: gmapApi,

    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.getBrowserLocation();
  },

  // async mounted() {
  //   this.initMap();
  //   await this.drawMarkers();
  // },

  methods: {
    ...mapActions('bike', ['getBikeTrail']),
    ...mapActions('tracker', ['getTrackerTrail']),

    initMap(){
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.controls[this.google.maps.ControlPosition.RIGHT_CENTER] //.push(this.showTrailButton());
      });
    },

    showTrailButton() {
      const controlButton = document.createElement("img");
      controlButton.style.padding = "0 10px";
      controlButton.textContent = "Ruta";
      controlButton.src = customTrailButton;

      controlButton.addEventListener("click", () => {
        if (this.getPolylineForBike != 0) {
          this.drawPolyline();
        }
      });

      return controlButton;
    },

    async drawMarkers() {
      if(this.markerCluster) { this.markerCluster.clearMarkers() }
      if(this.polyline) { this.polyline.setMap(null) }
               
      this.$refs.mapRef.$mapPromise.then((map) => {
      
        this.filteredBikesOrTrackers();

        const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

        const markers = this.bikesInfo.map((bikeInfo, i) => {
          const label = labels[i % labels.length];
          const pinGlyph = new this.google.maps.Marker({
            glyph: label,
            glyphColor: "white"
          });
          const marker = new this.google.maps.Marker({
            position: {lat: bikeInfo['lat'], lng: bikeInfo['lng']},
            content: pinGlyph.element,
            icon: bikeInfo['icon'],
            // draggable: false,
            shouldFocus: false
          });

          marker.addListener("click", () => {
            map.panTo(marker.getPosition());
            map.setZoom(20);
             bikeInfo['infoWindow'].open({
                anchor: marker,
                // map,
                shouldFocus: false
              });
            this.getPolylineForBike = bikeInfo['id']
            
          });
          return marker;
        });
        
      this.markerCluster = new MarkerClusterer({ markers, map });
        
      });
    },

    filteredBikesOrTrackers() {
      let content, lat, lng, bikeStatus;
      this.bikesInfo = [];
      this.bikes.forEach((bikeOrTracker) => {
        if(this.locationType == 'bikesLocations'){
          content = this.infoBoxContent(bikeOrTracker, 'bike')
          lat = Number(bikeOrTracker.last_known_position.latitude)
          lng = Number(bikeOrTracker.last_known_position.longitude)
          bikeStatus = bikeOrTracker.status
        }else if(this.locationType == 'trackersLocations') {
          content = this.infoBoxContent(bikeOrTracker, 'tracker')
          lat = Number(bikeOrTracker.tracker_latest_info.location.latitude)
          lng = Number(bikeOrTracker.tracker_latest_info.location.longitude)
          if(bikeOrTracker.bike) {
            bikeStatus = bikeOrTracker.bike.status
            }    
        }

        this.infoWindow = new this.google.maps.InfoWindow({
          content: content,
          shouldFocus: false,
          disableAutoPan: true,
        });

        const markerIcon = bikeOrTracker && bikeStatus === 'available_in_station' ? customMarkerAvailable : bikeStatus === 'in_ride' ? customMarkerInRide : bikeStatus === 'under_maintenance' ? customMarkerInMaintenance : customMarkerUnavailable

        this.bikesInfo.push({
          lat: lat,
          lng: lng,
          icon: markerIcon,
          infoWindow: this.infoWindow,
          id: bikeOrTracker.id
        })  
      })
    },

    renderTrackerInfoForBike(tracker){
      let trackerDev_eui = '';
      if (tracker){
        if(tracker) {trackerDev_eui = `<h5 id="firstHeading" class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">GPS: </b>${tracker.name}</h5>`}
      }

      return trackerDev_eui;
    },

    renderBikeInfoForTracker(bike){
    let bikeInfo = '';
      if (bike) {
        bikeInfo = 
          `<h5 id="firstHeading" class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4" title="Serie"><b class="pt-2 pb-2">${i18n.t("bikes-map.bike-serial-number")}: </b>${bike.serial_number}</h5>` +
          `<h5 id="firstHeading" class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("bikes-map.bike-rfid-code")}: </b> ${bike.rfid}</h5>` +
          `<h5 class="border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4"><b class="pt-2 pb-2">${i18n.t("bikes-map.bike-type")}: </b>${bike.bike_type}</h5>`
      }
      return bikeInfo
    },

    infoBoxContent(bikeOrTracker, type){
      let bikeInfo, trackerInfo,latitude, longitude;

      if (type == 'bike') {
        bikeInfo = bikeOrTracker
        trackerInfo = bikeOrTracker.tracker
        latitude = bikeOrTracker.last_known_position.latitude
        longitude = bikeOrTracker.last_known_position.longitude
      }else if(type == 'tracker'){
        bikeInfo = bikeOrTracker.bike
        trackerInfo = bikeOrTracker
        latitude = bikeOrTracker.tracker_latest_info.location.latitude
        longitude = bikeOrTracker.tracker_latest_info.location.longitude
      }
      const contentString =
        '<div id="content" class="info-box">' +
        '<div id="siteNotice">' +
        "</div>" +
        this.renderBikeInfoForTracker(bikeInfo) +
        this.renderTrackerInfoForBike(trackerInfo) +
        '<div class="hr-custom"></div>' +
        '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
        `<h4 class="pt-2 pb-2"></h4>
          <p class="margin-0 align-items-center justify-content-center font-weight-normal mb-2">${latitude}, ${longitude}</p>
          ` +
        "</div>" +
        "</div>";

      return contentString;

    },

    async drawPolyline(){
      const trail = await this.getTrail(this.getPolylineForBike)
      this.getPolylineForBike = 0;

      this.$refs.mapRef.$mapPromise.then((map) => {
        if(this.polyline) { this.polyline.setMap(null); }
          
        this.polyline = new this.google.maps.Polyline({
          path: trail,
          geodesic: true,
          strokeColor: "blue",
          strokeOpacity: 0.5,
          strokeWeight: 2,
          map: map
        });
      })
    },

    async getTrail(id){
      let trail = [];
      let response;
      if(this.locationType == 'bikesLocations'){
        response = await this.getBikeTrail(id)
        for (let i = 0; i < response.data.last_ride_coordinates.length; i++) {
          trail.push({
            lat: Number(response.data.last_ride_coordinates[i].latitude),
            lng: Number(response.data.last_ride_coordinates[i].longitude)
          })
        }
      }else if(this.locationType == 'trackersLocations') {
        let payload = {
          id: id,
          start_date: this.dateRange[0],
          end_date: this.dateRange[1],
        };
        response = await this.getTrackerTrail(payload)
          for (let i = 0; i < response.length; i++) {
            trail.push({
              lat: Number(response[i].latitude),
              lng: Number(response[i].longitude)
            })
          }
      }

      return trail;
    },

    renderInfoBoxStatus(bike) {
      if(bike) {
        if (inBetween("bikesStatuses", bike.status, 21, 40)) {
                return `
                        <span class="d-flex align-items-center justify-content-center bike-status">
                          <span class="oval completed"></span>
                          <span>${bike.status_object.label}</span>
                        </span>
                      `;
              }

              if (inBetween("bikesStatuses", bike.status, 41, 60) || inBetween("bikesStatuses", bike.status, 0, 1)) {
                return `
                        <span class="d-flex align-items-center justify-content-center bike-status">
                          <span class="oval bike-icon unavailable"></span>
                          <span>${bike.status_object.label}</span>
                        </span>
                      `;
              }

              if (inBetween("bikesStatuses", bike.status, 2, 20)) {
                return `
                        <span class="d-flex align-items-center justify-content-center bike-status">
                          <span class="oval bike-icon pending"></span>
                          <span>${bike.status_object.label}</span>
                        </span>
                      `;
              }
      }
      return '';
    },

    async getBrowserLocation() {
      // if   - user is not super admin or admin, center the map to one bike belonging to the same cost center
      // else - point the admin and super admin to the browser location
      if (checkIfNotSuperAndAdministrator() && this.bikes.length > 0) {
        this.center.lat = Number(this.bikes[0].address_lat);
        this.center.lng = Number(this.bikes[0].address_long);
      } else {
        navigator.geolocation.getCurrentPosition((location) => {
          this.center.lat = location.coords.latitude;
          this.center.lng = location.coords.longitude;
        });

        // if location is not working or turned off
        const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
        if (romaniaCoords) {
          this.center.lat = romaniaCoords.coords.latitude
          this.center.lng = romaniaCoords.coords.longitude
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .bike-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
</style>
