<template lang="pug">
  .edit-user-view
    h3 {{ $t('users.edit_user') }}
    el-breadcrumb.last-breadcrumb-bold.mt-3(separator="/")
      el-breadcrumb-item(:to="{ path: '/users' }") {{ $t('users.users') }}
      el-breadcrumb-item {{ $t('users.edit_user') }}
    .edit-user-wrapper.pt-4
      el-row.pl-4.pr-4.position-relative
        el-col(:span="24")
          UserDetailsForm(
            ref="userDetails"
            :user="userDetails"
          )
        el-button.dark-blue-btn.pop-up-btn.add-user-btn.position-absolute(
          @click="handleEditUser('AddUserForm')"
          v-loading="loading"
        ) {{ $t('common.save_changes') }}
      .hr-custom
      .custom-accordion
        el-row.pl-4.pr-4
          el-col(:span="24")
            el-collapse(
              v-model="activeNames"
              accordion
            )
              Collapse(
                v-for="(collapse, index) in collapses"
                :super-admin="checkIfSuperAdmin"
                :user-details="userDetails"
                :key="collapse"
                ref="collapseDetails"
                :isEdit='true'
                :collapse="collapse"
                :collapses="collapses"
                :index="index"
                @removeCollapse="handleRemoveCollapse"
              )
      el-button.ml-4.mt-4.dark-blue-btn.pop-up-btn(
        v-if="checkIfCurrentUserIsSuperAdmin || checkIfCurrentUserIsAdmin && !checkIfSuperAdmin && upgradeActive"
        @click="handleAddCollapse"
      ) {{ $t('common.add_more') }}
      el-button.ml-4.mt-4.dark-blue-btn.pop-up-btn(
        v-if="checkIfCurrentUserIsSuperAdmin && !checkIfSuperAdmin && upgradeActive"
        @click="handleUpgradeToAdmin"
      ) {{ $t('common.assign_admin_role') }}
</template>

<script>
import _ from "lodash";
import UserDetailsForm from "@components/users/UserDetailsForm";
import Collapse from "@components/users/Collapse";
import RolePermissionsList from "@components/_shared/RolePermissionsList";
import { mapActions, mapMutations, mapState } from "vuex";
import { roles } from "@utils/roles";

export default {
  name: "EditUserView",
  components: { UserDetailsForm, Collapse, RolePermissionsList },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("user", ["newAddUserData", "collapsesData"]),

    checkIfSuperAdmin(){
      if (this.userDetails) {
        return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(
            (r) => this.userDetails.roles.map((role) => role.name).indexOf(r) >= 0
        );
      }
    },

    adminRoleId() {
      return this.adminRoles.find(role => role.name === roles.ADMINISTRATOR).id;
    },

    checkIfCurrentUserIsSuperAdmin(){
      if (this.user) {
        return [roles.SUPER_ADMINISTRATOR].some(
            (r) => this.user.roles.map((role) => role.name).indexOf(r) >= 0
        );
      }
    },

    checkIfCurrentUserIsAdmin(){
      if (this.user) {
        return [roles.ADMINISTRATOR].some(
            (r) => this.user.roles.map((role) => role.name).indexOf(r) >= 0
        );
      }
    },
  },

  data() {
    return {
      adminRoles: [],
      userDetails: null,
      activeNames: [0],
      collapses: [],
      loading: false,
      loadEditCollapses: false,
      upgradeActive: true,
    };
  },

  destroyed() {
    this.removeAllCollapseData()
  },

  async created() {
    await this.handleGetUserDetails();
    await this.handleGetCostCenters();
    await this.handlePrefillCollapsesData();
    await this.handleGetRoles();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("user", ["updateUser", "getUser"]),
    ...mapMutations("user", [
      "setLoadedCostCenters",
      "setNewAddUserCollapseData",
      "setCollapsesData",
      "setDisabledCostCenter",
      "removeAllCollapseData"
    ]),

    ...mapActions("role", [
      "getRoles",
      "getRole",
      "getAllPermissionsByRoleIds",
    ]),

    async handleGetRoles(){
      this.adminRoles = await this.getRoles();
    },
    async handleGetUserDetails() {
      let id = this.$route.params.id
      this.userDetails = await this.getUser(id);
    },

    async handlePrefillCollapsesData() {
      this.collapses = []
      let collapsesData = []

      // prefill vuex data
      // create collapses with cost_center_id and role_ids

      this.userDetails.user_role_cost_centers.forEach((userRoleCostCenter, index) => {
        // if it's administrator add to collapsesData only once
        if ([roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(
            (r) => this.userDetails.roles.map((role) => role.name).indexOf(r) >= 0
        )) {
          collapsesData.push({
            cost_center_id: null,
            role_id: userRoleCostCenter.role.id,
            loadedChecklists: [...userRoleCostCenter.permissions],
            alreadyChecked: [...userRoleCostCenter.permissions.map(perm => perm.id)],
            permissions: [],
            collapse: index
          })
        }

        // map the cost centers with the permissions only if a users has a cost center assigned
        if (!userRoleCostCenter.cost_center) {
          return;
        }

        if (!collapsesData.some(el => el.cost_center_id === userRoleCostCenter.cost_center.id)) {
          collapsesData.push({
            cost_center_id: userRoleCostCenter.cost_center.id,
            role_id: userRoleCostCenter.role.id,
            loadedChecklists: [...userRoleCostCenter.permissions],
            alreadyChecked: [...userRoleCostCenter.permissions.map(perm => perm.id)],
            permissions: [],
            collapse: index
          })
        } else {
          let collapsesDataIndex = collapsesData.findIndex(el => el.cost_center_id === userRoleCostCenter.cost_center.id)
          collapsesData[collapsesDataIndex].role_ids.push(userRoleCostCenter.role.id)
          collapsesData[collapsesDataIndex].loadedChecklists.push(...userRoleCostCenter.permissions)
          collapsesData[collapsesDataIndex].alreadyChecked.push(...userRoleCostCenter.permissions.map(perm => perm.id))
        }
      })

      // update authorized
      this.userDetails.authorize_user_cost_center_permissions.forEach((authorized => {
        let foundCollapseDataIndex = collapsesData.findIndex(el => el.cost_center_id === authorized.cost_center.id)
        collapsesData[foundCollapseDataIndex].alreadyChecked.push(authorized.permission.id)
        collapsesData[foundCollapseDataIndex].loadedChecklists.push(authorized.permission.id)
      }))

      // update restricted
      collapsesData.forEach(data => {
        this.userDetails.restrict_user_cost_center_permissions.forEach((restricted) => {
          if (restricted.cost_center.id === data.cost_center_id) {
            _.remove(data.alreadyChecked, id => id === restricted.permission.id);
          }
        })
      })

      collapsesData.forEach(data => {
        this.collapses.push(data.collapse)
        this.setCollapsesData({
          collapse: data.collapse,
          cost_center_id: data.cost_center_id,
          role_id: data.role_id,
          alreadyChecked: data.alreadyChecked,
          loadedChecklists: data.loadedChecklists,
          permissions: [],
        });
        this.setDisabledCostCenter({
          selectedCostCenterId: data.cost_center_id,
          disabled: true,
        });
      })
    },

    async handleGetCostCenters() {
      const cost_centers = await this.getCostCenters("?page_size=1000");
      cost_centers.data.forEach((costCenter) => {
        costCenter.disabled = false;
      });

      this.setLoadedCostCenters(cost_centers.data);
    },

    handleAddCollapse() {
      if (this.collapses.length >= 1) {
        let lastItem = _.clone(this.collapses[this.collapses.length - 1]);
        this.collapses.push((lastItem += 1));
      } else {
        this.collapses.push(0)
      }

      this.activeNames = this.collapses.length - 1 // toggling collapse only the latest added
    },

    handleRemoveCollapse(index) {
      if (this.collapses.length > 1) {
        this.collapses.splice(index, 1);
      }
    },

    async handleEditUser() {
      let isUserDetailsValid = false;
      let isRolePermissionValid = false;

      this.$refs.userDetails.$refs["AddUserForm"].validate((valid) => {
        isUserDetailsValid = !!valid;
      });

      if (this.collapses.length > 1) {
        this.$refs.collapseDetails.forEach((collapse) => {
          collapse.$refs["RolesAndPermissionsForm"].validate((valid) => {
            isRolePermissionValid = !!valid;
          });
        });
      } else {
        isRolePermissionValid = true
      }

      if (isUserDetailsValid && isRolePermissionValid) {
        this.handleMapCollapseData();

        let payload = { ...this.newAddUserData, id: this.$route.params.id };

        // if it's administrator delete these 2 rows
        if ([roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(
            (r) => this.userDetails.roles.map((role) => role.name).indexOf(r) >= 0
        )) {
          delete payload.user_cost_center_permissions
        }

        await this.updateUser(payload).then(() => {
          this.$router.push('/users')
        }).finally(() => {
          this.loading = false;
        })
      }
    },

    handleMapCollapseData() {
      let mappedUserRoleCostCenters = [];
      let mappedUserCostCenterPermissions = [];

      const schemaUserRoleCostCenter = (role_id, cost_center_id) => {
        return {
          role_id: Number(role_id),
          cost_center_id: cost_center_id ? Number(cost_center_id) : null,
        };
      };

      const schemaUserRoleCostCenterPermissions = (cost_center_id, permission_id, grant_type) => {
        return {
          cost_center_id: cost_center_id ? Number(cost_center_id) : null,
          permission_id: Number(permission_id),
          grant_type,
        };
      };

      this.collapsesData.forEach((collapseData) => {
        // map user_role_cost_centers
        // collapseData.role_ids.forEach((role_id) => {
          mappedUserRoleCostCenters.push(
              schemaUserRoleCostCenter(collapseData.role_id, collapseData.cost_center_id)
          );
        // });

        // map user_cost_center_permissions
        collapseData.permissions.forEach((perm) => {
          mappedUserCostCenterPermissions.push(
              schemaUserRoleCostCenterPermissions(
                  collapseData.cost_center_id,
                  perm.permission_id,
                  perm.grant_type
              )
          );
        });
      });

      this.setNewAddUserCollapseData({
        mappedUserRoleCostCenters,
        mappedUserCostCenterPermissions,
      });
    },

    async handleUpgradeToAdmin(){
      this.upgradeActive = false;
        this.removeAllCollapseData()

        this.collapses = [];
        this.collapses.push(0)
        let checkList = []
        let params = `?by_role_ids=${this.adminRoleId}`;
        let loadedChecklists = await this.getAllPermissionsByRoleIds(params);

        let mappedRestrictedOrAuthorizedPermissions = [];

        const computeRestrictedOrAuthorizedPermission = (grant_type, permId) => {
          return {
            cost_center_id: null,
            permission_id: permId,
            grant_type: 'authorize',
            collapse: this.collapses[0],
          };
        };

        // map and add to user_cost_center_permissions
        loadedChecklists.forEach((addedPermId) => {
          mappedRestrictedOrAuthorizedPermissions.push(
              computeRestrictedOrAuthorizedPermission(addedPermId)
          );
          checkList.push(addedPermId.id)
        });
        
        this.setCollapsesData({
        collapse: this.collapses[0],
        cost_center_id: null,
        role_id: `${this.adminRoleId}`,
        alreadyChecked: checkList,
        loadedChecklists: loadedChecklists,
        permissions: mappedRestrictedOrAuthorizedPermissions,
      });

    },

  },
};
</script>

<style lang="scss" scoped>
.add-user-btn {
  right: 24px;
}
</style>
