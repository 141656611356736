<template lang="pug">
  .docking-stations-list-view
    h3 {{ $t('docking_stations_list.docking_stations_list') }}
    .filters
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .docking-stations-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['by_keyword'])"
        )
          el-button.dark-blue-btn(
            @click="showAddDockingStationDialog"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['create'])"
          )
            h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          DockingStationsListViewTable(
            v-loading="loading"
            :docks="docks"
          )
    Pagination(
      :view="'docksList'"
      :costCenterId="selectedCostCenterId"
      :search="search"
      @viewData="handleGetDocks"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { trimTableField } from "@utils/filterUtils";
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import DockingStationsListViewTable from "@components/docking_stations/DockingStationsListViewTable";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";

export default {
  mixins: [general],
  components: { Pagination, SearchWithButtons, DockingStationsListViewTable, FilterCostCenterDropdown },

  data() {
    return {
      search: "",
      selectedCostCenterId: "",
      docks: [],
      trimTableField,
      loading: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["showAddDockingStationDialog"]),

    handleGetDocks(data) {
      this.docks = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
