<template lang="pug">
  div
    // tickets with admin settings
    el-submenu(
      route
      index="/tickets"
      v-if="!checkIfNotSuperAndAdministrator()"
    )
      template(slot='title')
        router-link.sub-menu-link(to="/tickets")
          .img-wrapper
            img(src="@src/assets/sidebar/tickets.svg")
          span  {{ $t('sidebar.tickets') }}

      // tickets settings
      el-menu-item(
        index='/tickets_settings'
      )
        router-link.sub-menu-link(to="/tickets_settings")
          span  {{ $t('tickets_settings.tickets_settings') }}

    // normal tickets
    el-menu-item(
      index='/tickets'
      v-if=`
        showItemDynamically(user, dynamicViewsConstants.resource_names.TICKET, ['index']) &&
        checkIfNotSuperAndAdministrator()
       `
    )
      .img-wrapper
        img(src="@src/assets/sidebar/tickets.svg")
      router-link.sub-menu-link(to="/tickets")
        span  {{ $t('sidebar.tickets') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";

export default {
  name: "TicketsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants,
      checkIfNotSuperAndAdministrator
    }
  }
}
</script>
