<template lang="pug">
  .add-rent-tariff
    el-form(
      :model="form"
      ref="AddRentTariffForm"
      :rules="addRentTariffRules"
      @submit.native.prevent
    )
      el-row(:gutter="20")
        el-col(:span="8")
          el-form-item(
            prop="name"
            :label="$t('common.name')"
          )
            el-input(
              v-model="form.name"
              name="name"
            )
        el-col(:span="8")
          el-form-item(
            prop="cost_center_id"
            :label="$t('common.cost_center')"
          )
            el-select.w-100(
              v-model='form.cost_center_id'
              filterable
              :placeholder="$t('placeholder.enter_cost_center_id')"
            )
              el-option(
                v-for='costCenter in costCenters'
                :key='costCenter.id'
                :label='costCenter.name'
                :value='costCenter.id'
              )
      el-row(:gutter="20").fees-info
        p ⏳ {{ $t('dialogs.rent_tariffs.warranty.warranty') }} -
          |  {{ $t('dialogs.rent_tariffs.warranty.info') }}
      el-row(:gutter="10").fees-block
        el-col(:span="6.5")
          .fee
            h3 {{ $t('dialogs.rent_tariffs.fee_block.fee') }}:
            el-form-item(prop="warranty")
              el-input(
                v-model.number="form.warranty"
                name="warranty"
                type="number"
              )
      el-row(:gutter="20").fees-info
        p ⏳ {{ $t('dialogs.rent_tariffs.initialFee.initialFee') }} -
          |  {{ $t('dialogs.rent_tariffs.initialFee.info') }}
      el-row(:gutter="20").fees-block
        el-col(:span="6.5")
          .fee
            h3 {{ $t('dialogs.rent_tariffs.fee_block.fee') }}:
            el-form-item(prop="initial_fee")
              el-input(
                v-model.number="form.initial_fee"
                name="initial_fee"
                type="number"
              )
            //- h3 {{ $t('dialogs.rent_tariffs.fee_block.per') }}
        el-col(:span="6.5")
          .fee
            h3 {{ $t('dialogs.rent_tariffs.fee_block.time_block') }}:
            el-form-item(prop="initial_timeblock")
              el-input(
                v-model.number="form.initial_timeblock"
                name="initial_timeblock"
                type="number"
              )
            //- h3 {{ $t('dialogs.rent_tariffs.fee_block.min') }}
      el-row.fees-info.pl-2(:gutter="20")
        p ⏳ {{ $t('dialogs.rent_tariffs.secondaryFee.secondaryFee') }} -
          |  {{ $t('dialogs.rent_tariffs.secondaryFee.info') }}
      el-row(:gutter="20").fees-block
        el-col(:span="6.5")
          .fee
            h3 {{ $t('dialogs.rent_tariffs.fee_block.fee') }}:
            el-form-item(prop="secondary_fee")
              el-input(
                v-model.number="form.secondary_fee"
                name="secondary_fee"
                type="number"
              )
            //- h3 {{ $t('dialogs.rent_tariffs.fee_block.per') }}
        el-col(:span="6.5")
          .fee
            h3 {{ $t('dialogs.rent_tariffs.fee_block.time_block') }}:
            el-form-item(prop="secondary_timeblock")
              el-input(
                v-model.number="form.secondary_timeblock"
                name="secondary_timeblock"
                type="number"
              )
            //- h3 {{ $t('dialogs.rent_tariffs.fee_block.min') }}
    el-row.text-center
      el-button.dark-blue-btn.mt-5.pop-up-btn(
        @click="handleAddRentTariff('AddRentTariffForm')"
        :loading="loading"
      ) {{ $t('dialogs.common.create') }}
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { rentTariffViewValidations } from "@utils/formValidations/rentTariffViewValidations";
import { setObjectValues } from "@utils/generalUtils";

export default {
  name: "AddRentTariff",
  mixins: [rentTariffViewValidations],

  data() {
    return {
      costCenters: [],

      form: {
        name: "",
        cost_center_id: "",
        warranty: "",
        initial_fee: "",
        initial_timeblock: "",
        secondary_fee: "",
        secondary_timeblock: "",
      },
      loading: false,
      setObjectValues,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetCostCenters();
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),
    ...mapActions("rentTariff", ["addRentTariff"]),
    ...mapMutations("dialog", ["hideAddRentTariffDialog"]),
    ...mapMutations("rentTariff", ["setShouldFetchTariffs"]),

    clearForm() {
      this.setObjectValues(this.form, "")
    },

    handleAddRentTariff(formName) {
      this.loading = true;

      const form = this.$refs[formName];
      const random_dock_number = Math.floor(Math.random() * 200);

      this.form.dockName = `docking nr. ${random_dock_number}`;

      form.validate(async (valid) => {
        let params = {...this.form}

        if (valid) {
          this.addRentTariff(params)
              .then(() => {
                this.hideAddRentTariffDialog();
                this.clearForm();
                this.setShouldFetchTariffs(true);
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.loading = false;
        }
      });
    },

    async handleGetCostCenters() {
      let params = ""
      const response = await this.getCostCenters(params)
      this.costCenters = response.data
    },
  },
};
</script>

<style lang="scss" scoped>
.add-rent-tariff {
  .el-form {
    padding: 0 60px;

    .el-form-item {
      margin-bottom: 0;
    }
  }

  .fee {
    display: flex;
    align-items: center;

    h3 {
      font-size: 20px !important;
      letter-spacing: 0;
      line-height: 27px;
      font-weight: 500;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }

    .el-input {
      width: 64px;
    }
  }

  .fees-block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
