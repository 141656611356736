export const dynamicViewsConstants = {
  resource_names: {
    COST_CENTER: 'Cost center',
    BIKE: 'Bike',
    CUSTOMER: 'Customer',
    DOCKING_STATION: 'Docking station',
    INVENTORY_BIKE: 'Inventory bike',
    PERMISSION: 'Permission',
    RENT_TARIFF: 'Rent tariff',
    RIDE: 'Ride',
    ROLE: 'Role',
    TICKET: 'Ticket',
    SMART_CARD: 'Smart card',
    TRACKER: 'Tracker',
    TRANSACTION: 'Transaction',
    USER: 'User',
  }
}

export const transactionsConstants = {
  PARTIAL_REFUND: 'PARTIAL_REFUND',
  FULL_REFUND: 'FULL_REFUND',
  REFUND_TYPE: 'refund',
  SUCCESSFULLY_PAID_STATUS: 'successfully_paid',
  FAILED_BECAUSE_OF_SERVER_ERROR: 'failed_because_of_server_error'
}

export const stationCommands = {
  // /**
  //  * @param START_EM - Open EM (electro-magnet)
  //  *
  //  */
  // START_EM: "START_EM",
  // /**
  //  * @param STOP_EM - Close EM (electro-magnet)
  //  *
  //  */
  // STOP_EM: "STOP_EM",
  // /**
  //  * @param START_RED_LIGHT - Turn on red light
  //  *
  //  */
  // START_RED_LIGHT: "START_RED_LIGHT",
  // /**
  //  * @param START_RED_LIGHT_1 - the red light flashes every
  //  100ms
  //  *
  //  */
  // START_RED_LIGHT_1: "START_RED_LIGHT_1",
  // /**
  //  * @param START_RED_LIGHT_2 - the red light flashes every
  //  200ms
  //  *
  //  */
  // START_RED_LIGHT_2: "START_RED_LIGHT_2",
  // /**
  //  * @param START_RED_LIGHT_3 - the red light flashes every
  //  300ms
  //  *
  //  */
  // START_RED_LIGHT_3: "START_RED_LIGHT_3",
  // /**
  //  * @param START_RED_LIGHT_4 - the red light flashes every
  //  400ms
  //  *
  //  */
  // START_RED_LIGHT_4: "START_RED_LIGHT_4",
  //
  // /**
  //  * @param START_RED_LIGHT_5 - the red light flashes every
  //  500ms
  //  *
  //  */
  // START_RED_LIGHT_5: "START_RED_LIGHT_5",
  // /**
  //  * @param START_RED_LIGHT_6 - the red light flashes every
  //  600ms
  //  *
  //  */
  // START_RED_LIGHT_6: "START_RED_LIGHT_6",
  // /**
  //  * @param START_RED_LIGHT_7 - the red light flashes every
  //  700ms
  //  *
  //  */
  // START_RED_LIGHT_7: "START_RED_LIGHT_7",
  // /**
  //  * @param START_RED_LIGHT_8 - the red light flashes every
  //  800ms
  //  *
  //  */
  // START_RED_LIGHT_8: "START_RED_LIGHT_8",
  // /**
  //  * @param START_RED_LIGHT_9 - the red light flashes every
  //  900ms
  //  *
  //  */
  // START_RED_LIGHT_9: "START_RED_LIGHT_9",
  // /**
  //  * @param START_RED_LIGHT_10 - Turn on red light
  //  *
  //  */
  // START_RED_LIGHT_10: "START_RED_LIGHT_10",
  // /**
  //  * @param STOP_RED_LIGHT - turn off red light
  //  *
  //  */
  // STOP_RED_LIGHT: "STOP_RED_LIGHT",
  //
  // /**
  //  * @param UNLOCK_BIKE - start unlock remote bike
  //  *
  //  */
  // UNLOCK_BIKE: "UNLOCK_BIKE",
  // /**
  //  * @param START_GREEN_LIGHT - turn on green light
  //  *
  //  */
  // START_GREEN_LIGHT: "START_GREEN_LIGHT",
  // /**
  //  * @param START_GREEN_LIGHT_1 - the green light flashes every
  //  100ms
  //  *
  //  */
  // START_GREEN_LIGHT_1: "START_GREEN_LIGHT_1",
  // /**
  //  * @param START_GREEN_LIGHT_2 - the green light flashes every
  //  200ms
  //  *
  //  */
  // START_GREEN_LIGHT_2: "START_GREEN_LIGHT_2",
  // /**
  //  * @param START_GREEN_LIGHT_3 - the green light flashes every
  //  300ms
  //  *
  //  */
  // START_GREEN_LIGHT_3: "START_GREEN_LIGHT_3",
  // /**
  //  * @param START_GREEN_LIGHT_4 - the green light flashes every
  //  400ms
  //  *
  //  */
  // START_GREEN_LIGHT_4: "START_GREEN_LIGHT_4",
  // /**
  //  * @param START_GREEN_LIGHT_5 - the green light flashes every
  //  500ms
  //  *
  //  */
  // START_GREEN_LIGHT_5: "START_GREEN_LIGHT_5",
  //
  // /**
  //  * @param START_GREEN_LIGHT_6 - the green light flashes every
  //  600ms
  //  *
  //  */
  // START_GREEN_LIGHT_6: "START_GREEN_LIGHT_6",
  // /**
  //  * @param START_GREEN_LIGHT_7 - the green light flashes every
  //  700ms
  //  *
  //  */
  // START_GREEN_LIGHT_7: "START_GREEN_LIGHT_7",
  // /**
  //  * @param START_GREEN_LIGHT_8 - the green light flashes every
  //  800ms
  //  *
  //  */
  // START_GREEN_LIGHT_8: "START_GREEN_LIGHT_8",
  // /**
  //  * @param START_GREEN_LIGHT_9 - the green light flashes every
  //  900ms
  //  *
  //  */
  // START_GREEN_LIGHT_9: "START_GREEN_LIGHT_9",
  // /**
  //  * @param START_GREEN_LIGHT_10 - turn on green light
  //  *
  //  */
  // START_GREEN_LIGHT_10: "START_GREEN_LIGHT_10",
  // /**
  //  * @param STOP_GREEN_LIGHT - turn off green light
  //  */
  // STOP_GREEN_LIGHT: "STOP_GREEN_LIGHT",
  // /**
  //  * @param START_BUZZER - buzzer on
  //  */
  // START_BUZZER: "START_BUZZER",
  // /**
  //  * @param STOP_BUZZER - buzzer off
  //  */
  // STOP_BUZZER: "STOP_BUZZER",
  //
  // /**
  //  * @param START_RELAY - open relay
  //  */
  // START_RELAY: "START_RELAY",
  // /**
  //  * @param STOP_RELAY - close relay
  //  */
  // STOP_RELAY: "STOP_RELAY",
  // /**
  //  * @param ANTENNA_ACTIVATION - activates the RFID antenna for
  //  card reading
  //  */
  // ANTENNA_ACTIVATION: "ANTENNA_ACTIVATION",
  // /**
  //  * @param ANTENNA_DEACTIVATION - disables the RFID antenna for
  //  card reading
  //  */
  // ANTENNA_DEACTIVATION: "ANTENNA_DEACTIVATION",
  // /**
  //  * @param READ_ID - read the card near the RFID
  //  * In order to use this command we need an error prevention
  //  flow, so the commands are used in the following order:
  //  * @param ANTENNA_ACTIVATION
  //  * @param READ_ID
  //  * @param ANTENNA_DEACTIVATION
  //  * In case of remote control, the app create this flow for the
  //  READ_ID command
  //  */
  // READ_ID: "READ_ID",
  // /**
  //  * @param READ_DOOR_OF_BAY - read the status of the bay door
  //  if it is opened or closed
  //  */
  // READ_DOOR_OF_BAY: "READ_DOOR_OF_BAY",
  //
  /**
   * @param START_LIVE_LOGS starts the flow of live logs that
   provide real-time information about the station
   */
  START_LIVE_LOGS: "START_LIVE_LOGS",
  /**
   * @param STOP_LIVE_LOGS stops the flow of live logs that
   provide real-time information about the station
   */
  STOP_LIVE_LOGS: "STOP_LIVE_LOGS",
  /**
   * @param SYNC_LOGS upload local logs to DB
   */
  SYNC_LOGS: "SYNC_LOGS",
  // /**
  //  * @param START_RIDES enable new rides on station
  //  */
  // START_RIDES: "START_RIDES",
  // /**
  //  * @param END_RIDE close a ride with specific time and status
  //  */
  // END_RIDE: "END_RIDE",
  // /**
  //  * @param RFID_VERSION get informations about rfid soft version
  //  */
  // RFID_VERSION: "RFID_VERSION",
  // /**
  //  * @param MACRO_READ_PORTS read rfid ports
  //  */
  // MACRO_READ_PORTS: "MACRO_READ_PORTS",
  // /**
  //  * @param CHECK_MY_VERSION_CUSTOM get all information about
  //  rfid soft app version &amp; boot version
  //  */
  // CHECK_MY_VERSION_CUSTOM: "CHECK_MY_VERSION_CUSTOM",
  /**
   * @param CMD enable rfid commands
   */
  CMD: "CMD",
  /**
   * @param END_LIVE set app to MAINTENANCE mode
   */
  END_LIVE: "END_LIVE",
  /**
   * @param START_LIVE set app to LIVE mode
   */
  START_LIVE: "START_LIVE",
  /**
   * @param RESTART restart app
   */
  RESTART: "RESTART",
  /**
   * @param READ_TEMPERATURE
   */
  READ_TEMPERATURE: "READ_TEMPERATURE",
  /**
   * @param SYNC_CONFIG
   */
  SYNC_CONFIG: "SYNC_CONFIG",
  /**
   * @param UPDATE_KIOSK
   */
  UPDATE_KIOSK: "UPDATE_KIOSK",
  /**
   * @param UDP
   */
  UDP: "UDP",
  /**
   * @param REBOOT
   */
  REBOOT: "REBOOT",
  /**
   * @param INSTALL_MQTT
   */
  INSTALL_MQTT: "INSTALL_MQTT",
  /**
   * @param OPEN_MQTT_APP
   */
  OPEN_MQTT_APP: "OPEN_MQTT_APP",
  /**
   * @param KIOSK_ON
   */
  KIOSK_ON: "KIOSK_ON",
  /**
   * @param KIOSK_OFF
   */
  KIOSK_OFF: "KIOSK_OFF",
}
