<template lang="pug">
  .add-role-view.pb-2
    h3 {{ $t('roles.add_role') }}
    el-breadcrumb.last-breadcrumb-bold.mt-3(separator="/")
      el-breadcrumb-item(:to="{ path: '/roles' }") {{ $t('roles.roles') }}
      el-breadcrumb-item {{ $t('roles.add_role') }}
    .add-role-wrapper.pt-4.position-relative
      el-button.dark-blue-btn.pop-up-btn.add-role-btn.position-absolute(
        @click="handleSubmit('AddRoleForm')"
        v-loading="loading"
      ) {{ $t('dialogs.common.create') }}
      el-row.pl-4.pr-4
        el-col(:span="24")
          h3.mb-2 {{ $t('roles.role_details') }}
          el-form(
            :model="form"
            ref="AddRoleForm"
            :rules="addRoleFormRules"
            @submit.native.prevent
          )
            el-row(:gutter="25")
              el-col(:span="8")
                el-form-item(
                  prop="name"
                  :label="$t('common.name')"
                )
                  el-input(
                    v-model="form.name"
                    name="name"
                  )
      .role-details
        el-row.pl-4.pr-4.pb-1
          el-col(:span="16")
            h3.mb-2 {{ upperCaseFirstLetters(`${$t('roles.permissions').toLowerCase()}`) }}
        RolePermissionsList(
          v-model="checkList"
          :role="role"
          :newRoleSelect="false"
          :isEdit="true"
        )
</template>

<script>
import { mapActions } from 'vuex'
import RolePermissionsList from "@components/_shared/RolePermissionsList"
import { upperCaseFirstLetters } from "@utils/generalUtils";

export default {
  components: { RolePermissionsList },

  data() {
    return {
      checkList: [],
      role: {},
      form: {},
      addRoleFormRules: {
        name: [
          {
            required: true
          }
        ],
      },
      loading: false
    };
  },

  async mounted() {
  },

  methods: {
    ...mapActions("role", ['createRole', 'assignPermissions']),
    upperCaseFirstLetters,

    handleSubmit(formName) {
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            let createRolePayload = { ...this.form };
            const createdRole = await this.createRole(createRolePayload)

            let assignPermissionsPayload = {
              role_id: createdRole.id,
              permission_ids: this.checkList,
            };

            await this.assignPermissions(assignPermissionsPayload);
            await this.$router.push('/roles')
            this.loading = false;
          } catch (e) {
            this.loading = false;
          }

        } else {
          this.loading = false;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.add-role-btn {
  right: 24px;
  z-index: 10;
}
</style>

