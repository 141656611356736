<template lang="pug">
  .set-password-view
    .form
      .background-wrapper
        img.header-img(src="@assets/auth/header.svg")
        el-row.d-flex.justify-content-center
          el-col(:span="16")
            el-form(
              :model="form"
              ref="setPasswordForm"
              :rules="changePasswordViewValidations"
              @submit.native.prevent
              @keyup.enter.native="handleForgotPassword('setPasswordForm')"
            )
              h2 {{ $t('auth.set_your_password') }}
              h4.mt-4 {{ $t('auth.set_your_password_message') }}
              el-form-item.mt-4(
                prop="password"
                :label="$t('label.new_password')"
              )
                el-input(
                  v-model="form.password"
                  name="password"
                  type="password"
                )
              el-form-item.mt-4(
                prop="password_confirmation"
                :label="$t('auth.confirm_password')"
              )
                el-input(
                  v-model="form.password_confirmation"
                  name="password_confirmation"
                  type="password"
                )
            el-row.text-center
              el-button.dark-blue-btn(
                @click="setNewPassword('setPasswordForm')"
                :disabled="disabledButton"
                :loading="submitLoading"
              ) {{ $t('auth.send') }}
    .background
</template>

<script>
import { mapActions } from "vuex";
import { authenticationViewValidations } from "@/src/utils/formValidations/authenticationViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "ChangePasswordView",
  mixins: [authenticationViewValidations, customValidations],

  data() {
    return {
      access_token: null,
      form: {
        password: "",
        password_confirmation: "",
      },
      submitLoading: false,
    };
  },

  computed: {
    disabledButton() {
      return (
        this.form.password === "" || this.form.password_confirmation === ""
      );
    },
  },

  beforeRouteEnter(to, from, next) {
    let params = new URL(document.location).href;
    let token = params.split("=")[1];

    if (token) {
      next();
    } else {
      next("/login");
    }
  },

  mounted() {
    this.access_token = this.$route.query.token;
  },

  methods: {
    ...mapActions("user", ["changePassword"]),

    setNewPassword(formName) {
      this.submitLoading = true;
      const form = this.$refs[formName];
      form.validate(async (valid) => {
        if (valid) {
          const payload = { ...this.form };
          payload.access_token = this.access_token;

          if (payload.password === payload.password_confirmation) {
            this.changePassword(payload)
              .then(() => {
                this.submitLoading = false;
                this.$router.push("/login");
              })
              .finally(() => {
                this.submitLoading = false;
              });
          } else {
            this.submitLoading = false;
          }
        } else {
          this.submitLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.set-password-view {
  display: flex;

  h4 {
    color: rgba(6, 16, 88, 0.6) !important;
    font-family: Avenir, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-top: 100px;
  }
}
</style>
