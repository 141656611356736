<template lang="pug">
  el-row(v-loading="loading")
    el-col(
      :span="24"
    ).mt-2
      GmapMap.mapWrapper(
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="roadmap"
      )
      //- style="width: 500px; height: 300px"
</template>

<script>

import defaultMarker from "@assets/bikesMap/available-location.svg";
import customMarker from "@assets/dockingStationsMap/available-location.svg";
import startMarker from "@assets/rideHistory/pin-start.svg";
import endMarker from "@assets/rideHistory/pin-end.svg";
import { gmapApi } from "vue2-google-maps";
import { mapState } from "vuex";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";

export default {
  name: "TrackerGoogleMap",
  props: {
    dialogName: {
      type: String,
      required: false,
    },

    trackerCoordinates: {
      type: Array
    }
  },

  computed: {
    ...mapState("map", ["coordinates", "changedDialog", "isGoogleLoaded"]),

    google: gmapApi,
  },

  data() {
    return {
      markers: [],
      startMarker: null,
      endMarker: null,
      rideMarker: null,
      trackerMarker: null,
      flightPath: null,
      marker: null,
      loading: false,
      zoom: 13,

      markerPosition: {
        lat: null,
        lng: null,
      },

      center: {
        lat: 0,
        lng: 0,
      },
    };
  },

  watch: {
    google() {
      this.initMap()
    },

    coordinates() {
      this.initMap()
    },

    trackerCoordinates() {
      this.initMap()
    },
  },

  mounted() {
    if (this.google) {
      this.initMap()
    }
  },

  methods: {
    async initMap() {
      switch (this.dialogName) {
        case "lastLocation":
          return this.handleMapMarkers();
        case "locations":
          return this.handleMapTrail();
      }
    },

    handleMapMarkers() {
      if(this.startMarker) { this.startMarker.setMap(null) }
      if(this.endMarker) { this.endMarker.setMap(null) }
      if(this.flightPath) { this.flightPath.setMap(null) }
      this.zoom = 17;

      if(this.trackerMarker) { 
        this.trackerMarker.setMap(null);
      }
      this.$refs.mapRef.$mapPromise.then((map) => {
        // pan to the start coordinate
          map.panTo({
            lat: Number(0.0),
            lng: Number(0.0),
          });

        if (this.trackerCoordinates.length > 0) {
          map.panTo({
            lat: this.trackerCoordinates[0],
            lng: this.trackerCoordinates[1],
          });
          this.trackerMarker = new this.google.maps.Marker({
            position: {
              lat: this.trackerCoordinates[0],
              lng: this.trackerCoordinates[1],
            },
            map: map,
            icon: defaultMarker,

            label: { color: '#ffffff', fontWeight: 'bold', fontSize: '10px', text: "gps"},
            draggable: false,
          });
        }
      })
    },

    handleMapTrail() {
      if(this.trackerMarker) { this.trackerMarker.setMap(null) }
      if(this.startMarker) { this.startMarker.setMap(null) }
      if(this.endMarker) { this.endMarker.setMap(null) }
      if(this.flightPath) { this.flightPath.setMap(null) }
      if (this.trackerCoordinates.length > 0) {
        const mappedRideCoordinates = this.trackerCoordinates.map(coords => {
          return {
            lat: Number(coords.value[0]),
            lng: Number(coords.value[1])
          }
        })

        this.flightPath = new this.google.maps.Polyline({
          path: mappedRideCoordinates,
          geodesic: true,
          strokeColor: "#2564f0",
          strokeWeight: 5,
        });

        this.$refs.mapRef.$mapPromise.then((map) => {
          // pan to the start coordinate
          map.panTo({
            lat: mappedRideCoordinates[0].lat,
            lng: mappedRideCoordinates[0].lng,
          });

          this.flightPath.setMap(map);

          // add start/end markers
          this.startMarker = new this.google.maps.Marker({
            position: {
              lat: mappedRideCoordinates[mappedRideCoordinates.length - 1].lat,
              lng: mappedRideCoordinates[mappedRideCoordinates.length - 1].lng,
            },
            map: map,
            icon: startMarker,
            draggable: false,
          });
          this.endMarker = new this.google.maps.Marker({
            position: {
              lat: mappedRideCoordinates[0].lat,
              lng: mappedRideCoordinates[0].lng,
            },
            map: map,
            icon: endMarker,
            draggable: false,
          });
        })
      }
    },

    // async getUserLocation() {
    //   let location = null;
    //   await navigator.geolocation.getCurrentPosition(currentBrowserLocation => {
    //     location = currentBrowserLocation
    //   })

    //   // if location is not working or turned off
    //   const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
    //   if (romaniaCoords) {
    //     location = romaniaCoords
    //     if (!this.coordinates.lat) {
    //       this.zoom = 7
    //     }
    //   }

    //   return location;
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.mapWrapper {
  width: 100% !important;
  height: 340px !important;
  margin: 0 auto;
  margin-bottom: 32px;
}
</style>
