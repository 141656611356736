<template lang="pug">
  .cost-centers-filter(v-if="computeCostCenterIDFilter")
    span.filter-title {{ $t('common.cost_center_filter') }}
    el-select(
      v-model='selectedCostCenterId'
      filterable
      :placeholder="$t('placeholder.select_cost_center')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='cost_center in costCenters'
        :key='cost_center.id'
        :label='cost_center.name'
        :value="cost_center.value !== '' ? cost_center.id : cost_center.value"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterCostCenterDropdown",

  props: {
    value: {
      type: String,
      default: "",
    },

    view: {
      type: String,
      required: false,
    },
  },

  computed: {
    selectedCostCenterId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeCostCenterIDFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  data() {
    return {
      costCenters: [],
    };
  },

  async mounted() {
    if (this.computeCostCenterIDFilter) {
      await this.handleGetCostCenters()
    }
  },

  methods: {
    ...mapActions("costCenter", ["getCostCenters"]),

    async handleGetCostCenters() {
      const costCenters = await this.getCostCenters("?page_size=1000");
      this.costCenters = costCenters.data;
      if (this.view === 'trackers') {
        this.costCenters.unshift({
          id: "-1",
          name: "Unassigned",
          value: "-1",
        });
      }
      if (this.view != 'exportMaintenances') {
        this.costCenters.unshift({
          id: 0,
          name: "All",
          value: "",
        });
      }
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
