<template lang="pug">
  .chart
    Statistic.pt-2.pb-3.pl-1.pr-1(
      :name="statisticTitle"
      :value="averageEarningsStatistic"
      :fullWidth="false"
      :isMoneyStatistic="true"
      :justifyContent="'left'"
    )
    apexchart(
      type="bar"
      height="380"
      :options="chartOptions"
      :series="chartTransactions"
      :key="chartComponentKey"
    )
</template>

<script>
import { chartOptions } from "@utils/generalUtils";
import _ from "lodash";
import VueApexCharts from "vue-apexcharts";
import Statistic from "@components/_shared/Statistic";

export default {
  name: "Chart",

  props: {
    chartData: {
      type: Object,
      default: {},
    },

    chartType: {
      type: String,
    },

    statisticTitle: {
      type: String,
    }
  },

  components: {
    apexchart: VueApexCharts,
    Statistic,
  },

  watch: {
    async chartData() {
      await this.mapTransactionsForChart();
    },
  },

  data() {
    return {
      chartTransactions: [
        {
          name: this.$t("transactions.per_day"),
          data: [],
        },
        {
          name: this.$t("transactions.average_ride_time_per_day"),
          data: [],
        },
        {
          name: this.$t("transactions.total_earnings"),
          data: [],
        },
      ],

      averageEarningsStatistic: 0,
      chartComponentKey: 0,
      chartOptions: chartOptions,
    };
  },

  async created() {
    await this.mapTransactionsForChart();
  },

  methods: {
    mapTransactionsForChart() {
      if (this.chartType === "filteredTransactions") {
        this.computeFilteredTransactions();
      } else if (this.chartType === "lastDayTransactions") {
        this.computeLastDayTransactions();
      }
    },

    computeFilteredTransactions() {
      this.chartComponentKey += 1;

      // reset the chart
      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];

      this.chartTransactions[0].data.splice(0);
      this.chartTransactions[1].data.splice(0);
      this.chartTransactions[2].data.splice(0);

      // map the objects with the transactions into a array
      const filteredTransactionsArray = Object.entries(this.chartData);

      // order the transactions by date
      const orderedFilteredTransactionsArray = _.orderBy(
        filteredTransactionsArray,
        ["0"]
      );
      const mappedDays = [];

      // map the transactions to match apex data
      if (orderedFilteredTransactionsArray.length > 0) {
        orderedFilteredTransactionsArray.forEach((day) => {
          mappedDays.push(day[1].date);
          this.chartTransactions[0].data.push(day[1].avg_earnings_per_day);
          this.chartTransactions[1].data.push(day[1].avg_ride_time_per_day);
          this.chartTransactions[2].data.push(day[1].total_earnings_per_day);

          // calculate avg. earnings per total
          this.averageEarningsStatistic += day[1].avg_earnings_per_day;

          // we modify the chart to use corresponding dates
          this.chartOptions = {
            ...this.chartOptions,
            ...{
              xaxis: {
                categories: mappedDays,
              },

              yaxis: {
                title: {
                  text: "RON", // TODO: refactor this to be dynamic
                },
              },
            },
          };
        });
      }
    },

    computeLastDayTransactions() {
      this.chartComponentKey += 1;

      // reset the chart
      this.averageEarningsStatistic = 0;
      this.chartOptions.xaxis.categories = [];
      this.chartTransactions[0].data = [];
      this.chartTransactions[1].data = [];
      this.chartTransactions[2].data = [];

      if (!_.isEmpty(this.chartData)) {
        this.chartTransactions[0].data.push(
          this.chartData.avg_earnings_per_day
        );
        this.chartTransactions[1].data.push(
          this.chartData.avg_ride_time_per_day
        );
        this.chartTransactions[2].data.push(
          this.chartData.total_earnings_per_day
        );

        this.averageEarningsStatistic = this.chartData.avg_earnings_per_day;
      }

      this.chartOptions = {
        //
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: [this.$moment().subtract(1, "days").format("D-M-Y")],
          },

          yaxis: {
            title: {
              text: "RON", // TODO: refactor this to be dynamic
            },
          },
        },
      };
    },
  },
};
</script>
