import axios from "axios";
import messageUtils from "@utils/messageUtils";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  transaction: null,
  shouldFetchTransactions: false,
  group_ride: null,
};

export const actions = {
  async getTransactions({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/transactions${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTransactionInfo({ commit }, id) {
    try {
      const response = await axios.get(`/v1/transactions/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  

  async getGroupRides ({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/group_rides${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getGroupRideInfo({ commit }, id) {
    try {
      const response = await axios.get(`/v1/group_rides/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTransactionsStatistics({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/transactions/show_statistics${payload}`);
      return response.data
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async exportTransactions({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/transactions/export${payload}`, { responseType: 'arraybuffer' });
      return response
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTransactionsForARide({ commit }, id) {
    try {
      const response = await axios.get(`/v1/rides/${id}/transactions`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  }
};

export const mutations = {
  setTransaction(state, transaction) {
    state.transaction = transaction;
  },

  setShouldFetchTransactions(state, value) {
    state.shouldFetchTransactions = value;
  },

  setGroupRide(state, group_ride) {
    state.group_ride = group_ride;
  },
};
