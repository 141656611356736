<template lang="pug">
  .date-filter
    span.filter-title {{ $t('common.begin_end_date') }}
    el-date-picker(
      v-model="dateRange"
      type="datetimerange"
      align="right"
      format="dd/MM/yyyy HH:mm"
      :start-placeholder="$t('common.start_date')"
      :end-placeholder="$t('common.end_date')"
      :picker-options="pickerOptions"
      :default-value="new Date()"
      @change="emitChangeEvent"
    )
</template>

<script>
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";

export default {
  name: "FilterDatePicker",

  props: {
    value: {
      type: Array,
      default: "",
    },
  },

  computed: {
    dateRange: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return disabledFutureDates(time);
        },
        shortcuts: dateRangeShortcuts,
      },
    };
  },

  watch: {
    value() {
      // if (this.value && this.value[1].getDate()==new Date().getDate() && this.value[1].getDate()==this.value[0].getDate())
      //   this.value[1] = new Date();

      if (this.value) {
        if (this.endIsToday(this.value[1]) && this.startEndAreTheSame(this.value[0], this.value[1])) {
          let date = new Date();

          // Set the hours, minutes, seconds, and milliseconds
          date.setHours(date.getHours(), date.getMinutes(), 0, 0);

          // Assign the new Date object to this.value[1]
          // this.$set(this.value, 1, date);
          this.value[1] = date
        }
          // this.value[1] = new Date().setHours(new Date().getHours(),new Date().getMinutes(),0,0);
        else if (!this.endIsToday(this.value[1]) && this.startEndAreTheSame(this.value[0], this.value[1])) {
          this.value[1].setHours(23, 59, 0, 0); // Set time to 23:59:00
          // Ensure Vue reactivity picks up the change
        }
      }
    }
  },

  methods: {
    emitChangeEvent(values) {
      this.$emit('onChange', values)
    },

    endIsToday(end){
      const today = new Date();
      return end.getDate() == today.getDate() && end.getMonth() == today.getMonth() && end.getFullYear() == today.getFullYear();
    },

    startEndAreTheSame(start, end) {
      return start.getDate() == end.getDate() && start.getMonth() == end.getMonth() && start.getFullYear() == end.getFullYear();
    }
  }
};
</script>
