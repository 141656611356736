<template lang="pug">
  .bikes-view
    h3 {{ $t('bikes.bikes') }}
    .tab-view
      FilterTabs(
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_status'])"
        v-model="activeName"
        :view="'bikes'"
        :tabs="tabs"
      )
    .filters
      //- FilterDatePicker(
      //-   v-model="dateRange"
      //-   v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_date_range'])"
      //- )
      OrderByDropdown(
        v-model="selectedOrderByCategory"
        :view="'bikes'"
        )
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .bikes-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_keyword'])"
        )
          el-button.dark-orange-btn(
            @click="showUpdateBikesMaintenancesDialog"
            v-if="this.adminResource()"
          )
            h4 {{ $t('bikes.actions.edit_maintenances') }}
          el-button.dark-blue-btn(
            @click="showExportBikesMaintenancesDialog"
            v-if="this.adminResource()"
          )
            h4 {{ $t('bikes.actions.export_maintenances') }}
          el-button.green-btn(
            @click="showAddBikeDialog"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['create'])"
          )
            h4 {{ $t('common.add') }}
          el-button.el-icon-refresh-left.mr-2.cursor-pointer(
            style="font-size: 35px; margin-right: 0px;"
            :class="{disabled: generatePausedForSeconds}"
            :loading="generatePausedForSeconds"
            @click="reloadData()")
      el-row
        el-col(:span='24')
          BikesViewTable(
            v-loading="loading"
            :bikes="bikes"
          )
    Pagination(
      v-if="activeName && activeName !== ''"
      :view="'bikes'"
      :activeName="activeName"
      :search="search"
      :reload="reload"
      :dateRange="dateRange"
      :orderByCategory="selectedOrderByCategory"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetBikes"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterTabs from "@components/_shared/FilterTabs";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import OrderByDropdown from "@components/_shared/OrderByDropdown";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapMutations, mapState } from "vuex";
import BikesViewTable from "@components/bikes/BikesViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  mixins: [general, routerUtils],

  components: {
    Pagination,
    FilterTabs,
    FilterDatePicker,
    OrderByDropdown,
    FilterCostCenterDropdown,
    SearchWithButtons,
    BikesViewTable,
  },

  data() {
    return {
      activeName: "all",
      tabs: [
        { name: "all", roles: [] },
        { name: "available_in_station", roles: [] },
        { name: "in_ride", roles: [] },
        { name: "unavailable", roles: [] },
        { name: "reported_missing", roles: [] },
        { name: "under_maintenance", roles: [] },
      ],
      bikes: [],
      search: "",
      reload: false,
      generatePausedForSeconds: false,
      dateRange: [],
      selectedOrderByCategory: "",
      selectedCostCenterId: "",
      loading: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["showAddBikeDialog", "showExportBikesMaintenancesDialog", "showUpdateBikesMaintenancesDialog"]),

    handleGetBikes(data) {
      this.bikes = data;
    },

    handleLoading(loading) {
      this.loading = loading;
      if(!loading) {
        setTimeout(() => this.generatePausedForSeconds = loading, 5000);
      }else {      
        this.generatePausedForSeconds = loading
      }
      this.reload = false;
    },

    reloadData(){
      this.reload = true;
    },

    adminResource() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },

  },
};
</script>

<style lang="scss" scoped>
.bikes-view {
  .status-wrapper {
    .status {
      margin-left: 5px;
    }
  }
}
</style>
