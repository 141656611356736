import axios from "axios";
import messageUtils from "@utils/messageUtils";

import Jsona from "jsona";
const dataFormatter = new Jsona();

export const state = {
  coordinates: {
    lat: null,
    lng: null,
  },
};

export const actions = {
  async getMapStatistics({ commit }) {
    try {
      const response = await axios.get(`/v1/docking_stations/show_statistics`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getPlaceByKeyword({ commit }, keyword) {
    try {
      const response = await axios.get(`/v1/places/autocomplete${keyword}`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getPlaceByID({ commit }, id) {
    try {
      const response = await axios.get(`/v1/places/${id}`);
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setMapCoordinates(state, coordinates) {
    state.coordinates = { ...coordinates };
  },
};
