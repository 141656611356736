<template lang="pug">
  .bikes-view
    h3 {{ $t('trackers.trackers') }}
    .tab-view
    .filters
      FilterTrackerDropdown(v-model="selectedTrackerType")
      FilterCostCenterDropdown(v-model="selectedCostCenterId" :view="'trackers'")
    .trackers-wrapper
      .actions
        SearchWithButtons.search(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.TRACKER, ['by_keyword'])"
        )
        .tracker-data-actions
          .switch
            el-switch(
              v-model="lastTransmission"
              active-color="#2564F0"
              inactive-color="#dbe1fa"
              :active-text="$t('trackers.ordered')"
            )
          el-button.el-icon-refresh-left.mr-2.cursor-pointer(
            style="font-size: 35px; margin-right: 0px;"
            :class="{disabled: generatePausedForSeconds}"
            :loading="generatePausedForSeconds"
            @click="reloadData()")
      el-row
        el-col(:span='24')
          TrackersViewTable(
            v-loading="loading"
            :trackers="trackers"
          )
    Pagination(
      v-if="activeName && activeName !== ''"
      :view="'trackers'"
      :orderBy="lastTransmission"
      :activeName="activeName"
      :search="search"
      :reload="reload"
      :dateRange="dateRange"
      :costCenterId="selectedCostCenterId"
      :trackerType="selectedTrackerType"
      @viewData="handleGetTrackers"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import FilterTrackerDropdown from "@components/_shared/FilterTrackerDropdown";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapMutations, mapState } from "vuex";
import TrackersViewTable from "@components/trackers/TrackersViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [general],

  components: {
    Pagination,
    FilterDatePicker,
    FilterCostCenterDropdown,
    FilterTrackerDropdown,
    SearchWithButtons,
    TrackersViewTable,
  },

  data() {
    return {
      activeName: "all",
      trackers: [],
      search: "",
      lastTransmission: false,
      generatePausedForSeconds: false,
      dateRange: [],
      selectedCostCenterId: "",
      selectedTrackerType: "",
      loading: false,
      reload: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    handleGetTrackers(data) {
      this.trackers = data;
    },

    handleLoading(loading) {
      this.loading = loading;
      if(!loading) {
        setTimeout(() => this.generatePausedForSeconds = loading, 1000);
      }else {      
        this.generatePausedForSeconds = loading
      }
      this.reload = false;
    },

    reloadData(){
      this.reload = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.bikes-view {
  .status-wrapper {
    .status {
      margin-left: 5px;
    }
  }
}
</style>
