import messageUtils from "@utils/messageUtils";
import axios from "axios";

import Jsona from "jsona";
import i18n from "@i18n";
const dataFormatter = new Jsona();

export const state = {
  smartCard: null,
  shouldFetchSmartCards: false,
};

export const actions = {
  async getSmartCards({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/smart_cards${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getTicket({ commit }, id) {
    try {
      const response = await axios.get(`/v1/tickets/${id}`);
      return {
        data: dataFormatter.deserialize(response.data),
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateSmartCardStatus({ commit }, payload) {
    try {
      return axios.patch(`/v1/smart_cards/${payload.id}`, { ...payload });
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
  
  async deleteSmartCard({ commit }, payload) {
    try {
      await axios.delete(`/v1/smart_cards/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

};

export const mutations = {
  setSmartCard(state, smartCard) {
    state.smartCard = smartCard;
  },

  setShouldFetchSmartCards(state, value) {
    state.shouldFetchSmartCards = value;
  },
};
