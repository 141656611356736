<template lang="pug">
//- (v-if="computeTypeIDFilter") 
-//
  .cost-centers-filter
    span.filter-title {{ $t('common.type_filter') }}
    el-select(
      v-model='selectedType'
      filterable
      :placeholder="$t('placeholder.select_type')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='type in tickets.data'
        :key='type.object_hash.status'
        :label='type.object_hash.label'
        :value="type.object_hash.status !== '' ? type.object_hash.status : type.object_hash.label"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterType",

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeTypeIDFilter() {
      return (
        this.user &&
        [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(
          (r) =>
            store.state.auth.user.roles.map((role) => role.name).indexOf(r) >= 0
        )
      );
    },
  },

  data() {
    return {
      tickets: [],
    };
  },

  async mounted() {
    // if (this.computeTypeIDFilter) {
      await this.handleGetTypes();
    // }
  },

  methods: {
    ...mapActions("ticket", ["getTicketTypeStatuses"]),

    async handleGetTypes() {
      const tickets = await this.getTicketTypeStatuses("");
      this.tickets = tickets;
      this.tickets.data.unshift({
          id: 21,
          object_hash:{
            id:3009,
            label:"All",
            status:"all",
          }
      });
    },

    emitChangeEvent(values) {
      this.$emit("onChange", values);
    },
  },
};
</script>
