<template lang="pug">
  el-submenu(
    index=''
    v-if="!checkIfNotSuperAndAdministrator()"
  )
    template(slot='title')
      .img-wrapper
        i.el-icon-s-tools(
          style="font-size: 23px; margin-right: 0"
        )
      span {{ $t('sidebar.admin_settings') }}
    el-menu-item(
      index='/softwares'
    )
      router-link.sub-menu-link(to="/softwares")
        span  {{ $t('sidebar.firmwares') }}
    // station commands
    el-menu-item(
      index='/station_commands'
    )
      router-link.sub-menu-link(to="/station_commands")
        span  {{ $t('sidebar.station_commands') }}

    // logs
    el-menu-item(
      index='/logs'
    )
      router-link.sub-menu-link(to="/logs")
        span {{ $t('sidebar.logs') }}

    // technical dashboard
    el-menu-item(
      index='/technical_dashboard'
    )
      router-link.sub-menu-link(to="/technical_dashboard")
        span {{ $t('sidebar.technical_dashboard') }}

    // configurable settings
    el-menu-item(
      index='/configurable_settings'
    )
      router-link.sub-menu-link(to="/configurable_settings")
        span  {{ $t('configurable_settings.configurable_settings') }}
    // admin commands
    el-menu-item(
      index='/admin_commands'
    )
      router-link.sub-menu-link(to="/admin_commands")
        span  {{ $t('admin_commands.admin_commands') }}
    // roles    
    el-menu-item(
        index='/roles'
      )
        router-link.sub-menu-link(to="/roles")
          span.link-custom  {{ $t('sidebar.roles') }} 
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";

export default {
  name: "AdminSettingsItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants,
      checkIfNotSuperAndAdministrator
    }
  },

  methods: {

  }
}
</script>
