<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='rent_tariffs'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='name'
      :label="$t('common.name')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.name) }}
        span(v-if="scope.row.name.length <= MAX_FIELD_LENGTH") {{ scope.row.name }}
    el-table-column(
      prop='warranty'
      :label="$t('label.warranty')"
    )
      template(slot-scope='scope') {{ $n(scope.row.warranty, 'currency') }}
    el-table-column(
      prop='initialFee'
      :label="$t('label.initial_fee_initial_duration')"
    )
      template(slot-scope='scope') {{ $n(scope.row.initial_fee, 'currency') }} / {{ scope.row.initial_timeblock }}min.
    el-table-column(
      prop='secondaryFee'
      :label="$t('label.secondary_fee_secondary_duration')"
    )
      template(slot-scope='scope') {{ $n(scope.row.secondary_fee, 'currency') }} / {{ scope.row.secondary_timeblock }}min.
    el-table-column(
      prop='cost_center'
      :label="$t('common.cost_center_name')"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer {{ scope.row.cost_center.name }}
    el-table-column(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['show']) || showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['destroy'])"
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        .d-flex
          img.cursor-pointer.mr-2(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['show'])"
            src="@assets/preview.svg"
            @click="handleViewRentTariff(scope.row)"
          )
          img.cursor-pointer.ml-2(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RENT_TARIFF, ['destroy'])"
            src="@assets/actions/delete.svg"
            @click="handleDeleteRentTariffDialog(scope.row)"
          )
</template>

<script>
import { dynamicViewsConstants } from "@src/constants";
import {
  filterUtils,
  MAX_FIELD_LENGTH,
  trimTableField,
} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "RentTariffsViewTable",
  mixins: [general, filterUtils],

  props: {
    rent_tariffs: {
      type: Array,
    },
  },

  data() {
    return {
      dynamicViewsConstants,
      MAX_FIELD_LENGTH,
      trimTableField,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteRentTariff();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "collectionDataToDelete",
      "dialogData",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewRentTariffDialog",
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setAllowDeletion",
      "setDataToDelete",
      "setLoading",
    ]),

    ...mapMutations("rentTariff", ["setRentTariff"]),

    ...mapActions("rentTariff", ["deleteRentTariff"]),

    handleDeleteRentTariff() {
      if (this.allowDeletion) {
        let payload = {
          password_confirmation: this.passwordConfirmation,
          computedQueriesUrl: this.dataToDelete.id,
        };
        this.deleteRentTariff(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteRentTariffDialog(tariff) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: this.$t("rent_tariffs.validations.delete_title"),
        dialogMessage: this.$t("rent_tariffs.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(tariff);
    },

    handleViewRentTariff(tariff) {
      this.setRentTariff(tariff);
      this.showViewRentTariffDialog();
    },
  },
};
</script>
