<template lang="pug">
  el-table#expandTable(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='group_rides'
    ref="expandTable"
    @expand-change="handleViewGroupRideRelationships"
  )
    el-table-column(
      type='expand'
    )
      template(slot-scope="props")
        el-row
          el-col(
            :span="11"
            :offset="1"
          )
            h4 {{ $t('dialog.ride_details') }}
            ul
              li(v-for="(ride, index) in group_rides_relationships.rides")
                | {{ $t('common.ride') }} {{ ride.id }}
                ul
                  li(v-if="ride && ride.already_paid_ammount") {{ $t('common.amount_to_pay') }} : {{ ride.already_paid_ammount }} {{ $t("label.ron.label")}}
                  li(v-if="ride && ride.flag !== 'no_problems'") {{ $t('common.flagged') }} :  🚩 {{ ride.flag }}
                  li(v-else) Nemarcată
                  li(v-if="ride && ride.duration_seconds") {{ $t('common.ride_duration') }} : {{ computeRideDuration(ride.duration_seconds) }}
                  li(v-if="ride && ride.start_at") {{ $t('common.start_date') }} : {{ computeDateFormat(ride.start_at) }}
                  li(v-if="ride && ride.end_at") {{ $t('common.end_date') }} : {{ computeDateFormat(ride.end_at) }}
                  .status-wrapper(v-if="ride && ride.status_object.color=='green'")
                    li {{ $t('common.status') }} : {{ ride.status_object.label }} 
                    span.oval.completed
                  .status-wrapper(v-else-if="ride && ride.status_object.color=='red'")
                    li {{ $t('common.status') }} : {{ ride.status_object.label }} 
                    span.oval.cancelled
                  .status-wrapper(v-else-if="ride && ride.status_object.color=='yellow'")
                    li {{ $t('common.status') }} : {{ ride.status_object.label }} 
                    span.oval.pending
                  .status-wrapper(v-else)
                    li {{ $t('common.status') }} : {{ ride.status_object.label }}
          el-col(
            :span="12"
          )
            h4 {{ $t('dialog.transaction_details') }}
            ul
              li(v-for="(transaction, index) in group_rides_relationships.transactions")
                | {{ $t('common.transaction') }} {{ transaction.id }} asociată cursei {{ transaction.ride.id }}
                ul
                  li(v-if="!transaction") --
                  li(v-if="transaction && transaction.amount") {{ $t('common.amount_payed') }} : {{ transaction.amount }} {{ $t("label.ron.label")}}
                  li(v-if="transaction && transaction.refunded_amount") {{ $t('common.amount_refunded') }} : {{ transaction.refunded_amount }} {{ $t("label.ron.label")}}
                  li(v-else) {{ $t('common.amount_refunded') }} : 0 {{ $t("label.ron.label")}}
                  .status-wrapper(v-if="transaction && transaction.status_object.color=='green'")
                    li {{ $t('common.status') }} : {{ transaction.status_object.label }}
                    span.oval.completed
                  .status-wrapper(v-else-if="transaction && transaction.status_object.color=='red'")
                    li {{ $t('common.status') }} : {{ transaction.status_object.label }}
                    span.oval.cancelled
                  .status-wrapper(v-else-if="transaction && transaction.status_object.color=='yellow'")
                    li {{ $t('common.status') }} : {{ transaction.status_object.label }}
                    span.oval.pending
                  .status-wrapper(v-else)
                    li {{ $t('common.status') }} : {{ transaction.status_object.label }}
    el-table-column(
      prop='amount_to_pay'
      :label="$t('common.amount_to_pay')"
    )
      template(v-slot='scope')
        span {{ scope.row.amount_to_pay + " " + $t("label.ron.label") }}
    el-table-column(
      prop='customer_full_name'
      :label="$t('common.customer_name')"
      width="400"
    )
      template(v-slot='scope')
        span.text-underline.cursor-pointer(
          v-if='scope.row.customer_full_name!="Anonymized First Name Anonymized Last Name"'
          @click="goToWIthId('customers', scope.row.customer_id)"
        ) {{ scope.row.customer_full_name }}
        span(v-else) {{ scope.row.customer_full_name }}
    el-table-column(
      prop='completed'
      :label="$t('common.completed')"
    )
      template(v-slot='scope')
        span.ml-4(
          v-if='scope.row.completed'
        ) ✅
        span.ml-4(v-else) ❌
    el-table-column(
      prop='fully_paid'
      :label="$t('common.fully_paid')"
    )
      template(v-slot='scope')
        span.ml-5(
          v-if='scope.row.fully_paid'
        ) ✅
        span.ml-5(v-else) ❌
    el-table-column(
      prop='rating'
      :label="$t('common.rating')"
    )
      template(v-slot='scope')
        span(v-if="scope.row.rating") {{ scope.row.rating }}
        span(v-else) -
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { transactionStatuses } from "@utils/statuses";
import { flaggedRidesStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "GroupRidesTransactionsViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    group_rides: {
      type: Array,
    },

    group_ride_group_rides: {
      type: Array,
    },
  },

  data() {
    return {
      group_rides_relationships: [],
      flaggedRidesStatuses,
      transactionStatuses,
      inBetween,
      last_expanded_row: null,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("transaction", ["getGroupRideInfo"]),
    ...mapMutations("dialog", ["showViewGroupridesDetailsDialog"]),

    async fetchGroupRideInfo(id) {
      this.group_rides_relationships = await this.getGroupRideInfo(id);
    },

    timedOutHandleViewGroupRideRelationships(row){
      setTimeout(this.handleViewGroupRideRelationships(row), 3000);
    },

    async handleViewGroupRideRelationships(row) {
      var element = document.getElementById("expandTable");
      if(this.last_expanded_row==null){
        await this.fetchGroupRideInfo(row.id);
        this.last_expanded_row=row;}
      else
      {
        element.classList.add("noClick");
        await this.$refs.expandTable.toggleRowExpansion(this.last_expanded_row, false);
        await this.fetchGroupRideInfo(row.id);
        this.last_expanded_row=row;
        setTimeout(element.classList.remove("noClick"), 5000);
      }
    },
  },
};
</script>

<style lang="scss">
.status-wrapper {
  display: flex;
  align-items: center;
  word-break: keep-all !important;
  
  .oval{
    margin-left: 10px;
  }
}

.true {
  background-color: lightgreen;
  color: darkgreen;
  font-weight: bold;
  border-radius: 6px;
  padding: 5px;
}
.false {
  background-color: lightcoral;
  color: darkred;
  font-weight: bold;
  border-radius: 6px;
  padding: 5px;
}

.noClick {
  pointer-events: none;
}
</style>
