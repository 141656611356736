<template lang="pug">
  .view-docking-station
    .docking-station-table
      el-row(:gutter="50" style="margin: auto; text-align:center;")
        el-tabs.mt-4(
          v-model='activeName'
        )
          el-tab-pane(
            :label="$t('docking_stations.tab.bays_details')"
            name='bays_details'
          )
          el-tab-pane(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['statistics'])"
            :label="$t('docking_stations.tab.statistics')"
            name='statistics'
          )
          el-tab-pane(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['status_history'])"
            :label="$t('docking_stations.tab.status_history')"
            name='status_history'
          )
          el-tab-pane(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['watchdog_infos'])"
            :label="$t('docking_stations.tab.watchdog_infos')"
            name='watchdog_infos'
          )
      BaysDetails(
        v-if="activeName === 'bays_details'" 
        :activeName="activeName"
        :bays="dockingStation.bays"
        :loading="loading")
      RidesStatistics(
        v-if="activeName === 'statistics' && showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['statistics'])" 
        :activeName="activeName"
        :loading="loading")
      StatusHistory(
        v-if="activeName === 'status_history' && showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['status_history'])" 
        :activeName="activeName"
        :loading="loading")
      WatchdogsDetails(
        v-if="activeName === 'watchdog_infos' && showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['watchdog_infos'])"
        :activeName="activeName"
        :watchdog_messages="watchdog_messages"
        :loading="loading")
</template>

<script>
import BaysDetails from "@components/docking_stations/viewDockingStation/BaysDetails";
import RidesStatistics from "@components/docking_stations/viewDockingStation/RidesStatistics";
import StatusHistory from "@components/docking_stations/viewDockingStation/StatusHistory";
import WatchdogsDetails from "@components/docking_stations/viewDockingStation/WatchdogsDetails";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapActions, mapState } from "vuex";

export default {
  name: "ViewDockingStation",
  mixins: [general],
  components: { 
    BaysDetails,
    RidesStatistics,
    StatusHistory,
    WatchdogsDetails,
    },

  data() {
    return {
      activeName: "bays_details",
      bays: [],
      watchdog_messages: [],
      loading: false,

      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('dockingStation', ['dockingStation']),
  },

  async created() {
    this.loading = true;
    this.watchdog_messages = await this.getWatchdogMessages(this.dockingStation.id);
    this.loading = false;
  },

  methods: {
    ...mapActions('dockingStation', ['getDock', 'getWatchdogMessages']),
  }
};
</script>

