<template lang="pug">
  .role-permissions-list
    .permissions.pl-4.pr-4(v-loading="loading")
      .permissions-list
        .resource-name.d-flex(
          v-for="(permissions, index) in allPermissionsGrouped"
          :key="index"
        )
          p.font-weight-bolder.mb-2 {{ index }}
          el-checkbox.mb-2(
            :disabled='isDisabled'
            :indeterminate="isIndeterminate[index]"
            v-model="checkAll[index]"
            @input="handleCheckAllChange($event, permissions, index)"
          ) {{ $t('users.check_all') }}
          el-checkbox-group(
            :disabled='isDisabled'
            v-model="checkList"
            @input="handleCheckAllIsIndeterminate($event, permissions, index)"
          )
            el-checkbox.mb-2(
              v-for="permission in permissions"
              :label="permission.id"
              :name="permission.name"
              :class="`collapse-${collapse}`"
              :key="permission.id"
            )
              span {{ permission.name }}
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "RolePermissionsList",

  props: {
    isEdit: {
      type: Boolean,
      required: false,
    },

    newRoleSelect: {
      type: Boolean,
      required: false
    },

    role: {
      type: Object,
      required: true,
    },

    collapse: {
      type: Number,
      required: false,
    },

    value: {
      type: Array,
      default: [],
    },

    isDisabled: {
      type: Boolean,
      required: false
    },
  },

  data() {
    return {
      isIndeterminate: {},
      checkAll: {},
      allPermissionsGrouped: [],
      loading: false
    };
  },

  watch: {
    checkList() {
      this.handleCheckBoxesColors()
    },

    role() {
      this.computeCheckAllBtnFillState()
    }
  },

  computed: {
    ...mapGetters("user", ["getCollapsesData"]),

    checkList: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  async created() {
    this.loading = true

    // generates the checkboxes and groups them by resource_name
    await this.handleGetAllPermissionsGrouped();

    // fill the checkboxes based on the roles selected
    await this.fillChecks();

    // compute the state check/unchecked for the 'checkAll' btn in the checklist group
    this.computeCheckAllBtnFillState()

    // compute the checkboxes colors (blue, green or cut)
    this.handleCheckBoxesColors()
    this.loading = false
  },

  methods: {
    ...mapActions("role", ["getAllPermissions"]),
    ...mapMutations("user", ["setAllPermissionsGrouped"]),

    handleCheckAllChange(val, permissions, index) {
      if (val) {
        this.removeAllChecklistsFromGroup(permissions)
        this.checkList.push(...permissions.map((perm) => perm.id));
      } else {
        this.removeAllChecklistsFromGroup(permissions)
      }
      this.isIndeterminate[index] = false;
    },

    // remove all the checklist associated with the 'checkAll' btn
    removeAllChecklistsFromGroup(permissions) {
      for (let j = 0; j < permissions.length; j++) {
        for (let i = 0; i < this.checkList.length; i++) {
          if (this.checkList[i] === permissions[j].id) {
            this.checkList.splice(i, 1);
          }
        }
      }
    },

    // compute 'checkAll' isIndeterminate state btn on checklist change
    handleCheckAllIsIndeterminate(value, permissions, index) {
      let checkedCount = _.intersection(
          value,
          permissions.map((perm) => perm.id)
      ).length;
      this.checkAll[index] = checkedCount === permissions.length;
      this.isIndeterminate[index] =
          checkedCount > 0 && checkedCount < permissions.length;
    },

    fillChecks() {
      // don't run this in edit mode
      if (!this.isEdit) {
        this.checkList = this.role.permissions.map(perm => perm.id)
      }

      if (this.newRoleSelect) {
        this.checkList = this.role.permissions.map(perm => perm.id)
      }
    },

    handleCheckBoxesColors() {
      // clean the checkboxes
      let allCheckboxesGreen = document.querySelectorAll(
          `.collapse-${this.collapse} .green-check`
      );
      let allCheckboxesCut = document.querySelectorAll(
          `.collapse-${this.collapse} .text-cut`
      );

      allCheckboxesGreen.forEach((checkbox) => {
        checkbox.classList.remove("green-check");
      });

      allCheckboxesCut.forEach((checkbox) => {
        checkbox.classList.remove("text-cut");
      });

      // handle checkboxes edge cases: green check color and text cut
      this.$nextTick(() => {
        let foundCollapseDataIndex = this.getCollapsesData.findIndex(
            (data) => data.collapse === this.collapse
        );
        let foundCollapseData = this.getCollapsesData[foundCollapseDataIndex];

        if (foundCollapseData) {
          foundCollapseData.permissions.forEach((user_cost) => {
            let domCheck = document.querySelector(
                `.collapse-${this.collapse} .is-checked input[value='${Number(
                    user_cost.permission_id
                )}']`
            );
            let domUnchecked = document.querySelector(
                `.collapse-${
                    this.collapse
                } .el-checkbox__input input[value='${Number(
                    user_cost.permission_id
                )}']`
            );

            if (domCheck) {
              let parentElement = domCheck.parentElement;
              let check = parentElement.querySelector(".el-checkbox__inner");

              if (user_cost.grant_type === "authorize") {
                check.classList.add("green-check");
                check.classList.add(`collapse-${this.collapse}`);
              }
            }

            if (domUnchecked) {
              let parentElement = domUnchecked.parentElement;
              let spanText = parentElement.parentElement.querySelector(
                  ".el-checkbox__label"
              );

              if (user_cost.grant_type === "restrict") {
                spanText.classList.add("text-cut");
                spanText.classList.add(`collapse-${this.collapse}`);
              }
            }
          });
        }
      });
    },

    async handleGetAllPermissionsGrouped() {
      const response = await this.getAllPermissions();
      this.allPermissionsGrouped = _.groupBy(response, "resource_name");
      this.setAllPermissionsGrouped(this.allPermissionsGrouped)
    },

    computeCheckAllBtnFillState() {
      for (let permGroup in this.allPermissionsGrouped) {
        let allPermissionsGroupedIds = this.allPermissionsGrouped[
            permGroup
            ].map((perm) => perm.id);

        allPermissionsGroupedIds.forEach((perm) => {
          // reset the checkAll btn first
          this.checkAll[permGroup] = false

          // then handle check or uncheck
          this.checkAll[permGroup] = this.checkList.includes(perm);

          // then check if is indeterminate
          this.handleCheckAllIsIndeterminate(
              this.checkList,
              this.allPermissionsGrouped[permGroup],
              permGroup
          );
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.permissions-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)) !important;
  grid-row-gap: 20px;
}

.resource-name {
  flex-direction: column;
}

.el-checkbox-group {
  display: flex;
  flex-direction: column;
}
</style>
