<template lang="pug">
  .cost-centers-filter(v-if="computeRoleNameFilter")
    span.filter-title {{ $t('common.role_filter') }}
    el-select(
      v-model='selectedRoleName'
      filterable
      :placeholder="$t('placeholder.select_role')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='roleName in roleNames'
        :key='roleName.id'
        :label='roleName.name'
        :value="roleName.value !== '' ? roleName.name : roleName.value"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterRoleDropdown",

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedRoleName: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeRoleNameFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  data() {
    return {
      roleNames: [],
    };
  },

  async mounted() {
    if (this.computeRoleNameFilter) {
      await this.handleGetRoles()
    }
  },

  methods: {
    ...mapActions("role", ["getRoles"]),

    async handleGetRoles() {
      const roleNames = await this.getRoles("?page_size=1000");
      this.roleNames = roleNames;
      this.roleNames.unshift({
        id: 0,
        name: "All",
        value: "",
      });
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
