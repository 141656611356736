<template lang="pug">
  el-menu-item(
    index='/docking_stations_list'
    v-if=`
          showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['index']) &&
          !showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['show_statistics'])
      `
  )
    .img-wrapper
      img(src="@src/assets/sidebar/stations.svg")
    router-link.sub-menu-link(to="/docking_stations_list")
      span.link-custom  {{ $t('sidebar.docking_stations_list') }}
</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";


export default {
  name: "DockingStationsListItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  }
}
</script>
