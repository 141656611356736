<template lang="pug">
  .user-view
    h3 {{ $t('users.view_user') }}
    el-breadcrumb.mt-3.last-breadcrumb-bold(separator="/")
      el-breadcrumb-item(:to="{ path: '/users' }") {{ $t('users.users') }}
      el-breadcrumb-item {{ $t('users.view_user') }}
    .view-user-wrapper.pt-4(v-loading="loading")
      .user-details
        el-row.pl-4.pr-4.pb-4
          el-col(:span="16")
            h3.mb-2 {{ upperCaseFirstLetters(loadedUser.first_name + " " + loadedUser.last_name) }}
            el-row
              el-col(:span="6")
                h5.font-weight-bold.mb-2 {{ $t('common.name') }}
                h5.font-weight-normal {{ upperCaseFirstLetters(loadedUser.first_name + " " + loadedUser.last_name) }}
              el-col(:span="8")
                h5.font-weight-bold.mb-2 {{ $t('common.email_address') }}
                h5.font-weight-normal {{ loadedUser.email }}
              el-col(:span="6")
                h5.font-weight-bold.mb-2 {{ $t('common.phone_number') }}
                h5.font-weight-normal {{ loadedUser.phone_number }}
          el-col.d-flex.justify-content-flex-end(:span="8")
            el-button.dark-blue-btn.pop-up-btn.mr-2(
              v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['update']) && hideIcon()"
              @click="goToWIthId('users/edit_user', loadedUser.id)"
            ) {{ $t('common.edit') }}
            el-button.mb-4.dark-blue-btn.pop-up-btn.delete-btn(
              v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['destroy'])"
              @click="handleDeleteUserDialog(loadedUser)"
            ) {{ $t('common.remove') }}
      .hr-custom
      .custom-accordion
        el-row.pl-4.pr-4
          el-col(:span="24")
            el-collapse(
              v-model="activeNames"
              accordion
            )
              el-collapse-item(
                v-for="(costCenter, index) in groupedCostCenters"
                :title="costCenter.cost_center_id"
                :name="index"
                :key="index"
              )
                template(slot="title")
                  h3 {{ $t('cost_centers.roles_and_permissions') + `: ${costCenter.name} (${costCenter.role_names})`}}
                .permissions(v-for="permission in costCenter.permissions")
                  h5 {{ permission.name }}
                  .permissions-list.pt-2
                    p.mb-1(v-for="perm in permission.permissions")
                      span.check-icon.mr-2(v-if="['Default Permissions', 'Custom Permissions'].includes(permission.name)") &#10004;
                      span.x-icon.mr-2(v-else) &#10006;
                      span {{ perm.name }}
</template>

<script>
import { roles } from "@utils/roles";
import { mapActions, mapMutations, mapState } from "vuex";
import { upperCaseFirstLetters } from "@utils/generalUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "UserView",
  mixins: [routerUtils, general],

  data() {
    return {
      activeNames: [0],
      loadedUser: {},
      groupedCostCenters: [],
      loading: false,
      upperCaseFirstLetters,
      dynamicViewsConstants,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "allowDeletion",
      "dataToDelete",
      "passwordConfirmation",
    ]),
  },

  async created() {
    this.loading = true;
    await this.handleGetUser();
    this.mapCostCenters();
    this.loading = false;
  },

  watch: {
    allowDeletion() {
      this.handleDeleteUser();
    },
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapActions("user", ["getUser", "deleteUser"]),

    hideIcon(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },

    async handleGetUser() {
      this.loadedUser = await this.getUser(this.$route.params.id);
    },

    mapCostCenters() {
      let groupedCostCenters = this.handleGroupCostCenters();

      // handle update groupedCostCenters with authorized(custom) permissions
      this.handleUpdateWithAuthorizedPermissions(groupedCostCenters); // put CHECK icon to restricted fields

      // handle update groupedCostCenters with restricted permissions
      this.handleUpdateWithRestrictedPermissions(groupedCostCenters); // put X icon to restricted fields

      this.groupedCostCenters = groupedCostCenters;
    },

    handleGroupCostCenters() {
      let groupedCostCenters = [];

      // handle group cost centers
      this.loadedUser.user_role_cost_centers.forEach((userRoleCostCenter) => {
        // map the cost centers with the permissions only if a users has a cost center assigned
        if (!userRoleCostCenter.cost_center) {
          return;
        }

        if (
          !groupedCostCenters.some(
            (el) => el.cost_center_id === userRoleCostCenter.cost_center.id
          )
        ) {
          groupedCostCenters.push({
            cost_center_id: userRoleCostCenter.cost_center.id,
            name: userRoleCostCenter.cost_center.name,
            permissions: [
              {
                name: "Default Permissions",
                permissions: [...userRoleCostCenter.permissions],
              },
              {
                name: "Custom Permissions",
                permissions: [],
              },
              {
                name: "Restricted Permissions",
                permissions: [],
              },
            ],
            role_ids: [userRoleCostCenter.role.id],
            role_names: [upperCaseFirstLetters(userRoleCostCenter.role.name)],
          });
        } else {
          let foundGroupedUserCostCenter = groupedCostCenters.find(
            (el) => el.cost_center_id === userRoleCostCenter.cost_center.id
          );
          foundGroupedUserCostCenter.permissions[0].permissions.push(
            ...userRoleCostCenter.permissions
          ); // push to default permissions
          foundGroupedUserCostCenter.role_ids.push(userRoleCostCenter.role.id);
          foundGroupedUserCostCenter.role_names.push(
            upperCaseFirstLetters(userRoleCostCenter.role.name)
          );
        }
      });

      return groupedCostCenters;
    },

    handleUpdateWithAuthorizedPermissions(groupedCostCenters) {
      this.loadedUser.authorize_user_cost_center_permissions.forEach(
        (authorizedPermission) => {
          let indexFoundCostCenter = groupedCostCenters.findIndex(
            (costCenter) =>
              costCenter.cost_center_id === authorizedPermission.cost_center.id
          );

          if (indexFoundCostCenter >= 0) {
            groupedCostCenters[
              indexFoundCostCenter
            ].permissions[1].permissions.push(authorizedPermission.permission); // groupedCostCenters authorized permissions array
          }
        }
      );
    },

    handleUpdateWithRestrictedPermissions(groupedCostCenters) {
      this.loadedUser.restrict_user_cost_center_permissions.forEach(
        (restrictedPermission) => {
          let indexFoundCostCenter = groupedCostCenters.findIndex(
            (costCenter) =>
              costCenter.cost_center_id === restrictedPermission.cost_center.id
          );

          if (indexFoundCostCenter >= 0) {
            groupedCostCenters[
              indexFoundCostCenter
            ].permissions[2].permissions.push(restrictedPermission.permission); // groupedCostCenters restricted permissions array
          }
        }
      );
    },

    async handleDeleteUser() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };
        this.deleteUser(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
            this.$router.push("/users");
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteUserDialog(user) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("users.validations.delete_title")} ${
          user.first_name
        } ${user.last_name}`,
        dialogMessage: this.$t("users.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(user);
    },
  },
};
</script>
