import axios from "axios";
import messageUtils from "@utils/messageUtils";
import Jsona from "jsona";
import i18n from "@i18n";
const dataFormatter = new Jsona();

export const state = {
  rentTariff: null,
  shouldFetchTariffs: false,
};

export const actions = {
  async getTariffs({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/rent_tariffs${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async addRentTariff({ commit }, payload) {
    try {
      await axios.post("/v1/rent_tariffs", {
        ...payload,
      });
      return messageUtils.showSuccess(i18n.t("rent_tariffs.tariff_added"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteRentTariff({ commit }, payload) {
    try {
      await axios.delete(`/v1/rent_tariffs/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("tariff deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setRentTariff(state, tariff) {
    state.rentTariff = tariff;
  },

  setShouldFetchTariffs(state, value) {
    state.shouldFetchTariffs = value;
  },
};
