<template lang="pug">
  .ticket-view
    h3 {{ $t('tickets.tickets')  + ' - ' + $t("dialogs.tickets.details") }}
    .wrapper.w-100.px-3.py-5
      el-row(:gutter="24")
        el-col.ticket-details.mr-5.text-black(:span="4") {{$t('common.customer_name')}}
      el-row.mt-2(:gutter="24")
        el-col.text-underline.cursor-pointer(
          :span="4"
          @click="goToWIthId('customers', ticket.data.user.id)"
          ) {{ ticket.data.user.first_name + " " + ticket.data.user.last_name }}
      div.d-flex.mt-5.w-100
        div.w-25
          el-row.mt-2(:gutter="24")
            el-col.mr-5.text-black(:span="4") {{ $t('dialogs.tickets.reason') }}
          el-row.mt-2(:gutter="24")
            el-col(:span="4") {{ ticket.data.reason ? ticket.data.reason : '-' }}
        div.w-25
          el-row.mt-2(:gutter="24")
            el-col.mr-5.text-black(:span="4") {{ $t('common.status') }}
          el-row.mt-2(:gutter="24")
            el-col(:span="4")
              .status
                .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.data.status, 41, 60)")
                  span.oval.cancelled
                  span {{ ticketsStatuses[ticket.data.status].message }}
                .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.data.status, 1, 20)")
                  span.oval.pending
                  span {{ ticketsStatuses[ticket.data.status].message }}
                .status-wrapper(v-if="inBetween('ticketsStatuses', ticket.data.status, 21, 40)")
                  span.oval.completed
                  span {{ ticketsStatuses[ticket.data.status].message }}
        div.w-25
          el-row.mt-2(:gutter="24")
            el-col.ticket-details.mr-5.text-black(:span="4") {{  $t('dialogs.tickets.problem_type')  }}
          el-row.mt-2(:gutter="24")
            el-col(:span="4") {{  ticket.data.type ? ticket.data.type  : '-' }}
        div.w-25
          el-row.mt-2(:gutter="24")
            el-col.ticket-details.mr-5.text-black(:span="4") {{  $t('common.time_of_report')  }}
          el-row.mt-2(:gutter="24")
            el-col(:span="4") {{  computeDateFormat(ticket.data.created_at) ? computeDateFormat(ticket.data.created_at) : '-'  }}
      div.w-100.mt-5
        el-row.mt-2(:gutter="24")
          el-col.ticket-details.mr-5.text-black(:span="4") {{ $t('label.description') }}
        el-row.mt-2(:gutter="24")
          el-col(:span="16") {{ ticket.data.description ? ticket.data.description : '-' }}
      div.d-flex.w-100.mt-5
        div.w-50
          el-row.mt-2(:gutter="24")
            el-col.ticket-details.mr-5.text-black(:span="4") {{  $t('dialogs.tickets.ride') }}
          el-row.mt-2(:gutter="24")
            el-col(:span="4") {{ ticket.data.ride ? ticket.data.ride : '-' }}
        div.w-50
          el-row.mt-2(:gutter="24")
            el-col.ticket-details.mr-5.text-black(:span="4") {{  $t('dialogs.tickets.cost_center') }}
          el-row.mt-2(:gutter="24")
            el-col(:span="4") {{  ticket.data.cost_center.name ? ticket.data.cost_center.name + ' / ' +ticket.data.cost_center.company : '-' }}
      div.w-100.mt-5
        el-row.mt-2(:gutter="24")
          el-col.ticket-details.mr-5.text-black(:span="4") {{  $t('dialogs.tickets.images') }}
        el-row.mt-2(:gutter="24")
          el-col(:span="4")
            img.w-50(:src="imageUrl")
</template>

<script>
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapState } from "vuex";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { ticketsStatuses } from "@utils/statuses";

export default {
  name: "TicketView",
  mixins: [routerUtils, general, filterUtils],
  data() {
    return {
      ticket: null,
      ticketsStatuses,
      inBetween
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    imageUrl() {
      let response = this.ticket.data.images.length ? this.ticket.data.images : this.$t('dialogs.tickets.images_not_provided');
      return response[0];
    }
  },

  methods: {
    ...mapActions("ticket", ["getTicket"])
  },

  async created() {
    this.ticket = await this.getTicket(this.$route.path.split('/')[3]);
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: white;
  border-radius: 10px;
}
.status-wrapper {
  display: flex;
  align-items: center;
}

.ticket-details > * {
  margin: 10px;
}

.ticket-data > * {
  margin: 10px;
}
</style>
