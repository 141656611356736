<template lang="pug">
  .docking-station-map-wrapper
    GmapMap.custom-map(
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      map-type-id="roadmap"
      style="width: 400px; height: 300px"
    )
</template>

<script>
import customMarkerAvailable from "@assets/dockingStationsMap/available-location.svg";
import customMarkerAvailableOpacity from "@assets/dockingStationsMap/available-location-opacity.svg";
import customMarkerUnavailable from "@assets/dockingStationsMap/unavailable-location.svg";
import customMarkerUnavailableOpacity from "@assets/dockingStationsMap/unavailable-location-opacity.svg";
import { gmapApi } from "vue2-google-maps";
import { dockingStationsStatuses } from "@utils/statuses";
import { inBetween } from "@utils/filterUtils";
import bike from '@assets/dockingStationsMap/bike.svg'
import { mapState } from "vuex";
import { checkIfNotSuperAndAdministrator } from "@utils/roles";
import { getRomaniaCoordsIfLocationIsOff } from "@utils/map";

export default {
  props: {
    docks: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      markers: [],
      zoom: 10,
      center: {
        lat: 0,
        lng: 0,
      },

      dockingStationsStatuses,
      inBetween,
    };
  },

  watch: {
    google() {
      this.addMapMarkers();
    },
  },

  computed: {
    google: gmapApi,

    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.getBrowserLocation();
  },

  mounted() {
    this.addMapMarkers();
  },

  methods: {
    addMapMarkers() {
      if (this.docks.length === 0) {
        return;
      }
      
      this.$refs.mapRef.$mapPromise.then((map) => {
        this.docks.forEach((docke) => {
          // marker - pop up
          var dock = docke.attributes
          const contentString =
              '<div id="content" class="info-box">' +
              '<div id="siteNotice">' +
              "</div>" +
              `<h4 id="firstHeading" class="text-center border-bottom font-weight-normal mt-2 mb-2 pl-4 pr-4">${dock.name}</h4>` +
              '<div class="hr-custom"></div>' +
              '<div id="bodyContent" class="pl-3 pr-3 pb-1 text-center">' +
              `<h4 class="pt-2 pb-2"><b>${dock.address}</b></h4>
                 <p class="margin-0 mb-2">${dock.cost_center_name}</p>
                 ${this.renderInfoBoxStatus(dock)}
                 ${this.renderInfoBoxBikesStatus(dock)}
                ` +
              "</div>" +
              "</div>";

          const infoWindow = new this.google.maps.InfoWindow({
            content: contentString,
          });

          // marker
          const markerIcon = dock.status === 'working' ? customMarkerAvailable : customMarkerUnavailable
          const markerIconOpacity = dock.status === 'working' ? customMarkerAvailableOpacity : customMarkerUnavailableOpacity

          let marker = null

          // only not for super & admin
          if (
              checkIfNotSuperAndAdministrator() &&
              dock.cost_center_id !== Number(this.user.selected_cost_center.id)
          ) {
            marker = new this.google.maps.Marker({
              position: {
                lat: Number(dock.address_lat),
                lng: Number(dock.address_long),
              },
              map: map,
              icon: markerIconOpacity,
              draggable: false,
            });
          } else {
            marker = new this.google.maps.Marker({
              position: {
                lat: Number(dock.address_lat),
                lng: Number(dock.address_long),
              },
              map: map,
              icon: markerIcon,
              draggable: false,
            });

            marker.addListener("click", () => {
              infoWindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
              });
            });
          }
        });
      });
    },

    renderInfoBoxStatus(dock) {
      if (inBetween("docksStatuses", dock.status, 21, 40)) {
        return `
                 <span class="d-flex align-items-center justify-content-center dock-status">
                  <span class="oval completed"></span>
                  <span>${dockingStationsStatuses[dock.status].message}</span>
                </span>
              `;
      }

      if (inBetween("docksStatuses", dock.status, 41, 60)) {
        return `
                 <span class="d-flex align-items-center justify-content-center dock-status">
                  <span class="oval dock-icon unavailable"></span>
                  <span>${dockingStationsStatuses[dock.status].message}</span>
                </span>
              `;
      }

      if (inBetween("docksStatuses", dock.status, 1, 20)) {
        return `
                 <span class="d-flex align-items-center justify-content-center dock-status">
                  <span class="oval dock-icon pending"></span>
                  <span>${dockingStationsStatuses[dock.status].message}</span>
                </span>
              `;
      }
    },

    renderInfoBoxBikesStatus(dock) {
      return `
        <span class="d-flex justify-content-center dock-av-bikes mt-2 pb-2">
         <span class="dock-icon d-flex align-items-center">
           <img src="${bike}" alt="bike">
         <span class="ml-2">${this.handleBikesText(dock.no_of_available_bikes)} / ${this.handleBaysText(dock.no_of_available_bays)}</span>
      `
    },

    handleBikesText(no_of_available_bikes){
      if(no_of_available_bikes == 1){
        return `${this.$t('docking_stations_map.map.bike_available')}`
      }
      return `${no_of_available_bikes} ${this.$t('docking_stations_map.map.bikes_available')}`
    },

    handleBaysText(no_of_available_bays){
      if(no_of_available_bays == 1){
        return `${this.$t('docking_stations_map.map.bay_available')}`
      }
      return `${no_of_available_bays} ${this.$t('docking_stations_map.map.bays_available')}`
    },

    async getBrowserLocation() {
      // if   - user is not super admin or admin, center the map to one dock belonging to the same cost center
      // else - point the admin and super admin to the browser location
      if (checkIfNotSuperAndAdministrator() && this.docks.length > 0) {
        this.center.lat = Number(this.docks[0].attributes.address_lat);
        this.center.lng = Number(this.docks[0].attributes.address_long);
      } else {
        navigator.geolocation.getCurrentPosition((location) => {
          this.center.lat = location.coords.latitude;
          this.center.lng = location.coords.longitude;
        });

        // if location is not working or turned off
        const romaniaCoords = await getRomaniaCoordsIfLocationIsOff()
        if (romaniaCoords) {
          this.center.lat = romaniaCoords.coords.latitude
          this.center.lng = romaniaCoords.coords.longitude
          this.zoom = 7
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.customMarker {
  opacity: 0.4;
  pointer-events: none;
}

.custom-map {
  width: 100% !important;
  height: 70vh !important;
}

::v-deep .dock-icon {
  img {
    height: 14px;
  }
}

// override gmap info box
::v-deep .gm-style .gm-style-iw-c { // TODO: handle info box styling on addresses on locations the map
  padding: 0;
  max-width: 330px !important;
}

::v-deep .gm-style-iw-d {
  overflow: unset !important;
}

// info box close btn
::v-deep .gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;

  img {
    background: #adb5bd !important;
    border-radius: 2px;
  }
}

::v-deep #bodyContent {
  p {
    margin: 0;
  }
}
</style>
