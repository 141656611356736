<template lang="pug">
  .view-docking-station
    .docking-station-table
      el-row(:gutter="50" style="margin: auto; text-align:center;")
        el-tabs.mt-4(
          v-model='activeName'
        )
          el-tab-pane(
            :label="$t('cost_centers.tab.customers_communications')"
            name='customers_communications'
          )
      ViewCustomersCommunications(
        v-if="activeName === 'customers_communications'" 
        :activeName="activeName"
        :loading="loading")
</template>

<script>
import ViewCustomersCommunications from "@components/cost_centers/statistics/ViewCustomersCommunications";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "ViewCostCentersStatistics",
  components: { 
    ViewCustomersCommunications,
    },

  data() {
    return {
      activeName: "customers_communications",
      bays: [],
      watchdog_modules: [],
      watchdog_messages: [],
      loading: false,
      seeQrCode: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('dockingStation', ['dockingStation']),
  },

  methods: {
    ...mapActions('dockingStation', ['getDock', 'getWatchdogMessages']),

  }
};
</script>
