<template lang="pug">
  .pagination
    el-pagination.mt-3.mb-3.pages-custom-pagination(
      background
      layout="sizes, prev, pager, next"
      :current-page.sync="currentPage"
      :total="totalCount"
      :page-sizes="[10, 50, 100]"
      :page-size="pageSize"
      v-if="totalCount >= 1"
      @current-change="handleGetData"
      @size-change="handleChangePageSize"
    )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";

export default {
  props: {
    view: {
      type: String,
      required: true,
    },

    activeName: {
      type: String,
      required: false,
      default: null,
    },

    search: {
      type: String,
      required: false,
    },

    reload: {
      type: Boolean,
      required: false,
    },

    dateRange: {
      type: Array,
      required: false,
    },

    dateTimeRange: {
      type: Array,
      required: false,
    },

    roleName: {
      type: String,
      required: false,
    },

    costCenterId: {
      type: String,
      required: false,
    },

    trackerType: {
      type: String,
      required: false,
    },

    dockingStationId: {
      type: String,
      required: false,
    },

    ticketTypeId: {
      type: String,
      required: false,
    },

    statusType: {
      type: String,
      required: false,
    },

    categoryType:{
      type: String,
      required: false,
    },

    getAnonymized: {
      type: Boolean,
      required: false,
    },

    getRestricted: {
      type: Boolean,
      required: false,
    },

    getNonConfirmed: {
      type: Boolean,
      required: false,
    },

    getStationIsDifferentFromStartToEnd: {
      type: Boolean,
      required: false,
    },

    orderBy: {
      type: Boolean,
      required: false,
    },

    orderByCategory: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapState("passwordValidationDelete", ["loading", "allowDeletion"]),
    ...mapState("passwordValidationUpdate", ["loading", "allowUpdate"]),
    ...mapState("bike", ["shouldFetchBikes"]),
    ...mapState("costCenter", ["shouldFetchCostCenters", "shouldFetchCostCenterSettings", "costCenter"]),
    ...mapState("rentTariff", ["shouldFetchTariffs"]),
    ...mapState("dockingStation", ["shouldFetchDocks", "shouldFetchStationLog", "shouldFetchDockSettings", "dockingStation"]),
    ...mapState("ticket", ["shouldFetchTickets"]),
    ...mapState("smartCard", ["shouldFetchSmartCards"]),
    ...mapState("tracker", ["shouldFetchTrackers"]),
    ...mapState("customer", ["shouldFetchCustomers", "customer", "shouldFetchCustomerTickets"]),
    ...mapState("log", ["shouldFetchLogs"]),
    ...mapState("configSettings", ["shouldFetchConfSettings"]),
    ...mapState("configTicketsSettings", ["shouldFetchTicketsSettings"]),
    ...mapState("transaction", ["shouldFetchTransactions"]),
    ...mapState("firmware", ["shouldFetchFirmwares"])
  },

  watch: {
    activeName() {
      this.currentPage = 1;
      this.handleGetData();
    },

    search() {
      this.searchDebounce();
    },

    reload() {
      if(this.reload) {
        this.handleGetData();
      }
    },

    roleName() {
      this.handleGetData();
    },

    costCenterId() {
      this.handleGetData();
    },

    trackerType() {
      this.handleGetData();
    },

    dockingStationId() {
      this.handleGetData();
    },

    ticketTypeId() {
      this.handleGetData();
    },

    statusType() {
      this.handleGetData()
    },

    categoryType() {
      this.handleGetData()
    },

    getAnonymized() {
      this.handleGetData()
    },

    getRestricted() {
      this.handleGetData()
    },

    getNonConfirmed() {
      this.handleGetData()
    },

    getStationIsDifferentFromStartToEnd() {
      this.handleGetData()
    },

    orderBy() {
      this.handleGetData();
    },

    orderByCategory() {
      this.handleGetData();
    },

    handleGetDataLoading() {
      this.$emit("handleTableLoading", this.handleGetDataLoading);
    },

    loading() {
      if (this.loading === false && (this.allowDeletion === true || this.allowUpdate === true)) {
        this.handleGetData();
      }
    },

    dateRange() {
      this.handleGetData();
    },

    dateTimeRange() {
      this.handleGetData();
    },

    // TODO: refactor these in the next tech debt
    shouldFetchBikes() {
      if (this.shouldFetchBikes) {
        this.handleGetData();
        this.setShouldFetchBikes(false);
      }
    },

    shouldFetchCostCenters() {
      if (this.shouldFetchCostCenters) {
        this.handleGetData();
        this.setShouldFetchCostCenters(false);
      }
    },

    shouldFetchTariffs() {
      if (this.shouldFetchTariffs) {
        this.handleGetData();
        this.setShouldFetchTariffs(false);
      }
    },

    shouldFetchStationLog() {
      if (this.shouldFetchStationLog) {
        this.handleGetData();
        this.setShouldFetchStationLog(false);
      }
    },

    shouldFetchDocks() {
      if (this.shouldFetchDocks) {
        this.handleGetData();
        this.setShouldFetchDocks(false);
      }
    },

    shouldFetchTickets() {
      if (this.shouldFetchTickets) {
        this.handleGetData();
        this.setShouldFetchTickets(false);
      }
    },

    shouldFetchSmartCards(){
       if (this.shouldFetchSmartCards) {
        this.handleGetData();
        this.setShouldFetchSmartCards(false);
      }
      
    },

    shouldFetchTrackers() {
      if (this.shouldFetchTrackers) {
        this.handleGetData();
        this.setShouldFetchTrackers(false);
      }
    },

    shouldFetchCustomers() {
      if (this.shouldFetchCustomers) {
        this.handleGetData();
        this.setShouldFetchCustomers(false);
      }
    },

    shouldFetchCustomerTickets() {
      if (this.shouldFetchCustomerTickets) {
        this.handleGetData();
        this.setShouldFetchCustomerTickets(false);
      }
    },

    shouldFetchLogs() {
      if (this.shouldFetchLogs) {
        this.handleGetData();
        this.setShouldFetchLogs(false);
      }
    },

    shouldFetchTokens() {
      if (this.shouldFetchTokens) {
        this.handleGetData();
        this.setShouldFetchTokens(false);
      }
    },

    shouldFetchCostCenterSettings(){
      if (this.shouldFetchCostCenterSettings) {
        this.handleGetData();
        this.setShouldFetchCostCenterSettings(false);
      }
    },

    shouldFetchDockSettings(){
      if(this.shouldFetchDockSettings){
        this.handleGetData();
        this.setShouldFetchDockSettings(false);
      }
    },

    shouldFetchConfSettings(){
      if(this.shouldFetchConfSettings){
        this.handleGetData();
        this.setShouldFetchConfSettings(false);
      }
    },

    shouldFetchTicketsSettings(){
      if(this.shouldFetchTicketsSettings){
        this.handleGetData();
        this.setShouldFetchTicketsSettings(false);
      }
    },

    shouldFetchTransactions(){
      if(this.shouldFetchTransactions){
        this.handleGetData();
        this.setShouldFetchTransactions(false);
      }
    },

    shouldFetchFirmwares() {
      if(this.shouldFetchFirmwares) {
        this.handleGetData();
        this.setShouldFetchFirmwares(false);
      }
    },
    view(){
      this.handleGetData();
    }
  },

  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      handleGetDataLoading: false,
      filteredBikes: [],
      bikes: [],
    };
  },

  mounted() {
    this.pageSize = Number(localStorage.getItem('pageSize')) || 10
    this.handleGetData();
  },

  methods: {
    ...mapMutations("bike", ["setShouldFetchBikes"]),
    ...mapMutations("costCenter", ["setShouldFetchCostCenters"]),
    ...mapMutations("rentTariff", ["setShouldFetchTariffs"]),
    ...mapMutations("dockingStation", ["setShouldFetchDocks", "setShouldFetchStationLog", "setShouldFetchDockSettings"]),
    ...mapMutations("ticket", ["setShouldFetchTickets"]),
    ...mapMutations("smartCard", ["setShouldFetchSmartCards"]),
    ...mapMutations("tracker", ["setShouldFetchTrackers"]),
    ...mapMutations('customer', ["setShouldFetchCustomers"]),
    ...mapMutations('log', ["setShouldFetchLogs"]),
    ...mapMutations("technicalDashboard", ['setShouldFetchTokens']),
    ...mapMutations('configSettings', ["setShouldFetchConfSettings"]),
    ...mapMutations('configTicketSettings', ["setShouldFetchTicketsSettings"]),
    ...mapMutations("transaction", ['setShouldFetchTransactions']),
    ...mapMutations("firmware", ["setShouldFetchFirmwares"]),

    ...mapActions("costCenter", ["getCostCenters", "getCostCenterSettings"]),
    ...mapActions("user", ["getUsers"]),
    ...mapActions("bike", ["getBikes", "getLightBikes","getBikesLocations", "getBikesInventory"]),
    ...mapActions("tracker", ["getTrackersLocations", "getTrackersUnassignedToBikes"]),
    ...mapActions("rentTariff", ["getTariffs"]),
    ...mapActions("dockingStation", ["getDocks", "getDockingStationStatusHistory","getDockingStationSettings","getStationLogs"]),
    ...mapActions("ride", ["getRides", "getAllRides"]),
    ...mapActions("customer", ["getCustomers", "getCustomerRideHistory", "getCustomerTickets"]),
    ...mapActions("ticket", ['getTickets']),
    ...mapActions('smartCard', ['getSmartCards']),
    ...mapActions("tracker", ['getTrackers']),
    ...mapActions("log", ['getLogs']),
    ...mapActions("technicalDashboard", ['getTokens']),
    ...mapActions("transaction", ['getTransactions', 'getGroupRides']),
    ...mapActions("configSettings", ['getAllConfigSettings']),
    ...mapActions("configTicketsSettings", ['getAllConfigTicketSettings']),
    ...mapActions("firmware", ["getFirmwares"]),

    computedParams() {
      let computedParams = `?page=${this.currentPage}`;

      // if a tab is selected
      if (this.activeName && this.activeName !== "") {
        if (this.view === "users") {
          computedParams += `&by_role_name=${this.activeName}`;
        }

        if (this.view === "admin_settings" || this.view === "cost_center_settings" || this.view === "dock_station_settings") {
          if (this.activeName !== "all") {
            computedParams += `&by_category=${this.activeName}`;
          }
        }

        if (this.view === "rides") {
          if (this.activeName !== "all") {
            this.activeName === 'flagged' ? computedParams += `&is_flagged=true` : computedParams += `&by_status=${this.activeName}`
          }
        } else if (this.activeName !== "all") { // OTHER VIEWS: BIKES, TICKETS
          computedParams += `&by_status=${this.activeName}`;
        }
      }

      if (this.search && this.view !== "trackers") {
        computedParams += `&by_keyword=${this.search}`;
      }

      if (this.search && this.view == "trackers") {
        computedParams += `&by_array_keyword=${this.search}`;
      }

      if(this.roleName) {
        computedParams += `&by_role_name=${this.roleName}`;
      }

      if (this.costCenterId) {
        if(this.view === "trackers" && this.costCenterId == "-1") {
          computedParams += `&unasigned_to_bikes=true`;
        }
        else {
          computedParams += `&by_cost_center_id=${this.costCenterId}`;
        }
      }

      if (this.trackerType) {
        if(this.trackerType === "2") {
          computedParams += `&complete_v2=true`;
        }
        if(this.trackerType === "3") {
          computedParams += `&without_imei_v2=true`;
        }
        if(this.trackerType === "4") {
          computedParams += `&complete_v1=true`;
        }
      }

      if (this.dockingStationId) {
        computedParams += `&by_docking_station_id=${this.dockingStationId}`;
      }

      if (this.ticketTypeId && this.ticketTypeId !== 'All' && this.ticketTypeId !== 'all') {
        computedParams += `&by_ticket_type=${this.ticketTypeId}`;
      }

      // by status type - tickets
      if (this.statusType && this.statusType !== 'All' && this.statusType !== 'all') {
        computedParams += `&by_status_type=${this.statusType}`
      }

      // by cateogry type - tickets
      if (this.categoryType && this.categoryType !== 'all' && this.categoryType !== 'All') {
        computedParams += `&by_ticket_type_category=${this.categoryType}`
      }

      if(this.getAnonymized == false && this.view == "customers") {
        computedParams += `&non_anonymized=true`;
      }

      if(this.getAnonymized == true) {
        computedParams += `&anonymized=true`;
      }

      if(this.getRestricted) {
        computedParams += `&restricted=true`;
      }

      if(this.getNonConfirmed) {
        computedParams += `&non_confirmed=true`;
      }

      if (this.getStationIsDifferentFromStartToEnd == true && this.view== "rides") {
        computedParams += `&is_station_different_from_start_to_end=true`;
      }

      if (this.orderBy && this.view == "trackers") {
        computedParams += `&ordered=true`;
      }

      if(this.orderByCategory) {
        if(this.orderByCategory === "1") {
          computedParams += `&order_by=last_maintenace`;
        }
        if(this.orderByCategory === "2") {
          computedParams += `&order_by=next_maintenance_asc`;
        }
        if(this.orderByCategory === "3") {
          computedParams += `&order_by=next_maintenance_desc`;
        }
        if(this.orderByCategory === "4") {
          computedParams += `&order_by=last_ride`;
        }
      }

      // add page size
      computedParams += `&page_size=${this.pageSize}`

      if (this.dateTimeRange && this.dateTimeRange.length > 0) {
        let dateTimeRangeComputed = {
          start_time: this.dateTimeRange[0].toISOString(),
          end_time: this.dateTimeRange[1].toISOString(),
        };

        computedParams += `&by_datetime_range[start_time]=${dateTimeRangeComputed.start_time}&by_datetime_range[end_time]=${dateTimeRangeComputed.end_time}`;
      }

      if (this.dateRange && this.dateRange.length > 0) {
        let dateRangeComputed = {
          start_date: this.dateRange[0].toISOString(),
          end_date: this.dateRange[1].toISOString(),
        };

        computedParams += `&by_date_range[start_date]=${dateRangeComputed.start_date}&by_date_range[end_date]=${dateRangeComputed.end_date}`;
      }

      // ADDITIONAL CUSTOM PARAMETERS, ex: {params, id}
      // for the endpoints with more complex params
      if (this.view === 'customerRideHistory' || this.view === 'customerTickets') {
        return {
          params: computedParams,
          id: this.$route.params.id
        }
      } else {
        // for the endpoints with just params
        return { params: computedParams };
      }
    },

    async handleGetData() {
      this.handleGetDataLoading = true;
      let computedParams = this.computedParams();

      const response = await this.filteredGetActionByView(computedParams);
      this.emitDataToView(response);

      this.handleGetDataLoading = false;
    },

    async filteredGetActionByView(computedParams) {
      switch (this.view) {
        case "users":
          return await this.getUsers(computedParams.params);
        case "cost_centers":
          return await this.getCostCenters(computedParams.params);
        case "bikes":
          return await this.getLightBikes(computedParams.params);
        case "inventoryTrackers":
          return await this.getTrackersUnassignedToBikes(computedParams.params);
        case "bikesLocations":
          return await this.getBikesLocations(computedParams.params);
        case "trackersLocations":
          return await this.getTrackersLocations(computedParams.params);
        case "inventory":
          return await this.getBikesInventory(computedParams.params);
        case "tariffs":
          return await this.getTariffs(computedParams.params)
        case "station_logs":{
          return await this.getStationLogs(computedParams.params)}
        case "docksList":
          return await this.getDocks(computedParams.params)
        case "dockingStationStatusHistory":
            let statusHistoryPayload = {
            id: this.dockingStation.id,
            params: computedParams.params
          };
          return await this.getDockingStationStatusHistory(statusHistoryPayload)
        case "rides":
          return await this.getRides(computedParams.params)
        case "customers":
          return await this.getCustomers(computedParams.params)
        case "tickets":
          return await this.getTickets(computedParams.params)
        case "smart_cards":
          return await this.getSmartCards(computedParams.params)
        case "trackers":
          return await this.getTrackers(computedParams.params)
        case "logs":
          return await this.getLogs(computedParams.params)
        case "tokens":
          return await this.getTokens(computedParams.params)
        case "ticket_settings":
          return await this.getAllConfigTicketSettings(computedParams.params)
        case "group_rides":
          return await this.getGroupRides(computedParams.params)
        case "transactions":
          return await this.getTransactions(computedParams.params)
        case "cost_center_settings":
          let costPayload = {
            id: this.costCenter.id,
            params: computedParams.params
          };
          return await this.getCostCenterSettings(costPayload);
        case "dock_station_settings":
          let dockPayload = {
            id: this.dockingStation.id,
            params: computedParams.params
          };
          return await this.getDockingStationSettings(dockPayload);
        case "admin_settings":
          return await this.getAllConfigSettings(computedParams.params);

          // ADDITIONAL CUSTOM PARAMETERS, ex: {params, id}
        case "firmwares":
          return await this.getFirmwares(computedParams.params);
        case "customerRideHistory":
          return await this.getCustomerRideHistory(computedParams)
        case "customerTickets":
          return await this.getCustomerTickets(computedParams)
      }
    },

    emitDataToView(response) {
      let data = response.data;
      this.totalCount = Number(response.headers["total-count"]);

      this.$emit("viewData", data);
    },

    searchDebounce: _.debounce(function () {
      this.currentPage = 1;
      this.handleGetData();
    }, 1000),

    async handleChangePageSize(newPageSize) {
      localStorage.setItem('pageSize', newPageSize)

      this.pageSize = newPageSize;
      await this.handleGetData()
    }
  },
};
</script>
