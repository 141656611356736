<template lang="pug">
  .cost-centers-view
    h3 {{ $t('cost_centers.cost_centers_list') }}
    .cost-centers-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.COST_CENTER, ['by_keyword'])"
        )
          el-button.dark-blue-btn(
            @click="handleOpenViewCostCenterStatisticsDialog"
            v-if="hideSubmenu()"
          )
            h4 {{ $t('common.show_statistics') }}
          el-button.dark-blue-btn(
            @click="handleOpenAddCostCenterDialog"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.COST_CENTER, ['create'])"
          )
            h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          CostCentersViewTable(
            v-loading="loading"
            :cost_centers="cost_centers"
          )
    Pagination(
      :view="'cost_centers'"
      :search="search"
      @viewData="handleGetCostCenters"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import { currencyOptions } from "@utils/generalUtils";
import CostCentersViewTable from "@components/cost_centers/CostCentersViewTable";
import { mapMutations, mapState } from "vuex";
import { roles } from "@utils/roles";
import { routerUtils } from "@utils/router";
import store from "@state/store";

export default {
  mixins: [general, currencyOptions, routerUtils],
  components: { Pagination, SearchWithButtons, CostCentersViewTable },

  data() {
    return {
      search: "",
      cost_centers: [],
      loading: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("dialog", ["showAddCostCenterDialog", "showViewCostCenterStatisticsDialog"]),

    handleGetCostCenters(data) {
      this.cost_centers = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    handleOpenAddCostCenterDialog() {
      this.showAddCostCenterDialog();
    },

    handleOpenViewCostCenterStatisticsDialog() {
      this.showViewCostCenterStatisticsDialog();
    },

    hideSubmenu() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },
};
</script>
