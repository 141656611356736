export const sidebarBlacklist = ["login", "forgot_password", "reset-password"];

export const routerUtils = {
  methods: {
    async goToWIthId(location, id) {
      await this.$router.push(`/${location}/${id}`);
    },
    async goTo(location) {
      await this.$router.push(`/${location}`);
    },
  },
};
