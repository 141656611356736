<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='trackers'
    @selection-change="$emit('selectionChange', $event)"
  )
    el-table-column(
      prop='created_at'
      :label="$t('common.time_of_report')"
      width="130px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_last_location.data") {{ computeDateFormat(scope.row.tracker_last_location.data.attributes.created_at) }}
        span(v-else) -   
    el-table-column(
      prop='name'
      :label="$t('common.name')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.name") {{ scope.row.name }}
        span(v-else) -
    el-table-column(
      prop='category'
      :label="$t('common.category')"
    )
      template(slot-scope='scope')
        span {{ scope.row.category }}
    el-table-column(
      prop='name'
      :label="$t('common.name')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.name") {{ scope.row.name }}
        span(v-else) -   
    el-table-column(
      prop='mac'
      :label="$t('common.mac')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.mac") {{ scope.row.mac }}
        span(v-else) -
    el-table-column(
      prop='imei'
      :label="$t('common.imei')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.imei") {{ scope.row.imei }}
        span(v-else) -
    el-table-column(
      prop='dev_eui'
      :label="$t('common.dev_eui')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.dev_eui") {{ scope.row.dev_eui }}
        span(v-else) -
    el-table-column(
      prop='device_id'
      :label="$t('common.device_id')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.device_id") {{ scope.row.device_id }}
        span(v-else) -
    el-table-column(
      prop='tracker_last_location.data'
      :label="$t('common.tracker_last_location')"
      width="400"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_last_location.data")
          p {{$t('label.reported_at') + ": " + computeDateFormat(scope.row.tracker_last_location.data.attributes.reported_at) }}
          p {{$t('label.latitude') + ": " + scope.row.tracker_last_location.data.attributes.latitude }}
          p {{$t('label.longitude') + ": " + scope.row.tracker_last_location.data.attributes.longitude }}
        span(v-else) -      
</template>

<script>
import {
  filterUtils,
  MAX_FIELD_LENGTH,
  trimTableField
} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { joinValuesWithComma } from "@utils/generalUtils";
import { mapMutations } from "vuex";

export default {
  name: "TrackerViewTable",
  mixins: [filterUtils, general],

  props: {
    trackers: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
    };
  },

  methods: {
    trimTableField,
    joinValuesWithComma,
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),
  }
};
</script>
