<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='trackers'
    @selection-change="$emit('selectionChange', $event)"
  )
    el-table-column(
      width="55"
      align="center"
    )
      template(slot-scope='scope')
        span {{ scope.$index + 1}}
    el-table-column(
      prop='created_at'
      :label="$t('trackers.last_report')"
      width="110px"
      align="center"
    )
      template(slot-scope='scope')
        span.text-black(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.last_report") {{scope.row.tracker_latest_info.last_report.category}}
        br
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.updated_at") {{ computeTrackerDateFormat(scope.row.tracker_latest_info.updated_at) }}
        span(v-else) -  
    el-table-column(
      prop='name'
      :label="$t('trackers.serial_number')"
      width="185px"
      align="center"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer.text-black(
          v-if="scope.row.name" 
          :title="$t('common.name')" 
          @click="handleOpenViewTrackerModal(scope.row)"
          ) {{ scope.row.name }}
        span(v-else) -
        br
        span(v-if="scope.row.mac" :title="$t('common.mac')") {{ scope.row.mac }}<br> 
        span(v-if="scope.row.imei" :title="$t('common.imei')") {{ scope.row.imei }} <br>
        span(v-if="scope.row.dev_eui" :title="$t('common.dev_eui')") {{ scope.row.dev_eui }} <br>
        span(v-if="scope.row.device_id" :title="$t('common.device_id')") {{ scope.row.device_id }}
    el-table-column(
      prop='category'
      :label="$t('trackers.bike')"
      align="center"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.bike") 
          span.text-underline.cursor-pointer(
            :title="$t('trackers.bike_details.serial_number')"
            @click="handleOpenViewBikeModal(scope.row.bike)"
            ) {{ scope.row.bike.serial_number }}
          br
          span(:title="$t('trackers.bike_details.rfid')") {{ scope.row.bike.rfid }}
          br
          span(v-if="scope.row.bike && scope.row.bike.docking_station" :title="$t('trackers.bike_details.docking_station')") {{ scope.row.bike.docking_station.serial_number }}-
          span(v-else :title="$t('trackers.bike_details.docking_station')") -
          span(v-if="scope.row.bike && scope.row.bike.bay_number" :title="$t('trackers.bike_details.bay')") {{ scope.row.bike.bay_number.number}}
          span(v-else :title="$t('trackers.bike_details.bay')") -
        span(v-else) - 
    el-table-column(
      prop='imei'
      :label="$t('trackers.fw_version')"
      width="140px"
      align="center"
    )
      template(slot-scope='scope')
        span.text-black(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.about") {{ computeFWVerionDateFormat(scope.row.tracker_latest_info.about.fw_ver) }}
        span(v-else) -
    el-table-column(
      prop='category'
      :label="$t('trackers.last_power')"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.power")
          span {{$t('trackers.v_in')}}: 
          span.text-black {{ scope.row.tracker_latest_info.power.v_in /1000.0 }}V
          br
          span {{$t('trackers.v_bat')}}: 
          span.text-black {{ scope.row.tracker_latest_info.power.v_bat /1000.0 }}V
          br
          span {{computeTrackerDateFormat(scope.row.tracker_latest_info.power.added_at) }}
        span(v-else) -
    el-table-column(
      prop='category'
      :label="$t('trackers.last_connection')"
    )
      template(slot-scope='scope' v-if="scope.row.tracker_latest_info")
        span(v-if="scope.row.tracker_latest_info.connection_gsm")
          span {{$t('trackers.gsm')}}: 
          span.text-black(title="Operator, IP") {{ scope.row.tracker_latest_info.connection_gsm.operator}}
          br
          span {{computeTrackerDateFormat(scope.row.tracker_latest_info.connection_gsm.added_at) }}
          br
        span(v-else) -
        span(v-if="scope.row.tracker_latest_info.connection_wifi")
          span {{$t('trackers.wifi')}}: 
          span.text-black(title="SSID, IP") {{ scope.row.tracker_latest_info.connection_wifi.ssid + ", " + scope.row.tracker_latest_info.connection_wifi.ip }}
          span(v-if="!scope.row.tracker_latest_info.connection_wifi") -
          br
          span {{computeTrackerDateFormat(scope.row.tracker_latest_info.connection_wifi.added_at) }}
        span(v-else) -
    el-table-column(
      prop='tracker_last_location.data'
      :label="$t('trackers.last_location')"
      width="155px"
      align="center"
    )
      template.center(slot-scope='scope')
        span(v-if="scope.row.tracker_latest_info && scope.row.tracker_latest_info.location")
          //- img.cursor-pointer.ml-1(
          //-   src="@assets/preview.svg"
          //-   @click="handleOpenViewTrackerOnMap(scope.row)"
          //- )
          //- span.text-underline.cursor-pointer(
          //-   @click="handleOpenViewTrackerOnMap(scope.row)"
          //- ) {{ 'Arată locația' }}
          el-button.custom-table-btn.mr-2(
            @click="handleOpenViewTrackerOnMap(scope.row)"
          ) {{ 'Arată locația' }}
          br
          span {{computeTrackerDateFormat(scope.row.tracker_latest_info.location.added_at) }} <br> 
        span(v-else) - 
</template>

<script>
import { filterUtils} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations } from "vuex";
import { routerUtils } from "@utils/router";

export default {
  name: "TrackersViewTable",
  mixins: [routerUtils, filterUtils, general],

  props: {
    trackers: {
      type: Array,
    },
  },

  methods: {
    ...mapMutations("tracker", ["setTracker"]),
    ...mapMutations("bike", ["setBike"]),
    ...mapMutations("dialog", [
      "showViewBikeDetailsDialog",
      "showViewTrackerDialog",
      "showViewTrackerOnMapDialog"
    ]),

    ...mapActions("bike", ["getBike"]),

    handleBikeStateOfCharge(state_of_charge){
      if (state_of_charge == 1) return 'bikes.state_of_charge.fully_charged'
      if (state_of_charge == 2) return 'bikes.state_of_charge.medium_charged'
      if (state_of_charge == 3) return 'bikes.state_of_charge.low_battery'
      return 'bikes.state_of_charge.undefined'
    },

    handleOpenViewTrackerModal(tracker) {
      this.setTracker(tracker);
      this.showViewTrackerDialog();
    },

    async handleOpenViewBikeModal(bike) {
      const response = await this.getBike(bike.id);
      this.setBike(response);
      this.showViewBikeDetailsDialog();
    },

    handleOpenViewTrackerOnMap(tracker){
      this.setTracker(tracker);
      this.showViewTrackerOnMapDialog();
    },

    handleBikeType(type) {
      if (type == "mechanical") return 'Mecanică'
      else if (type == "electrical") return 'Electrică'
    }
  }
};
</script>

