import { render, staticRenderFns } from "./AddRoleView.vue?vue&type=template&id=67eb616c&scoped=true&lang=pug&"
import script from "./AddRoleView.vue?vue&type=script&lang=js&"
export * from "./AddRoleView.vue?vue&type=script&lang=js&"
import style0 from "./AddRoleView.vue?vue&type=style&index=0&id=67eb616c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67eb616c",
  null
  
)

export default component.exports