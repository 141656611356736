<template lang="pug">
  .user-details(v-loading="user === null")
    el-row
      el-col
        h3.mb-2 {{ $t('cost_centers.user_details') }}
        el-form(
          :model="form"
          ref="AddUserForm"
          :rules="addUserFormRules"
          @validate="handleSetNewAddUserDetails"
          @submit.native.prevent
        )
          el-row(:gutter="25")
            el-col(:span="8")
              el-form-item(
                prop="name"
                :label="$t('common.name')"
              )
                el-input(
                  v-model="form.name"
                  name="name"
                )
            el-col(:span="8")
              el-form-item(
                prop="email"
                :label="$t('common.email_address')"
              )
                el-input(
                  v-model="email"
                  name="email"
                )
          el-row(:gutter="25")
            el-col(:span="8")
              el-form-item(
                prop="phone_number"
                :label="$t('common.phone_number')"
              )
                el-input(
                  v-model="form.phone_number"
                  name="phone_number"
                )
</template>

<script>
import { usersViewValidations } from "@utils/formValidations/usersViewValidations";
import { customValidations } from "@utils/customValidators";
import { mapMutations, mapState } from "vuex";

export default {
  name: "UserDetailsForm",
  mixins: [usersViewValidations, customValidations],

  props: {
    user: {
      type: Object,
      required: false
    }
  },

  watch: {
    user() {
      if (this.user) {
        this.handlePrefillForm();
      }

      // if(this.form.email.length){
      //   let user = this.getUser(this.user.id);
      //
      //   if(form.email === this.user.email){
      //     // this.$router.push('redirect');
      //   }
      // }
    }
  },

  data() {
    return {
      form: {
        name: "",
        email: "",
        phone_number: "",
      },
      loading: false,
    };
  },

  computed: {
    ...mapState("user", ["newAddUserData"]),

    email: {
      get: function() {
        return this.form.email;
      },

      set: function(newValue) {
        this.form.email = newValue.replace(/[+]/g, '');
      }
    }
  },

  methods: {
    ...mapMutations("user", ["setNewAddUserDetails", "getUser"]),
    ...mapMutations("customer", ["setNewAddCustomerDetails"]),
    ...mapMutations("passwordValidationDelete", ["setDialogData"]),

    handlePrefillForm() {
      this.form.name = this.user.first_name + " " + this.user.last_name;
      this.form.email = this.user.email;
      this.form.phone_number = this.user.phone_number;
    },

    handleSetNewAddUserDetails() {
      let parts = this.form.name.split(" "),
          first = parts.shift(),
          last = parts.shift() || "",
          second = parts.shift() || "";

      let payload = {
        first_name: first,
        last_name: second.length > 1 ? last + " " + second : last,
        email: this.form.email,
        phone_number: this.form.phone_number,
      };

      if (this.form.email && this.form.phone_number) {
        this.setNewAddUserDetails(payload);
        this.setNewAddCustomerDetails(payload);
      }
    }
  },
};
</script>
