<template lang="pug">
  .docking-station-logs-view
    h3 {{ $t('station_logs.station_logs') }}
    .filters
      FilterDatePicker(
        v-model="dateTimeRange"
      )
      FilterDockingStationDropdown(v-model="selectedDockingStationId")
    .docking-station-logs-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['by_keyword'])"
        )
          el-button.el-icon-refresh-left.mr-2.cursor-pointer(
            style="font-size: 35px; margin-right: 0px;"
            :class="{disabled: generatePausedForSeconds}"
            :loading="generatePausedForSeconds"
            @click="reloadData()")
      el-row
        el-col(:span='24')
          StationLogsViewTable(
            v-loading="loading"
            :station_logs="station_logs"
          )
    Pagination(
      :view="'station_logs'"
      :search="search"
      :reload="reload"
      :dateTimeRange="dateTimeRange"
      :dockingStationId="selectedDockingStationId"
      @viewData="handleGetStationLogs"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { roles } from '@utils/roles'
import SearchWithButtons from '@components/_shared/SearchWithButtons'
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import Pagination from '@components/_shared/Pagination'
import FilterDockingStationDropdown from '@components/_shared/FilterDockingStationDropdown'
import { dynamicViewsConstants } from '@src/constants'
import { general } from '@src/services/dynamicViews/general'
import StationLogsViewTable from '@components/station_logs/StationLogsViewTable'

export default {
  mixins: [general],
  components: {
    SearchWithButtons,
    Pagination,
    FilterDatePicker,
    FilterDockingStationDropdown,
    StationLogsViewTable,
  },

  data() {
    return {
      station_logs: [],
      search: '',
      dateTimeRange: [],
      selectedDockingStationId: '',
      loading: false,
      showHowToRead: false,
      generatePausedForSeconds: false,
      reload: false,
      roles,
      dynamicViewsConstants,
    }
  },

  computed: {
    ...mapState('auth', ['user']),
  },

  methods: {
    ...mapMutations('dialog', ['showAddDockingStationsLogDialog']),

    handleGetStationLogs(data) {
      this.station_logs = data
    },

    handleLoading(loading) {
      this.loading = loading
    },

    handleLoading(loading) {
      this.loading = loading;
      if(!loading) {
        setTimeout(() => this.generatePausedForSeconds = loading, 1000);
      }else {      
        this.generatePausedForSeconds = loading
      }
      this.reload = false;
    },

    reloadData(){
      this.reload = true;
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@variables';

.docking-station-logs-view {
  .how-to-read-pricing {
    padding: 16px;
    background: #fff;
    border-radius: 14px;
    align-items: center;

    span {
      display: block;
      cursor: pointer;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 25px;
      color: #1c2567;

      i {
        margin-left: 16px;
      }
    }

    .content {
      margin-top: 16px;

      p {
        margin: 0;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        color: #000;
        font-weight: 300;
        margin-bottom: 8px;
        font-family: $avenir-next-regular;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
