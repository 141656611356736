<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='bikes'
    @selection-change="$emit('selectionChange', $event)"
  )
    el-table-column(
      type="selection"
      width="55"
    )
    el-table-column(
      prop='serial_number'
      :label="$t('common.serial_number')"
      min-width="210px"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.serial_number.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.serial_number"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.serial_number) }}
        span(v-if="scope.row.serial_number.length <= MAX_FIELD_LENGTH") {{ scope.row.serial_number }}
    el-table-column(
      prop='rfid'
      :label="$t('label.rfid')"
      min-width="140px"
    )
      template(slot-scope='scope')
        span {{ scope.row.rfid }}
    el-table-column(
      prop='tracker'
      :label="'Tracker'"
      min-width="140px"
    )
      template(slot-scope='scope')
        span(v-if="scope.row.tracker_name != null") {{ scope.row.tracker_name.name }}
        span(v-else) {{ "-" }}
    el-table-column(
      prop='date'
      :label="$t('label.date')"
      min-width="130px"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
      min-width="130px"
    )
      template(slot-scope='scope')
        .d-flex
          img.cursor-pointer.ml-2(
            src="@assets/actions/delete.svg"
            @click="handleBikeInventoryActionsManipulation(scope.row, 'deleteBike')"
          )
</template>

<script>
import {
  filterUtils,
  MAX_FIELD_LENGTH,
  trimTableField,
} from "@utils/filterUtils";
import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "InventoryViewTable",
  mixins: [filterUtils, general],

  props: {
    bikes: {
      type: Array,
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
      trimTableField,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteBike();
    },
  },

  computed: {
    ...mapState("passwordValidationDelete", [
      "allowDeletion",
      "dataToDelete",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapActions("bike", ["deleteBike"]),

    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    ...mapMutations("bike", ["setSelectedBikes"]),

    async handleBikeInventoryActionsManipulation(bike, type) {
      if (type === "deleteBike") {
        this.handleDeleteBikeDialog(bike);
      }
    },

    handleDeleteBike() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteBike(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteBikeDialog(bike) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("bikes.validations.delete_title")} ${
          bike.serial_number
        }`,
        dialogMessage: this.$t("bikes.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(bike);
    },
  },
};
</script>
