<template lang="pug">
  .station-commands-view
    h3 {{ $t('station_commands.station_commands') }}
    .station-commands-wrapper
      el-form.mt-5(
        label-width="220px"
        :model="form"
        ref="sendCommandForm"
        :rules="sendCommandFormRules"
        @submit.native.prevent
      )
        el-row
          el-col(:span="12")
            el-form-item(
              prop="cost_center_id"
              :label="$t('common.cost_center_name')"
            )
              el-select.w-100(
                v-model='cost_center_id'
                filterable
                :placeholder="$t('placeholder.select_cost_center')"
                @change="handleGetDocks"
              )
                el-option(
                  v-for='costCenter in cost_centers'
                  :key='costCenter.id'
                  :label='costCenter.name'
                  :value='costCenter.id'
                )
        el-row
          el-col(:span="12")
            el-form-item(
              prop="docking_station_id"
              :label="$t('common.docking_station')"
            )
              el-select.w-100(
                v-model='docking_station_id'
                filterable
                :placeholder="$t('placeholder.select_dock')"
                @change="(currSelectedValue) => {handleComputeBays(currSelectedValue); subscribeStation(currSelectedValue)}"
              )
                el-option(
                  v-for='dock in docks'
                  :key='dock.id'
                  :label='dock.name'
                  :value='dock.id'
                )
        el-row
          el-col(:span="12")
            el-form-item(
              prop="bay_number"
              :label="$t('common.bay')"
            )
              el-select.w-100(
                v-model='form.bay_number'
                filterable
                :placeholder="$t('placeholder.select_bay')"
              )
                el-option(
                  v-for='bayNumber in bays'
                  :key='bayNumber'
                  :label='bayNumber'
                  :value='bayNumber'
                )
        el-row
          el-col(:span="12")
            el-form-item(
              prop="command"
              :label="$t('label.command')"
            )
              el-select.w-100(
                v-model='form.cmd'
                filterable
                :placeholder="$t('placeholder.select_command')"
              )
                el-option(
                  v-for='command in commands'
                  :key='command'
                  :label='command'
                  :value='command'
                )

        // CMD adittional fields
        div(v-if="form.cmd === commands.CMD || form.cmd === commands.UDP")
          el-row
            el-col(:span="12")
              el-form-item(
                prop="cmdCommand"
                :label="$t('label.cmd_command')"
              )
                el-input(
                  v-model="cmdCommand"
                  name="cmdCommand"
                )
          el-row(v-if="form.cmd === commands.CMD || form.cmd === commands.UDP")
            el-col(:span="12")
              el-form-item(
                prop="cmdParams"
                :label="$t('label.cmd_params')"
              )
                el-input(
                  v-model="cmdParams"
                  name="cmdParams"
                )
        // endRide additional fields
        //div(v-if="form.cmd === commands.END_RIDE")
        //  el-row
        //    el-col(:span="12")
        //      el-form-item(
        //        prop="rideId"
        //        :label="$t('label.ride_id')"
        //      )
        //        el-input(
        //          v-model.number="form.ride_id"
        //          name="rideId"
        //        )
        //  el-row
        //    el-col(:span="12")
        //      el-form-item(
        //        prop="endRideTime"
        //        :label="$t('label.end_ride_time')"
        //      )
        //        el-input(
        //          v-model.number="endRideTime"
        //          name="endRideTime"
        //        )
        //  el-row
        //    el-col(:span="12")
        //      el-form-item(
        //        prop="statusRide"
        //        :label="$t('label.status_ride')"
        //      )
        //        el-input(
        //          v-model.number="statusRide"
        //          name="statusRide"
        //        )
        el-form-item.ml-4
          el-button.dark-blue-btn.flat-btn(
            @click="handleSubmitCommand('sendCommandForm')"
            v-loading="loading"
          ) Trimite comanda
      .hr-custom
      div.console-logs.d-flex.flex-direction-column.py-2
        div(v-for="(message, i) in messages" :key="i")
          span {{ message }}
</template>

<script>
import { stationCommandsViewValidations } from "@utils/formValidations/stationCommandsViewValidations";
import { stationCommands } from "@src/constants";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [stationCommandsViewValidations],

  data() {
    return {
      socket: null,
      commands: stationCommands,
      cost_centers: [],
      docks: [],
      messages: [],
      bays: [],
      docking_station_id: null,
      cost_center_id: null,
      endRideTime: '',
      statusRide: '',

      form: {
        bay_number: null,
        cmd: null,
        cmd_command: "",
        cmd_params: '',
        // cmd_response: '',
        status: 1,
      },

      loading: false,
    };
  },

  watch: {
    docking_station_id: {
      handler() {
        this.form.bay_number = null;
      }
    },

    cost_center_id: {
      handler() {
        this.docking_station_id = null;
      }
    },

    'form.cmd'(newVal) {
      if (newVal === this.commands.CMD) {
        this.form.cmd_command = this.form.cmd_command.replace(/[^\dA-Fa-f0-9]/g, '').replace(/(.{2})/g, '$1 ').trim();
        this.form.cmd_params = this.form.cmd_params.replace(/[^\dA-Fa-f0-9]/g, '').replace(/(.{2})/g, '$1 ').trim();
      }
    },

  },

  computed: {
    ...mapState('auth', ['access_token']),

    cmdCommand: {
      get: function () {
        return this.form.cmd_command;

      },
      set: function (newValue) {
        if( this.form.cmd === this.commands.CMD) {
          this.form.cmd_command = newValue.replace(/[^\dA-Fa-f0-9]/g, '').replace(/(.{2})/g, '$1 ').trim();
        } else {
          this.form.cmd_command = newValue;
        }

      }
    },

    cmdParams: {
      get: function () {
        return this.form.cmd_params;
      },

      set: function (newValue) {
        if( this.form.cmd === this.commands.CMD) {
          this.form.cmd_params = newValue.replace(/[^\dA-Fa-f0-9]/g, '').replace(/(.{2})/g, '$1 ').trim();
        } else {
          this.form.cmd_params = newValue;
        }
      }
    }
  },

  async created() {
    await this.handleGetCostCenters()
    await this.connectToWss()
  },

  methods: {
    ...mapActions('dockingStation', ["getDocks"]),
    ...mapActions('costCenter', ["getCostCenters"]),

    connectToWss() {
      this.socket = new WebSocket(`${process.env.VUE_APP_WSS_BASE_URL}?auth_token=${this.access_token}`);

      this.socket.onopen = (e) => {
        this.messages.push('[open] Connection established')
      };

      this.socket.onmessage = (event) => {
        let parsedData = JSON.parse(event.data)

        if (parsedData.type !== 'ping') {
          this.messages.push(parsedData)
        }
      };

      this.socket.onerror = function (error) {
        console.log(`[error] ${error.message}`);
      };
    },

    command(command, channel, id, data) {
      let cm = {
        command: command,
        identifier: JSON.stringify({
          channel: channel,
          id: id
        }),
        data: JSON.stringify(data)
      }

      return JSON.stringify(cm)
    },

    handleSubmitCommand(formName) {
      this.loading = true
      const form = this.$refs[formName];

      if (this.form.bay_number === 'All')
        this.form.bay_number = 255;

      form.validate(async (valid) => {
        if (valid) {
          let params = {
            bay_number: Number.parseInt(this.form.bay_number),
            cmd: this.form.cmd,
            cmd_command: this.form.cmd_command,
            cmd_params: this.form.cmd_params,
            status: Number.parseInt(this.form.status),
          };

          if (this.form.cmd === 'CMD') {
            if (!this.evenOrOdd(this.form.cmd_command)) {
              this.loading = false;
              return this.$message({
                type: "error",
                message: this.$t("station_commands.odd_hexadecimal_number"),
              });
            }
          }
          this.socket.send(this.command('message', 'StationCommandChannel', this.docking_station_id, params))

          this.loading = false;
        } else {
          this.loading = false;
        }
      });

      if (this.form.bay_number === 255) {
        this.form.bay_number = 'All';
      }
    },

    async handleGetCostCenters() {
      let response = await this.getCostCenters("?page_size=1000")
      this.cost_centers = response.data
    },

    async handleGetDocks(id) {
      
      let computedUrl = `?page_size=1000&by_cost_center_id=${id}`
      let response = await this.getDocks(computedUrl)
      this.docks = response.data
    },

    handleComputeBays(id) {
      const { bays_count }  = this.docks.find(dock => dock.id === id)

      if (bays_count) {
        this.generateBays(bays_count);
      }
    },

    subscribeStation(stationId) {
      this.socket.send(this.command('subscribe', 'StationCommandChannel', stationId))
    },

    generateBays(no_of_available_bays) {
      this.bays = Array.from({
        length: no_of_available_bays
      }, (v, k) => k + 1);

      this.bays.push('All');
    },

    // Check if a hexadecimal number is odd or even
    evenOrOdd(hexaNumber) {
      let len = hexaNumber.length;

      // return ['0','2','4','6','8','A','C','E'].contains(hexaNumber[len - 1]);

      // check if the last digit
      // is either '0', '2', '4',
      // '6', '8', 'A'(=10),
      // 'C'(=12) or 'E'(=14)
      return hexaNumber[len - 1] === '0'
          || hexaNumber[len - 1] === '2'
          || hexaNumber[len - 1] === '4'
          || hexaNumber[len - 1] === '6'
          || hexaNumber[len - 1] === '8'
          || hexaNumber[len - 1] === 'A'
          || hexaNumber[len - 1] === 'C'
          || hexaNumber[len - 1] === 'E';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@variables";

.console-logs {
  white-space: pre-wrap;
  word-break: break-word;
  background: $lighter-gray;
  padding: 0 20px;
  height: 400px;
  width: 70%;
  margin-top: 30px;
  overflow-y: scroll;
}
</style>
