<template lang="pug">
  .tracker-view(v-loading="loading")
    .page-load(v-if="!loading")
      el-breadcrumb.mt-3.last-breadcrumb-bold(separator="/")
        el-breadcrumb-item(:to="{ path: '/trackers' }") {{ $t('trackers.goTo') }} 
        el-breadcrumb-item {{ trackerInfo ? trackerInfo.name : "-"}} ( {{ trackerInfo ? (trackerInfo.mac ? trackerInfo.mac + "/" + trackerInfo.imei : trackerInfo.dev_eui) : "" }} )
      br
      el-button.hidden-btn(
        @click="handleTracker()"
        :class="{disabled: btnLoading}"
        :loading="btnLoading"
      ) {{ $t('trackers.details') + " " + (trackerInfo ? trackerInfo.name : "")}}
      .prices-wrapper
        .map
          el-button.hidden-btn(
            @click="handleGetLocation()"
            :class="{disabled: btnLoading}"
            :loading="btnLoading"
          ) {{ $t('trackers.map') }}
          GoogleMap(
            @markerDragEnd="handleMarkerDragEnd"
            :dialogName="mapDialogName"
            :trackerCoordinates="this.coordinates"
          )
        .version
          el-button.hidden-btn(
            @click="handleGetVersion()"
            :class="{disabled: btnLoading}"
            :loading="btnLoading"
          ) {{ $t('trackers.version') }}
          .info
            span {{ versionInfo ? computeFWVerionDateFormat(versionInfo) : '-'}}
        .chart
          el-button.hidden-btn(
            @click="handleGetPower()"
            :class="{disabled: btnLoading}"
            :loading="btnLoading"
          ) {{ $t('trackers.chart') }}
          ChartPower(
            :statisticTitle="$t('transactions.average_earnings')"
            :chartData="powerVbatInfos"
            :chartType="'powerVbat'"
          )
          ChartPower(
            :statisticTitle="$t('transactions.average_earnings')"
            :chartData="powerVinInfos"
            :chartType="'powerVin'"
          )
        .connection
          el-button.hidden-btn(
            @click="handleGetConnection()"
            :class="{disabled: btnLoading}"
            :loading="btnLoading"
          ) {{ $t('trackers.connection') }}
          .types
            span.wifi(title="Dată înregistrare, SSID, IP") {{ "Wifi: " + (wifiInfo ? wifiInfo : '-')}}
            span.gsm(title="Dată înregistrare, Operator, IP, ICCID") {{ "GSM: " + (gsmInfo ? gsmInfo : '-')}}
        .bike
          el-button.hidden-btn(
            :disabled= "true"
            :loading="btnLoading"
          ) {{ $t('trackers.bike') }}
          .types(v-if="trackerBike")
            span {{ "Serie: " + (trackerBike.serial_number ? trackerBike.serial_number : '-')}}
            span {{ "Status: " + (trackerBike.status ? trackerBike.status : '-')}}
            span {{ "Centru de cost: " + (trackerBike.cost_center ? trackerBike.cost_center.name : '-')}}
            span {{ "RFID: " + (trackerBike.rfid ? trackerBike.rfid : '-')}}
            span {{ "Tip: " + (trackerBike.bike_type ? trackerBike.bike_type : '-')}}
            span {{ "Stație: " + (trackerBike.docking_station ? trackerBike.docking_station.serial_number : '-') + '-' + (trackerBike.bay_number ? trackerBike.bay_number.number : '-')}}
        .event
          el-button.hidden-btn(
            @click="handleGetEvent()"
            :class="{disabled: btnLoading}"
            :loading="btnLoading"
          ) {{ $t('trackers.event') }}
          .types
            .rotation
              ChartRotation(
                :statisticTitle="$t('transactions.average_earnings')"
                :chartData="eventRotationInfos"
                :chartType="'powerVbat'"
              )
            //- .status
            //-   span {{ "Serie: " + (trackerBike.serial_number ? trackerBike.serial_number : '-')}}
            //-   span {{ "Status: " + (trackerBike.status ? trackerBike.status : '-')}}

</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import GoogleMap from "@components/trackers/GoogleMap";
import ChartPower from "@components/trackers/ChartPower";
import ChartRotation from "@components/trackers/ChartRotation";
import DetailsTable from "@components/trackers/DetailsTable";


export default {
  mixins: [filterUtils, general, routerUtils],

  components: {
    GoogleMap,
    ChartPower,
    ChartRotation,
    DetailsTable,
  },

  data() {
    return {
      trackerLatestInfo: {},
      trackerInfo: null,
      trackerBike: [],
      powerVbatInfos: [],
      powerVinInfos: [],
      eventRotationInfos: [],
      eventPitchInfos: [],
      coordinates: [],
      versionInfo: "",
      wifiInfo: "",
      gsmInfo: "",
      loading: false,
      btnLoading: false,
      mapDialogName: 'lastLocation',

      inBetween,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleTracker();
  },

  methods: {
    ...mapActions("tracker", ["getTracker", "getTrackerDetailsForCategory"]),
    ...mapMutations("map", ["setMapCoordinates"]),

    handleGoTo(){
      this.goTo('trackers')
    },

    async handleMarkerDragEnd(markerPosition) {
      await this.setMapCoordinates(markerPosition);
    },

    async handleTracker(){
      this.btnLoading = true;
      await this.handleGetTrackerLatestInfo();
      await this.handleGetConnection();
      await this.handlePopulateTrackerLatestInfo();
      this.btnLoading = false;
    },

    async handleGetTrackerLatestInfo(){
      const response = await this.getTracker(this.$route.params.id);
      this.trackerInfo = response.data;
      if(response.data.tracker_latest_info)
        this.trackerLatestInfo = response.data.tracker_latest_info;
      if(response.data.bike)
        this.trackerBike = response.data.bike;
    },

    async handlePopulateTrackerLatestInfo(){
      var vBat = {};
      var vIn = {};
      this.powerVbatInfos = [];
      this.powerVinInfos = [];
      if(this.trackerLatestInfo && this.trackerLatestInfo.power != null){
        vBat = {};
        vBat.value = this.trackerLatestInfo.power.v_bat / 1000.0;
        vBat.date = this.trackerLatestInfo.power.added_at;
        this.powerVbatInfos.push(vBat);
        vIn = {};
        vIn.value = this.trackerLatestInfo.power.v_in / 1000.0;
        vIn.date = this.trackerLatestInfo.power.added_at;
        this.powerVinInfos.push(vIn);
      }

      if(this.trackerLatestInfo && this.trackerLatestInfo.about != null){
            this.versionInfo = this.trackerLatestInfo.about.fw_ver;
      }
      if(this.trackerLatestInfo && this.trackerLatestInfo.location != null){
      this.mapDialogName = 'lastLocation';
      this.coordinates = [this.trackerLatestInfo.location.latitude, this.trackerLatestInfo.location.longitude, this.trackerLatestInfo.location.added_at];
      }
    },

    async handleGetLocation() {
      this.btnLoading = true;

      const coordInfo = [];
      var vBat = {};
      let costPayload = {
        id: this.$route.params.id,
        params: 'location'
      };
      
      const response = await this.getTrackerDetailsForCategory(costPayload);

      if(response.data){
        response.data.forEach((detail) => {
          vBat = {};
          vBat.value = [detail.latitude, detail.longitude, detail.created_at];
          coordInfo.push(vBat);
        })
        this.mapDialogName = 'locations';
        this.coordinates = coordInfo;
      }

      this.btnLoading = false;
    },

    async handleGetVersion() {
      this.btnLoading = true;

      let wifiPayload = {
        id: this.$route.params.id,
        params: 'version'
      };
      const response = await this.getTrackerDetailsForCategory(wifiPayload);
      if(response && response.data && response.data.details) {
        this.versionInfo = response.data.details.fw_ver;
      }

      this.btnLoading = false;
    },

    async handleGetPower() {
      this.btnLoading = true;

      const vBatInfos = [];
      const vInInfos = [];
      var vBat = {};
      var vIn = {};
      let costPayload = {
        id: this.$route.params.id,
        params: 'power'
      };

      const response = await this.getTrackerDetailsForCategory(costPayload);

      if(response && response.data) {
        response.data.forEach((detail) => {
          vBat = {};
          vIn = {};
          vBat.value = detail.details.v_bat / 1000.0;
          vBat.date = detail.created_at;
          vIn.value = detail.details.v_in / 1000.0;
          vIn.date = detail.created_at;
          vBatInfos.push(vBat);
          vInInfos.push(vIn);
        })
        this.powerVbatInfos = vBatInfos;
        this.powerVinInfos = vInInfos;
      }

      this.btnLoading = false;
    },

    async handleGetEvent() {
      this.btnLoading = true;

      const rotationInfos = [];
      var rotation = {};
      let costPayload = {
        id: this.$route.params.id,
        params: 'event'
      };

      const response = await this.getTrackerDetailsForCategory(costPayload);

      if(response && response.data) {
        response.data.forEach((detail) => {
          rotation = {};
          rotation.roll = detail.details.roll;
          rotation.pitch = detail.details.pitch;
          rotation.date = detail.created_at;
          rotationInfos.push(rotation);
        })
        this.eventRotationInfos = rotationInfos;
      }
      this.btnLoading = false;
    },

    async handleGetConnection() {
      this.btnLoading = true;

      let wifiPayload = {
        id: this.$route.params.id,
        params: 'wifi'
      };
      const wifiResponse = await this.getTrackerDetailsForCategory(wifiPayload);
      const wifiData = wifiResponse.data
      if(wifiData) {
        this.wifiInfo = this.computeTrackerDateFormat(wifiData.created_at) + ", " + wifiData.details.ssid + ", " + wifiData.details.ip;
      }

      let gsmPayload = {
        id: this.$route.params.id,
        params: 'gsm'
      };
      const gsmResponse = await this.getTrackerDetailsForCategory(gsmPayload);
      const gsmData = gsmResponse.data;
      if(gsmData) {
        this.gsmInfo = this.computeTrackerDateFormat(gsmData.created_at) + ", " + gsmData.details.operator + ", " + gsmData.details.ip + ", " + gsmData.details.iccid ;
      }
      
      this.btnLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

</style>
