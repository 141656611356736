import Vue from "vue";
import store from "@state/store";
import router from "@router";
import design from "@design/main.scss";
import i18n from "@i18n/index.js";
import App from "./App.vue";
import "@plugins/element-ui/index";
import 'nprogress/nprogress.css';
import Geocoder from "@mariopando/vue2-geocoder";

import * as VueGoogleMaps from "vue2-google-maps";

import axiosSetup from "./services/axiosSetup";
axiosSetup()

// google maps setup
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GMAP_KEY,
    libraries: "places",
  },
  installComponents: true,
});

Vue.use(Geocoder, {
  googleMapsApiKey: process.env.VUE_APP_GMAP_KEY
});

Vue.use(require("vue-cookies"));
Vue.use(require("vue-moment"));

export const bus = new Vue();

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  design,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
