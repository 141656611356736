<template lang="pug">
  .view-ride-details
    GoogleMaps(
      v-if="this.coordinates && this.coordinates.length > 0"
      :dialogName="'viewRideDetails'"
      :rideCoordinates="this.coordinates && this.coordinates"
    )
    span.gdpr-message(v-else) {{ $t('common.gdpr_message') }}
    .col
      el-row.center.details-bold(
        :gutter="40"
        type="flex"
        justify="center"
      )
        el-col(:span="6")
          span {{ $t('dialogs.customer.bike_sn') }}

        el-col(:span="6")
          span {{ $t('dialogs.customer.beginning_date') }}

        el-col(:span="6")
          span {{  $t('common.end_date') }}

        el-col(:span="6")
          span {{  $t('dialogs.customer.customer') }}
      el-row.center(
        :gutter="40"
        type="flex"
        justify="center"
        style="margin-top: 10px"
      )
        el-col(:span="6")
          span(
            v-if="ride.bike_serial_number"
          ) {{ ride.bike_serial_number }}
          span(v-else) -

        el-col(:span="6")
          span.pre-line {{ computeDateFormat(ride.start_at) }}

        el-col(:span="6")
          span.pre-line {{ computeDateFormat(ride.end_at) }}

        el-col(:span="6")
          span(v-if="ride.customer_email") {{ ride.customer_email }}
          span(v-else) -
    .col
      el-row.center.details-bold(
        :gutter="40"
        type="flex"
        justify="center"
      )
        el-col(:span="6")
          span {{ $t('common.ride_price') }}

        el-col(:span="6")
          span {{ $t('dialogs.customer.paid') }}

        el-col(:span="6")
          span {{  $t('dialogs.customer.start_dock') }}

        el-col(:span="6")
          span {{  $t('dialogs.customer.finish_dock') }}

      el-row.center(
        :gutter="40"
        type="flex"
        justify="center"
        style="margin-top: 10px"
      )
        el-col.pre-line(:span="6")
          span {{ $n(ride.price, 'currency') }}

        // TODO: dont' forget about paid
        el-col.pre-line(:span="6")
          span {{ $n(ride.already_paid_ammount, 'currency') }}

        el-col.pre-line.break-word(:span="6")
          span(
            v-if="ride.start_docking_station_name"
          ) {{ ride.start_docking_station_name }}
          span(v-else) -

        el-col.pre-line.break-word(:span="6")
          span(
            v-if="ride.end_docking_station_name"
          ) {{ ride.end_docking_station_name }}
          span(v-else) -
    .col
      el-row.center.details-bold(
        :gutter="40"
        type="flex"
        justify="center"
      )
        el-col(:span="6")
          span {{ $t('common.ride_duration') }}

        el-col(:span="6")
          span {{ $t('dialogs.customer.ride_tariff') }}

        el-col(:span="6")
          span {{  $t('common.ride_status') }}

        el-col(:span="6")
          span {{  $t('common.flagged') }}

      el-row.center(
        :gutter="40"
        type="flex"
        justify="center"
        style="margin-top: 10px"
      )
        el-col.pre-line(:span="6")
          span {{ computeRideDuration(ride.duration_seconds) }}

        el-col.pre-line(:span="6")
          .price-plans
            span(v-if="ride.rent_tariff_name") <strong> {{ ride.rent_tariff_name }} </strong>
            span(v-else) -
            span {{ride.rent_tariff_warranty}} {{$t('dialogs.rent_tariffs.currency')}} {{'garanție'}} 
            span(v-if="ride.rent_tariff_initial_timeblock == 1") {{ ride.rent_tariff_initial_fee }} {{$t('dialogs.rent_tariffs.currency')}} {{'primul'}} {{ ride.rent_tariff_initial_timeblock }} {{'minut'}}
            span(v-else) {{ ride.rent_tariff_initial_fee }} {{$t('dialogs.rent_tariffs.currency')}} {{'primele'}} {{ ride.rent_tariff_initial_timeblock }} {{'minute'}}
            span {{ ride.rent_tariff_secondary_fee }} {{$t('dialogs.rent_tariffs.currency')}} {{$t('customers.every_next')}} {{ ride.rent_tariff_secondary_timeblock }} {{'de minute'}}

        el-col.pre-line.break-word(:span="6")
          .status-wrapper.text-underline(
            v-if="inBetween('ridesStatuses', ride.status, 21, 40)")
            span.oval.completed
            span {{ ridesStatuses[ride.status] ? ridesStatuses[ride.status].message : ride.status }} - {{ computePaidStatus(ride.price, ride.already_paid_ammount) }}

          .status-wrapper.text-underline(v-if="inBetween(ride.status, 41, 60)")
            span.oval.cancelled
            span {{ ridesStatuses[ride.status] ? ridesStatuses[ride.status].message : ride.status }} - {{ computePaidStatus(ride.price, ride.already_paid_ammount) }}

          .status-wrapper.text-underline(v-if="inBetween('ridesStatuses', ride.status, 1, 20)")
            span.oval.pending
            span {{ ridesStatuses[ride.status] ? ridesStatuses[ride.status].message : ride.status }}

        el-col.pre-line.break-word(:span="6")
          span(v-if="ride.flag") 🚩 {{ ride.flag }}
          span(v-else) --
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { ridesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import GoogleMaps from "@components/_shared/GoogleMaps";

export default {
  name: "ViewCustomerRideDetails",
  mixins: [filterUtils],
  components: { GoogleMaps },

  data() {
    return {
      coordinates: [],
      loading: false,
      ridesStatuses,
      inBetween,
    };
  },

  computed: {
    ...mapState("ride", ["ride"]),
  },

  async created() {
    this.handleRideData();
  },

  watch: {
    ride(){
      this.handleRideData();
    }
  },

  methods: {
    ...mapMutations("dialog", [
      "hideViewRideDetailsDialog",
      "showViewTransactionDetailsDialog",
    ]),

    // ...mapMutations("ride", ["setRide"]),

    
    ...mapActions("ride", ["getRide"]),

    async handleRideData(){
      const response = await this.getRide(this.ride.id);
      this.coordinates = response.ride_coordinates
    },


  },
};
</script>

<style lang="scss" scoped>
.gdpr-message {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper {
  height: 293px;
  margin: 23px auto 40px;
  width: 100%;
}

.view-ride-details {
  padding-bottom: 24px;

  .price-plans {
    span {
      display: block;
      overflow-wrap: break-word;
    }
  }

  .col {
    margin-bottom: 27px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.status-wrapper {
  display: flex;
  align-items: center;
}
</style>
