<template lang="pug">
  .view-bike-details
    .bike-modal-table
      el-row(:gutter="50" style="margin: auto; text-align:center;")
        el-tabs.mt-4(
          v-model='activeName'
        )
          el-tab-pane(
            :label="$t('bikes.modal.tab.bike_details')"
            name='bike_details'
          )
          el-tab-pane(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_rides']) || showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['statistics'])"
            :label="$t('bikes.modal.tab.rides_history')"
            name='rides_history'
          )
          el-tab-pane(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_maintenances']) || showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['statistics'])"
            :label="$t('bikes.modal.tab.maintenances_history')"
            name='maintenances_history'
          )
      Details(
        v-if="activeName === 'bike_details'" 
        :activeName="activeName"
        )
      RidesHistory(
        v-if="activeName === 'rides_history' && (showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_rides']) || showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['statistics']))"
        :activeName="activeName"
      )
      MaintenancesHistory(
        v-if="activeName === 'maintenances_history' && (showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_maintenances']) || showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['statistics']))"
        :activeName="activeName"
      )
</template>

<script>
import Details from "@components/bikes/viewBike/Details";
import MaintenancesHistory from "@components/bikes/viewBike/BikeMaintenancesHistory";
import RidesHistory from "@components/bikes/viewBike/BikeRidesHistory";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";

export default {
  name: "ViewBikeDetails",
  mixins: [general],
  components: { 
    MaintenancesHistory, 
    RidesHistory, 
    Details 
  },

  data() {
    return {
      activeName: "bike_details",
      dynamicViewsConstants
    };
  },


  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("bike", ["bike"]),
  },

  watch: {
    bike() {
      this.activeName = "bike_details";
    }
  },
};
</script>

