<template lang="pug">
  .roles-view
    h3 {{ $t('roles.roles') }}
    .roles-wrapper
      //.actions.d-flex // @todo: no role creation for this version of the app
        //el-button.dark-blue-btn.ml-auto(
        //  @click="goToWIthId('roles/add_role', '')"
        //)
        //  h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          el-table(
            style='width: 100%;'
            header-cell-class-name='table-header'
            cell-class-name='pl-4'
            :data='roles'
            v-loading="loading"
          )
            el-table-column(
              prop='role'
              :label="$t('roles.roles')"
            )
              template(slot-scope='scope')
                span {{ upperCaseFirstLetters(scope.row.name) }}
            el-table-column(
              prop='permissions'
              :label="$t('roles.permissions')"
              min-width="300px"
            )
              template(slot-scope='scope')
                el-tooltip.item(
                  v-if="scope.row.permissions && computePermissions(scope.row.permissions).length > MAX_FIELD_LENGTH"
                  effect="dark"
                  :content="computePermissions(scope.row.permissions)"
                  placement="bottom-end"
                )
                  span {{ trimTableField(computePermissions(scope.row.permissions)) }}
                span(v-if="scope.row.permissions && computePermissions(scope.row.permissions).length <= MAX_FIELD_LENGTH") {{ computePermissions(scope.row.permissions) }}
            el-table-column(
              prop='actions'
              :label="$t('label.actions')"
            )
              template(slot-scope='scope')
                .d-flex
                  img.cursor-pointer.mr-2(
                    v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.ROLE, ['show'])"
                    src="@assets/preview.svg"
                    @click="handleOpenRolePageView(scope.row)"
                  )
                  img.cursor-pointer(
                    v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.ROLE, ['show'])"
                    src="@assets/actions/edit.svg"
                    @click="handleOpenEditRolePageView(scope.row)"
                  )
                  img.cursor-pointer.ml-1(
                    v-if="showDeleteBtn(scope.row)"
                    src="@assets/actions/delete.svg"
                    @click="handleDeleteRoleDialog(scope.row)"
                  )
</template>

<script>
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";
import { upperCaseFirstLetters } from "@utils/generalUtils";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { routerUtils } from "@utils/router";
import { roles as rolesConstants } from "@utils/roles";

export default {
  mixins: [general, routerUtils],
  components: { SearchWithButtons },

  data() {
    return {
      roles: [],
      loading: false,
      MAX_FIELD_LENGTH,
      trimTableField,
      upperCaseFirstLetters,
      dynamicViewsConstants,
    };
  },

  watch: {
    allowDeletion() {
      this.handleDeleteRole();
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  async mounted() {
    await this.handleGetRoles();
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),
    ...mapActions("role", ["getRoles", "getRole", "deleteRole"]),

    showDeleteBtn(role) {
      let restrictedRoles = [
        rolesConstants.TECHNICIAN,
        rolesConstants.ADMINISTRATOR_OPERATOR,
        rolesConstants.ADMINISTRATOR,
        rolesConstants.ACCOUNTANT,
      ];
      return !restrictedRoles.includes(role.name);
    },

    async handleGetRoles() {
      this.loading = true;
      this.roles = await this.getRoles();
      this.loading = false;
    },

    handleOpenRolePageView(role) {
      this.$router.push(`/roles/${role.id}`);
    },

    handleOpenEditRolePageView(role) {
      this.$router.push(`/roles/edit/${role.id}`);
    },

    computePermissions(permissions) {
      return _.uniqBy(permissions, "resource_name")
        .map((perm) => perm.resource_name)
        .join(", ");
    },

    handleDeleteRoleDialog(role) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t(
          "roles.validations.delete_title"
        )} ${upperCaseFirstLetters(role.name)}`,
        dialogMessage: this.$t("roles.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(role);
    },

    handleDeleteRole() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };
        this.deleteRole(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },
  },
};
</script>
