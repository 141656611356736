<template lang="pug">
  div
    el-submenu(
      index='/bikes_map'
      v-if=`showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['index'])`
    )
      template(slot='title')
        router-link.sub-menu-link(to="/bikes_map")
          .img-wrapper
            img(src="@src/assets/sidebar/bikes.svg")
          router-link.sub-menu-link(to="/bikes_map")
            span  {{ $t('sidebar.bikes_map') }}
      el-menu-item(
        index='/bikes'
      )
        router-link.sub-menu-link(to="/bikes")
          span {{ $t('sidebar.bikes') }}
      el-menu-item(
        v-if="hideSubmenu()"
        index='/trackers'
      )
        router-link.sub-menu-link(to="/trackers")
          span {{ $t('sidebar.trackers') }}
      el-menu-item(
        v-if="hideSubmenu()"
        index='/inventory'
      )
        router-link.sub-menu-link(to="/inventory")
          span {{ $t('sidebar.inventory') }}    

</template>

<script>
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { roles } from "@utils/roles";


export default {
  name: "BikesItem",
  mixins: [general],

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      dynamicViewsConstants
    }
  },
  methods: {
    hideSubmenu() {
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    }
  }
}
</script>
