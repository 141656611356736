<template lang="pug">
  .cost-centers-filter(v-if="computeDockingStationIDFilter")
    span.filter-title {{ $t('common.docking_station_filter') }}
    el-select(
      v-model='selectedDockingStationId'
      filterable
      :placeholder="$t('placeholder.select_dock')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='docking_station in dockingStations'
        :key='docking_station.id'
        :label='docking_station.name'
        :value="docking_station.value !== '' ? docking_station.id : docking_station.value"
      )
</template>

<script>
import { mapActions, mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterDockingStationDropdown",

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedDockingStationId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeDockingStationIDFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  data() {
    return {
      dockingStations: [],
    };
  },

  async mounted() {
    if (this.computeDockingStationIDFilter) {
      await this.handleGetDockingStations()
    }
  },

  methods: {
    ...mapActions("dockingStation", ["getDocks"]),

    async handleGetDockingStations() {
      const dockingStations = await this.getDocks("?page_size=1000");
      this.dockingStations = dockingStations.data;
      this.dockingStations.unshift({
        id: 0,
        name: "All",
        value: "",
      });
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
