import axios from "axios";
import messageUtils from "@utils/messageUtils";
import { oneYearFromNow } from "@/src/utils/generalUtils";
import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  user: null,
  access_token: null,
  refresh_token: null,
};

export const getters = {
  loggedIn: (state) => !!state.access_token,
};

export const actions = {
  async login({ commit }, payload) {
    try {
      const loginResponse = await axios.post("/sessions/token", {
        grant_type: "password",
        username: payload.email,
        password: payload.password,
      });

      if (loginResponse) {
        const currentUserResponse = await axios.get(
          "/v1/profiles/show_current",
          {
            headers: {
              Authorization: `Bearer ${loginResponse.data.access_token}`,
            },
          }
        );

        const deserializedUser = dataFormatter.deserialize(
          currentUserResponse.data
        );

        commit("loginSuccess", {
          accessToken: loginResponse.data.access_token,
          refreshToken: loginResponse.data.refresh_token,
        });
        commit("setAccessToken", loginResponse.data.access_token);
        commit("setRefreshToken", loginResponse.data.refresh_token);
        commit("setUser", deserializedUser);
      }
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async refreshToken({ commit }) {
    try {
      const response = await axios.post("/sessions/token", {
        grant_type: "refresh_token",
        refresh_token: $cookies.get("refresh_token"),
      });
      commit("setAccessToken", response.data.access_token);
      commit("setRefreshToken", response.data.refresh_token);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async sessionUser({ commit }, { access_token, refresh_token }) {
    try {
      const currentUserResponse = await axios.get("/v1/profiles/show_current", {
        headers: { Authorization: `Bearer ${access_token}` },
      });

      const deserializedUser = dataFormatter.deserialize(
        currentUserResponse.data
      );

      commit("setUser", deserializedUser);
      commit("setAccessToken", access_token);
      commit("setRefreshToken", refresh_token);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async logout({ commit }) {
    try {
      await axios.post("/sessions/revoke");
      commit("logout");
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  loginSuccess(state, payload) {
    state.access_token = payload.accessToken;
    state.refresh_token = payload.refreshToken;
  },

  setAccessToken(state, accessToken) {
    state.access_token = accessToken;
    $cookies.set("access_token", accessToken, {
      expires: oneYearFromNow,
      path: "/",
    });
  },

  setRefreshToken(state, refreshToken) {
    state.refresh_token = refreshToken;
    $cookies.set("refresh_token", refreshToken, {
      expires: oneYearFromNow,
      path: "/",
    });
  },

  setUser(state, user) {
    state.user = user;
  },

  logout(state) {
    state.user = null;
    state.access_token = null;
    $cookies.remove("access_token");
    $cookies.remove("refresh_token");
  },
};
