export const state = {
  isViewStartBikeMaintenanceVisible: false,

  isViewCustomerDetailsDialogVisible: false,

  isViewCustomerDeviceInfoDialogVisible: false,

  isViewPaymentActionsDialogVisible: false,

  isViewTransactionDetailsDialogVisible: false,

  isAddBulkDialogVisible: false,

  isAddCostCenterDialogVisible: false,
  isEditCostCenterDialogVisible: false,
  isViewCostCenterStatisticsDialogVisible: false,

  isAddRentTariffDialogVisible: false,
  isViewRentTariffDialogVisible: false,

  isAddDockingStationDialogVisible: false,
  isEditDockingStationDialogVisible: false,
  isViewDockDetailsDialogVisible: false,

  isViewAddBikeDialogVisible: false,

  isViewBikeDetailsDialogVisible: false,
  isViewBikeMaintenanceDialogVisible: false,

  isEditBikeDetailsDialogVisible: false,

  isAddBikeInventoryDialogVisible: false,

  isViewAssignCostCenterDialogVisible: false,

  isViewUserDialogVisible: false,
  isEditUserDialogVisible: false,
  isAddNewUserDialogVisible: false,

  isViewTicketDialogVisible: false,

  isViewCustomerTicketsDialogVisible: false,

  isViewRideDetailsDialogVisible: false,

  isViewPasswordValidationDelete: false,

  isViewPasswordValidationUpdate:false,

  isSwitchCostCenterDialogVisible: false,

  isSetLogsSizeDialogVisible: false,

  isEditConfSettingsVisible: false,

  isForceStopDialogVisible: false,

  isViewGroupridesDetailsDialogVisible: false,

  isQrCodeVisible: false,

  isUpdateCustomerDialogVisible: false,

  isViewTrackerDialogVisible: false,
  
  isUploadFirmwareDialogVisible: false,

  isExportBikesMaintenancesDialogVisible: false,

  isUpdateBikesMaintenancesDialogVisible: false,

  isViewTrackerOnMapDialogVisible: false
};

export const mutations = {
  showViewPasswordValidationDeleteDialog() {
    state.isViewPasswordValidationDelete = true;
  },
  hideViewPasswordValidationDeleteDialog() {
    state.isViewPasswordValidationDelete = false;
  },

  showViewPasswordValidationUpdateDialog() {
    state.isViewPasswordValidationUpdate = true;
  },
  hideViewPasswordValidationUpdateDialog() {
    state.isViewPasswordValidationUpdate = false;
  },

  showStartBikeMaintenanceDialog() {
    state.isViewStartBikeMaintenanceVisible = true;
  },
  hideStartBikeMaintenanceDialog() {
    state.isViewStartBikeMaintenanceVisible = false;
  },

  showCustomerDetailsDialog() {
    state.isViewCustomerDetailsDialogVisible = true;
  },
  hideCustomerDetailsDialog() {
    state.isViewCustomerDetailsDialogVisible = false;
  },

  showCustomerDeviceInfoDialog(){
    state.isViewCustomerDeviceInfoDialogVisible = true;
  },
  hideCustomerDeviceInfoDialog(){
    state.isViewCustomerDeviceInfoDialogVisible = false;
  },

  showViewPaymentActionDialog() {
    state.isViewPaymentActionsDialogVisible = true;
  },
  hideViewPaymentActionDialog() {
    state.isViewPaymentActionsDialogVisible = false;
  },

  showViewTransactionDetailsDialog() {
    state.isViewTransactionDetailsDialogVisible = true;
  },
  hideViewTransactionDetailsDialog() {
    state.isViewTransactionDetailsDialogVisible = false;
  },

  showViewBikeMaintenanceDialog() {
    state.isViewBikeMaintenanceDialogVisible = true;
  },
  hideViewBikeMaintenanceDialog() {
    state.isViewBikeMaintenanceDialogVisible = false;
  },

  showAddBikeDialog() {
    state.isViewAddBikeDialogVisible = true;
  },
  hideAddBikeDialog() {
    state.isViewAddBikeDialogVisible = false;
  },

  showExportBikesMaintenancesDialog() {
    state.isExportBikesMaintenancesDialogVisible = true;
  },

  hideExportBikesMaintenancesDialog() {
    state.isExportBikesMaintenancesDialogVisible = false;
  },

  showUpdateBikesMaintenancesDialog() {
    state.isUpdateBikesMaintenancesDialogVisible = true;
  },

  hideUpdateBikesMaintenancesDialog() {
    state.isUpdateBikesMaintenancesDialogVisible = false;
  },

  showAddBulkDialog() {
    state.isAddBulkDialogVisible = true;
  },
  hideAddBulkDialog() {
    state.isAddBulkDialogVisible = false;
  },

  showViewAssignCostCenterDialog() {
    state.isViewAssignCostCenterDialogVisible = true;
  },
  hideViewAssignCostCenterDialog() {
    state.isViewAssignCostCenterDialogVisible = false;
  },

  showAddBikeInventoryDialog() {
    state.isAddBikeInventoryDialogVisible = true;
  },
  hideAddBikeInventoryDialog() {
    state.isAddBikeInventoryDialogVisible = false;
  },

  showViewRideDetailsDialog() {
    state.isViewRideDetailsDialogVisible = true;
  },
  hideViewRideDetailsDialog() {
    state.isViewRideDetailsDialogVisible = false;
  },

  showViewCustomerTickets() {
    state.isViewCustomerTicketsDialogVisible = true;
  },
  hideViewCustomerTickets() {
    state.isViewCustomerTicketsDialogVisible = false;
  },

  showViewTicketDetailsDialog() {
    state.isViewTicketDialogVisible = true;
  },
  hideViewTicketDetailsDialog() {
    state.isViewTicketDialogVisible = false;
  },

  showAddCostCenterDialog() {
    state.isAddCostCenterDialogVisible = true;
  },
  hideAddCostCenterDialog() {
    state.isAddCostCenterDialogVisible = false;
  },

  showEditCostCenterDialog() {
    state.isEditCostCenterDialogVisible = true;
  },
  hideEditCostCenterDialog() {
    state.isEditCostCenterDialogVisible = false;
  },

  showViewCostCenterStatisticsDialog() {
    state.isViewCostCenterStatisticsDialogVisible = true;
  },

  hideViewCostCenterStatisticsDialog(){
    state.isViewCostCenterStatisticsDialogVisible = false;
  },

  showAddRentTariffDialog() {
    state.isAddRentTariffDialogVisible = true;
  },
  hideAddRentTariffDialog() {
    state.isAddRentTariffDialogVisible = false;
  },

  showViewRentTariffDialog() {
    state.isViewRentTariffDialogVisible = true;
  },
  hideViewRentTariffDialog() {
    state.isViewRentTariffDialogVisible = false;
  },

  showAddDockingStationDialog() {
    state.isAddDockingStationDialogVisible = true;
  },
  hideAddDockingStationDialog() {
    state.isAddDockingStationDialogVisible = false;
  },

  showEditDockingStationDialog() {
    state.isEditDockingStationDialogVisible = true;
  },
  hideEditDockingStationDialog() {
    state.isEditDockingStationDialogVisible = false;
  },

  showViewDockDetailsDialog() {
    state.isViewDockDetailsDialogVisible = true;
  },
  hideViewDockDetailsDialog() {
    state.isViewDockDetailsDialogVisible = false;
  },

  showViewBikeDetailsDialog() {
    state.isViewBikeDetailsDialogVisible = true;
  },
  hideViewBikeDetailsDialog() {
    state.isViewBikeDetailsDialogVisible = false;
  },

  showViewTrackerDialog(){
    state.isViewTrackerDialogVisible = true;
  },

  hideViewTrackerDialog(){
    state.isViewTrackerDialogVisible = false;
  },

  showViewTrackerOnMapDialog(){
    state.isViewTrackerOnMapDialogVisible = true;
  },

  hideViewTrackerOnMapDialog(){
    state.isViewTrackerOnMapDialogVisible = false;
  },

  showUploadFirmwareDialog(){
    state.isUploadFirmwareDialogVisible = true;
  },

  hideUploadFirmwareDialog(){
    state.isUploadFirmwareDialogVisible = false;
  },

  showEditBikeDetailsDialog() {
    state.isEditBikeDetailsDialogVisible = true;
  },
  hideEditBikeDetailsDialog() {
    state.isEditBikeDetailsDialogVisible = false;
  },

  showViewUserDialog() {
    state.isViewUserDialogVisible = true;
  },
  hideViewUserDialog() {
    state.isViewUserDialogVisible = false;
  },

  showEditUserDialog() {
    state.isEditUserDialogVisible = true;
  },
  hideEditUserDialog() {
    state.isEditUserDialogVisible = false;
  },

  showAddNewUserDialog() {
    state.isAddNewUserDialogVisible = true;
  },
  hideAddNewUserDialog() {
    state.isAddNewUserDialogVisible = false;
  },

  showSwitchCostCenterDialog() {
    state.isSwitchCostCenterDialogVisible = true;
  },
  hideSwitchCostCenterDialog() {
    state.isSwitchCostCenterDialogVisible = false;
  },

  showSetLogsSizeDialog() {
    state.isSetLogsSizeDialogVisible = true;
  },
  hideSetLogsSizeDialog() {
    state.isSetLogsSizeDialogVisible = false;
  },

  showViewGroupridesDetailsDialog() {
    state.isViewGroupridesDetailsDialogVisible = true;
  },
  hideViewGroupridesDetailsDialog() {
    state.isViewGroupridesDetailsDialogVisible = false;
  },

  showEditConfSettingsDialog() {
    state.isEditConfSettingsVisible = true;
  },
  hideEditConfSettingsDialog() {
    state.isEditConfSettingsVisible = false;
  },

  showForceStopRideDialog() {
    state.isForceStopDialogVisible = true;
  },
  hideForceStopRideDialog() {
    state.isForceStopDialogVisible = false;
  },

  showQrCodeDialog() {
    state.isQrCodeVisible = true;
  },
  hideQrCodeDialog() {
    state.isQrCodeVisible = false;
  },

  showUpdateCustomerDialog() {
    state.isUpdateCustomerDialogVisible = true;
  },

  hideUpdateCustomerDialog() {
    state.isUpdateCustomerDialogVisible = false;
  },
};
