<template lang="pug">
  .bikes-map
    h3 {{ $t('bikes_map.bikes_map') }}
    .filters
      //- FilterDatePicker(
      //-   v-model="dateRange"
      //-   v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_date_range'])"
      //- )
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .bikes-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_keyword'])"
        )
          .status-wrapper.d-flex.align-items-center
            .switch(
              :class="{disabled: generatePausedForSeconds}"
              :loading="generatePausedForSeconds"
              @click="handleSwitchShowBike(location)")
              el-switch(
                v-model="location"
                active-color="#2564F0"
                inactive-color="#0D91F3"
                :active-text="$t('bikes_map.tracker_data')"
                :inactive-text="$t('bikes_map.mobile_data')"
              )
          el-button.el-icon-refresh-left.mr-2.cursor-pointer(
            style="font-size: 35px; margin-right: 0px;"
            :class="{disabled: generatePausedForSeconds}"
            :loading="generatePausedForSeconds"
            @click="reloadData()"
          )
      BikesMap(
        v-loading="loading"
        :bikes="bikes"
        :locationType="loadBikes"
        :dateRange="dateRange"
      )
    Pagination(
      :view="loadBikes"
      :search="search"
      :reload="reload"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetMapBikes"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import { dateRangeShortcuts, disabledFutureDates } from "@utils/dateRange";
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import BikesMap from "@components/bikes/BikesMap.vue";
import { mapActions, mapState } from "vuex";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  mixins: [filterUtils, general],

  components: {
    FilterDatePicker,
    Pagination,
    FilterCostCenterDropdown,
    BikesMap,
    SearchWithButtons
  },

  data() {
    return {
      selectedCostCenterId: "",
      search: "",
      dateRange: [new Date(new Date().setHours(0,0,0,0)), new Date()],
      location: false,
      loadBikes: 'bikesLocations',
      bikes: [],
      dynamicViewsConstants,
      loading: false,
      reload: false,
      generatePausedForSeconds: false,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions('map', ['getMapStatistics']),

    handleSwitchShowBike(location){
      if(location){
        this.loadBikes = "trackersLocations"
      }else {
        this.loadBikes = "bikesLocations"
      }
    },

    handleGetMapBikes(data) {
      this.bikes = data;
    },

    handleLoading(loading) {
      this.loading = loading;
      if(!loading) {
        setTimeout(() => this.generatePausedForSeconds = loading, 5000);
      }else {      
        this.generatePausedForSeconds = loading
      }
      this.reload = false;
    },

    reloadData(){
      this.reload = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.docking-stations-chart {
  align-items: center;
}

.bikes-map {
  .custom-legend {
    flex-direction: column;
    min-width: 130px;

    img {
      height: 20px;
    }

    span {
      font-size: 14px;
    }
  }

  .statistics {
    margin: 24px 0;

    .statistic {
      align-items: center;
      width: 244px;
      padding: 18px 25px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-right: 24px;
    }
  }
}
</style>
