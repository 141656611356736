<template lang="pug">
  .info-box-statistic(
    :class="{'full-width': fullWidth, 'fit-content': this.$slots.content}"
    :style="{'justify-content': justifyContent}"
  )
    .info-box-wrapper.d-flex.justify-space-between.w-100
      div.d-flex.align-items-center
        img(
          v-if="icon_name"
          :src="require('@assets/_shared/'+ icon_name +'.svg')"
        )
        .statistics-wrapper.ml-3
          span.mb-2.text-black(:class="icon_name ? 'statistic-header-small' : 'statistic-header-bold'") {{ name }}
          .tooltip-container(v-if="showTooltip")
            span.tooltiptext.break-word(v-html="formatCustomerErrorsText")
            span.tooltip-trigger
              Tooltip(:tooltipText="formatCustomerErrorsText")
          // TODO: handle dinamically the valute
          br
          span.transaction-tariff-ammount(v-if="value && !isMoneyStatistic") {{ value }}
          span.transaction-tariff-ammount(v-if="value && isMoneyStatistic") {{ $n(value, 'currency') }}
          span(v-if="!value") -
      slot(name="content")
</template>

<script>
import Tooltip from '@components/_shared/Tooltip';
export default {
  name: "Statistic",

  components: { 
    Tooltip,
  },


  props: {
    name: {
      type: String,
    },
    value: {
      type: Number | String,
    },
    icon_name: {
      type: String,
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isMoneyStatistic: {
      type: Boolean,
    },
    justifyContent: {
      type: String,
      default: 'center'
    },
    showTooltip: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    formatCustomerErrorsText(){
      this.customerErrorsText = "Media câștig per călătorie rezultă din nr. total de încasări împărțit la nr. de călătorii care au tranzacții cu succes.";
      return this.customerErrorsText;
    }
  },

};
</script>

<style lang="scss" scoped>
@import "@variables";

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 3px rgb(238, 238, 238);
  // border-style: solid;
  // border: 1px solid grey;
  padding: 10px;
  padding-left: 15px;
  position: absolute;
  z-index: 100;
  bottom: 110%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
  text-align: left;
  // font-family: "AvenirNextW10Medium", sans-serif;
  // font-family: Montserat, sans-serif;
  font-family: "AvenirNextW10Medium", sans-serif;
}

.tooltip-container:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -9px;
  border-width: 7px;
  border-style: solid;
  border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.statistic-header-small {
  opacity: 0.5;
  color: #061058;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.77px;
  line-height: 22px;
}

.statistic-header-bold {
  color: $dark-blue;
  font-size: 20px !important;
  font-weight: 600;
  letter-spacing: -0.63px;
  line-height: 27px;
  // font-family: $avenir-next-bold;
  font-family: Montserat, sans-serif;
  
}

.info-box-statistic {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 200px;
  background: $white;
  border-radius: 20px;

  margin: 0 20px;
}

.full-width {
  width: 100% !important;
}

.fit-content {
  width: fit-content !important;
}

.text-black {
  color: black !important;
  opacity: 0.9;
}
</style>
