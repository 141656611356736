<template lang="pug">
  el-form(
    :model="form"
    ref="EditProfileSettings"
    :rules="profileDataFormRules"
    @submit.native.prevent
  )
    el-row.mt-4.responsive(:gutter="20")
      el-col(:span="6")
        el-form-item(
          prop="first_name"
          :label="$t('label.first_name')"
        )
          el-input(
            v-model="form.first_name"
            name="name"
          )
      el-col(:span="6")
        el-form-item(
          prop="last_name"
          :label="$t('label.last_name')"
        )
          el-input(
            v-model="form.last_name"
            name="last_name"
          )
      el-col(:span="6")
        el-form-item(
          prop="phone_number"
          :label="$t('common.phone_number')"
        )
          el-input(
            v-model="form.phone_number"
            name="phone_number"
          )
    el-row.responsive
      el-col(:span="6")
        el-form-item.profile-picture-form(:label="$t('label.profile_picture')")
          el-upload(
            :action='this.imageFile ? this.uploadPictureCard(this.imageFile) : "undefined"'
            list-type='picture-card'
            :show-file-list="false"
            :on-change='handlePictureCardPreview'
            name="profileImage"
            :multiple="false"
          )
            img.avatar(
              v-if="this.form.profileImage"
              :src="this.form.profileImage"
              style='width: 100%; height: 100%'
            )
    el-button.dark-blue-btn.mt-4.settings-btn(
      @click="handleEditEmail"
      :loading="loadingChangeEmail"
    ) {{ $t('settings.change_email') }}
    el-button.dark-blue-btn.mt-4.settings-btn(
      @click="handleUpdateProfile('EditProfileSettings')"
      :loading="loadingUpdateProfile"
    ) {{ $t('settings.update_profile_settings') }}
</template>

<script>
import { mapActions, mapState } from "vuex";
import { settingsViewValidations } from "@/src/utils/formValidations/settingsViewValidations";
import { customValidations } from "@utils/customValidators";

export default {
  name: "ProfileDataForm",
  mixins: [settingsViewValidations, customValidations],

  data() {
    return {
      imageFile: null,

      form: {
        first_name: null,
        last_name: null,
        phone_number: null,
        profile_image: null,
      },

      loadingChangeEmail: false,
      loadingUpdateProfile: false,
    };
  },

  computed: {
    ...mapState("auth", ["access_token", "refresh_token", "user"]),
  },

  watch: {
    user() {
      this.initForm();
      this.getUserAvatar();
    },
  },

  mounted() {
    this.initForm();
    this.getUserAvatar();
  },

  methods: {
    ...mapActions({
      generateChangeEmailLink: "user/generateChangeEmailLink",
      updateProfile: "user/updateProfile",
      sessionUser: "auth/sessionUser",
      uploadPictureCard: "user/uploadPictureCard"
    }),

    initForm() {
      if (!this.user) return false;
      const { email, first_name, last_name, phone_number } = this.user;

      this.email = email;
      this.form.first_name = first_name;
      this.form.last_name = last_name;
      this.form.phone_number = phone_number;
    },

    handlePictureCardPreview(file) {
      this.imageFile = file.raw;
    },

    getUserAvatar() {
      this.form.profileImage = this.user.image_url
      // TODO: handle this when server is ready
      // if (this.user != null) {
      //   usersCollection.doc(this.user.uid)
      //       .get()
      //       .then((doc) => {
      //         this.form.profileImage = doc.data().profileImage;
      //       });
      // }
    },

    handleEditEmail() {
      this.loadingChangeEmail = true;

      this.generateChangeEmailLink().finally(() => {
        this.loadingChangeEmail = false;
      });
    },

    handleUpdateProfile(formName) {
      this.loadingUpdateProfile = true;

      const form = this.$refs[formName];
      form.validate(async (valid) => {
        if (valid) {
          const payload = { ...this.form };

          this.updateProfile(payload)
            .then(async () => {
              await this.sessionUser({
                access_token: $cookies.get("access_token"),
                refresh_token: $cookies.get("refresh_token")
              });
            })
            .finally(() => {
              this.loadingUpdateProfile = false;
            });
        } else {
          this.loadingUpdateProfile = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .responsive {
    display: flex;
    flex-direction: column !important;

    .el-col {
      width: 100%;
    }
  }
}
</style>