<template lang="pug">
  .docking-station-details-modal
    el-table(
      :data="bays"
      v-loading="loading"
      :gutter="0"
      center
      height="290"
      style="width: 100%;"
    )
      el-table-column(
        prop="number"
        :label="$t('common.bay_number')"
        width="100"
      )        
        template(slot-scope='scope')
          span.bay_number {{ scope.row.number }}
      el-table-column(
        prop="status"
        :label="$t('common.bay_status')"
      )
        template(slot-scope='scope')
          span {{ scope.row.status }}
      el-table-column(
        prop="bike.serial_number"
        :label="$t('common.bike_number')"
      )
        template(slot-scope='scope')
          span(v-if="scope.row.bike && scope.row.bike.serial_number")
            span(v-if="userIsAdmin()")
              span.text-underline.cursor-pointer(
                v-if="!scope.row.foreign_bike"
                @click="handleViewBikeDetails(scope.row.bike, scope.row.foreign_bike)"
              ) {{ scope.row.bike.serial_number + `(${scope.row.bike.cost_center_name.name})` }}
              span.text-underline.cursor-pointer(
                v-if="scope.row.foreign_bike"
                @click="handleViewBikeDetails(scope.row.bike, scope.row.foreign_bike)"
              ) {{ scope.row.bike.serial_number }}
            span(v-else)
              span.text-underline.cursor-pointer(
                v-if="scope.row.foreign_bike"
                @click="handleViewBikeDetails(scope.row.bike, scope.row.foreign_bike)"
              ) {{ scope.row.bike.serial_number }}
              span(
                v-if="!scope.row.foreign_bike"
                :class="{ 'disabled': true }"
              ) {{ scope.row.bike.serial_number + `(${scope.row.bike.cost_center_name.name})` }}
          span(v-else) -
      el-table-column(
        prop="bike.serial_number"
        :label="$t('common.tracker')"
      )
        template(slot-scope='scope')
          span(v-if="scope.row.bike && scope.row.bike.tracker_name")
            span(v-if="userIsAdmin()")
              span.text-underline.cursor-pointer(
                @click="handleOpenViewTrackerModal(scope.row.bike.tracker, scope.row.foreign_bike)"
              ) {{ scope.row.bike.tracker_name.name }}
            span(v-else)
              span.text-underline.cursor-pointer(
                v-if="scope.row.foreign_bike && showItemDynamically(user, dynamicViewsConstants.resource_names.TRACKER, ['show'])"
                @click="handleOpenViewTrackerModal(scope.row.bike.tracker, scope.row.foreign_bike)"
                ) {{ scope.row.bike.tracker_name.name }}
              span(
                v-if="scope.row.foreign_bike && !showItemDynamically(user, dynamicViewsConstants.resource_names.TRACKER, ['show'])"
                :class="{ 'disabled': true }"
                ) {{ scope.row.bike.tracker_name.name }}
              span(
                v-if="!scope.row.foreign_bike"
                :class="{ 'disabled': true }"
                ) {{ scope.row.bike.tracker_name.name }} 
          span(v-else) -
      el-table-column(
        prop="bike.status_object.label"
        :label="$t('common.bike_status')"
      )
        template(slot-scope='scope')
          span(v-if="scope.row.bike && scope.row.bike.status_object.label") {{ scope.row.bike.status_object.label }}
          span(v-else) -
      el-table-column(
        prop="qr_code"
        :label="$t('common.qr_code')"
      )
        template(v-slot='scope')
          el-button.dark-blue-btn(
            @click="handleQrCodeClick(scope.row.qr_code)"
          ) {{ $t('common.see_qr_code') }}
</template>

<script>
import { bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "BaysDetails",
  mixins: [filterUtils, general],

  props: {
    bays: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      bikesStatuses,
      inBetween,
      dynamicViewsConstants,
      trackerData: {
        latitude: null,
        longitude: null,
        reported_at: null,
        created_at: null
      },
      isSelectedBikeDisabled: true

    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
    ...mapState('dockingStation', ['dockingStation']),
  },

  methods: {
    ...mapActions("bike", ["getBike", "startMaintenance"]),
    ...mapActions("tracker", ["getTracker"]),
    ...mapMutations("tracker", ["setTracker"]),

    ...mapMutations("dialog", [
      "showViewBikeDetailsDialog",
      "showViewTrackerDialog",
      "showViewRideDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "hideViewBikeDetailsDialog",
      "showQrCodeDialog"
    ]),

        ...mapMutations('dockingStation', ['setQrCode']),
    ...mapMutations("ride", ["setRide"]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    async handleOpenViewTrackerModal(tracker, foreign_bike) {
      // if (this.userIsAdmin() ? false : foreign_bike) {
        const response = await this.getTracker(tracker.id)
        this.setTracker(response.data);
        this.showViewTrackerDialog();
      // }
    },

    async handleViewBikeDetails(bike, foreign_bike) {
      // if (this.userIsAdmin() ? false : foreign_bike) {
        const response = await this.getBike(bike.id);
        this.showViewBikeDetailsDialog();
        this.setBike(response);
      // }
    },

    handleQrCodeClick(qrCode){
      this.setQrCode(qrCode);
      this.showQrCodeDialog();
    },

    userIsAdmin() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },
};
</script>

<style scoped>
.disabled {
  cursor: not-allowed;
  color: grey; /* Adjust this color to indicate the disabled state */
  text-decoration: none; /* Remove underline if desired */
}
</style>
