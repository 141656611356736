import axios from "axios";
import Jsona from "jsona";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

export const actions = {
  async databaseBackup({ commit }) {
    try {
      let response = await axios.post("/v1/database_backup");
      messageUtils.showSuccess(i18n.t("dialogs.database.database_backup"));
      return { data: response.data, headers: response.headers}
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  }
};