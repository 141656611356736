<template lang="pug">
  .edit-cost-center
    .rides-statistics(v-loading="loadingStatistics")
    .filters
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    br
    .chart
      h4 {{ this.$t("cost_centers.statistics.total_emails") + "/ " + this.$t("cost_centers.statistics.total_emails_by_year") + ": " + totalEmails + "/ " + totalEmailsByYear}}
      apexchart(
        type="area"
        :height="280"
        :weight="230"
        :options="chartOptionsEmails"
        :series="chartSeriesEmails"
      )
    br
    .chart
      h4 {{ this.$t("cost_centers.statistics.total_sms")+ "/ " + this.$t("cost_centers.statistics.total_sms_by_year") + ": " + totalSms + "/ " + totalSmsByYear}}
      apexchart(
        type="area"
        :height="280"
        :weight="230"
        :options="chartOptionsSms"
        :series="chartSeriesSms"
      )
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";

import { mapActions, mapMutations, mapState } from "vuex";
import { customValidations } from "@utils/customValidators.js";
import { CostCentersViewValidations } from "@utils/formValidations/costCentersViewValidations";
import _ from "lodash";
import { currencyOptions } from "@utils/generalUtils";
import Vue from "vue";

export default {
  name: "ViewCustomersCommunications",
  mixins: [CostCentersViewValidations, customValidations, currencyOptions],

  components: {
    FilterCostCenterDropdown,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      selectedCostCenterId: "",
      loadingStatistics: false,
      statistics: {
        total_emails_by_month: {},
        total_sms_by_month: {},
        total_emails: 0,
        total_sms: 0,
        total_emails_by_year: 0,
        total_sms_by_year: 0,
      },
      chartOptionsEmails: {
        chart: {
          height: 200,
          type: 'area',
        },
        colors: [ "#407eca"],
        legend: {
          horizontalAlign: 'left'
        },
        xaxis: {
          categories: [],
        },
        
        stroke: {
          curve: 'smooth',
        },
      },
      chartSeriesEmails: [],
      chartOptionsSms: {
        chart: {
          height: 200,
          type: 'area',
        },
        colors: [ "#6bcf90"],
        xaxis: {
          categories: [],
        },
        stroke: {
          curve: 'smooth',
        },
      },
      chartSeriesSms: [],

    };
  },

  computed: {
    totalEmails() {
      return this.statistics.total_emails;
    },
    totalSms() {
      return this.statistics.total_sms;
    },
    totalEmailsByYear() {
      return this.statistics.total_emails_by_year;
    },
    totalSmsByYear() {
      return this.statistics.total_sms_by_year;
    },
  },

  watch: {
    async selectedCostCenterId() {
      await this.handleGetCostCentersStatistics(this.selectedCostCenterId);
    },
  },

  async created() {
    await this.handleGetCostCentersStatistics("");
  },

  methods: {
    ...mapActions("costCenter", ["getCostCentersCommunicationsStatistics"]),

    async handleGetCostCentersStatistics(costCenterId) {
      this.loadingStatistics = true;
      const params = costCenterId ? `?cost_center_id=${costCenterId}` : "";

      try {
        const response = await this.getCostCentersCommunicationsStatistics(params);
        this.statistics = response.data;

        this.processEmailsChartData();
        this.processSmsChartData();
      } catch (error) {
        console.error("Error fetching statistics:", error);
      } finally {
        this.loadingStatistics = false;
      }
    },

    processEmailsChartData() {
      const months = Object.keys(this.statistics.total_emails_by_month).map(month => this.formatMonth(month));

      this.chartOptionsEmails = {
        ...this.chartOptionsEmails,
        ...{
          xaxis: {
            categories: months, 
          },
        }
      }
      this.chartSeriesEmails = [
        {
          name: 'Emails',
          data: Object.values(this.statistics.total_emails_by_month),
        },
      ];
    },

    processSmsChartData() {
      const months = Object.keys(this.statistics.total_sms_by_month).map(month => this.formatMonth(month));

      this.chartOptionsSms = {
        ...this.chartOptionsSms,
        ...{
          xaxis: {
            categories: months, 
          },
        }
      }
      this.chartSeriesSms = [
        {
          name: 'SMS',
          data: Object.values(this.statistics.total_sms_by_month),
        },
      ];
    },

    formatMonth(monthString) {
      const date = new Date(monthString);
      return date.toLocaleString('default', { month: 'short', year: 'numeric' });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>