<template lang="pug">
  .tickets-settings-view
    h3 {{ $t('tickets_settings.tickets_settings') }}
    .tickets-settings-wrapper.my-4
      TicketsSettingsTable(
        v-loading="loadingTable"
        :ticketsSettings="ticketsSettings"
      )
    Pagination(
      :view="'ticket_settings'"
      :search="search"
      :dateRange="dateRange"
      @viewData="handleGetTicketSettings"
      @handleTableLoading="handleLoading"
    )  
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { filterUtils, } from "@utils/filterUtils";
import TicketsSettingsTable from "@components/settings/TicketsSettingsTable";
import Pagination from "@components/_shared/Pagination";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [filterUtils, general],

  components: { TicketsSettingsTable, Pagination },

  data() {
    return {
      dateRange: [],
      ticketsSettings: [],
      search: "",
      loadingTable: false,
      loadingStatistics: false,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState('configTicketsSettings', ['shouldFetchTicketsSettings', 'ticketsSetting']),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "dialogData",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  watch: {
    async shouldFetchTicketsSettings() {
      if (this.shouldFetchTicketsSettings) {
        await this.getTechnicalStats()
        this.setShouldFetchStatistics(false)
      }
    }
  },

  methods: {

    ...mapActions('configTicketsSettings', ['getAllConfigTicketSettings' ,'updateConfSettings']),

    ...mapMutations("configTicketsSettings", ["setShouldFetchConfSettings"]),

    handleGetTicketSettings(data) {
      this.ticketsSettings = data;
    },

    handleLoading(loading) {
      this.loadingTable = loading;
    },
  }
};
</script>
