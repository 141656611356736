<template lang="pug">
  .ticket-view
    h3 {{ $t('admin_commands.admin_commands') }}
    el-row.d-flex.mt-3(:gutter="24")
      el-button.dark-blue-btn.ml-2(
        v-loading="loading"
        @click="backup"
      ) {{ $t('admin_commands.database_backup') }}
    h3.mt-5 {{ $t('admin_commands.firmware_upload') }}
      el-form(
        :model="form"
        ref="DatabaseDownloadForm"
        @submit.native.prevent
      )
        el-row.d-flex.mt-3(
          :gutter="24"
        )
        .upload-wrapper.d-flex
          label(
            for="file-upload"
          )
          .center
            img.cursor-pointer.pr-2(src="@src/assets/inventory/upload.svg")
            span.file-name {{ uploadFileName }}

            input.pl-5(
              @change="selectFile"
              id="file-upload"
              type="file"
            )
        el-row
          el-button.pop-up-btn.mt-4(
            :class="form.file ? 'dark-blue-btn' : 'gray-btn'"
            @click="handleAddBulk('DatabaseDownloadForm')"
            :loading="loading"
          ) {{ $t('common.add') }}
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "AdminCommands",

  data() {
    return {
      form: {
        file: undefined,
      },
      uploadFileName: this.$t("dialogs.inventory.upload_file"),
      loading: false,
    };
  },

  methods: {
    ...mapActions("database", ["databaseBackup"]),

    ...mapActions("firmware", ["firmwareUpload"]),

    async backup() {
      this.loading = true;
      await this.databaseBackup();
      this.loading = false;
    },

    selectFile(e) {
      if (e.target.files[0] === undefined) {
        this.form.file = undefined;
        this.uploadFileName = this.$t("dialogs.inventory.upload_file");
        return;
      }

      this.form.file = e.target.files[0];
      const file = document.querySelector("#file-upload");
      this.uploadFileName = file.value
        .split("\\")
        [file.value.split("\\").length - 1].toString();
    },

    isFileTypeValid() {
      let allowedTypes = /(\.bin)$/i;
      return allowedTypes.exec(this.form.file.name);
    },

    handleAddBulk(formName) {
      this.loading = true;
      const form = this.$refs[formName];

      form.validate(async (valid) => {
        if (valid) {
          if (this.form.file !== undefined) {
            if (this.isFileTypeValid()) {
              const formData = new FormData();
              formData.append("file", this.form.file, this.form.file.name);
              formData.append("comment", this.form.file.name);
              this.firmwareUpload(formData).finally(() => {
                this.loading = false;
              });
            } else {
              this.$message({
                type: "warning",
                message: this.$t("dialogs.inventory.invalid_filename"),
              });
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
