<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='ticketsSettings'
  )
    el-table-column(
      prop='object_hash.label'
      :label="$t('dialogs.tickets.problem_type')"
    )
    el-table-column(
      :label="$t('label.role')"
      prop="object_hash.roles"
    )
      template(v-slot='scope')
        el-select(
          v-model="role_ids[scope.row.id]"
          :placeholder="$t('common.select_role')"
          multiple
        )
          el-option(
            v-for='role in roles'
            :key='role'
            :label='role.name'
            :value='role.id'
          )
    el-table-column(
      :label="$t('label.user')"
      prop="object_hash.users"
    )
      template(v-slot='scope')
        el-select(
          v-model="user_ids[scope.row.id]"
          multiple
          filterable
          allow-create
          default-first-option
        )
          el-option.w-100(
              v-for='(user,index) in users'
              :key='index'
              :label='user.email'
              :value='user.id'
          )
    el-table-column(
      :span="0"
    width="100"
      prop='actions'
      :label="$t('label.actions')"
    )
      template(v-slot='scope')
        img.cursor-pointer.ml-1(
          src="@assets/actions/edit.svg"
          @click="handleAssignUsersRoles(scope.row.id)"
        )
</template>

<script>

import { general } from "@src/services/dynamicViews/general";
import { mapActions, mapMutations } from "vuex";
export default {
  name: "TicketsSettingsTable",
  mixins: [general],

  props: {
    ticketsSettings: {
      type: Array,
      required: true,
      default: []
    },

    userDetails: {
      type: Object,
      required: false
    }
  },

  data() {
    return {
      role_ids: [],
      user_ids: [],
      newRoleSelect: false,
      loading: false,
      roles: [],
      users: []
    };
  },

  async created() {
    await this.handleGetRoles();
    await this.handleGetUsers();
    this.handlePrefillForm();
  },

  computed: {
    roleName(){
      let roleName = this.roles.filter(role => this.form.role_ids
          .includes(role.id))
          .map(role => role.name);

      return roleName.toString();
    },
  },

  methods: {
    ...mapMutations('configTicketSettings', ['setConfigSetting']),
    ...mapActions("role", [
      "getRoles",
      "getRole",
      "getAllPermissionsByRoleIds",
    ]),

    ...mapActions("ticket", ["assignUsers", "assignRoles"]),
    ...mapActions("user", ["getAllUsers"]),

    async handleGetRoles() {
      this.roles = await this.getRoles();
    },

    async handleGetUsers() {
      this.users = await this.getAllUsers();
    },

    async handleAssignUsersRoles(ticketId){
      let params = {
        id: ticketId,
        user_ids: this.user_ids[ticketId],
        role_ids: this.role_ids[ticketId]
      }
      await this.assignUsers(params);
      await this.assignRoles(params);

    },

    handlePrefillForm() {
        this.ticketsSettings.forEach(ticket => {
          this.user_ids[ticket.id] = ticket.users.map(user => user.id);
          this.role_ids[ticket.id] = ticket.roles.map(role => role.id);
        })
    },
  },

}
</script>

<style lang="scss">
.el-select {
  width: 100%;
}
</style>