<template lang="pug">
  .logs-view
    h3 {{ $t('logs.logs') }}
    .statistics.d-flex.justify-space-around(v-loading="loadingStatistics")
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('logs.size')"
        :value="logsSettings.space_allocated"
        :icon_name="'expanding-blue'"
        :fullWidth="true"
        :justifyContent="'left'"
      )
        template(v-slot:content)
          el-button.dark-blue-btn.flat-btn.align-self-end.ml-5(@click="showSetLogsSizeDialog") {{ $t('logs.set_logs_size') }}
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('logs.reserved_log_size')"
        :value="logsSettings.space_reserved"
        :icon_name="'expanding-orange'"
        :fullWidth="false"
        :justifyContent="'left'"
      )
      Statistic.pt-3.pb-3.pl-3.pr-3.mt-4(
        :name="$t('logs.free_log_size')"
        :value="handleComputeFreeAvailableLogsSize"
        :icon_name="'expanding-green'"
        :fullWidth="true"
        :justifyContent="'left'"
      )
        template(v-slot:content)
          span.percentage.align-self-end {{ computeAvailableLogSizePercentage }}
    .filters.mt-4
      // TODO: don't forget sort_by when server is ready
      FilterDatePicker(
        v-model="dateRange"
        v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['by_date_range'])"
      )
    .logs-wrapper
      .actions
        SearchWithButtons(v-model="search" :showSearch="true")
      el-row
        el-col(:span='24')
          LogsViewTable(
            v-loading="loadingTable"
            :logs="logs"
            @selectionChange="handleSelectionChange"
          )
    Pagination(
      :view="'logs'"
      :search="search"
      :dateRange="dateRange"
      @viewData="handleGetLogs"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import FilterDatePicker from "@components/_shared/FilterDatePicker";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";
import Statistic from "@components/_shared/Statistic";
import LogsViewTable from "@components/logs/LogsViewTable";
import { computeFreeAvailableLogsSize } from "@utils/logs";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  mixins: [filterUtils, general],

  components: {
    Pagination,
    FilterDatePicker,
    SearchWithButtons,
    Statistic,
    LogsViewTable,
  },

  data() {
    return {
      dateRange: [],
      logs: [],
      space_occupied: "",
      search: "",
      multipleSelection: [],
      loadingTable: false,
      loadingStatistics: false,
      dynamicViewsConstants,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("log", ["shouldFetchStatistics", "logsSettings"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "dialogData",
      "allowDeletion",
      "passwordConfirmation",
    ]),

    ...mapGetters("log", ["formattedLogsSettings"]),

    handleComputeFreeAvailableLogsSize() {
      if (this.logsSettings.space_reserved) {
        return (
          this.formattedLogsSettings.space_occupied_size +
          "/ " +
          computeFreeAvailableLogsSize(
            this.formattedLogsSettings.max_log_size_unit,
            this.formattedLogsSettings.insert_reserved_log_size_unit,
            this.formattedLogsSettings.insert_reserved_log_size,
            this.formattedLogsSettings.max_log_size
          )
        );
      } else {
        return "-";
      }
    },

    computeAvailableLogSizePercentage() {
      function percentage(partialValue, totalValue) {
        return (100 * partialValue) / totalValue;
      }

      if (this.logsSettings.space_occupied) {
        const availableSizePercent = percentage(
          Number(this.formattedLogsSettings.space_occupied_size),
          Number(
            computeFreeAvailableLogsSize(
              this.formattedLogsSettings.max_log_size_unit,
              this.formattedLogsSettings.insert_reserved_log_size_unit,
              this.formattedLogsSettings.insert_reserved_log_size,
              this.formattedLogsSettings.max_log_size
            ).split(" ")[0]
          )
        );

        return availableSizePercent.toFixed(3) + "% used";
      }
    },
  },

  watch: {
    allowDeletion() {
      this.handleDeleteLog();
    },

    async shouldFetchStatistics() {
      if (this.shouldFetchStatistics) {
        await this.getLogsStatistics();
        this.setShouldFetchStatistics(false);
      }
    },
  },

  async created() {
    this.handleGetStatistics();
  },

  methods: {
    computeFreeAvailableLogsSize,

    ...mapActions("log", ["getLogsStatistics", "deleteLogs"]),

    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
      "showSetLogsSizeDialog",
    ]),

    ...mapMutations("passwordValidationDelete", ["setLoading"]),

    ...mapMutations("log", ["setShouldFetchStatistics", "setShouldFetchLogs"]),

    handleGetLogs(data) {
      this.logs = data;
    },

    handleLoading(loading) {
      this.loadingTable = loading;
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleDeleteLog() {
      if (this.allowDeletion) {
        let selectionIds = this.multipleSelection.map((selection) =>
          Number(selection.id)
        );
        let computedQueriesUrl = {
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteLogs({ computedQueriesUrl, selectionIds })
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
            this.setShouldFetchLogs(true);
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    async handleGetStatistics() {
      this.loadingStatistics = true;
      await this.getLogsStatistics();
      this.loadingStatistics = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.percentage {
  color: $red;
}
</style>
