import axios from "axios";
import messageUtils from "@utils/messageUtils";

import Jsona from "jsona";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  shouldFetchStatistics: false,
  shouldFetchLogs: false,
  logsSettings: {},
};

export const getters = {
  formattedLogsSettings(state) {
    return {
      max_log_size_unit: state.logsSettings.space_allocated.split(" ")[1],
      insert_reserved_log_size_unit:
        state.logsSettings.space_reserved.split(" ")[1],
      insert_reserved_log_size: Number(
        state.logsSettings.space_reserved.split(" ")[0]
      ),
      max_log_size: Number(state.logsSettings.space_allocated.split(" ")[0]),
      space_occupied_size: Number(
        state.logsSettings.space_occupied.split(" ")[0]
      ),
    };
  },
};

export const actions = {
  async getLogs({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/history_logs${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getLogsStatistics({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/history_logs/show_statistics`);
      return commit("setLogsSettings", response.data.statistics);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateLogsSettings({ commit }, payload) {
    try {
      await axios.patch(`/v1/history_logs_preferences/update_current`, {
        ...payload,
      });

      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteLogs({ commit }, { computedQueriesUrl, selectionIds }) {
    try {
      await axios.delete(`/v1/history_logs/bulk_destroy`, {
        password_confirmation: computedQueriesUrl.password_confirmation,
        data: {
          history_log_ids: [...selectionIds],
        },
      });
      return messageUtils.showSuccess(i18n.t("common.success"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },
};

export const mutations = {
  setShouldFetchStatistics(state, value) {
    state.shouldFetchStatistics = value;
  },

  setShouldFetchLogs(state, value) {
    state.shouldFetchLogs = value;
  },

  setLogsSettings(state, payload) {
    state.logsSettings = payload;
  },
};
