<template lang="pug">
  .users-view
    h3 {{ $t('users.users') }}
    .filters
      FilterRoleDropdown(v-model="selectedRoleName")
      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .users-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
          :showSearch="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['by_keyword'])"
        )
          el-button.dark-blue-btn(
            @click="goToWIthId('users/add_user', '')"
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.USER, ['create'])"
          )
            h4 {{ $t('common.add') }}
      el-row
        el-col(:span='24')
          UsersViewTable(
            v-loading="loading"
            :users="users"
          )
    Pagination(
      :view="'users'"
      :search="search"
      @viewData="handleGetUsers"
      @handleTableLoading="handleLoading"
      :costCenterId="selectedCostCenterId"
      :roleName="selectedRoleName"
    )
</template>

<script>
import Pagination from "@components/_shared/Pagination";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import { mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";
import UsersViewTable from "@components/users/UsersViewTable";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import FilterRoleDropdown from "@components/_shared/FilterRoleDropdown";

export default {
  components: { Pagination, SearchWithButtons, UsersViewTable, FilterCostCenterDropdown, FilterRoleDropdown},
  mixins: [routerUtils, general],

  data() {
    return {
      activeName: "",
      users: [],
      search: "",
      loading: false,
      dynamicViewsConstants,
      selectedCostCenterId: "",
      selectedRoleName: ""
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapMutations("user", ["setSelectedTabRole"]),

    handleGetUsers(data) {
      this.setSelectedTabRole(this.activeName);
      this.users = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
