import axios from "axios";
import messageUtils from "@utils/messageUtils";
import i18n from "@i18n";

import Jsona from "jsona";

const dataFormatter = new Jsona();

export const state = {
  shouldFetchFirmwares: false,
};

export const actions = {
  async firmwareUpload({ commit }, formData) {
    try {
      await axios.post("/v1/firmwares/upload", formData);
      return messageUtils.showSuccess(i18n.t("dialogs.firmwares.upload"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getFirmware({ commit }, id) {
    try {
      await axios.get(`/v1/firmwares/${id}`);
      return messageUtils.showSuccess(i18n.t("dialogs.firmwares.upload"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getFirmwares({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/firmwares${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  }
};

export const mutations = {
  setShouldFetchFirmwares(state, value) {
    state.shouldFetchFirmwares = value;
  },
};