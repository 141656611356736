<template lang="pug">
  .docking-station-details-modal
    el-table(
      style='width: 100%;'
      header-cell-class-name='table-header'
      cell-class-name='pl-4'
      :data="watchdog_messages"
      height="290"
    )
      el-table-column(
        width="55"
      )
        template(slot-scope='scope')
          span.pre-line {{ scope.$index + 1}}
      el-table-column(
        prop="created_at"
        :label="$t('label.date')"
        width="120"
      )
        template(slot-scope='scope')
          span {{ computeDateFormat(scope.row.created_at) }}
      el-table-column(
        prop="created_at"
        :label="$t('label.watchdog_mac')"
        width="185"
      )
        template(slot-scope='scope')
          span {{ scope.row.mac }}
      el-table-column(
        prop="message_info.power"
        :label="$t('common.power')"
      )
        template(slot-scope='scope')
          span {{ scope.row.message_info.power }}
      el-table-column(
        prop="message_info.tamper"
        :label="$t('common.tamper_state')"
      )
        template(slot-scope='scope')
          span {{ scope.row.message_info.tamper }}
      el-table-column(
        prop="message_info.temperature"
        :label="$t('common.temperature')"
      )
        template(slot-scope='scope')
          span {{ scope.row.message_info.temperature }}      
      el-table-column(
        prop="message_info.battery_voltage"
        :label="$t('common.battery_voltage')"
      )
        template(slot-scope='scope')
          span {{ scope.row.message_info.battery_voltage }}
      el-table-column(
        prop="message_info.first_fan"
        :label="$t('common.fan_in')"
      )
        template(slot-scope='scope')
          span {{ scope.row.message_info.first_fan }}
      el-table-column(
        prop="message_info.second_fan"
        :label="$t('common.fan_out')"
      )
        template(slot-scope='scope')
          span {{ scope.row.message_info.second_fan }}
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import { mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "WatchdogsDetails",
  mixins: [filterUtils, general],

  props: {
    watchdog_messages: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
  },

};
</script>
