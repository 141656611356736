<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='customers'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='first_name'
      :label="$t('label.first_name')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.first_name && scope.row.first_name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.first_name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.first_name) }}
        span(v-if="scope.row.first_name && scope.row.first_name.length <= MAX_FIELD_LENGTH") {{ scope.row.first_name }}
    el-table-column(
      prop='last_name'
      :label="$t('label.last_name')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.last_name && scope.row.last_name.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.last_name"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.last_name) }}
        span(v-if="scope.row.last_name && scope.row.last_name.length <= MAX_FIELD_LENGTH") {{ scope.row.last_name }}
    el-table-column(
      prop='email'
      :label="$t('common.email_address')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.email && scope.row.email.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.email"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.email) }}
        span(v-if="scope.row.email && scope.row.email.length <= MAX_FIELD_LENGTH") {{ scope.row.email }}
    el-table-column(
      prop='status'
      :label="$t('common.status')"
    )
      template(slot-scope='scope')
        span(v-if="(scope.row.email_confirmation == false || scope.row.phone_confirmation == false)")
          .status-wrapper(v-if="inBetween('customerStatuses', scope.row.status, 41, 60)")
            span.oval.unavailable
            span {{ usersAndCustomersStatuses[scope.row.status].message }}
          .status-wrapper(v-if="!inBetween('customerStatuses', scope.row.status, 41, 60)")
            span.oval.pending
            span {{ $t('statuses.users_and_customers.unconfirmed') }}
        span(v-else)
          .status-wrapper(v-if="inBetween('customerStatuses', scope.row.status, 1, 20)")
            span.oval.pending
            span {{ usersAndCustomersStatuses[scope.row.status].message }}
          .status-wrapper(v-if="inBetween('customerStatuses', scope.row.status, 21, 40)")
            span.oval.completed
            span {{ usersAndCustomersStatuses[scope.row.status].message }}
          .status-wrapper(v-if="inBetween('customerStatuses', scope.row.status, 41, 60)")
            span.oval.unavailable
            span {{ usersAndCustomersStatuses[scope.row.status].message }}
          .status-wrapper(v-if="inBetween('customerStatuses', scope.row.status, 61, 80)")
            span.oval.grey
            span {{ usersAndCustomersStatuses[scope.row.status].message }}
          .status-wrapper(v-if="inBetween('customerStatuses', scope.row.status, 81, 100)")
            span.oval.oval-available
            span {{ usersAndCustomersStatuses[scope.row.status].message }}
    el-table-column(
      v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['update'])"
      :label="$t('customers.restricted')"
      prop="status"
    )
      template(slot-scope='scope')
        .status-wrapper
          .switch(
            @click="switchRestrictedCustomer(scope.row)"
          )
            el-switch(
              :value="!scope.row.restricted"
              active-color="#13ce66"
              inactive-color="#ff4949"
            )
          span.status(v-if="!scope.row.restricted") {{ $t('common.available_m') }}
          span.status(v-if="scope.row.restricted") {{ $t('customers.restricted') }}
    el-table-column
      template(slot-scope="scope")
        el-checkbox.mb-2.text-sm.text-primary(
          prop="confirm_client_email"
          name="confirm_client_email"
          :label="$t('rides.confirm_customer_email')"
          :key="scope.row.id"
          :disabled="scope.row.email_confirmation"
          @input="handleConfirmCustomerEmail(scope.row.id)"
        )
        el-checkbox.mb-2.text-sm.text-primary(
          prop="confirm_client_phone"
          name="confirm_client_phone"
          :label="$t('rides.confirm_customer_phone')"
          :key="scope.row.id"
          :disabled="scope.row.phone_confirmation"
          @input="handleConfirmCustomerPhoneNumber(scope.row.id)"
        )
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-2(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['show'])"
          src="@assets/preview.svg"
          @click="goToWIthId('customers', scope.row.id)"
        )
        img.cursor-pointer.ml-2(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.CUSTOMER, ['destroy'])"
          src="@assets/actions/delete.svg"
          @click="handleDeleteCustomerDialog(scope.row)"
        )
</template>

<script>
import {
  inBetween,
  MAX_FIELD_LENGTH,
  trimTableField,
} from "@utils/filterUtils";
import { usersAndCustomersStatuses } from "@utils/statuses";
import { mapActions, mapMutations, mapState } from "vuex";
import { routerUtils } from "@utils/router";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "CustomersViewTable",
  mixins: [routerUtils, general],

  props: {
    customers: {
      type: Array,
    },
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  watch: {
    allowDeletion() {
      this.handleDeleteCustomer();
    },
  },

  data() {
    return {
      MAX_FIELD_LENGTH,
      usersAndCustomersStatuses,
      inBetween,
      trimTableField,
      dynamicViewsConstants,
    };
  },

  methods: {
    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog",
    ]),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setAllowDeletion",
      "setLoading",
      "setDataToDelete",
    ]),

    ...mapMutations("customer", ["setShouldFetchCustomers"]),

    ...mapActions("customer", [
      "deleteCustomer",
      "getCustomersStatistics",
      "updateCustomerRestricted",
      "confirmCustomerPhoneNumber",
      "confirmCustomerEmail",
    ]),

    async switchRestrictedCustomer(customer) {
      let params = {
        id: customer.id,
        status: customer.restricted ? "active" : "inactive",
      };

      this.loadingTable = true;
      this.updateCustomerRestricted(params).then(async () => {
        this.setShouldFetchCustomers(true);

        // emit event to refresh customer's view statistics
        this.$emit("emitGetCustomersStatistics");
      });
    },

    handleDeleteCustomer() {
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteCustomer(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteCustomerDialog(customer) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("customers.validations.delete_title")} ${
          customer.first_name
        } ${customer.last_name}`,
        dialogMessage: this.$t("customers.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(customer);
    },

    async handleConfirmCustomerEmail(customer){
      await this.confirmCustomerEmail(customer).then(async () => {
        this.setShouldFetchCustomers(true);
      });
    },

    async handleConfirmCustomerPhoneNumber(customer){
      await this.confirmCustomerPhoneNumber(customer).then(async () => {
        this.setShouldFetchCustomers(true);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;

  span {
    font-size: 16px;
  }

  .status {
    margin-left: 5px;
  }
}
</style>
