import axios from "axios";
import messageUtils from "@utils/messageUtils";

import Jsona from "jsona";
import i18n from "@i18n";

const dataFormatter = new Jsona();

export const state = {
  customer: null,
  deviceInfo: null,
  shouldFetchCustomers: false,
  shouldFetchCustomer: false,
  shouldFetchCustomerTickets: false,
  collapsesData: [],
  customerLoadedCostCenters: [],
  newAddCustomerData: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    user_role_cost_centers: [],
    user_cost_center_permissions: [],
  },
};

export const getters = {
  getCustomerCollapsesData: (state) => state.collapsesData,
};

export const actions = {
  async getCustomers({ commit }, payload) {
    try {
      const response = await axios.get(`/v1/customers${payload}`);
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getCustomer({ commit }, id) {
    try {
      const response = await axios.get(`/v1/customers/${id}`);
      return dataFormatter.deserialize(response.data);
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateCustomer({ commit }, payload) {
    try {
      await axios.patch(`/v1/customers/${payload.id}`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async confirmCustomerPhoneNumber({ commit }, id) {
    try {
      await axios.post(`/v1/customers/${id}/confirm_phone_number`);
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async confirmCustomerEmail({ commit }, id) {
    try {
      await axios.post(`/v1/customers/${id}/confirm_email`);
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  removeCollapseCustomerData(state, collapse) {
    let foundCollapseDataIndex = state.collapsesData.findIndex(
      (data) => data.collapse === collapse
    );
    state.collapsesData.splice(foundCollapseDataIndex, 1);
  },

  removeAllCollapseData(state) {
    state.collapsesData = [];
  },

  async getCustomerRideHistory({ commit }, payload) {
    try {
      const response = await axios.get(
        `/v1/customers/${payload.id}/show_rides${payload.params}`
      );
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async deleteCustomer({ commit }, payload) {
    try {
      await axios.delete(`/v1/customers/${payload.computedQueriesUrl}`, {
        data: { password_confirmation: payload.password_confirmation },
      });
      messageUtils.showSuccess(i18n.t("common.customer_was_deleted"));
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getCustomersStatistics({ commit }, params) {
    try {
      const response = await axios.get(
        `/v1/customers/show_customers_statistics${params}`
      );
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getCustomerTransactionsStatistics({ commit }, payload) {
    try {
      // ${payload.params}
      const response = await axios.get(
        `/v1/customers/${payload.id}/show_customer_transactions_statistics`
      );
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async updateCustomerRestricted({ commit }, payload) {
    try {
      await axios.patch(`/v1/customers/${payload.id}`, {
        ...payload,
      });
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async getCustomerTickets({ commit }, payload) {
    try {
      const response = await axios.get(
        `/v1/customers/${payload.id}/show_tickets${payload.params}`
      );
      return {
        data: dataFormatter.deserialize(response.data),
        headers: response.headers,
      };
    } catch (error) {
      messageUtils.showErrors(error.response.data.message);
      return false;
    }
  },

  async getDeviceInfo({ commit }, payload) {
    try {
      const response = await axios.get(
        `/v1/customers/${payload}/show_device_infos`
      );
      return response.data;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async assignNfcToCustomer({ commit }, payload) {
    try {
      await axios.patch(`/v1/customers/${payload.id}/assign_nfcs`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

  async unassignNfcToCustomer({ commit }, payload) {
    try {
      await axios.patch(`/v1/customers/${payload.id}/unassign_nfcs`, {
        ...payload,
      });
      messageUtils.showSuccess(i18n.t("users.updated_user"));
      return false;
    } catch (error) {
      messageUtils.showErrors(error);
      return Promise.reject(error);
    }
  },

};

export const mutations = {
  setCustomer(state, customer) {
    state.customer = customer;
  },

  setShouldFetchCustomers(state, value) {
    state.shouldFetchCustomers = value;
  },

  setShouldFetchCustomer(state, value) {
    state.shouldFetchCustomer = value;
  },

  setShouldFetchCustomerTickets(state, value) {
    state.shouldFetchCustomerTickets = value;
  },

  setDeviceInfo(state, deviceInfo) {
    state.deviceInfo = deviceInfo;
  },

  setLoadedCostCenters(state, payload) {
    state.customerLoadedCostCenters = payload;
  },

  setDisabledCostCenter(state, payload) {
    let foundCostCenterIndex = state.customerLoadedCostCenters.findIndex(
      (loadedCostCenter) => loadedCostCenter.id === payload.selectedCostCenterId
    );
    if (foundCostCenterIndex !== -1) {
      state.customerLoadedCostCenters[foundCostCenterIndex].disabled =
        payload.disabled;
    }
  },

  setNewAddCustomerDetails(state, payload) {
    state.newAddCustomerData.first_name = payload.first_name;
    state.newAddCustomerData.last_name = payload.last_name;
    state.newAddCustomerData.email = payload.email;
    state.newAddCustomerData.phone_number = payload.phone_number;
  },

  //the culprit
  setNewAddCustomerCollapseData(state, payload) {
    state.newAddCustomerData.user_role_cost_centers =
      payload.mappedCustomerRoleCostCenters;
    state.newAddCustomerData.user_cost_center_permissions =
      payload.mappedCustomerCostCenterPermissions;
  },

  setCollapsesDataCustomer(state, payload) {
    let ifExistsIndex = state.collapsesData.findIndex(
      (data) => data.collapse === payload.collapse
    );
    if (ifExistsIndex !== -1) {
      state.collapsesData[ifExistsIndex] = {
        collapse: payload.collapse,
        cost_center_id: payload.cost_center_id,
        role_ids: payload.role_ids,
        alreadyChecked: payload.alreadyChecked,
        loadedChecklists: payload.loadedChecklists,
        permissions: payload.permissions,
      };
    } else {
      state.collapsesData.push({
        collapse: payload.collapse,
        cost_center_id: payload.cost_center_id,
        role_ids: payload.role_ids,
        alreadyChecked: payload.alreadyChecked,
        loadedChecklists: payload.loadedChecklists,
        permissions: payload.permissions,
      });
    }
  },
};
