<template lang="pug">
  .cost-centers-filter(v-if="computeCostCenterIDFilter")
    span.filter-title {{ $t('common.tracker_type_filter') }}
    el-select(
      v-model='selectedTrackerType'
      filterable
      :placeholder="$t('placeholder.select_tracker_type')"
      @change="emitChangeEvent"
    )
      el-option(
        v-for='tracker_type in trackerTypes'
        :key='tracker_type.id'
        :label='tracker_type.name'
        :value="tracker_type.value !== '' ? tracker_type.id : tracker_type.value"
      )
</template>

<script>
import { mapState } from "vuex";
import { roles } from "@utils/roles";
import store from "@state/store";

export default {
  name: "FilterTrackerDropdown",

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  computed: {
    selectedTrackerType: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    ...mapState("auth", ["user"]),

    computeCostCenterIDFilter() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  },

  data() {
    return {
      trackerTypes: [],
    };
  },

  mounted() {
    if (this.computeCostCenterIDFilter) {
      this.handleGetTrackerTypes()
    }
  },

  methods: {
    handleGetTrackerTypes() {
      this.trackerTypes.unshift({
        id: "1",
        name: "All",
        value: "",
      });
      this.trackerTypes.unshift({
        id: "2",
        name: "Complete(V2)",
        value: "2",
      });
      this.trackerTypes.unshift({
        id: "3",
        name: "Without IMEI(V2)",
        value: "3",
      });
      this.trackerTypes.unshift({
        id: "4",
        name: "Complete(V1)",
        value: "4",
      });
    },

    emitChangeEvent(values) {
      this.$emit('onChange', values)
    }
  },
};
</script>
