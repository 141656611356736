<template lang="pug">
  .view-ride-details
    GoogleMap(
      @markerDragEnd="handleMarkerDragEnd"
      :dialogName="mapDialogName"
      :trackerCoordinates="this.coordinates"
    )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import GoogleMap from "@components/trackers/GoogleMap";

export default {
  name: "ViewTrackerOnMapDialog",

  components: {
    GoogleMap,
  },

  computed: {
    ...mapState("tracker", ["tracker"]),
  },

  data() {
    return {
      trackerLatestInfo: {},
      coordinates: [],
      mapDialogName: 'lastLocation',
    };
  },

  async created() {
    await this.handleTracker();
  },

  watch: {
    async tracker() {
      await this.handleTracker();
    },
  },

  methods: {
    ...mapActions("tracker", ["getTracker"]),
    ...mapMutations("map", ["setMapCoordinates"]),

    async handleMarkerDragEnd(markerPosition) {
      await this.setMapCoordinates(markerPosition);
    },

    async handleTracker(){
      await this.handleGetTrackerLatestInfo();
      await this.handlePopulateTrackerLatestInfo();
    },

    async handleGetTrackerLatestInfo(){
      const response = await this.getTracker(this.tracker.id);
      this.trackerInfo = response.data;
      if(response.data.tracker_latest_info)
        this.trackerLatestInfo = response.data.tracker_latest_info;
      else
      this.trackerLatestInfo = {};
    },

    async handlePopulateTrackerLatestInfo(){
      if(this.trackerLatestInfo && this.trackerLatestInfo.location != null){
      this.mapDialogName = 'lastLocation';
      this.coordinates = [this.trackerLatestInfo.location.latitude, this.trackerLatestInfo.location.longitude, this.trackerLatestInfo.location.added_at];
      }
    },
  },
};
</script>

