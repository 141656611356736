<template lang="pug">
  el-menu-item(
    index='/station_logs'
    v-if=`showItemDynamically(user, dynamicViewsConstants.resource_names.DOCKING_STATION, ['logs'])`
  )
    .img-wrapper
      img(src="@src/assets/sidebar/stations.svg")
    router-link.sub-menu-link(to="/station_logs")
      span  {{ $t('sidebar.station_logs') }}
</template>

<script>
import { general } from '@src/services/dynamicViews/general'
import { dynamicViewsConstants } from '@src/constants'
import { mapState } from 'vuex'

export default {
  name: 'StationLogsItem',
  mixins: [general],

  computed: {
    ...mapState('auth', ['user']),
  },

  data() {
    return {
      dynamicViewsConstants,
    }
  },
}
</script>
