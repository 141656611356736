<template lang="pug">
  .tickets-view
    h3 {{ $t('smart_cards.smart_cards') }}
    .filters
      // filter by status
      FilterStatus(
        :name="$t('common.status_filter')"
        :statuses="ticketsStatuses"
        v-model="selectedStatus"
      )

      FilterCostCenterDropdown(v-model="selectedCostCenterId")
    .tickets-wrapper
      .actions
        SearchWithButtons(
          v-model="search"
        )
      SmartCardsViewTable(
        v-loading="loading"
        :smartCards="smartCards"
        :selectedStatus="selectedStatus"
      )
    Pagination(
      v-if="selectedStatus && selectedStatus !== ''"
      :view="'smart_cards'"
      :activeName="selectedStatus"
      :search="search"
      :costCenterId="selectedCostCenterId"
      @viewData="handleGetSmartCards"
      @handleTableLoading="handleLoading"
    )
</template>

<script>
import { filterUtils } from "@utils/filterUtils";
import Pagination from "@components/_shared/Pagination";
import FilterCostCenterDropdown from "@components/_shared/FilterCostCenterDropdown";
import FilterStatus from "@components/_shared/FilterStatus";
import SearchWithButtons from "@components/_shared/SearchWithButtons";
import SmartCardsViewTable from "@components/smart_cards/SmartCardsViewTable";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";
import { ticketsReports } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";

export default {
  name: 'SmartCardsView',
  mixins: [general, routerUtils, filterUtils],
  components: {
    Pagination,
    FilterCostCenterDropdown,
    SearchWithButtons,
    SmartCardsViewTable,
    FilterStatus
  },

  data() {
    return {
      ticketsStatuses: [
        { name: "All", value: 'all' },
        { name: "Initiated", value: 'order_initiated' },
        { name: "Received", value: 'order_received' },
        { name: "Completed", value: 'order_completed' },
      ],
      search: "",
      activeName: 'smart_cards',
      viewName: 'smart_cards',

      // filters
      dateRange: [],
      selectedCostCenterId: "",
      selectedStatus: "all",
      selectedType: "all",

      smartCards: [],
      loading: false,
      dynamicViewsConstants
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {

    handleGetSmartCards(data) {
      this.smartCards = data
    },

    handleLoading(loading) {
      this.loading = loading
    },

    hideIcon(){
      return [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].includes(this.user.roles[0].name)
    },
  },
};
</script>

<style lang="scss" scoped>
.images {
  display: flex;
  align-items: center;

  .img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 8px;
  }

  .plus-icon {
    margin-left: 4px;
  }
}
</style>
